export const affirmations = [
  "Your outfit today: 11 out of 10",
  "Rating of your personality: ⭐️⭐️⭐️⭐️⭐️",
  " # of thumbs up you deserve: 👍👍",
  "How you make others feel: 😊",
  "Number of days you weren't enough: 0",
  "Should you go for it: Yes",
  "If you needed a sign: 🪧",
  "Things you got: This",
  "People that can do it: You",
  "Your worth: ♾",
  "Did you understand the assignment: ✅",
  "Your grade: 💯",
  "Things you shine like: 💎",
];

export const eights_hints = [
  {
    WORD: "CHAIRMAN",
    ALPHA: "AACHIMN",
    LETTERS: ["R"],
    INDICES: [4],
    HINTS: [6, 3, 1],
  },
  {
    WORD: "EXPENSES",
    ALPHA: "EEENSSX",
    LETTERS: ["P"],
    INDICES: [2],
    HINTS: [0, 4, 1],
  },
  {
    WORD: "GAMBLING",
    ALPHA: "ABGGILN",
    LETTERS: ["M"],
    INDICES: [2],
    HINTS: [3, 1, 0],
  },
  {
    WORD: "NATIONAL",
    ALPHA: "AAILNOT",
    LETTERS: ["N"],
    INDICES: [5],
    HINTS: [3, 4, 7],
  },
  {
    WORD: "SMALLEST",
    ALPHA: "ELLMSST",
    LETTERS: ["A"],
    INDICES: [2],
    HINTS: [5, 1, 7],
  },
  {
    WORD: "ASSESSED",
    ALPHA: "ADEESSS",
    LETTERS: ["S"],
    INDICES: [4],
    HINTS: [3, 0, 7],
  },
  {
    WORD: "MANUALLY",
    ALPHA: "AALMNUY",
    LETTERS: ["L"],
    INDICES: [6],
    HINTS: [2, 7, 3],
  },
  {
    WORD: "TOMATOES",
    ALPHA: "EMOOSTT",
    LETTERS: ["A"],
    INDICES: [3],
    HINTS: [5, 0, 6],
  },
  {
    WORD: "INSPIRED",
    ALPHA: "DEIIPRS",
    LETTERS: ["N"],
    INDICES: [1],
    HINTS: [4, 6, 3],
  },
  {
    WORD: "COUNTIES",
    ALPHA: "CEINSTU",
    LETTERS: ["O"],
    INDICES: [1],
    HINTS: [6, 3, 0],
  },
  {
    WORD: "COACHING",
    ALPHA: "ACGHINO",
    LETTERS: ["C"],
    INDICES: [3],
    HINTS: [7, 6, 2],
  },
  {
    WORD: "EARRINGS",
    ALPHA: "AEGINRS",
    LETTERS: ["R"],
    INDICES: [2],
    HINTS: [4, 7, 1],
  },
  {
    WORD: "TERRIBLE",
    ALPHA: "BEEILRT",
    LETTERS: ["R"],
    INDICES: [2],
    HINTS: [4, 1, 5],
  },
  {
    WORD: "ULTIMATE",
    ALPHA: "AEILTTU",
    LETTERS: ["M"],
    INDICES: [4],
    HINTS: [7, 6, 2],
  },
  {
    WORD: "ARRESTED",
    ALPHA: "ADERRST",
    LETTERS: ["E"],
    INDICES: [3],
    HINTS: [0, 4, 1],
  },
  {
    WORD: "PLEASURE",
    ALPHA: "AEELPRU",
    LETTERS: ["S"],
    INDICES: [4],
    HINTS: [1, 2, 0],
  },
  {
    WORD: "PROBLEMS",
    ALPHA: "BELMOPS",
    LETTERS: ["R"],
    INDICES: [1],
    HINTS: [4, 3, 6],
  },
  {
    WORD: "CARNIVAL",
    ALPHA: "AACILNR",
    LETTERS: ["V"],
    INDICES: [5],
    HINTS: [7, 1, 4],
  },
  {
    WORD: "LIFETIME",
    ALPHA: "EEFIILM",
    LETTERS: ["T"],
    INDICES: [4],
    HINTS: [2, 1, 3],
  },
  {
    WORD: "PERSONAL",
    ALPHA: "ALNOPRS",
    LETTERS: ["E"],
    INDICES: [1],
    HINTS: [2, 6, 5],
  },
  {
    WORD: "SALARIES",
    ALPHA: "AEILRSS",
    LETTERS: ["A"],
    INDICES: [1],
    HINTS: [5, 6, 2],
  },
  {
    WORD: "BROWSERS",
    ALPHA: "BERRSSW",
    LETTERS: ["O"],
    INDICES: [2],
    HINTS: [5, 0, 3],
  },
  {
    WORD: "STRAIGHT",
    ALPHA: "GHIRSTT",
    LETTERS: ["A"],
    INDICES: [3],
    HINTS: [6, 5, 4],
  },
  {
    WORD: "PARTICLE",
    ALPHA: "ACEILPR",
    LETTERS: ["T"],
    INDICES: [3],
    HINTS: [5, 4, 1],
  },
  {
    WORD: "CATALYST",
    ALPHA: "AACLSTY",
    LETTERS: ["T"],
    INDICES: [2],
    HINTS: [0, 3, 1],
  },
  {
    WORD: "CARRIERS",
    ALPHA: "ACEIRRS",
    LETTERS: ["R"],
    INDICES: [2],
    HINTS: [0, 1, 5],
  },
  {
    WORD: "INTEGRAL",
    ALPHA: "AEGILRT",
    LETTERS: ["N"],
    INDICES: [1],
    HINTS: [3, 7, 0],
  },
  {
    WORD: "MESSAGES",
    ALPHA: "EEGMSSS",
    LETTERS: ["A"],
    INDICES: [4],
    HINTS: [6, 2, 0],
  },
  {
    WORD: "PLAYLIST",
    ALPHA: "AILLPST",
    LETTERS: ["Y"],
    INDICES: [3],
    HINTS: [1, 0, 7],
  },
  {
    WORD: "PRINCESS",
    ALPHA: "EINPRSS",
    LETTERS: ["C"],
    INDICES: [4],
    HINTS: [2, 3, 1],
  },
  {
    WORD: "CONSISTS",
    ALPHA: "CINSSST",
    LETTERS: ["O"],
    INDICES: [1],
    HINTS: [4, 5, 2],
  },
  {
    WORD: "TRACKING",
    ALPHA: "CGIKNRT",
    LETTERS: ["A"],
    INDICES: [2],
    HINTS: [3, 5, 6],
  },
  {
    WORD: "DEMOCRAT",
    ALPHA: "ADEMORT",
    LETTERS: ["C"],
    INDICES: [4],
    HINTS: [7, 3, 5],
  },
  {
    WORD: "VIOLENCE",
    ALPHA: "CEILNOV",
    LETTERS: ["E"],
    INDICES: [4],
    HINTS: [6, 5, 2],
  },
  {
    WORD: "ATTACHED",
    ALPHA: "AACDETT",
    LETTERS: ["H"],
    INDICES: [5],
    HINTS: [1, 2, 7],
  },
  {
    WORD: "RELATION",
    ALPHA: "AILNORT",
    LETTERS: ["E"],
    INDICES: [1],
    HINTS: [3, 5, 6],
  },
  {
    WORD: "MARRIAGE",
    ALPHA: "AAEGIMR",
    LETTERS: ["R"],
    INDICES: [3],
    HINTS: [6, 1, 5],
  },
  {
    WORD: "INCLUDED",
    ALPHA: "CDDEINU",
    LETTERS: ["L"],
    INDICES: [3],
    HINTS: [4, 1, 7],
  },
  {
    WORD: "EQUALITY",
    ALPHA: "AELQTUY",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [2, 7, 0],
  },
  {
    WORD: "VERSIONS",
    ALPHA: "ENORSSV",
    LETTERS: ["I"],
    INDICES: [4],
    HINTS: [6, 7, 1],
  },
  {
    WORD: "PUBLICLY",
    ALPHA: "BCILLPY",
    LETTERS: ["U"],
    INDICES: [1],
    HINTS: [0, 7, 5],
  },
  {
    WORD: "TAXATION",
    ALPHA: "AANOTTX",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [3, 7, 0],
  },
  {
    WORD: "ROULETTE",
    ALPHA: "EELORTU",
    LETTERS: ["T"],
    INDICES: [5],
    HINTS: [0, 2, 4],
  },
  {
    WORD: "WHEREVER",
    ALPHA: "EEEHRRW",
    LETTERS: ["V"],
    INDICES: [5],
    HINTS: [4, 3, 2],
  },
  {
    WORD: "TOWNSHIP",
    ALPHA: "HINPSTW",
    LETTERS: ["O"],
    INDICES: [1],
    HINTS: [3, 2, 6],
  },
  {
    WORD: "ALLIANCE",
    ALPHA: "AACEILL",
    LETTERS: ["N"],
    INDICES: [5],
    HINTS: [7, 3, 4],
  },
  {
    WORD: "PREPARED",
    ALPHA: "ADEPPRR",
    LETTERS: ["E"],
    INDICES: [2],
    HINTS: [0, 1, 3],
  },
  {
    WORD: "DISPLAYS",
    ALPHA: "ADILPSS",
    LETTERS: ["Y"],
    INDICES: [6],
    HINTS: [2, 0, 5],
  },
  {
    WORD: "ANNOYING",
    ALPHA: "AGINNOY",
    LETTERS: ["N"],
    INDICES: [6],
    HINTS: [7, 5, 0],
  },
  {
    WORD: "SYMPTOMS",
    ALPHA: "MMPSSTY",
    LETTERS: ["O"],
    INDICES: [5],
    HINTS: [2, 1, 4],
  },
  {
    WORD: "DRINKING",
    ALPHA: "DGIKNNR",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [1, 3, 0],
  },
  {
    WORD: "BENEFITS",
    ALPHA: "BEEFNST",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [4, 0, 6],
  },
  {
    WORD: "REVIEWED",
    ALPHA: "DEEIRVW",
    LETTERS: ["E"],
    INDICES: [4],
    HINTS: [3, 0, 5],
  },
  {
    WORD: "PROSPECT",
    ALPHA: "CEOPPST",
    LETTERS: ["R"],
    INDICES: [1],
    HINTS: [7, 6, 4],
  },
  {
    WORD: "IMPERIAL",
    ALPHA: "AEIILMR",
    LETTERS: ["P"],
    INDICES: [2],
    HINTS: [3, 4, 0],
  },
  {
    WORD: "DIALOGUE",
    ALPHA: "ADEILOU",
    LETTERS: ["G"],
    INDICES: [5],
    HINTS: [1, 0, 3],
  },
  {
    WORD: "SPECIALS",
    ALPHA: "ACILPSS",
    LETTERS: ["E"],
    INDICES: [2],
    HINTS: [3, 4, 0],
  },
  {
    WORD: "HOLDINGS",
    ALPHA: "DGHILNS",
    LETTERS: ["O"],
    INDICES: [1],
    HINTS: [4, 5, 0],
  },
  {
    WORD: "STARRING",
    ALPHA: "AGINRRS",
    LETTERS: ["T"],
    INDICES: [1],
    HINTS: [2, 3, 4],
  },
  {
    WORD: "REALIZED",
    ALPHA: "ADEEILR",
    LETTERS: ["Z"],
    INDICES: [5],
    HINTS: [2, 6, 0],
  },
  {
    WORD: "HARDWARE",
    ALPHA: "AAEHRRW",
    LETTERS: ["D"],
    INDICES: [3],
    HINTS: [6, 0, 1],
  },
  {
    WORD: "VETERANS",
    ALPHA: "AENRSTV",
    LETTERS: ["E"],
    INDICES: [1],
    HINTS: [7, 0, 4],
  },
  {
    WORD: "EQUIPPED",
    ALPHA: "DEIPPQU",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [2, 5, 4],
  },
  {
    WORD: "FIRMWARE",
    ALPHA: "AEFIMRW",
    LETTERS: ["R"],
    INDICES: [6],
    HINTS: [4, 7, 3],
  },
  {
    WORD: "DOWNLOAD",
    ALPHA: "DDLNOOW",
    LETTERS: ["A"],
    INDICES: [6],
    HINTS: [3, 2, 0],
  },
  {
    WORD: "AVOIDING",
    ALPHA: "ADGIIOV",
    LETTERS: ["N"],
    INDICES: [6],
    HINTS: [4, 1, 3],
  },
  {
    WORD: "CONTRACT",
    ALPHA: "ACCNRTT",
    LETTERS: ["O"],
    INDICES: [1],
    HINTS: [6, 4, 5],
  },
  {
    WORD: "RESOLVED",
    ALPHA: "DELORSV",
    LETTERS: ["E"],
    INDICES: [1],
    HINTS: [4, 2, 0],
  },
  {
    WORD: "CRIMINAL",
    ALPHA: "ACILMNR",
    LETTERS: ["I"],
    INDICES: [4],
    HINTS: [7, 0, 5],
  },
  {
    WORD: "BRUNETTE",
    ALPHA: "BEENRTU",
    LETTERS: ["T"],
    INDICES: [6],
    HINTS: [2, 1, 3],
  },
  {
    WORD: "PROMISES",
    ALPHA: "EIOPRSS",
    LETTERS: ["M"],
    INDICES: [3],
    HINTS: [1, 6, 4],
  },
  {
    WORD: "FORMERLY",
    ALPHA: "EFLMRRY",
    LETTERS: ["O"],
    INDICES: [1],
    HINTS: [0, 6, 3],
  },
  {
    WORD: "ORDERING",
    ALPHA: "DGINORR",
    LETTERS: ["E"],
    INDICES: [3],
    HINTS: [2, 4, 1],
  },
  {
    WORD: "NEGATIVE",
    ALPHA: "AEGINTV",
    LETTERS: ["E"],
    INDICES: [1],
    HINTS: [6, 4, 0],
  },
  {
    WORD: "SURROUND",
    ALPHA: "DNORRSU",
    LETTERS: ["U"],
    INDICES: [1],
    HINTS: [7, 0, 6],
  },
  {
    WORD: "DISTANCE",
    ALPHA: "ADEINST",
    LETTERS: ["C"],
    INDICES: [6],
    HINTS: [3, 5, 1],
  },
  {
    WORD: "JUVENILE",
    ALPHA: "EEJLNUV",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [4, 2, 6],
  },
  {
    WORD: "DEFERRED",
    ALPHA: "DDEEERR",
    LETTERS: ["F"],
    INDICES: [2],
    HINTS: [4, 3, 0],
  },
  {
    WORD: "MEMBRANE",
    ALPHA: "ABEEMNR",
    LETTERS: ["M"],
    INDICES: [2],
    HINTS: [7, 3, 4],
  },
  {
    WORD: "TRANSMIT",
    ALPHA: "AIMRSTT",
    LETTERS: ["N"],
    INDICES: [3],
    HINTS: [6, 5, 4],
  },
  {
    WORD: "CHAMPION",
    ALPHA: "ACHIMNO",
    LETTERS: ["P"],
    INDICES: [4],
    HINTS: [2, 1, 7],
  },
  {
    WORD: "MULTIPLE",
    ALPHA: "EILLMPU",
    LETTERS: ["T"],
    INDICES: [3],
    HINTS: [5, 2, 1],
  },
  {
    WORD: "NOTIFIED",
    ALPHA: "DEFIINO",
    LETTERS: ["T"],
    INDICES: [2],
    HINTS: [5, 1, 7],
  },
  {
    WORD: "EPISODES",
    ALPHA: "EEIOPSS",
    LETTERS: ["D"],
    INDICES: [5],
    HINTS: [1, 7, 0],
  },
  {
    WORD: "PROGRESS",
    ALPHA: "EGOPRRS",
    LETTERS: ["S"],
    INDICES: [6],
    HINTS: [1, 3, 0],
  },
  {
    WORD: "ATHLETES",
    ALPHA: "AEEHSTT",
    LETTERS: ["L"],
    INDICES: [3],
    HINTS: [4, 2, 5],
  },
  {
    WORD: "ENABLING",
    ALPHA: "ABEGILN",
    LETTERS: ["N"],
    INDICES: [1],
    HINTS: [0, 7, 2],
  },
  {
    WORD: "RELATIVE",
    ALPHA: "AEEILRT",
    LETTERS: ["V"],
    INDICES: [6],
    HINTS: [3, 0, 2],
  },
  {
    WORD: "ANIMATED",
    ALPHA: "AADEIMN",
    LETTERS: ["T"],
    INDICES: [5],
    HINTS: [1, 4, 0],
  },
  {
    WORD: "INVOLVES",
    ALPHA: "EILOSVV",
    LETTERS: ["N"],
    INDICES: [1],
    HINTS: [6, 0, 3],
  },
  {
    WORD: "FAMILIES",
    ALPHA: "AEFILMS",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [1, 2, 0],
  },
  {
    WORD: "FLASHERS",
    ALPHA: "AEFHRSS",
    LETTERS: ["L"],
    INDICES: [1],
    HINTS: [5, 6, 2],
  },
  {
    WORD: "EARLIEST",
    ALPHA: "AEEILST",
    LETTERS: ["R"],
    INDICES: [2],
    HINTS: [0, 5, 4],
  },
  {
    WORD: "DRESSING",
    ALPHA: "DGINRSS",
    LETTERS: ["E"],
    INDICES: [2],
    HINTS: [7, 1, 3],
  },
  {
    WORD: "PRODUCED",
    ALPHA: "DDEOPRU",
    LETTERS: ["C"],
    INDICES: [5],
    HINTS: [0, 4, 1],
  },
  {
    WORD: "PORTIONS",
    ALPHA: "INOPRST",
    LETTERS: ["O"],
    INDICES: [5],
    HINTS: [7, 2, 4],
  },
  {
    WORD: "INTERVAL",
    ALPHA: "AEILNRV",
    LETTERS: ["T"],
    INDICES: [2],
    HINTS: [0, 1, 7],
  },
  {
    WORD: "STRICTLY",
    ALPHA: "CLRSTTY",
    LETTERS: ["I"],
    INDICES: [3],
    HINTS: [1, 4, 7],
  },
  {
    WORD: "PATTERNS",
    ALPHA: "AENPRST",
    LETTERS: ["T"],
    INDICES: [2],
    HINTS: [7, 1, 0],
  },
  {
    WORD: "DISCLOSE",
    ALPHA: "CDEILSS",
    LETTERS: ["O"],
    INDICES: [5],
    HINTS: [1, 0, 6],
  },
  {
    WORD: "SCIENCES",
    ALPHA: "CCEENSS",
    LETTERS: ["I"],
    INDICES: [2],
    HINTS: [4, 1, 3],
  },
  {
    WORD: "HANDBOOK",
    ALPHA: "BDHKNOO",
    LETTERS: ["A"],
    INDICES: [1],
    HINTS: [4, 0, 5],
  },
  {
    WORD: "COMPOSER",
    ALPHA: "CEMOOPR",
    LETTERS: ["S"],
    INDICES: [5],
    HINTS: [7, 0, 4],
  },
  {
    WORD: "CREATURE",
    ALPHA: "ACEERRU",
    LETTERS: ["T"],
    INDICES: [4],
    HINTS: [7, 1, 6],
  },
  {
    WORD: "PIPELINE",
    ALPHA: "EEILNPP",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [6, 7, 4],
  },
  {
    WORD: "REVISION",
    ALPHA: "EIINRSV",
    LETTERS: ["O"],
    INDICES: [6],
    HINTS: [4, 0, 7],
  },
  {
    WORD: "FRANKLIN",
    ALPHA: "AFILNNR",
    LETTERS: ["K"],
    INDICES: [4],
    HINTS: [0, 5, 1],
  },
  {
    WORD: "WEDDINGS",
    ALPHA: "DDEGNSW",
    LETTERS: ["I"],
    INDICES: [4],
    HINTS: [6, 3, 7],
  },
  {
    WORD: "ALTHOUGH",
    ALPHA: "AGHHLOT",
    LETTERS: ["U"],
    INDICES: [5],
    HINTS: [1, 2, 7],
  },
  {
    WORD: "DECLINED",
    ALPHA: "CDDEEIL",
    LETTERS: ["N"],
    INDICES: [5],
    HINTS: [3, 0, 1],
  },
  {
    WORD: "FIREWALL",
    ALPHA: "EFILLRW",
    LETTERS: ["A"],
    INDICES: [5],
    HINTS: [4, 0, 1],
  },
  {
    WORD: "SURVIVOR",
    ALPHA: "IORRSUV",
    LETTERS: ["V"],
    INDICES: [3],
    HINTS: [0, 7, 1],
  },
  {
    WORD: "FLOORING",
    ALPHA: "FGILNOR",
    LETTERS: ["O"],
    INDICES: [3],
    HINTS: [4, 6, 1],
  },
  {
    WORD: "MANAGING",
    ALPHA: "AGGIMNN",
    LETTERS: ["A"],
    INDICES: [3],
    HINTS: [2, 4, 7],
  },
  {
    WORD: "HUMIDITY",
    ALPHA: "HIIMTUY",
    LETTERS: ["D"],
    INDICES: [4],
    HINTS: [3, 6, 0],
  },
  {
    WORD: "PRESSURE",
    ALPHA: "EEPRSSU",
    LETTERS: ["R"],
    INDICES: [1],
    HINTS: [7, 4, 5],
  },
  {
    WORD: "OPERATED",
    ALPHA: "ADEEOPT",
    LETTERS: ["R"],
    INDICES: [3],
    HINTS: [0, 4, 6],
  },
  {
    WORD: "SYMPATHY",
    ALPHA: "HMPSTYY",
    LETTERS: ["A"],
    INDICES: [4],
    HINTS: [7, 2, 3],
  },
  {
    WORD: "WARNINGS",
    ALPHA: "AINNRSW",
    LETTERS: ["G"],
    INDICES: [6],
    HINTS: [7, 5, 4],
  },
  {
    WORD: "EXPANDED",
    ALPHA: "ADDEEPX",
    LETTERS: ["N"],
    INDICES: [4],
    HINTS: [6, 2, 3],
  },
  {
    WORD: "PRODUCES",
    ALPHA: "CDEPRSU",
    LETTERS: ["O"],
    INDICES: [2],
    HINTS: [7, 4, 3],
  },
  {
    WORD: "MAGAZINE",
    ALPHA: "AEGIMNZ",
    LETTERS: ["A"],
    INDICES: [1],
    HINTS: [5, 0, 6],
  },
  {
    WORD: "SLEEPING",
    ALPHA: "EEGILNS",
    LETTERS: ["P"],
    INDICES: [4],
    HINTS: [2, 1, 7],
  },
  {
    WORD: "PRODUCTS",
    ALPHA: "DOPRSTU",
    LETTERS: ["C"],
    INDICES: [5],
    HINTS: [0, 4, 6],
  },
  {
    WORD: "QUANTITY",
    ALPHA: "AINQTUY",
    LETTERS: ["T"],
    INDICES: [4],
    HINTS: [5, 1, 3],
  },
  {
    WORD: "OBTAINED",
    ALPHA: "ADEINOT",
    LETTERS: ["B"],
    INDICES: [1],
    HINTS: [4, 0, 2],
  },
  {
    WORD: "RESERVES",
    ALPHA: "EEERRSS",
    LETTERS: ["V"],
    INDICES: [5],
    HINTS: [2, 1, 7],
  },
  {
    WORD: "SPORTING",
    ALPHA: "GINORST",
    LETTERS: ["P"],
    INDICES: [1],
    HINTS: [7, 0, 6],
  },
  {
    WORD: "OFFERING",
    ALPHA: "EFFGNOR",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [6, 3, 7],
  },
  {
    WORD: "ANALYZES",
    ALPHA: "AAELNSZ",
    LETTERS: ["Y"],
    INDICES: [4],
    HINTS: [6, 7, 1],
  },
  {
    WORD: "SUPPLIES",
    ALPHA: "ILPPSSU",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [3, 5, 2],
  },
  {
    WORD: "COLONIAL",
    ALPHA: "CILLNOO",
    LETTERS: ["A"],
    INDICES: [6],
    HINTS: [7, 4, 2],
  },
  {
    WORD: "ACTIVELY",
    ALPHA: "ACEILTY",
    LETTERS: ["V"],
    INDICES: [4],
    HINTS: [7, 3, 2],
  },
  {
    WORD: "REACTION",
    ALPHA: "CEINORT",
    LETTERS: ["A"],
    INDICES: [2],
    HINTS: [0, 7, 6],
  },
  {
    WORD: "SESSIONS",
    ALPHA: "EINOSSS",
    LETTERS: ["S"],
    INDICES: [2],
    HINTS: [5, 4, 1],
  },
  {
    WORD: "HOMETOWN",
    ALPHA: "EHNOOTW",
    LETTERS: ["M"],
    INDICES: [2],
    HINTS: [6, 4, 3],
  },
  {
    WORD: "THREADED",
    ALPHA: "ADDEEHT",
    LETTERS: ["R"],
    INDICES: [2],
    HINTS: [5, 1, 4],
  },
  {
    WORD: "BEDROOMS",
    ALPHA: "BDEMOOS",
    LETTERS: ["R"],
    INDICES: [3],
    HINTS: [0, 2, 1],
  },
  {
    WORD: "SUPPORTS",
    ALPHA: "OPRSSTU",
    LETTERS: ["P"],
    INDICES: [3],
    HINTS: [4, 6, 7],
  },
  {
    WORD: "ENVELOPE",
    ALPHA: "EEELOPV",
    LETTERS: ["N"],
    INDICES: [1],
    HINTS: [4, 2, 0],
  },
  {
    WORD: "TREASURY",
    ALPHA: "AERRTUY",
    LETTERS: ["S"],
    INDICES: [4],
    HINTS: [2, 3, 7],
  },
  {
    WORD: "WORKSHOP",
    ALPHA: "HKOOPSW",
    LETTERS: ["R"],
    INDICES: [2],
    HINTS: [1, 3, 0],
  },
  {
    WORD: "MIDLANDS",
    ALPHA: "ADDLMNS",
    LETTERS: ["I"],
    INDICES: [1],
    HINTS: [7, 6, 4],
  },
  {
    WORD: "SPEECHES",
    ALPHA: "CEEEPSS",
    LETTERS: ["H"],
    INDICES: [5],
    HINTS: [0, 2, 6],
  },
  {
    WORD: "SUPERIOR",
    ALPHA: "EIOPRSU",
    LETTERS: ["R"],
    INDICES: [4],
    HINTS: [0, 6, 3],
  },
  {
    WORD: "VICTORIA",
    ALPHA: "ACIIRTV",
    LETTERS: ["O"],
    INDICES: [4],
    HINTS: [5, 3, 1],
  },
  {
    WORD: "STEERING",
    ALPHA: "EEGINRS",
    LETTERS: ["T"],
    INDICES: [1],
    HINTS: [7, 0, 5],
  },
  {
    WORD: "STICKERS",
    ALPHA: "CIKRSST",
    LETTERS: ["E"],
    INDICES: [5],
    HINTS: [6, 0, 2],
  },
  {
    WORD: "POLITICS",
    ALPHA: "CIILOPS",
    LETTERS: ["T"],
    INDICES: [4],
    HINTS: [6, 0, 7],
  },
  {
    WORD: "MODIFIED",
    ALPHA: "DEFIIMO",
    LETTERS: ["D"],
    INDICES: [2],
    HINTS: [3, 6, 0],
  },
  {
    WORD: "SUPPOSED",
    ALPHA: "DEPPSSU",
    LETTERS: ["O"],
    INDICES: [4],
    HINTS: [1, 5, 0],
  },
  {
    WORD: "REMEDIES",
    ALPHA: "DEEIMRS",
    LETTERS: ["E"],
    INDICES: [3],
    HINTS: [7, 0, 4],
  },
  {
    WORD: "REVEALED",
    ALPHA: "ADEEERV",
    LETTERS: ["L"],
    INDICES: [5],
    HINTS: [6, 4, 3],
  },
  {
    WORD: "PASSPORT",
    ALPHA: "OPPRSST",
    LETTERS: ["A"],
    INDICES: [1],
    HINTS: [4, 7, 6],
  },
  {
    WORD: "SLIGHTLY",
    ALPHA: "GHILSTY",
    LETTERS: ["L"],
    INDICES: [1],
    HINTS: [7, 3, 4],
  },
  {
    WORD: "RESEARCH",
    ALPHA: "ACEEHRR",
    LETTERS: ["S"],
    INDICES: [2],
    HINTS: [0, 7, 3],
  },
  {
    WORD: "STAFFING",
    ALPHA: "AFFGINS",
    LETTERS: ["T"],
    INDICES: [1],
    HINTS: [0, 7, 6],
  },
  {
    WORD: "LICENSES",
    ALPHA: "EEILNSS",
    LETTERS: ["C"],
    INDICES: [2],
    HINTS: [3, 1, 7],
  },
  {
    WORD: "AUDIENCE",
    ALPHA: "ACDEEIU",
    LETTERS: ["N"],
    INDICES: [5],
    HINTS: [2, 0, 3],
  },
  {
    WORD: "UNTITLED",
    ALPHA: "DEILNTU",
    LETTERS: ["T"],
    INDICES: [4],
    HINTS: [7, 6, 0],
  },
  {
    WORD: "CRITERIA",
    ALPHA: "ACEIRRT",
    LETTERS: ["I"],
    INDICES: [2],
    HINTS: [4, 0, 5],
  },
  {
    WORD: "WRAPPING",
    ALPHA: "AGNPPRW",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [6, 2, 0],
  },
  {
    WORD: "LAUGHING",
    ALPHA: "AGGILNU",
    LETTERS: ["H"],
    INDICES: [4],
    HINTS: [3, 0, 1],
  },
  {
    WORD: "SCANNERS",
    ALPHA: "ACENNSS",
    LETTERS: ["R"],
    INDICES: [6],
    HINTS: [2, 1, 4],
  },
  {
    WORD: "RELATING",
    ALPHA: "AGILNRT",
    LETTERS: ["E"],
    INDICES: [1],
    HINTS: [0, 7, 4],
  },
  {
    WORD: "COMPARED",
    ALPHA: "ACDEMOP",
    LETTERS: ["R"],
    INDICES: [5],
    HINTS: [1, 7, 4],
  },
  {
    WORD: "ENTRANCE",
    ALPHA: "ACEENRT",
    LETTERS: ["N"],
    INDICES: [5],
    HINTS: [0, 6, 7],
  },
  {
    WORD: "SOLDIERS",
    ALPHA: "DEIORSS",
    LETTERS: ["L"],
    INDICES: [2],
    HINTS: [6, 0, 5],
  },
  {
    WORD: "TRAILERS",
    ALPHA: "AELRRST",
    LETTERS: ["I"],
    INDICES: [3],
    HINTS: [1, 7, 2],
  },
  {
    WORD: "COCKTAIL",
    ALPHA: "CCIKLOT",
    LETTERS: ["A"],
    INDICES: [5],
    HINTS: [6, 7, 2],
  },
  {
    WORD: "THEOLOGY",
    ALPHA: "EGLOOTY",
    LETTERS: ["H"],
    INDICES: [1],
    HINTS: [2, 5, 3],
  },
  {
    WORD: "PROJECTS",
    ALPHA: "CEJOPST",
    LETTERS: ["R"],
    INDICES: [1],
    HINTS: [3, 0, 2],
  },
  {
    WORD: "REGIONAL",
    ALPHA: "AEGILOR",
    LETTERS: ["N"],
    INDICES: [5],
    HINTS: [3, 2, 7],
  },
  {
    WORD: "DRAINAGE",
    ALPHA: "ADEGINR",
    LETTERS: ["A"],
    INDICES: [2],
    HINTS: [7, 3, 6],
  },
  {
    WORD: "PATIENTS",
    ALPHA: "AEIPSTT",
    LETTERS: ["N"],
    INDICES: [5],
    HINTS: [7, 6, 3],
  },
  {
    WORD: "CONTINUE",
    ALPHA: "CEINOTU",
    LETTERS: ["N"],
    INDICES: [2],
    HINTS: [7, 1, 3],
  },
  {
    WORD: "CULTURAL",
    ALPHA: "ACLLRUU",
    LETTERS: ["T"],
    INDICES: [3],
    HINTS: [1, 2, 5],
  },
  {
    WORD: "POSSIBLE",
    ALPHA: "BELOPSS",
    LETTERS: ["I"],
    INDICES: [4],
    HINTS: [7, 5, 2],
  },
  {
    WORD: "COSTUMES",
    ALPHA: "CEMOSST",
    LETTERS: ["U"],
    INDICES: [4],
    HINTS: [2, 3, 1],
  },
  {
    WORD: "CHILDREN",
    ALPHA: "CEHILNR",
    LETTERS: ["D"],
    INDICES: [4],
    HINTS: [2, 6, 5],
  },
  {
    WORD: "ANNOUNCE",
    ALPHA: "AENNNOU",
    LETTERS: ["C"],
    INDICES: [6],
    HINTS: [1, 7, 0],
  },
  {
    WORD: "REPORTER",
    ALPHA: "EEOPRRT",
    LETTERS: ["R"],
    INDICES: [4],
    HINTS: [6, 3, 5],
  },
  {
    WORD: "QUESTION",
    ALPHA: "EINOQTU",
    LETTERS: ["S"],
    INDICES: [3],
    HINTS: [5, 0, 7],
  },
  {
    WORD: "DECLARED",
    ALPHA: "ACDDELR",
    LETTERS: ["E"],
    INDICES: [1],
    HINTS: [2, 5, 3],
  },
  {
    WORD: "COUNTERS",
    ALPHA: "CENORST",
    LETTERS: ["U"],
    INDICES: [2],
    HINTS: [7, 5, 0],
  },
  {
    WORD: "TOGETHER",
    ALPHA: "EEGORTT",
    LETTERS: ["H"],
    INDICES: [5],
    HINTS: [6, 2, 7],
  },
  {
    WORD: "MEETINGS",
    ALPHA: "EEGIMST",
    LETTERS: ["N"],
    INDICES: [5],
    HINTS: [7, 6, 0],
  },
  {
    WORD: "INTERNET",
    ALPHA: "EINNRTT",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [1, 0, 2],
  },
  {
    WORD: "SEXUALLY",
    ALPHA: "AELLSUY",
    LETTERS: ["X"],
    INDICES: [2],
    HINTS: [6, 3, 0],
  },
  {
    WORD: "ADAPTIVE",
    ALPHA: "AADEITV",
    LETTERS: ["P"],
    INDICES: [3],
    HINTS: [4, 0, 6],
  },
  {
    WORD: "INDUSTRY",
    ALPHA: "DIRSTUY",
    LETTERS: ["N"],
    INDICES: [1],
    HINTS: [5, 7, 6],
  },
  {
    WORD: "PROMOTES",
    ALPHA: "EMOPRST",
    LETTERS: ["O"],
    INDICES: [4],
    HINTS: [3, 7, 0],
  },
  {
    WORD: "MEDICINE",
    ALPHA: "CDEEIMN",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [2, 1, 4],
  },
  {
    WORD: "TREATING",
    ALPHA: "AEGIRTT",
    LETTERS: ["N"],
    INDICES: [6],
    HINTS: [2, 7, 0],
  },
  {
    WORD: "CATEGORY",
    ALPHA: "CEGORTY",
    LETTERS: ["A"],
    INDICES: [1],
    HINTS: [0, 4, 5],
  },
  {
    WORD: "DOMINANT",
    ALPHA: "ADMNNOT",
    LETTERS: ["I"],
    INDICES: [3],
    HINTS: [6, 0, 1],
  },
  {
    WORD: "AGENCIES",
    ALPHA: "ACEGINS",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [3, 0, 4],
  },
  {
    WORD: "VARIABLE",
    ALPHA: "ABEILRV",
    LETTERS: ["A"],
    INDICES: [1],
    HINTS: [6, 0, 3],
  },
  {
    WORD: "INCLUDES",
    ALPHA: "CDEILNS",
    LETTERS: ["U"],
    INDICES: [4],
    HINTS: [5, 6, 1],
  },
  {
    WORD: "UPLOADED",
    ALPHA: "ADDELPU",
    LETTERS: ["O"],
    INDICES: [3],
    HINTS: [0, 5, 2],
  },
  {
    WORD: "EXPLAINS",
    ALPHA: "AEILNPS",
    LETTERS: ["X"],
    INDICES: [1],
    HINTS: [3, 7, 6],
  },
  {
    WORD: "NEIGHBOR",
    ALPHA: "BEHINOR",
    LETTERS: ["G"],
    INDICES: [3],
    HINTS: [1, 5, 6],
  },
  {
    WORD: "COUNCILS",
    ALPHA: "CCILOSU",
    LETTERS: ["N"],
    INDICES: [3],
    HINTS: [7, 2, 4],
  },
  {
    WORD: "ORIENTAL",
    ALPHA: "AEILNOT",
    LETTERS: ["R"],
    INDICES: [1],
    HINTS: [2, 3, 4],
  },
  {
    WORD: "APPEARED",
    ALPHA: "AADEPPR",
    LETTERS: ["E"],
    INDICES: [3],
    HINTS: [0, 1, 5],
  },
  {
    WORD: "INSIGHTS",
    ALPHA: "GHIINSS",
    LETTERS: ["T"],
    INDICES: [6],
    HINTS: [3, 1, 5],
  },
  {
    WORD: "COMPOUND",
    ALPHA: "CDNOOPU",
    LETTERS: ["M"],
    INDICES: [2],
    HINTS: [6, 1, 7],
  },
  {
    WORD: "NETWORKS",
    ALPHA: "EKNORSW",
    LETTERS: ["T"],
    INDICES: [2],
    HINTS: [6, 7, 4],
  },
  {
    WORD: "SUPPLIER",
    ALPHA: "ELPPRSU",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [2, 4, 1],
  },
  {
    WORD: "CLEANING",
    ALPHA: "ACEGINN",
    LETTERS: ["L"],
    INDICES: [1],
    HINTS: [7, 0, 5],
  },
  {
    WORD: "BASELINE",
    ALPHA: "ABEEINS",
    LETTERS: ["L"],
    INDICES: [4],
    HINTS: [5, 7, 1],
  },
  {
    WORD: "PLASTICS",
    ALPHA: "AILPSST",
    LETTERS: ["C"],
    INDICES: [6],
    HINTS: [7, 0, 4],
  },
  {
    WORD: "ACADEMIC",
    ALPHA: "AACCDEM",
    LETTERS: ["I"],
    INDICES: [6],
    HINTS: [1, 2, 7],
  },
  {
    WORD: "ABSTRACT",
    ALPHA: "AABCRST",
    LETTERS: ["T"],
    INDICES: [3],
    HINTS: [6, 4, 2],
  },
  {
    WORD: "ACTIVITY",
    ALPHA: "ACIITTY",
    LETTERS: ["V"],
    INDICES: [4],
    HINTS: [3, 6, 7],
  },
  {
    WORD: "FEELINGS",
    ALPHA: "EFGILNS",
    LETTERS: ["E"],
    INDICES: [2],
    HINTS: [5, 4, 0],
  },
  {
    WORD: "CLICKING",
    ALPHA: "CCGIKLN",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [0, 6, 3],
  },
  {
    WORD: "UNLIKELY",
    ALPHA: "EILLNUY",
    LETTERS: ["K"],
    INDICES: [4],
    HINTS: [3, 7, 1],
  },
  {
    WORD: "CAPACITY",
    ALPHA: "ACCIPTY",
    LETTERS: ["A"],
    INDICES: [3],
    HINTS: [5, 0, 7],
  },
  {
    WORD: "CYLINDER",
    ALPHA: "CDELNRY",
    LETTERS: ["I"],
    INDICES: [3],
    HINTS: [1, 5, 2],
  },
  {
    WORD: "PERFORMS",
    ALPHA: "FMOPRRS",
    LETTERS: ["E"],
    INDICES: [1],
    HINTS: [5, 6, 7],
  },
  {
    WORD: "OCCASION",
    ALPHA: "ACCINOO",
    LETTERS: ["S"],
    INDICES: [4],
    HINTS: [0, 2, 3],
  },
  {
    WORD: "PLANNING",
    ALPHA: "AGILNNP",
    LETTERS: ["N"],
    INDICES: [6],
    HINTS: [5, 7, 2],
  },
  {
    WORD: "PLAYBACK",
    ALPHA: "ABCKLPY",
    LETTERS: ["A"],
    INDICES: [2],
    HINTS: [6, 1, 0],
  },
  {
    WORD: "CHECKOUT",
    ALPHA: "CCEHKTU",
    LETTERS: ["O"],
    INDICES: [5],
    HINTS: [0, 2, 6],
  },
  {
    WORD: "STUNNING",
    ALPHA: "GINNNSU",
    LETTERS: ["T"],
    INDICES: [1],
    HINTS: [4, 0, 2],
  },
  {
    WORD: "FAVORITE",
    ALPHA: "AEFIRTV",
    LETTERS: ["O"],
    INDICES: [3],
    HINTS: [4, 2, 5],
  },
  {
    WORD: "MACHINES",
    ALPHA: "ACEIMNS",
    LETTERS: ["H"],
    INDICES: [3],
    HINTS: [7, 1, 6],
  },
  {
    WORD: "POSITION",
    ALPHA: "IINOPST",
    LETTERS: ["O"],
    INDICES: [6],
    HINTS: [2, 5, 4],
  },
  {
    WORD: "MAINLAND",
    ALPHA: "ADILMNN",
    LETTERS: ["A"],
    INDICES: [5],
    HINTS: [2, 0, 3],
  },
  {
    WORD: "EMBEDDED",
    ALPHA: "BDDDEEM",
    LETTERS: ["E"],
    INDICES: [3],
    HINTS: [7, 1, 2],
  },
  {
    WORD: "IMPORTED",
    ALPHA: "DEIOPRT",
    LETTERS: ["M"],
    INDICES: [1],
    HINTS: [2, 5, 7],
  },
  {
    WORD: "INDICATE",
    ALPHA: "ACEIINT",
    LETTERS: ["D"],
    INDICES: [2],
    HINTS: [3, 7, 0],
  },
  {
    WORD: "RECOVERY",
    ALPHA: "CEEORVY",
    LETTERS: ["R"],
    INDICES: [6],
    HINTS: [3, 1, 2],
  },
  {
    WORD: "GENERATE",
    ALPHA: "AEEEGRT",
    LETTERS: ["N"],
    INDICES: [2],
    HINTS: [1, 3, 0],
  },
  {
    WORD: "FEATURES",
    ALPHA: "EEFRSTU",
    LETTERS: ["A"],
    INDICES: [2],
    HINTS: [6, 7, 5],
  },
  {
    WORD: "ATTENDED",
    ALPHA: "ADEENTT",
    LETTERS: ["D"],
    INDICES: [5],
    HINTS: [0, 2, 1],
  },
  {
    WORD: "AIRCRAFT",
    ALPHA: "AACFRRT",
    LETTERS: ["I"],
    INDICES: [1],
    HINTS: [3, 6, 7],
  },
  {
    WORD: "COTTAGES",
    ALPHA: "ACEGOST",
    LETTERS: ["T"],
    INDICES: [3],
    HINTS: [0, 7, 4],
  },
  {
    WORD: "MODELING",
    ALPHA: "DEGIMNO",
    LETTERS: ["L"],
    INDICES: [4],
    HINTS: [2, 5, 3],
  },
  {
    WORD: "PROTOCOL",
    ALPHA: "CLOOOPR",
    LETTERS: ["T"],
    INDICES: [3],
    HINTS: [6, 7, 0],
  },
  {
    WORD: "ARTICLES",
    ALPHA: "ACEILRS",
    LETTERS: ["T"],
    INDICES: [2],
    HINTS: [1, 6, 7],
  },
  {
    WORD: "DATABASE",
    ALPHA: "AAABDES",
    LETTERS: ["T"],
    INDICES: [2],
    HINTS: [5, 1, 7],
  },
  {
    WORD: "RETAILER",
    ALPHA: "AEILRRT",
    LETTERS: ["E"],
    INDICES: [1],
    HINTS: [7, 4, 0],
  },
  {
    WORD: "INFORMED",
    ALPHA: "DEFIMNR",
    LETTERS: ["O"],
    INDICES: [3],
    HINTS: [7, 1, 6],
  },
  {
    WORD: "EMISSION",
    ALPHA: "EIMNOSS",
    LETTERS: ["I"],
    INDICES: [2],
    HINTS: [1, 3, 4],
  },
  {
    WORD: "ENCODING",
    ALPHA: "DEGINNO",
    LETTERS: ["C"],
    INDICES: [2],
    HINTS: [6, 3, 7],
  },
  {
    WORD: "ARTISTIC",
    ALPHA: "ACIRSTT",
    LETTERS: ["I"],
    INDICES: [6],
    HINTS: [4, 5, 0],
  },
  {
    WORD: "SEARCHES",
    ALPHA: "ACEEHSS",
    LETTERS: ["R"],
    INDICES: [3],
    HINTS: [1, 2, 4],
  },
  {
    WORD: "SEASONAL",
    ALPHA: "AAELOSS",
    LETTERS: ["N"],
    INDICES: [5],
    HINTS: [0, 6, 7],
  },
  {
    WORD: "INDIRECT",
    ALPHA: "CDIINRT",
    LETTERS: ["E"],
    INDICES: [5],
    HINTS: [6, 4, 0],
  },
  {
    WORD: "BROADWAY",
    ALPHA: "AABDRWY",
    LETTERS: ["O"],
    INDICES: [2],
    HINTS: [4, 7, 3],
  },
  {
    WORD: "STOPPING",
    ALPHA: "GINOPST",
    LETTERS: ["P"],
    INDICES: [4],
    HINTS: [2, 6, 7],
  },
  {
    WORD: "OPTIONAL",
    ALPHA: "ALNOOPT",
    LETTERS: ["I"],
    INDICES: [3],
    HINTS: [7, 4, 0],
  },
  {
    WORD: "SWINGERS",
    ALPHA: "EGIRSSW",
    LETTERS: ["N"],
    INDICES: [3],
    HINTS: [5, 2, 0],
  },
  {
    WORD: "SUBJECTS",
    ALPHA: "BCEJSSU",
    LETTERS: ["T"],
    INDICES: [6],
    HINTS: [3, 1, 2],
  },
  {
    WORD: "YOURSELF",
    ALPHA: "EFLORUY",
    LETTERS: ["S"],
    INDICES: [4],
    HINTS: [5, 6, 7],
  },
  {
    WORD: "DISCRETE",
    ALPHA: "CDEEIRS",
    LETTERS: ["T"],
    INDICES: [6],
    HINTS: [3, 2, 5],
  },
  {
    WORD: "ADVISORY",
    ALPHA: "ADIORSY",
    LETTERS: ["V"],
    INDICES: [2],
    HINTS: [0, 7, 3],
  },
  {
    WORD: "LOCATION",
    ALPHA: "AILNOOT",
    LETTERS: ["C"],
    INDICES: [2],
    HINTS: [4, 7, 5],
  },
  {
    WORD: "RESPONSE",
    ALPHA: "EENOPRS",
    LETTERS: ["S"],
    INDICES: [6],
    HINTS: [7, 3, 5],
  },
  {
    WORD: "BLOGGERS",
    ALPHA: "BEGLORS",
    LETTERS: ["G"],
    INDICES: [4],
    HINTS: [2, 7, 1],
  },
  {
    WORD: "CASHIERS",
    ALPHA: "ACHIRSS",
    LETTERS: ["E"],
    INDICES: [5],
    HINTS: [3, 2, 0],
  },
  {
    WORD: "ANYTHING",
    ALPHA: "AGHNNTY",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [0, 4, 6],
  },
  {
    WORD: "SOUTHERN",
    ALPHA: "EHNORSU",
    LETTERS: ["T"],
    INDICES: [3],
    HINTS: [2, 0, 7],
  },
  {
    WORD: "COMMENTS",
    ALPHA: "CEMNOST",
    LETTERS: ["M"],
    INDICES: [3],
    HINTS: [0, 5, 4],
  },
  {
    WORD: "SHOPPING",
    ALPHA: "GHNOPPS",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [1, 0, 6],
  },
  {
    WORD: "HIGHLAND",
    ALPHA: "ADGHHIL",
    LETTERS: ["N"],
    INDICES: [6],
    HINTS: [5, 3, 2],
  },
  {
    WORD: "SEARCHED",
    ALPHA: "ACDEHRS",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [5, 7, 3],
  },
  {
    WORD: "ATTACKED",
    ALPHA: "AACDEKT",
    LETTERS: ["T"],
    INDICES: [2],
    HINTS: [5, 0, 6],
  },
  {
    WORD: "DEFINING",
    ALPHA: "DEFGINN",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [4, 6, 1],
  },
  {
    WORD: "DEVELOPS",
    ALPHA: "DEELPSV",
    LETTERS: ["O"],
    INDICES: [5],
    HINTS: [4, 3, 6],
  },
  {
    WORD: "MEDIEVAL",
    ALPHA: "AEEILMV",
    LETTERS: ["D"],
    INDICES: [2],
    HINTS: [3, 4, 7],
  },
  {
    WORD: "TRIANGLE",
    ALPHA: "AEGILRT",
    LETTERS: ["N"],
    INDICES: [4],
    HINTS: [6, 3, 1],
  },
  {
    WORD: "SOFTBALL",
    ALPHA: "ABFLOST",
    LETTERS: ["L"],
    INDICES: [6],
    HINTS: [5, 4, 1],
  },
  {
    WORD: "CEREMONY",
    ALPHA: "CEEMNRY",
    LETTERS: ["O"],
    INDICES: [5],
    HINTS: [4, 1, 2],
  },
  {
    WORD: "WRITINGS",
    ALPHA: "GIINRSW",
    LETTERS: ["T"],
    INDICES: [3],
    HINTS: [6, 4, 7],
  },
  {
    WORD: "BOOKINGS",
    ALPHA: "BGIKOOS",
    LETTERS: ["N"],
    INDICES: [5],
    HINTS: [2, 1, 3],
  },
  {
    WORD: "POTATOES",
    ALPHA: "AOOPSTT",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [1, 4, 3],
  },
  {
    WORD: "SOMEWHAT",
    ALPHA: "AEMOSTW",
    LETTERS: ["H"],
    INDICES: [5],
    HINTS: [2, 1, 4],
  },
  {
    WORD: "CREATIVE",
    ALPHA: "ACEIRTV",
    LETTERS: ["E"],
    INDICES: [2],
    HINTS: [6, 1, 5],
  },
  {
    WORD: "SECURELY",
    ALPHA: "CEELRSY",
    LETTERS: ["U"],
    INDICES: [3],
    HINTS: [6, 5, 1],
  },
  {
    WORD: "OUTREACH",
    ALPHA: "ACHORTU",
    LETTERS: ["E"],
    INDICES: [4],
    HINTS: [6, 2, 5],
  },
  {
    WORD: "VEHICLES",
    ALPHA: "CEEILSV",
    LETTERS: ["H"],
    INDICES: [2],
    HINTS: [6, 7, 1],
  },
  {
    WORD: "PRESSING",
    ALPHA: "GINPRSS",
    LETTERS: ["E"],
    INDICES: [2],
    HINTS: [7, 1, 3],
  },
  {
    WORD: "LAUNCHED",
    ALPHA: "ACDELNU",
    LETTERS: ["H"],
    INDICES: [5],
    HINTS: [4, 7, 3],
  },
  {
    WORD: "SHIPPING",
    ALPHA: "GHINPPS",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [0, 3, 4],
  },
  {
    WORD: "PROTEINS",
    ALPHA: "INOPRST",
    LETTERS: ["E"],
    INDICES: [4],
    HINTS: [3, 5, 7],
  },
  {
    WORD: "OUTLINED",
    ALPHA: "DEILNOT",
    LETTERS: ["U"],
    INDICES: [1],
    HINTS: [5, 2, 3],
  },
  {
    WORD: "CONTAINS",
    ALPHA: "ACINNOS",
    LETTERS: ["T"],
    INDICES: [3],
    HINTS: [0, 2, 5],
  },
  {
    WORD: "CONCERTS",
    ALPHA: "CCNORST",
    LETTERS: ["E"],
    INDICES: [4],
    HINTS: [7, 0, 6],
  },
  {
    WORD: "ACQUIRED",
    ALPHA: "ACDEIQR",
    LETTERS: ["U"],
    INDICES: [3],
    HINTS: [5, 0, 1],
  },
  {
    WORD: "EXPLICIT",
    ALPHA: "EIILPTX",
    LETTERS: ["C"],
    INDICES: [5],
    HINTS: [4, 6, 3],
  },
  {
    WORD: "HEADLINE",
    ALPHA: "ADEHILN",
    LETTERS: ["E"],
    INDICES: [1],
    HINTS: [4, 6, 0],
  },
  {
    WORD: "ANALYSIS",
    ALPHA: "AAILSSY",
    LETTERS: ["N"],
    INDICES: [1],
    HINTS: [2, 0, 6],
  },
  {
    WORD: "FAMILIAR",
    ALPHA: "AFIILMR",
    LETTERS: ["A"],
    INDICES: [6],
    HINTS: [7, 4, 2],
  },
  {
    WORD: "WARCRAFT",
    ALPHA: "AAFRRTW",
    LETTERS: ["C"],
    INDICES: [3],
    HINTS: [1, 7, 5],
  },
  {
    WORD: "OUTCOMES",
    ALPHA: "CMOOSTU",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [5, 4, 1],
  },
  {
    WORD: "CREATION",
    ALPHA: "CEINORT",
    LETTERS: ["A"],
    INDICES: [3],
    HINTS: [4, 6, 5],
  },
  {
    WORD: "EARNINGS",
    ALPHA: "AEGINNS",
    LETTERS: ["R"],
    INDICES: [2],
    HINTS: [6, 0, 7],
  },
  {
    WORD: "REPEATED",
    ALPHA: "DEEEPRT",
    LETTERS: ["A"],
    INDICES: [4],
    HINTS: [1, 6, 5],
  },
  {
    WORD: "WEEKENDS",
    ALPHA: "DEEEKSW",
    LETTERS: ["N"],
    INDICES: [5],
    HINTS: [1, 0, 2],
  },
  {
    WORD: "ANTIQUES",
    ALPHA: "AEIQSTU",
    LETTERS: ["N"],
    INDICES: [1],
    HINTS: [3, 4, 2],
  },
  {
    WORD: "SURGEONS",
    ALPHA: "EGNORSS",
    LETTERS: ["U"],
    INDICES: [1],
    HINTS: [2, 5, 7],
  },
  {
    WORD: "CONCLUDE",
    ALPHA: "CCDELOU",
    LETTERS: ["N"],
    INDICES: [2],
    HINTS: [5, 7, 1],
  },
  {
    WORD: "PROVIDER",
    ALPHA: "DEIPRRV",
    LETTERS: ["O"],
    INDICES: [2],
    HINTS: [7, 0, 4],
  },
  {
    WORD: "REFERRAL",
    ALPHA: "AEELRRR",
    LETTERS: ["F"],
    INDICES: [2],
    HINTS: [1, 6, 4],
  },
  {
    WORD: "COMMANDS",
    ALPHA: "ACDMMNS",
    LETTERS: ["O"],
    INDICES: [1],
    HINTS: [0, 3, 2],
  },
  {
    WORD: "ORGANIZE",
    ALPHA: "AEINORZ",
    LETTERS: ["G"],
    INDICES: [2],
    HINTS: [5, 6, 1],
  },
  {
    WORD: "MATCHING",
    ALPHA: "ACGHIMT",
    LETTERS: ["N"],
    INDICES: [6],
    HINTS: [0, 4, 1],
  },
  {
    WORD: "CELLULAR",
    ALPHA: "ACELLRU",
    LETTERS: ["L"],
    INDICES: [3],
    HINTS: [4, 0, 1],
  },
  {
    WORD: "HOMELESS",
    ALPHA: "EHLMOSS",
    LETTERS: ["E"],
    INDICES: [3],
    HINTS: [4, 2, 7],
  },
  {
    WORD: "PARTNERS",
    ALPHA: "AENPRST",
    LETTERS: ["R"],
    INDICES: [6],
    HINTS: [1, 3, 0],
  },
  {
    WORD: "LINGERIE",
    ALPHA: "EGIILNR",
    LETTERS: ["E"],
    INDICES: [4],
    HINTS: [2, 6, 5],
  },
  {
    WORD: "COLORADO",
    ALPHA: "ACDOOOR",
    LETTERS: ["L"],
    INDICES: [2],
    HINTS: [5, 0, 6],
  },
  {
    WORD: "ORDINARY",
    ALPHA: "DINORRY",
    LETTERS: ["A"],
    INDICES: [5],
    HINTS: [0, 6, 4],
  },
  {
    WORD: "COVERING",
    ALPHA: "CEGIORV",
    LETTERS: ["N"],
    INDICES: [6],
    HINTS: [4, 7, 0],
  },
  {
    WORD: "FUNCTION",
    ALPHA: "FINNOTU",
    LETTERS: ["C"],
    INDICES: [3],
    HINTS: [6, 7, 1],
  },
  {
    WORD: "EXTERIOR",
    ALPHA: "EIORRTX",
    LETTERS: ["E"],
    INDICES: [3],
    HINTS: [6, 2, 5],
  },
  {
    WORD: "CHEAPEST",
    ALPHA: "ACEEHPT",
    LETTERS: ["S"],
    INDICES: [6],
    HINTS: [3, 4, 2],
  },
  {
    WORD: "VACATION",
    ALPHA: "AACINOV",
    LETTERS: ["T"],
    INDICES: [4],
    HINTS: [2, 6, 3],
  },
  {
    WORD: "JUDICIAL",
    ALPHA: "CDIIJLU",
    LETTERS: ["A"],
    INDICES: [6],
    HINTS: [4, 0, 1],
  },
  {
    WORD: "BUILDING",
    ALPHA: "BDGIINU",
    LETTERS: ["L"],
    INDICES: [3],
    HINTS: [2, 6, 5],
  },
  {
    WORD: "REPRINTS",
    ALPHA: "EINRRST",
    LETTERS: ["P"],
    INDICES: [2],
    HINTS: [0, 6, 5],
  },
  {
    WORD: "HUMANITY",
    ALPHA: "AHIMNUY",
    LETTERS: ["T"],
    INDICES: [6],
    HINTS: [2, 4, 0],
  },
  {
    WORD: "SEPARATE",
    ALPHA: "AAEPRST",
    LETTERS: ["E"],
    INDICES: [1],
    HINTS: [0, 6, 5],
  },
  {
    WORD: "SYNDROME",
    ALPHA: "DEMORSY",
    LETTERS: ["N"],
    INDICES: [2],
    HINTS: [3, 7, 6],
  },
  {
    WORD: "ENROLLED",
    ALPHA: "DEELLOR",
    LETTERS: ["N"],
    INDICES: [1],
    HINTS: [4, 0, 2],
  },
  {
    WORD: "SYNOPSIS",
    ALPHA: "INOPSSS",
    LETTERS: ["Y"],
    INDICES: [1],
    HINTS: [7, 0, 3],
  },
  {
    WORD: "SOLUTION",
    ALPHA: "INOOSTU",
    LETTERS: ["L"],
    INDICES: [2],
    HINTS: [1, 6, 3],
  },
  {
    WORD: "INTIMATE",
    ALPHA: "AEIINTT",
    LETTERS: ["M"],
    INDICES: [4],
    HINTS: [7, 2, 1],
  },
  {
    WORD: "SHANGHAI",
    ALPHA: "AAHHINS",
    LETTERS: ["G"],
    INDICES: [4],
    HINTS: [5, 3, 7],
  },
  {
    WORD: "APPROACH",
    ALPHA: "AACHOPP",
    LETTERS: ["R"],
    INDICES: [3],
    HINTS: [5, 1, 2],
  },
  {
    WORD: "REPUBLIC",
    ALPHA: "BCEIPRU",
    LETTERS: ["L"],
    INDICES: [5],
    HINTS: [0, 2, 3],
  },
  {
    WORD: "DESKTOPS",
    ALPHA: "DEKOPSS",
    LETTERS: ["T"],
    INDICES: [4],
    HINTS: [6, 3, 5],
  },
  {
    WORD: "GENETICS",
    ALPHA: "CEGINST",
    LETTERS: ["E"],
    INDICES: [1],
    HINTS: [0, 2, 6],
  },
  {
    WORD: "SOMEBODY",
    ALPHA: "BDEMOSY",
    LETTERS: ["O"],
    INDICES: [5],
    HINTS: [2, 4, 6],
  },
  {
    WORD: "EXAMPLES",
    ALPHA: "EELMPSX",
    LETTERS: ["A"],
    INDICES: [2],
    HINTS: [7, 1, 4],
  },
  {
    WORD: "PRACTICE",
    ALPHA: "ACEIPRT",
    LETTERS: ["C"],
    INDICES: [6],
    HINTS: [5, 2, 4],
  },
  {
    WORD: "LICENSED",
    ALPHA: "CDEEILN",
    LETTERS: ["S"],
    INDICES: [5],
    HINTS: [3, 2, 1],
  },
  {
    WORD: "DETECTOR",
    ALPHA: "CDEORTT",
    LETTERS: ["E"],
    INDICES: [3],
    HINTS: [7, 4, 2],
  },
  {
    WORD: "TROPICAL",
    ALPHA: "ACILOPT",
    LETTERS: ["R"],
    INDICES: [1],
    HINTS: [0, 4, 2],
  },
  {
    WORD: "DIABETES",
    ALPHA: "ABDEIST",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [2, 0, 1],
  },
  {
    WORD: "TITANIUM",
    ALPHA: "AIIMNTU",
    LETTERS: ["T"],
    INDICES: [2],
    HINTS: [3, 6, 5],
  },
  {
    WORD: "BREEDING",
    ALPHA: "BDEEGIN",
    LETTERS: ["R"],
    INDICES: [1],
    HINTS: [4, 2, 3],
  },
  {
    WORD: "REGARDED",
    ALPHA: "DDEEGRR",
    LETTERS: ["A"],
    INDICES: [3],
    HINTS: [0, 6, 7],
  },
  {
    WORD: "PACKAGES",
    ALPHA: "AACGKPS",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [0, 2, 3],
  },
  {
    WORD: "EXPLORER",
    ALPHA: "EELORRX",
    LETTERS: ["P"],
    INDICES: [2],
    HINTS: [0, 7, 6],
  },
  {
    WORD: "CHAMBERS",
    ALPHA: "ABCEHMS",
    LETTERS: ["R"],
    INDICES: [6],
    HINTS: [2, 7, 3],
  },
  {
    WORD: "OFFICIAL",
    ALPHA: "AFFIILO",
    LETTERS: ["C"],
    INDICES: [4],
    HINTS: [3, 5, 0],
  },
  {
    WORD: "ATTORNEY",
    ALPHA: "AENORTY",
    LETTERS: ["T"],
    INDICES: [1],
    HINTS: [6, 4, 7],
  },
  {
    WORD: "PURPOSES",
    ALPHA: "EOPPSSU",
    LETTERS: ["R"],
    INDICES: [2],
    HINTS: [3, 7, 6],
  },
  {
    WORD: "FLORISTS",
    ALPHA: "FIORSST",
    LETTERS: ["L"],
    INDICES: [1],
    HINTS: [4, 7, 0],
  },
  {
    WORD: "PROGRAMS",
    ALPHA: "AGMPRRS",
    LETTERS: ["O"],
    INDICES: [2],
    HINTS: [4, 3, 5],
  },
  {
    WORD: "EDITIONS",
    ALPHA: "DEINOST",
    LETTERS: ["I"],
    INDICES: [2],
    HINTS: [3, 0, 5],
  },
  {
    WORD: "SITUATED",
    ALPHA: "ADISTTU",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [3, 0, 7],
  },
  {
    WORD: "STUDYING",
    ALPHA: "DGINSTY",
    LETTERS: ["U"],
    INDICES: [2],
    HINTS: [5, 6, 0],
  },
  {
    WORD: "DISCOUNT",
    ALPHA: "CDIOSTU",
    LETTERS: ["N"],
    INDICES: [6],
    HINTS: [2, 5, 3],
  },
  {
    WORD: "PREVIOUS",
    ALPHA: "EIOPSUV",
    LETTERS: ["R"],
    INDICES: [1],
    HINTS: [7, 0, 6],
  },
  {
    WORD: "INTERIOR",
    ALPHA: "IINORRT",
    LETTERS: ["E"],
    INDICES: [3],
    HINTS: [1, 5, 2],
  },
  {
    WORD: "BATHROOM",
    ALPHA: "ABHMORT",
    LETTERS: ["O"],
    INDICES: [5],
    HINTS: [4, 2, 7],
  },
  {
    WORD: "BACTERIA",
    ALPHA: "AABEIRT",
    LETTERS: ["C"],
    INDICES: [2],
    HINTS: [1, 5, 0],
  },
  {
    WORD: "DENTISTS",
    ALPHA: "DEINSTT",
    LETTERS: ["S"],
    INDICES: [5],
    HINTS: [6, 4, 0],
  },
  {
    WORD: "RECEIVES",
    ALPHA: "CEEEIRS",
    LETTERS: ["V"],
    INDICES: [5],
    HINTS: [2, 1, 6],
  },
  {
    WORD: "ROUTINES",
    ALPHA: "EINORSU",
    LETTERS: ["T"],
    INDICES: [3],
    HINTS: [5, 0, 4],
  },
  {
    WORD: "OPPOSITE",
    ALPHA: "EIOOPPT",
    LETTERS: ["S"],
    INDICES: [4],
    HINTS: [0, 1, 7],
  },
  {
    WORD: "ARCHIVED",
    ALPHA: "ACDEHIV",
    LETTERS: ["R"],
    INDICES: [1],
    HINTS: [4, 6, 0],
  },
  {
    WORD: "BEVERAGE",
    ALPHA: "ABEEEGR",
    LETTERS: ["V"],
    INDICES: [2],
    HINTS: [5, 7, 6],
  },
  {
    WORD: "PRISONER",
    ALPHA: "EINOPRR",
    LETTERS: ["S"],
    INDICES: [3],
    HINTS: [6, 2, 0],
  },
  {
    WORD: "BALANCED",
    ALPHA: "ABCDELN",
    LETTERS: ["A"],
    INDICES: [3],
    HINTS: [7, 6, 2],
  },
  {
    WORD: "CULTURES",
    ALPHA: "CERSTUU",
    LETTERS: ["L"],
    INDICES: [2],
    HINTS: [6, 1, 5],
  },
  {
    WORD: "STANDING",
    ALPHA: "ADGINNS",
    LETTERS: ["T"],
    INDICES: [1],
    HINTS: [2, 3, 7],
  },
  {
    WORD: "PREVIEWS",
    ALPHA: "EEIPRSV",
    LETTERS: ["W"],
    INDICES: [6],
    HINTS: [7, 2, 0],
  },
  {
    WORD: "REQUIRES",
    ALPHA: "EEIQRRS",
    LETTERS: ["U"],
    INDICES: [3],
    HINTS: [0, 5, 1],
  },
  {
    WORD: "PLEASANT",
    ALPHA: "AELNPST",
    LETTERS: ["A"],
    INDICES: [3],
    HINTS: [6, 0, 1],
  },
  {
    WORD: "EXPECTED",
    ALPHA: "DEEEPTX",
    LETTERS: ["C"],
    INDICES: [4],
    HINTS: [1, 2, 0],
  },
  {
    WORD: "COMMERCE",
    ALPHA: "CEEMMOR",
    LETTERS: ["C"],
    INDICES: [6],
    HINTS: [2, 1, 7],
  },
  {
    WORD: "JOURNALS",
    ALPHA: "JLNORSU",
    LETTERS: ["A"],
    INDICES: [5],
    HINTS: [2, 4, 3],
  },
  {
    WORD: "SELECTED",
    ALPHA: "CDEELST",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [0, 5, 7],
  },
  {
    WORD: "RELEVANT",
    ALPHA: "AEELNRT",
    LETTERS: ["V"],
    INDICES: [4],
    HINTS: [0, 2, 3],
  },
  {
    WORD: "RECORDER",
    ALPHA: "CDEERRR",
    LETTERS: ["O"],
    INDICES: [3],
    HINTS: [2, 1, 7],
  },
  {
    WORD: "ORIENTED",
    ALPHA: "DEINORT",
    LETTERS: ["E"],
    INDICES: [3],
    HINTS: [4, 2, 7],
  },
  {
    WORD: "INJURIES",
    ALPHA: "IIJNRSU",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [1, 0, 4],
  },
  {
    WORD: "VISITING",
    ALPHA: "GIIINSV",
    LETTERS: ["T"],
    INDICES: [4],
    HINTS: [3, 2, 7],
  },
  {
    WORD: "EVERYDAY",
    ALPHA: "DEERVYY",
    LETTERS: ["A"],
    INDICES: [6],
    HINTS: [0, 5, 2],
  },
  {
    WORD: "DISPOSAL",
    ALPHA: "ADILOPS",
    LETTERS: ["S"],
    INDICES: [2],
    HINTS: [6, 3, 7],
  },
  {
    WORD: "VARIANCE",
    ALPHA: "ACEINRV",
    LETTERS: ["A"],
    INDICES: [4],
    HINTS: [6, 2, 3],
  },
  {
    WORD: "EMERGING",
    ALPHA: "EEGGIMN",
    LETTERS: ["R"],
    INDICES: [3],
    HINTS: [5, 7, 6],
  },
  {
    WORD: "PROPOSAL",
    ALPHA: "ALOOPPS",
    LETTERS: ["R"],
    INDICES: [1],
    HINTS: [2, 5, 7],
  },
  {
    WORD: "FLORENCE",
    ALPHA: "CEEFLNO",
    LETTERS: ["R"],
    INDICES: [3],
    HINTS: [1, 2, 0],
  },
  {
    WORD: "ESTIMATE",
    ALPHA: "EEIMSTT",
    LETTERS: ["A"],
    INDICES: [5],
    HINTS: [3, 2, 1],
  },
  {
    WORD: "THOROUGH",
    ALPHA: "GHOORTU",
    LETTERS: ["H"],
    INDICES: [1],
    HINTS: [6, 4, 3],
  },
  {
    WORD: "ALLOWING",
    ALPHA: "AGILLOW",
    LETTERS: ["N"],
    INDICES: [6],
    HINTS: [4, 5, 3],
  },
  {
    WORD: "EXTERNAL",
    ALPHA: "EELNRTX",
    LETTERS: ["A"],
    INDICES: [6],
    HINTS: [0, 7, 5],
  },
  {
    WORD: "SERVICES",
    ALPHA: "EEIRSSV",
    LETTERS: ["C"],
    INDICES: [5],
    HINTS: [4, 1, 6],
  },
  {
    WORD: "INTRANET",
    ALPHA: "AEINNRT",
    LETTERS: ["T"],
    INDICES: [2],
    HINTS: [3, 5, 4],
  },
  {
    WORD: "OPTIMIZE",
    ALPHA: "EIIMOPZ",
    LETTERS: ["T"],
    INDICES: [2],
    HINTS: [0, 4, 5],
  },
  {
    WORD: "STANDARD",
    ALPHA: "AADDNST",
    LETTERS: ["R"],
    INDICES: [6],
    HINTS: [5, 2, 3],
  },
  {
    WORD: "AIRPLANE",
    ALPHA: "AAELNPR",
    LETTERS: ["I"],
    INDICES: [1],
    HINTS: [6, 2, 5],
  },
  {
    WORD: "RATIONAL",
    ALPHA: "AAILORT",
    LETTERS: ["N"],
    INDICES: [5],
    HINTS: [0, 3, 7],
  },
  {
    WORD: "MODERATE",
    ALPHA: "ADEEMOR",
    LETTERS: ["T"],
    INDICES: [6],
    HINTS: [0, 4, 1],
  },
  {
    WORD: "READINGS",
    ALPHA: "ADEINRS",
    LETTERS: ["G"],
    INDICES: [6],
    HINTS: [4, 0, 7],
  },
  {
    WORD: "NUMEROUS",
    ALPHA: "MNORSUU",
    LETTERS: ["E"],
    INDICES: [3],
    HINTS: [7, 2, 5],
  },
  {
    WORD: "CIRCUITS",
    ALPHA: "CIIRSTU",
    LETTERS: ["C"],
    INDICES: [3],
    HINTS: [2, 5, 7],
  },
  {
    WORD: "REMEMBER",
    ALPHA: "BEEEMRR",
    LETTERS: ["M"],
    INDICES: [4],
    HINTS: [5, 0, 1],
  },
  {
    WORD: "WIRELESS",
    ALPHA: "EILRSSW",
    LETTERS: ["E"],
    INDICES: [5],
    HINTS: [2, 7, 1],
  },
  {
    WORD: "TEXTBOOK",
    ALPHA: "BKOOTTX",
    LETTERS: ["E"],
    INDICES: [1],
    HINTS: [2, 5, 0],
  },
  {
    WORD: "COMPLETE",
    ALPHA: "CEELOPT",
    LETTERS: ["M"],
    INDICES: [2],
    HINTS: [1, 0, 3],
  },
  {
    WORD: "GREETING",
    ALPHA: "EEGGINR",
    LETTERS: ["T"],
    INDICES: [4],
    HINTS: [0, 5, 7],
  },
  {
    WORD: "CONSUMER",
    ALPHA: "CENORSU",
    LETTERS: ["M"],
    INDICES: [5],
    HINTS: [6, 0, 2],
  },
  {
    WORD: "MINISTRY",
    ALPHA: "IIMNRTY",
    LETTERS: ["S"],
    INDICES: [4],
    HINTS: [7, 3, 5],
  },
  {
    WORD: "ADEQUATE",
    ALPHA: "ADEEQTU",
    LETTERS: ["A"],
    INDICES: [5],
    HINTS: [3, 1, 6],
  },
  {
    WORD: "SOFTWARE",
    ALPHA: "AEORSTW",
    LETTERS: ["F"],
    INDICES: [2],
    HINTS: [3, 6, 4],
  },
  {
    WORD: "WEIGHTED",
    ALPHA: "DEGHITW",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [4, 5, 2],
  },
  {
    WORD: "LIMITING",
    ALPHA: "GIIILMT",
    LETTERS: ["N"],
    INDICES: [6],
    HINTS: [5, 0, 7],
  },
  {
    WORD: "OCCUPIED",
    ALPHA: "CDEIOPU",
    LETTERS: ["C"],
    INDICES: [1],
    HINTS: [4, 7, 6],
  },
  {
    WORD: "SAVANNAH",
    ALPHA: "AAAHNSV",
    LETTERS: ["N"],
    INDICES: [5],
    HINTS: [6, 7, 1],
  },
  {
    WORD: "POSSIBLY",
    ALPHA: "BIOPSSY",
    LETTERS: ["L"],
    INDICES: [6],
    HINTS: [3, 0, 5],
  },
  {
    WORD: "UPCOMING",
    ALPHA: "CGMNOPU",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [6, 7, 2],
  },
  {
    WORD: "REMAINED",
    ALPHA: "ADEIMNR",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [2, 3, 7],
  },
  {
    WORD: "SHOOTING",
    ALPHA: "GHINOST",
    LETTERS: ["O"],
    INDICES: [3],
    HINTS: [7, 0, 1],
  },
  {
    WORD: "ENTERING",
    ALPHA: "EEGNNRT",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [7, 4, 0],
  },
  {
    WORD: "INFORMAL",
    ALPHA: "AFILMOR",
    LETTERS: ["N"],
    INDICES: [1],
    HINTS: [4, 0, 5],
  },
  {
    WORD: "ANYWHERE",
    ALPHA: "AEEHNWY",
    LETTERS: ["R"],
    INDICES: [6],
    HINTS: [4, 5, 2],
  },
  {
    WORD: "FLOATING",
    ALPHA: "FGILNOT",
    LETTERS: ["A"],
    INDICES: [3],
    HINTS: [2, 0, 5],
  },
  {
    WORD: "ELIGIBLE",
    ALPHA: "BEEGILL",
    LETTERS: ["I"],
    INDICES: [4],
    HINTS: [6, 7, 0],
  },
  {
    WORD: "GRATEFUL",
    ALPHA: "AEFGLRU",
    LETTERS: ["T"],
    INDICES: [3],
    HINTS: [1, 2, 6],
  },
  {
    WORD: "RESULTED",
    ALPHA: "DEERSTU",
    LETTERS: ["L"],
    INDICES: [4],
    HINTS: [0, 3, 6],
  },
  {
    WORD: "ACCEPTED",
    ALPHA: "ACCDEPT",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [5, 7, 1],
  },
  {
    WORD: "EXERCISE",
    ALPHA: "CEEEIRS",
    LETTERS: ["X"],
    INDICES: [1],
    HINTS: [3, 6, 7],
  },
  {
    WORD: "WARRIORS",
    ALPHA: "AORRRSW",
    LETTERS: ["I"],
    INDICES: [4],
    HINTS: [6, 3, 5],
  },
  {
    WORD: "PLATFORM",
    ALPHA: "FLMOPRT",
    LETTERS: ["A"],
    INDICES: [2],
    HINTS: [0, 1, 3],
  },
  {
    WORD: "DOCUMENT",
    ALPHA: "DEMNOTU",
    LETTERS: ["C"],
    INDICES: [2],
    HINTS: [5, 1, 3],
  },
  {
    WORD: "OPINIONS",
    ALPHA: "IINOOPS",
    LETTERS: ["N"],
    INDICES: [6],
    HINTS: [0, 1, 4],
  },
  {
    WORD: "SUNSHINE",
    ALPHA: "EINNSSU",
    LETTERS: ["H"],
    INDICES: [4],
    HINTS: [3, 1, 5],
  },
  {
    WORD: "CONFUSED",
    ALPHA: "CDFNOSU",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [4, 0, 1],
  },
  {
    WORD: "IDENTITY",
    ALPHA: "EIINTTY",
    LETTERS: ["D"],
    INDICES: [1],
    HINTS: [0, 5, 2],
  },
  {
    WORD: "CHAPTERS",
    ALPHA: "ACEHRST",
    LETTERS: ["P"],
    INDICES: [3],
    HINTS: [5, 2, 7],
  },
  {
    WORD: "CRITICAL",
    ALPHA: "CCIILRT",
    LETTERS: ["A"],
    INDICES: [6],
    HINTS: [0, 3, 2],
  },
  {
    WORD: "IMPROVED",
    ALPHA: "DEIMOPV",
    LETTERS: ["R"],
    INDICES: [3],
    HINTS: [0, 7, 1],
  },
  {
    WORD: "SUITABLE",
    ALPHA: "ABELSTU",
    LETTERS: ["I"],
    INDICES: [2],
    HINTS: [4, 1, 0],
  },
  {
    WORD: "SUFFERED",
    ALPHA: "DEEFRSU",
    LETTERS: ["F"],
    INDICES: [3],
    HINTS: [6, 1, 0],
  },
  {
    WORD: "CARRYING",
    ALPHA: "ACGINRY",
    LETTERS: ["R"],
    INDICES: [2],
    HINTS: [1, 6, 7],
  },
  {
    WORD: "REFLECTS",
    ALPHA: "CEFLRST",
    LETTERS: ["E"],
    INDICES: [1],
    HINTS: [6, 3, 5],
  },
  {
    WORD: "PRECIOUS",
    ALPHA: "CEIOPSU",
    LETTERS: ["R"],
    INDICES: [1],
    HINTS: [2, 5, 6],
  },
  {
    WORD: "PREGNANT",
    ALPHA: "AGNNPRT",
    LETTERS: ["E"],
    INDICES: [2],
    HINTS: [7, 1, 0],
  },
  {
    WORD: "DIAMONDS",
    ALPHA: "ADDIMNS",
    LETTERS: ["O"],
    INDICES: [4],
    HINTS: [3, 6, 5],
  },
  {
    WORD: "OBSERVER",
    ALPHA: "BEEORRS",
    LETTERS: ["V"],
    INDICES: [5],
    HINTS: [0, 6, 3],
  },
  {
    WORD: "DIRECTED",
    ALPHA: "DDEEIRT",
    LETTERS: ["C"],
    INDICES: [4],
    HINTS: [6, 1, 2],
  },
  {
    WORD: "ELECTRIC",
    ALPHA: "CCEELRT",
    LETTERS: ["I"],
    INDICES: [6],
    HINTS: [3, 5, 1],
  },
  {
    WORD: "KEYBOARD",
    ALPHA: "ABDEKOY",
    LETTERS: ["R"],
    INDICES: [6],
    HINTS: [0, 4, 3],
  },
  {
    WORD: "EMOTIONS",
    ALPHA: "EIMNOST",
    LETTERS: ["O"],
    INDICES: [5],
    HINTS: [4, 6, 3],
  },
  {
    WORD: "DRAMATIC",
    ALPHA: "AACDMRT",
    LETTERS: ["I"],
    INDICES: [6],
    HINTS: [4, 3, 2],
  },
  {
    WORD: "ADMITTED",
    ALPHA: "ADEIMTT",
    LETTERS: ["D"],
    INDICES: [1],
    HINTS: [6, 0, 5],
  },
  {
    WORD: "REQUIRED",
    ALPHA: "DEEQRRU",
    LETTERS: ["I"],
    INDICES: [4],
    HINTS: [7, 6, 0],
  },
  {
    WORD: "TRAINING",
    ALPHA: "AGINNRT",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [1, 2, 7],
  },
  {
    WORD: "SHOPPERS",
    ALPHA: "HOPPRSS",
    LETTERS: ["E"],
    INDICES: [5],
    HINTS: [4, 3, 0],
  },
  {
    WORD: "ADVANCES",
    ALPHA: "AADENSV",
    LETTERS: ["C"],
    INDICES: [5],
    HINTS: [4, 6, 1],
  },
  {
    WORD: "CINGULAR",
    ALPHA: "ACGILNR",
    LETTERS: ["U"],
    INDICES: [4],
    HINTS: [6, 2, 0],
  },
  {
    WORD: "OPERATES",
    ALPHA: "AEOPRST",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [1, 4, 3],
  },
  {
    WORD: "MARITIME",
    ALPHA: "EIIMMRT",
    LETTERS: ["A"],
    INDICES: [1],
    HINTS: [5, 6, 2],
  },
  {
    WORD: "SECURITY",
    ALPHA: "CEIRSTY",
    LETTERS: ["U"],
    INDICES: [3],
    HINTS: [1, 7, 2],
  },
  {
    WORD: "DISCOVER",
    ALPHA: "CDEIRSV",
    LETTERS: ["O"],
    INDICES: [4],
    HINTS: [7, 6, 0],
  },
  {
    WORD: "SYMPHONY",
    ALPHA: "HMOPSYY",
    LETTERS: ["N"],
    INDICES: [6],
    HINTS: [5, 2, 0],
  },
  {
    WORD: "AFFECTED",
    ALPHA: "ACDEEFF",
    LETTERS: ["T"],
    INDICES: [5],
    HINTS: [3, 7, 0],
  },
  {
    WORD: "EXAMINES",
    ALPHA: "AEEINSX",
    LETTERS: ["M"],
    INDICES: [3],
    HINTS: [4, 6, 2],
  },
  {
    WORD: "FOCUSING",
    ALPHA: "CFGINSU",
    LETTERS: ["O"],
    INDICES: [1],
    HINTS: [0, 6, 5],
  },
  {
    WORD: "HANDLING",
    ALPHA: "ADGHILN",
    LETTERS: ["N"],
    INDICES: [6],
    HINTS: [1, 7, 4],
  },
  {
    WORD: "OPPONENT",
    ALPHA: "ENOOPPT",
    LETTERS: ["N"],
    INDICES: [4],
    HINTS: [3, 7, 1],
  },
  {
    WORD: "SWIMMING",
    ALPHA: "GIMMNSW",
    LETTERS: ["I"],
    INDICES: [2],
    HINTS: [6, 4, 0],
  },
  {
    WORD: "SPELLING",
    ALPHA: "EGILLNS",
    LETTERS: ["P"],
    INDICES: [1],
    HINTS: [2, 0, 5],
  },
  {
    WORD: "DELIVERS",
    ALPHA: "DEILRSV",
    LETTERS: ["E"],
    INDICES: [5],
    HINTS: [4, 2, 7],
  },
  {
    WORD: "WEBSITES",
    ALPHA: "BEEISTW",
    LETTERS: ["S"],
    INDICES: [3],
    HINTS: [6, 1, 2],
  },
  {
    WORD: "VENTURES",
    ALPHA: "ENRSTUV",
    LETTERS: ["E"],
    INDICES: [1],
    HINTS: [4, 0, 5],
  },
  {
    WORD: "FESTIVAL",
    ALPHA: "AFILSTV",
    LETTERS: ["E"],
    INDICES: [1],
    HINTS: [6, 3, 2],
  },
  {
    WORD: "GATHERED",
    ALPHA: "ADEEGHT",
    LETTERS: ["R"],
    INDICES: [5],
    HINTS: [2, 0, 1],
  },
  {
    WORD: "GORGEOUS",
    ALPHA: "EGGOOSU",
    LETTERS: ["R"],
    INDICES: [2],
    HINTS: [3, 4, 0],
  },
  {
    WORD: "RENDERED",
    ALPHA: "DDEENRR",
    LETTERS: ["E"],
    INDICES: [4],
    HINTS: [3, 5, 2],
  },
  {
    WORD: "VITAMINS",
    ALPHA: "AIIMSTV",
    LETTERS: ["N"],
    INDICES: [6],
    HINTS: [3, 1, 5],
  },
  {
    WORD: "PERIODIC",
    ALPHA: "CDEIIPR",
    LETTERS: ["O"],
    INDICES: [4],
    HINTS: [1, 5, 3],
  },
  {
    WORD: "PAVILION",
    ALPHA: "AILNOPV",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [4, 0, 2],
  },
  {
    WORD: "FREQUENT",
    ALPHA: "EEFNQTU",
    LETTERS: ["R"],
    INDICES: [1],
    HINTS: [7, 0, 5],
  },
  {
    WORD: "HARDCORE",
    ALPHA: "ACEHORR",
    LETTERS: ["D"],
    INDICES: [3],
    HINTS: [1, 7, 6],
  },
  {
    WORD: "NECKLACE",
    ALPHA: "ACCEELN",
    LETTERS: ["K"],
    INDICES: [3],
    HINTS: [5, 4, 0],
  },
  {
    WORD: "REMOVING",
    ALPHA: "EGIMNOR",
    LETTERS: ["V"],
    INDICES: [4],
    HINTS: [3, 1, 0],
  },
  {
    WORD: "PHYSICAL",
    ALPHA: "CHILPSY",
    LETTERS: ["A"],
    INDICES: [6],
    HINTS: [3, 7, 4],
  },
  {
    WORD: "ECONOMIC",
    ALPHA: "CCEIMNO",
    LETTERS: ["O"],
    INDICES: [2],
    HINTS: [6, 5, 0],
  },
  {
    WORD: "THOUSAND",
    ALPHA: "ADHNOST",
    LETTERS: ["U"],
    INDICES: [3],
    HINTS: [5, 2, 7],
  },
  {
    WORD: "CAMPAIGN",
    ALPHA: "ACGIMNP",
    LETTERS: ["A"],
    INDICES: [1],
    HINTS: [2, 7, 3],
  },
  {
    WORD: "MARATHON",
    ALPHA: "AHMNORT",
    LETTERS: ["A"],
    INDICES: [3],
    HINTS: [4, 6, 7],
  },
  {
    WORD: "PROSTATE",
    ALPHA: "AEOPRTT",
    LETTERS: ["S"],
    INDICES: [3],
    HINTS: [2, 6, 4],
  },
  {
    WORD: "ANALYZED",
    ALPHA: "AADELYZ",
    LETTERS: ["N"],
    INDICES: [1],
    HINTS: [5, 3, 7],
  },
  {
    WORD: "MEASURES",
    ALPHA: "AEEMSSU",
    LETTERS: ["R"],
    INDICES: [5],
    HINTS: [4, 2, 1],
  },
  {
    WORD: "EXPOSURE",
    ALPHA: "EEOPSUX",
    LETTERS: ["R"],
    INDICES: [6],
    HINTS: [3, 7, 0],
  },
  {
    WORD: "INTERNAL",
    ALPHA: "EILNNRT",
    LETTERS: ["A"],
    INDICES: [6],
    HINTS: [1, 3, 2],
  },
  {
    WORD: "EXCITING",
    ALPHA: "CEGIINX",
    LETTERS: ["T"],
    INDICES: [4],
    HINTS: [5, 7, 1],
  },
  {
    WORD: "CLINICAL",
    ALPHA: "CCIILLN",
    LETTERS: ["A"],
    INDICES: [6],
    HINTS: [0, 7, 2],
  },
  {
    WORD: "NAVIGATE",
    ALPHA: "AAEGINT",
    LETTERS: ["V"],
    INDICES: [2],
    HINTS: [5, 3, 7],
  },
  {
    WORD: "SANDWICH",
    ALPHA: "ADHINSW",
    LETTERS: ["C"],
    INDICES: [6],
    HINTS: [1, 3, 7],
  },
  {
    WORD: "COSMETIC",
    ALPHA: "CCEMOST",
    LETTERS: ["I"],
    INDICES: [6],
    HINTS: [5, 1, 2],
  },
  {
    WORD: "FEEDBACK",
    ALPHA: "ACDEEFK",
    LETTERS: ["B"],
    INDICES: [4],
    HINTS: [1, 5, 7],
  },
  {
    WORD: "HAPPENED",
    ALPHA: "ADEHNPP",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [5, 0, 2],
  },
  {
    WORD: "ACCURATE",
    ALPHA: "AACCERT",
    LETTERS: ["U"],
    INDICES: [3],
    HINTS: [6, 5, 1],
  },
  {
    WORD: "DISAGREE",
    ALPHA: "ADEEGIS",
    LETTERS: ["R"],
    INDICES: [5],
    HINTS: [6, 4, 0],
  },
  {
    WORD: "DESIGNED",
    ALPHA: "DDEEINS",
    LETTERS: ["G"],
    INDICES: [4],
    HINTS: [1, 2, 6],
  },
  {
    WORD: "HOMELAND",
    ALPHA: "ADHLMNO",
    LETTERS: ["E"],
    INDICES: [3],
    HINTS: [6, 2, 0],
  },
  {
    WORD: "CLEARING",
    ALPHA: "ACEGILN",
    LETTERS: ["R"],
    INDICES: [4],
    HINTS: [7, 6, 5],
  },
  {
    WORD: "POINTING",
    ALPHA: "GINNOPT",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [4, 0, 1],
  },
  {
    WORD: "DOMESTIC",
    ALPHA: "CDEMOST",
    LETTERS: ["I"],
    INDICES: [6],
    HINTS: [7, 2, 0],
  },
  {
    WORD: "DIVISION",
    ALPHA: "DIIINOS",
    LETTERS: ["V"],
    INDICES: [2],
    HINTS: [6, 1, 7],
  },
  {
    WORD: "MOBILITY",
    ALPHA: "BIILMOY",
    LETTERS: ["T"],
    INDICES: [6],
    HINTS: [1, 0, 5],
  },
  {
    WORD: "VERIFIED",
    ALPHA: "DEEFIRV",
    LETTERS: ["I"],
    INDICES: [3],
    HINTS: [6, 1, 2],
  },
  {
    WORD: "CHANNELS",
    ALPHA: "ACELNNS",
    LETTERS: ["H"],
    INDICES: [1],
    HINTS: [7, 5, 3],
  },
  {
    WORD: "TEMPLATE",
    ALPHA: "AEELMTT",
    LETTERS: ["P"],
    INDICES: [3],
    HINTS: [1, 7, 0],
  },
  {
    WORD: "MANAGERS",
    ALPHA: "AEGMNRS",
    LETTERS: ["A"],
    INDICES: [3],
    HINTS: [6, 2, 7],
  },
  {
    WORD: "CHURCHES",
    ALPHA: "CEHHRSU",
    LETTERS: ["C"],
    INDICES: [4],
    HINTS: [3, 2, 5],
  },
  {
    WORD: "HOLIDAYS",
    ALPHA: "ADHILOS",
    LETTERS: ["Y"],
    INDICES: [6],
    HINTS: [5, 2, 7],
  },
  {
    WORD: "PROMPTLY",
    ALPHA: "MOPPRTY",
    LETTERS: ["L"],
    INDICES: [6],
    HINTS: [1, 2, 0],
  },
  {
    WORD: "ROTATION",
    ALPHA: "AINORTT",
    LETTERS: ["O"],
    INDICES: [1],
    HINTS: [7, 0, 5],
  },
  {
    WORD: "FAILURES",
    ALPHA: "AEFILRS",
    LETTERS: ["U"],
    INDICES: [4],
    HINTS: [3, 7, 0],
  },
  {
    WORD: "CURRENCY",
    ALPHA: "CCENRUY",
    LETTERS: ["R"],
    INDICES: [2],
    HINTS: [7, 6, 1],
  },
  {
    WORD: "HOMEWORK",
    ALPHA: "HKMOORW",
    LETTERS: ["E"],
    INDICES: [3],
    HINTS: [6, 7, 5],
  },
  {
    WORD: "MONSTERS",
    ALPHA: "EMNOSST",
    LETTERS: ["R"],
    INDICES: [6],
    HINTS: [5, 1, 4],
  },
  {
    WORD: "COUNTING",
    ALPHA: "CGINNOU",
    LETTERS: ["T"],
    INDICES: [4],
    HINTS: [7, 3, 2],
  },
  {
    WORD: "VILLAGES",
    ALPHA: "AGILLSV",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [7, 1, 5],
  },
  {
    WORD: "MOUNTAIN",
    ALPHA: "AIMNNOT",
    LETTERS: ["U"],
    INDICES: [2],
    HINTS: [5, 7, 1],
  },
  {
    WORD: "GRAPHICS",
    ALPHA: "AGHIPRS",
    LETTERS: ["C"],
    INDICES: [6],
    HINTS: [7, 2, 0],
  },
  {
    WORD: "DISTRICT",
    ALPHA: "CDIIRST",
    LETTERS: ["T"],
    INDICES: [3],
    HINTS: [0, 4, 5],
  },
  {
    WORD: "SCENARIO",
    ALPHA: "ACEIORS",
    LETTERS: ["N"],
    INDICES: [3],
    HINTS: [7, 0, 2],
  },
  {
    WORD: "MOMENTUM",
    ALPHA: "MMMNOTU",
    LETTERS: ["E"],
    INDICES: [3],
    HINTS: [2, 1, 6],
  },
  {
    WORD: "CHANGING",
    ALPHA: "ACGGHIN",
    LETTERS: ["N"],
    INDICES: [6],
    HINTS: [7, 0, 1],
  },
  {
    WORD: "PICTURES",
    ALPHA: "CEIPRST",
    LETTERS: ["U"],
    INDICES: [4],
    HINTS: [2, 5, 0],
  },
  {
    WORD: "DIVIDEND",
    ALPHA: "DDDEIIN",
    LETTERS: ["V"],
    INDICES: [2],
    HINTS: [4, 3, 0],
  },
  {
    WORD: "EGYPTIAN",
    ALPHA: "AEGINPY",
    LETTERS: ["T"],
    INDICES: [4],
    HINTS: [7, 5, 0],
  },
  {
    WORD: "PRINTERS",
    ALPHA: "ENPRRST",
    LETTERS: ["I"],
    INDICES: [2],
    HINTS: [1, 6, 7],
  },
  {
    WORD: "PRIORITY",
    ALPHA: "IIOPRTY",
    LETTERS: ["R"],
    INDICES: [1],
    HINTS: [3, 2, 0],
  },
  {
    WORD: "EXECUTED",
    ALPHA: "CDEETUX",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [3, 5, 1],
  },
  {
    WORD: "CONCERNS",
    ALPHA: "CCNNORS",
    LETTERS: ["E"],
    INDICES: [4],
    HINTS: [0, 6, 3],
  },
  {
    WORD: "CHOOSING",
    ALPHA: "CGHINOS",
    LETTERS: ["O"],
    INDICES: [3],
    HINTS: [7, 0, 4],
  },
  {
    WORD: "FREEWARE",
    ALPHA: "AEEEFRW",
    LETTERS: ["R"],
    INDICES: [6],
    HINTS: [5, 3, 0],
  },
  {
    WORD: "WARRANTY",
    ALPHA: "AANRTWY",
    LETTERS: ["R"],
    INDICES: [3],
    HINTS: [0, 5, 6],
  },
  {
    WORD: "SPEAKING",
    ALPHA: "AEGIKNS",
    LETTERS: ["P"],
    INDICES: [1],
    HINTS: [3, 0, 4],
  },
  {
    WORD: "BUILDERS",
    ALPHA: "BDEIRSU",
    LETTERS: ["L"],
    INDICES: [3],
    HINTS: [7, 1, 6],
  },
  {
    WORD: "PORTRAIT",
    ALPHA: "AOPRRTT",
    LETTERS: ["I"],
    INDICES: [6],
    HINTS: [3, 7, 0],
  },
  {
    WORD: "SPANKING",
    ALPHA: "AGINNPS",
    LETTERS: ["K"],
    INDICES: [4],
    HINTS: [1, 7, 0],
  },
  {
    WORD: "SPECIFIC",
    ALPHA: "CCEFIIS",
    LETTERS: ["P"],
    INDICES: [1],
    HINTS: [7, 4, 0],
  },
  {
    WORD: "BECOMING",
    ALPHA: "BCEGMNO",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [0, 2, 3],
  },
  {
    WORD: "ATTITUDE",
    ALPHA: "ADEITTU",
    LETTERS: ["T"],
    INDICES: [1],
    HINTS: [0, 7, 3],
  },
  {
    WORD: "DRAWINGS",
    ALPHA: "ADGIRSW",
    LETTERS: ["N"],
    INDICES: [5],
    HINTS: [0, 2, 1],
  },
  {
    WORD: "WORKFLOW",
    ALPHA: "FKLORWW",
    LETTERS: ["O"],
    INDICES: [6],
    HINTS: [4, 7, 0],
  },
  {
    WORD: "AIRPORTS",
    ALPHA: "AIOPRST",
    LETTERS: ["R"],
    INDICES: [5],
    HINTS: [6, 7, 1],
  },
  {
    WORD: "CLASSICS",
    ALPHA: "ACCILSS",
    LETTERS: ["S"],
    INDICES: [4],
    HINTS: [1, 5, 6],
  },
  {
    WORD: "FOOTBALL",
    ALPHA: "ABFLLOT",
    LETTERS: ["O"],
    INDICES: [2],
    HINTS: [3, 7, 6],
  },
  {
    WORD: "EXHIBITS",
    ALPHA: "BEHISTX",
    LETTERS: ["I"],
    INDICES: [3],
    HINTS: [6, 4, 7],
  },
  {
    WORD: "RETURNED",
    ALPHA: "DENRRTU",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [5, 2, 7],
  },
  {
    WORD: "BARRIERS",
    ALPHA: "ABEIRRS",
    LETTERS: ["R"],
    INDICES: [3],
    HINTS: [5, 1, 0],
  },
  {
    WORD: "CARTOONS",
    ALPHA: "ACNORST",
    LETTERS: ["O"],
    INDICES: [4],
    HINTS: [0, 1, 3],
  },
  {
    WORD: "HOMEPAGE",
    ALPHA: "AEEGHMP",
    LETTERS: ["O"],
    INDICES: [1],
    HINTS: [3, 5, 6],
  },
  {
    WORD: "CATALOGS",
    ALPHA: "ACGLOST",
    LETTERS: ["A"],
    INDICES: [3],
    HINTS: [2, 7, 0],
  },
  {
    WORD: "MEMORIAL",
    ALPHA: "AELMMOR",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [4, 7, 1],
  },
  {
    WORD: "STATUTES",
    ALPHA: "ESSTTTU",
    LETTERS: ["A"],
    INDICES: [2],
    HINTS: [6, 7, 0],
  },
  {
    WORD: "ADJACENT",
    ALPHA: "AACEJNT",
    LETTERS: ["D"],
    INDICES: [1],
    HINTS: [2, 6, 3],
  },
  {
    WORD: "LEARNING",
    ALPHA: "AEGLNNR",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [1, 6, 4],
  },
  {
    WORD: "SURVIVAL",
    ALPHA: "AILRSVV",
    LETTERS: ["U"],
    INDICES: [1],
    HINTS: [2, 6, 3],
  },
  {
    WORD: "STUDENTS",
    ALPHA: "DESSTTU",
    LETTERS: ["N"],
    INDICES: [5],
    HINTS: [4, 3, 7],
  },
  {
    WORD: "PARENTAL",
    ALPHA: "AAELPRT",
    LETTERS: ["N"],
    INDICES: [4],
    HINTS: [7, 6, 2],
  },
  {
    WORD: "SHOWCASE",
    ALPHA: "ACEHOSW",
    LETTERS: ["S"],
    INDICES: [6],
    HINTS: [2, 7, 4],
  },
  {
    WORD: "BLOCKING",
    ALPHA: "BCGILNO",
    LETTERS: ["K"],
    INDICES: [4],
    HINTS: [3, 1, 6],
  },
  {
    WORD: "FINISHED",
    ALPHA: "DEFIINS",
    LETTERS: ["H"],
    INDICES: [5],
    HINTS: [1, 4, 3],
  },
  {
    WORD: "PROBABLY",
    ALPHA: "BBLOPRY",
    LETTERS: ["A"],
    INDICES: [4],
    HINTS: [0, 5, 7],
  },
  {
    WORD: "SURPRISE",
    ALPHA: "EIPRRSU",
    LETTERS: ["S"],
    INDICES: [6],
    HINTS: [7, 3, 2],
  },
  {
    WORD: "BIBLICAL",
    ALPHA: "ABBIILL",
    LETTERS: ["C"],
    INDICES: [5],
    HINTS: [4, 1, 7],
  },
  {
    WORD: "EXISTING",
    ALPHA: "EGINSTX",
    LETTERS: ["I"],
    INDICES: [2],
    HINTS: [0, 4, 1],
  },
  {
    WORD: "EMIRATES",
    ALPHA: "EEIMRST",
    LETTERS: ["A"],
    INDICES: [4],
    HINTS: [2, 1, 5],
  },
  {
    WORD: "TEXTILES",
    ALPHA: "EEILSTX",
    LETTERS: ["T"],
    INDICES: [3],
    HINTS: [5, 1, 4],
  },
  {
    WORD: "PRODUCER",
    ALPHA: "DEOPRRU",
    LETTERS: ["C"],
    INDICES: [5],
    HINTS: [3, 1, 0],
  },
  {
    WORD: "CLUSTERS",
    ALPHA: "CELSSTU",
    LETTERS: ["R"],
    INDICES: [6],
    HINTS: [4, 3, 2],
  },
  {
    WORD: "INCREASE",
    ALPHA: "CEEINRS",
    LETTERS: ["A"],
    INDICES: [5],
    HINTS: [4, 6, 7],
  },
  {
    WORD: "POLISHED",
    ALPHA: "DEHILPS",
    LETTERS: ["O"],
    INDICES: [1],
    HINTS: [4, 7, 0],
  },
  {
    WORD: "THEORIES",
    ALPHA: "EHIORST",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [0, 5, 4],
  },
  {
    WORD: "COVERAGE",
    ALPHA: "ACEEGRV",
    LETTERS: ["O"],
    INDICES: [1],
    HINTS: [5, 7, 6],
  },
  {
    WORD: "FABULOUS",
    ALPHA: "ABFOSUU",
    LETTERS: ["L"],
    INDICES: [4],
    HINTS: [2, 5, 0],
  },
  {
    WORD: "TEACHERS",
    ALPHA: "CEEHRST",
    LETTERS: ["A"],
    INDICES: [2],
    HINTS: [6, 3, 7],
  },
  {
    WORD: "SPEAKERS",
    ALPHA: "AEEKRSS",
    LETTERS: ["P"],
    INDICES: [1],
    HINTS: [3, 2, 0],
  },
  {
    WORD: "CONSOLES",
    ALPHA: "CELNOOS",
    LETTERS: ["S"],
    INDICES: [3],
    HINTS: [1, 0, 5],
  },
  {
    WORD: "GOVERNOR",
    ALPHA: "GNOORRV",
    LETTERS: ["E"],
    INDICES: [3],
    HINTS: [2, 4, 7],
  },
  {
    WORD: "STRUGGLE",
    ALPHA: "EGLRSTU",
    LETTERS: ["G"],
    INDICES: [5],
    HINTS: [6, 0, 3],
  },
  {
    WORD: "DAUGHTER",
    ALPHA: "ADEHRTU",
    LETTERS: ["G"],
    INDICES: [3],
    HINTS: [7, 5, 0],
  },
  {
    WORD: "ENTITLED",
    ALPHA: "DEEILNT",
    LETTERS: ["T"],
    INDICES: [2],
    HINTS: [3, 5, 7],
  },
  {
    WORD: "DIRECTLY",
    ALPHA: "CDILRTY",
    LETTERS: ["E"],
    INDICES: [3],
    HINTS: [6, 4, 0],
  },
  {
    WORD: "HORRIBLE",
    ALPHA: "BEHIORR",
    LETTERS: ["L"],
    INDICES: [6],
    HINTS: [2, 7, 5],
  },
  {
    WORD: "PURSUANT",
    ALPHA: "ANPRSTU",
    LETTERS: ["U"],
    INDICES: [1],
    HINTS: [5, 6, 2],
  },
  {
    WORD: "KEYWORDS",
    ALPHA: "DEKORSY",
    LETTERS: ["W"],
    INDICES: [3],
    HINTS: [7, 5, 1],
  },
  {
    WORD: "GASOLINE",
    ALPHA: "EGILNOS",
    LETTERS: ["A"],
    INDICES: [1],
    HINTS: [2, 3, 6],
  },
  {
    WORD: "VELOCITY",
    ALPHA: "CEILOVY",
    LETTERS: ["T"],
    INDICES: [6],
    HINTS: [5, 0, 2],
  },
  {
    WORD: "APPARENT",
    ALPHA: "AAENPRT",
    LETTERS: ["P"],
    INDICES: [2],
    HINTS: [4, 7, 5],
  },
  {
    WORD: "PLANNERS",
    ALPHA: "AELNNPS",
    LETTERS: ["R"],
    INDICES: [6],
    HINTS: [5, 7, 2],
  },
  {
    WORD: "SURGICAL",
    ALPHA: "ACILRSU",
    LETTERS: ["G"],
    INDICES: [3],
    HINTS: [7, 5, 1],
  },
  {
    WORD: "OFFICERS",
    ALPHA: "CEFFIOS",
    LETTERS: ["R"],
    INDICES: [6],
    HINTS: [1, 5, 7],
  },
  {
    WORD: "POLICIES",
    ALPHA: "CEIILPS",
    LETTERS: ["O"],
    INDICES: [1],
    HINTS: [3, 5, 6],
  },
  {
    WORD: "PORTABLE",
    ALPHA: "ABELOPT",
    LETTERS: ["R"],
    INDICES: [2],
    HINTS: [6, 7, 0],
  },
  {
    WORD: "RELEASED",
    ALPHA: "ADEEELR",
    LETTERS: ["S"],
    INDICES: [5],
    HINTS: [1, 7, 6],
  },
  {
    WORD: "CHARGING",
    ALPHA: "ACGHINR",
    LETTERS: ["G"],
    INDICES: [4],
    HINTS: [2, 6, 5],
  },
  {
    WORD: "OUTDOORS",
    ALPHA: "DOORSTU",
    LETTERS: ["O"],
    INDICES: [4],
    HINTS: [7, 6, 2],
  },
  {
    WORD: "SWITCHED",
    ALPHA: "DEHISTW",
    LETTERS: ["C"],
    INDICES: [4],
    HINTS: [0, 1, 7],
  },
  {
    WORD: "ASSISTED",
    ALPHA: "ADEISST",
    LETTERS: ["S"],
    INDICES: [4],
    HINTS: [7, 5, 6],
  },
  {
    WORD: "ADVOCACY",
    ALPHA: "ACCDOVY",
    LETTERS: ["A"],
    INDICES: [5],
    HINTS: [1, 7, 2],
  },
  {
    WORD: "AVIATION",
    ALPHA: "AAIINOV",
    LETTERS: ["T"],
    INDICES: [4],
    HINTS: [1, 0, 7],
  },
  {
    WORD: "ACTUALLY",
    ALPHA: "AACLLUY",
    LETTERS: ["T"],
    INDICES: [2],
    HINTS: [0, 5, 4],
  },
  {
    WORD: "FINANCES",
    ALPHA: "ACEFINS",
    LETTERS: ["N"],
    INDICES: [2],
    HINTS: [7, 1, 5],
  },
  {
    WORD: "RELIABLE",
    ALPHA: "ABEILLR",
    LETTERS: ["E"],
    INDICES: [1],
    HINTS: [0, 3, 6],
  },
  {
    WORD: "TEMPORAL",
    ALPHA: "ALMOPRT",
    LETTERS: ["E"],
    INDICES: [1],
    HINTS: [3, 4, 0],
  },
  {
    WORD: "BRANCHES",
    ALPHA: "ABCHNRS",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [3, 5, 1],
  },
  {
    WORD: "SECTIONS",
    ALPHA: "CEINSST",
    LETTERS: ["O"],
    INDICES: [5],
    HINTS: [3, 4, 2],
  },
  {
    WORD: "FLASHING",
    ALPHA: "AFGHINS",
    LETTERS: ["L"],
    INDICES: [1],
    HINTS: [5, 2, 6],
  },
  {
    WORD: "DEPOSITS",
    ALPHA: "DEIOPSS",
    LETTERS: ["T"],
    INDICES: [6],
    HINTS: [1, 2, 0],
  },
  {
    WORD: "POSTINGS",
    ALPHA: "GINOPST",
    LETTERS: ["S"],
    INDICES: [2],
    HINTS: [1, 0, 3],
  },
  {
    WORD: "ENHANCED",
    ALPHA: "CDEEHNN",
    LETTERS: ["A"],
    INDICES: [3],
    HINTS: [2, 1, 0],
  },
  {
    WORD: "MISSIONS",
    ALPHA: "IIMOSSS",
    LETTERS: ["N"],
    INDICES: [6],
    HINTS: [1, 3, 4],
  },
  {
    WORD: "CROSSING",
    ALPHA: "CGIORSS",
    LETTERS: ["N"],
    INDICES: [6],
    HINTS: [7, 3, 1],
  },
  {
    WORD: "CONTRAST",
    ALPHA: "CNORSTT",
    LETTERS: ["A"],
    INDICES: [5],
    HINTS: [2, 6, 0],
  },
  {
    WORD: "INVASION",
    ALPHA: "AIINNOV",
    LETTERS: ["S"],
    INDICES: [4],
    HINTS: [1, 2, 6],
  },
  {
    WORD: "AQUARIUM",
    ALPHA: "AAIMQRU",
    LETTERS: ["U"],
    INDICES: [2],
    HINTS: [1, 3, 5],
  },
  {
    WORD: "CEMETERY",
    ALPHA: "CEEMRTY",
    LETTERS: ["E"],
    INDICES: [1],
    HINTS: [2, 7, 0],
  },
  {
    WORD: "MOVEMENT",
    ALPHA: "EEMMNOT",
    LETTERS: ["V"],
    INDICES: [2],
    HINTS: [5, 1, 4],
  },
  {
    WORD: "OVERCOME",
    ALPHA: "CEEMOOV",
    LETTERS: ["R"],
    INDICES: [3],
    HINTS: [6, 5, 4],
  },
  {
    WORD: "GUIDANCE",
    ALPHA: "ACDEGIN",
    LETTERS: ["U"],
    INDICES: [1],
    HINTS: [4, 0, 2],
  },
  {
    WORD: "TOMORROW",
    ALPHA: "MOORRTW",
    LETTERS: ["O"],
    INDICES: [3],
    HINTS: [0, 4, 2],
  },
  {
    WORD: "ENORMOUS",
    ALPHA: "EMNOOSU",
    LETTERS: ["R"],
    INDICES: [3],
    HINTS: [7, 5, 1],
  },
  {
    WORD: "JUDGMENT",
    ALPHA: "DEGJMNT",
    LETTERS: ["U"],
    INDICES: [1],
    HINTS: [0, 2, 3],
  },
  {
    WORD: "ACCOUNTS",
    ALPHA: "ACCNSTU",
    LETTERS: ["O"],
    INDICES: [3],
    HINTS: [4, 7, 5],
  },
  {
    WORD: "DISPATCH",
    ALPHA: "ACDHIPS",
    LETTERS: ["T"],
    INDICES: [5],
    HINTS: [0, 4, 1],
  },
  {
    WORD: "HANDHELD",
    ALPHA: "ADDHHLN",
    LETTERS: ["E"],
    INDICES: [5],
    HINTS: [3, 2, 6],
  },
  {
    WORD: "SENTENCE",
    ALPHA: "CEENNST",
    LETTERS: ["E"],
    INDICES: [4],
    HINTS: [3, 0, 5],
  },
  {
    WORD: "COLLEGES",
    ALPHA: "CEEGLOS",
    LETTERS: ["L"],
    INDICES: [3],
    HINTS: [4, 5, 1],
  },
  {
    WORD: "POSITIVE",
    ALPHA: "EIIOPSV",
    LETTERS: ["T"],
    INDICES: [4],
    HINTS: [6, 0, 5],
  },
  {
    WORD: "FIXTURES",
    ALPHA: "EFISTUX",
    LETTERS: ["R"],
    INDICES: [5],
    HINTS: [1, 7, 3],
  },
  {
    WORD: "EMPLOYED",
    ALPHA: "DEELMOY",
    LETTERS: ["P"],
    INDICES: [2],
    HINTS: [0, 1, 5],
  },
  {
    WORD: "PROVIDES",
    ALPHA: "DEIOPRS",
    LETTERS: ["V"],
    INDICES: [3],
    HINTS: [7, 6, 4],
  },
  {
    WORD: "REGISTER",
    ALPHA: "EEIRRST",
    LETTERS: ["G"],
    INDICES: [2],
    HINTS: [7, 5, 1],
  },
  {
    WORD: "ARRIVALS",
    ALPHA: "AAILRSV",
    LETTERS: ["R"],
    INDICES: [1],
    HINTS: [5, 6, 3],
  },
  {
    WORD: "ADVANCED",
    ALPHA: "ACDDENV",
    LETTERS: ["A"],
    INDICES: [3],
    HINTS: [7, 4, 5],
  },
  {
    WORD: "FIGHTING",
    ALPHA: "FGGHINT",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [0, 7, 4],
  },
  {
    WORD: "ACHIEVED",
    ALPHA: "ACDEHIV",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [2, 5, 0],
  },
  {
    WORD: "BLEEDING",
    ALPHA: "BDEEGIN",
    LETTERS: ["L"],
    INDICES: [1],
    HINTS: [3, 2, 4],
  },
  {
    WORD: "PRESENCE",
    ALPHA: "CEENPRS",
    LETTERS: ["E"],
    INDICES: [4],
    HINTS: [0, 5, 1],
  },
  {
    WORD: "RESTORED",
    ALPHA: "DEERRST",
    LETTERS: ["O"],
    INDICES: [4],
    HINTS: [7, 3, 5],
  },
  {
    WORD: "TEACHING",
    ALPHA: "ACEGINT",
    LETTERS: ["H"],
    INDICES: [4],
    HINTS: [1, 3, 5],
  },
  {
    WORD: "PURCHASE",
    ALPHA: "ACEPRSU",
    LETTERS: ["H"],
    INDICES: [4],
    HINTS: [5, 7, 0],
  },
  {
    WORD: "STRATEGY",
    ALPHA: "AEGRSTY",
    LETTERS: ["T"],
    INDICES: [4],
    HINTS: [0, 6, 3],
  },
  {
    WORD: "ASSUMING",
    ALPHA: "AGINSSU",
    LETTERS: ["M"],
    INDICES: [4],
    HINTS: [6, 5, 1],
  },
  {
    WORD: "MINERALS",
    ALPHA: "AEILMNS",
    LETTERS: ["R"],
    INDICES: [4],
    HINTS: [6, 7, 1],
  },
  {
    WORD: "OVERVIEW",
    ALPHA: "EIORVVW",
    LETTERS: ["E"],
    INDICES: [2],
    HINTS: [5, 0, 7],
  },
  {
    WORD: "MEDICARE",
    ALPHA: "CDEEIMR",
    LETTERS: ["A"],
    INDICES: [5],
    HINTS: [1, 3, 7],
  },
  {
    WORD: "CASSETTE",
    ALPHA: "ACEESTT",
    LETTERS: ["S"],
    INDICES: [3],
    HINTS: [4, 1, 5],
  },
  {
    WORD: "MATTRESS",
    ALPHA: "EMRSSTT",
    LETTERS: ["A"],
    INDICES: [1],
    HINTS: [7, 3, 6],
  },
  {
    WORD: "SETTINGS",
    ALPHA: "EGINSST",
    LETTERS: ["T"],
    INDICES: [2],
    HINTS: [1, 6, 0],
  },
  {
    WORD: "STRIKING",
    ALPHA: "GIIKNRS",
    LETTERS: ["T"],
    INDICES: [1],
    HINTS: [3, 7, 5],
  },
  {
    WORD: "APPENDIX",
    ALPHA: "ADEINPX",
    LETTERS: ["P"],
    INDICES: [2],
    HINTS: [6, 0, 7],
  },
  {
    WORD: "TALENTED",
    ALPHA: "ADELNTT",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [2, 1, 0],
  },
  {
    WORD: "EMPHASIS",
    ALPHA: "AEIMPSS",
    LETTERS: ["H"],
    INDICES: [3],
    HINTS: [2, 5, 4],
  },
  {
    WORD: "LANGUAGE",
    ALPHA: "AEGGLNU",
    LETTERS: ["A"],
    INDICES: [5],
    HINTS: [0, 7, 6],
  },
  {
    WORD: "FACILITY",
    ALPHA: "ACFIITY",
    LETTERS: ["L"],
    INDICES: [4],
    HINTS: [7, 2, 5],
  },
  {
    WORD: "LIGHTING",
    ALPHA: "GGHILNT",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [7, 2, 4],
  },
  {
    WORD: "CHECKING",
    ALPHA: "CEGHIKN",
    LETTERS: ["C"],
    INDICES: [3],
    HINTS: [1, 7, 5],
  },
  {
    WORD: "ANTIBODY",
    ALPHA: "ABDNOTY",
    LETTERS: ["I"],
    INDICES: [3],
    HINTS: [1, 0, 4],
  },
  {
    WORD: "PAYMENTS",
    ALPHA: "AEMNPST",
    LETTERS: ["Y"],
    INDICES: [2],
    HINTS: [5, 7, 4],
  },
  {
    WORD: "REACHING",
    ALPHA: "CEGHINR",
    LETTERS: ["A"],
    INDICES: [2],
    HINTS: [6, 1, 5],
  },
  {
    WORD: "HISTORIC",
    ALPHA: "CHIIORT",
    LETTERS: ["S"],
    INDICES: [2],
    HINTS: [7, 4, 5],
  },
  {
    WORD: "INFRARED",
    ALPHA: "ADFINRR",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [7, 1, 5],
  },
  {
    WORD: "EXAMINED",
    ALPHA: "ADEIMNX",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [1, 5, 3],
  },
  {
    WORD: "RESTRICT",
    ALPHA: "CEIRRTT",
    LETTERS: ["S"],
    INDICES: [2],
    HINTS: [6, 4, 7],
  },
  {
    WORD: "COMBINES",
    ALPHA: "BCEIMOS",
    LETTERS: ["N"],
    INDICES: [5],
    HINTS: [7, 6, 0],
  },
  {
    WORD: "CONTROLS",
    ALPHA: "CLNORST",
    LETTERS: ["O"],
    INDICES: [5],
    HINTS: [6, 4, 2],
  },
  {
    WORD: "SEMINARS",
    ALPHA: "EIMNRSS",
    LETTERS: ["A"],
    INDICES: [5],
    HINTS: [7, 2, 1],
  },
  {
    WORD: "REJECTED",
    ALPHA: "CDEEJRT",
    LETTERS: ["E"],
    INDICES: [1],
    HINTS: [4, 5, 7],
  },
  {
    WORD: "PROFILES",
    ALPHA: "EFILOPS",
    LETTERS: ["R"],
    INDICES: [1],
    HINTS: [5, 6, 0],
  },
  {
    WORD: "APPROVED",
    ALPHA: "ADEPPRV",
    LETTERS: ["O"],
    INDICES: [4],
    HINTS: [1, 2, 3],
  },
  {
    WORD: "INFECTED",
    ALPHA: "CDEFINT",
    LETTERS: ["E"],
    INDICES: [3],
    HINTS: [1, 7, 4],
  },
  {
    WORD: "TREASURE",
    ALPHA: "AERRSTU",
    LETTERS: ["E"],
    INDICES: [2],
    HINTS: [1, 5, 4],
  },
  {
    WORD: "MIDNIGHT",
    ALPHA: "DGHIIMT",
    LETTERS: ["N"],
    INDICES: [3],
    HINTS: [1, 5, 6],
  },
  {
    WORD: "SPONSORS",
    ALPHA: "NOOPSSS",
    LETTERS: ["R"],
    INDICES: [6],
    HINTS: [5, 0, 3],
  },
  {
    WORD: "RESELLER",
    ALPHA: "EELLRRS",
    LETTERS: ["E"],
    INDICES: [1],
    HINTS: [4, 7, 2],
  },
  {
    WORD: "INCIDENT",
    ALPHA: "CDEINNT",
    LETTERS: ["I"],
    INDICES: [3],
    HINTS: [2, 4, 5],
  },
  {
    WORD: "GREATEST",
    ALPHA: "AEGRSTT",
    LETTERS: ["E"],
    INDICES: [2],
    HINTS: [0, 4, 6],
  },
  {
    WORD: "STRANGER",
    ALPHA: "EGNRRST",
    LETTERS: ["A"],
    INDICES: [3],
    HINTS: [1, 2, 7],
  },
  {
    WORD: "UPGRADES",
    ALPHA: "DEGPRSU",
    LETTERS: ["A"],
    INDICES: [4],
    HINTS: [6, 0, 2],
  },
  {
    WORD: "SEGMENTS",
    ALPHA: "EEGNSST",
    LETTERS: ["M"],
    INDICES: [3],
    HINTS: [4, 0, 7],
  },
  {
    WORD: "NATURALS",
    ALPHA: "ALNRSTU",
    LETTERS: ["A"],
    INDICES: [1],
    HINTS: [4, 3, 7],
  },
  {
    WORD: "CONTACTS",
    ALPHA: "ACCOSTT",
    LETTERS: ["N"],
    INDICES: [2],
    HINTS: [0, 4, 3],
  },
  {
    WORD: "MILITARY",
    ALPHA: "AIIMRTY",
    LETTERS: ["L"],
    INDICES: [2],
    HINTS: [5, 4, 6],
  },
  {
    WORD: "STRENGTH",
    ALPHA: "EGHNRST",
    LETTERS: ["T"],
    INDICES: [6],
    HINTS: [5, 4, 2],
  },
  {
    WORD: "ADDITION",
    ALPHA: "ADIINOT",
    LETTERS: ["D"],
    INDICES: [1],
    HINTS: [7, 0, 6],
  },
  {
    WORD: "STRONGER",
    ALPHA: "ENORRST",
    LETTERS: ["G"],
    INDICES: [5],
    HINTS: [7, 6, 4],
  },
  {
    WORD: "ROOMMATE",
    ALPHA: "AEMMORT",
    LETTERS: ["O"],
    INDICES: [1],
    HINTS: [6, 4, 5],
  },
  {
    WORD: "REDUCING",
    ALPHA: "CDEGIRU",
    LETTERS: ["N"],
    INDICES: [6],
    HINTS: [4, 5, 2],
  },
  {
    WORD: "GEOMETRY",
    ALPHA: "EEGORTY",
    LETTERS: ["M"],
    INDICES: [3],
    HINTS: [5, 4, 0],
  },
  {
    WORD: "DONATION",
    ALPHA: "ADNNOOT",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [0, 2, 3],
  },
  {
    WORD: "PASSWORD",
    ALPHA: "ADOPRSS",
    LETTERS: ["W"],
    INDICES: [4],
    HINTS: [0, 6, 5],
  },
  {
    WORD: "FORESTRY",
    ALPHA: "FORRSTY",
    LETTERS: ["E"],
    INDICES: [3],
    HINTS: [4, 0, 7],
  },
  {
    WORD: "MINORITY",
    ALPHA: "IIMNORY",
    LETTERS: ["T"],
    INDICES: [6],
    HINTS: [3, 5, 0],
  },
  {
    WORD: "AIRLINES",
    ALPHA: "AIILNRS",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [2, 7, 5],
  },
  {
    WORD: "TRAINERS",
    ALPHA: "AINRRST",
    LETTERS: ["E"],
    INDICES: [5],
    HINTS: [7, 1, 3],
  },
  {
    WORD: "SCHEDULE",
    ALPHA: "CDEELSU",
    LETTERS: ["H"],
    INDICES: [2],
    HINTS: [1, 0, 6],
  },
  {
    WORD: "HYDROGEN",
    ALPHA: "DEGHNOY",
    LETTERS: ["R"],
    INDICES: [3],
    HINTS: [2, 5, 0],
  },
  {
    WORD: "BOOKMARK",
    ALPHA: "BKKMOOR",
    LETTERS: ["A"],
    INDICES: [5],
    HINTS: [3, 1, 2],
  },
  {
    WORD: "ANNUALLY",
    ALPHA: "AALLNUY",
    LETTERS: ["N"],
    INDICES: [1],
    HINTS: [0, 7, 5],
  },
  {
    WORD: "REMINDER",
    ALPHA: "DEEINRR",
    LETTERS: ["M"],
    INDICES: [2],
    HINTS: [3, 0, 7],
  },
  {
    WORD: "THRILLER",
    ALPHA: "EHLLRRT",
    LETTERS: ["I"],
    INDICES: [3],
    HINTS: [4, 2, 7],
  },
  {
    WORD: "CHEMICAL",
    ALPHA: "ACCEHIL",
    LETTERS: ["M"],
    INDICES: [3],
    HINTS: [2, 1, 6],
  },
  {
    WORD: "FORECAST",
    ALPHA: "ACEFORT",
    LETTERS: ["S"],
    INDICES: [6],
    HINTS: [2, 7, 1],
  },
  {
    WORD: "COMPILER",
    ALPHA: "CEIMOPR",
    LETTERS: ["L"],
    INDICES: [5],
    HINTS: [2, 1, 0],
  },
  {
    WORD: "DRILLING",
    ALPHA: "DGIILNR",
    LETTERS: ["L"],
    INDICES: [4],
    HINTS: [6, 7, 0],
  },
  {
    WORD: "BIRTHDAY",
    ALPHA: "BDHIRTY",
    LETTERS: ["A"],
    INDICES: [6],
    HINTS: [1, 2, 7],
  },
  {
    WORD: "DIRECTOR",
    ALPHA: "DEIORRT",
    LETTERS: ["C"],
    INDICES: [4],
    HINTS: [2, 6, 0],
  },
  {
    WORD: "EXCLUDED",
    ALPHA: "CDDEEUX",
    LETTERS: ["L"],
    INDICES: [3],
    HINTS: [4, 1, 7],
  },
  {
    WORD: "ELECTION",
    ALPHA: "CEILNOT",
    LETTERS: ["E"],
    INDICES: [2],
    HINTS: [3, 1, 5],
  },
  {
    WORD: "POWERFUL",
    ALPHA: "ELOPRUW",
    LETTERS: ["F"],
    INDICES: [5],
    HINTS: [7, 2, 4],
  },
  {
    WORD: "RELIANCE",
    ALPHA: "CEEILNR",
    LETTERS: ["A"],
    INDICES: [4],
    HINTS: [3, 0, 2],
  },
  {
    WORD: "ISOLATED",
    ALPHA: "DEILOST",
    LETTERS: ["A"],
    INDICES: [4],
    HINTS: [1, 6, 5],
  },
  {
    WORD: "ASSIGNED",
    ALPHA: "ADEGNSS",
    LETTERS: ["I"],
    INDICES: [3],
    HINTS: [5, 4, 6],
  },
  {
    WORD: "ORIGINAL",
    ALPHA: "AGILNOR",
    LETTERS: ["I"],
    INDICES: [4],
    HINTS: [3, 6, 1],
  },
  {
    WORD: "MEASURED",
    ALPHA: "ADEEMSU",
    LETTERS: ["R"],
    INDICES: [5],
    HINTS: [7, 6, 4],
  },
  {
    WORD: "SOMERSET",
    ALPHA: "EMORSST",
    LETTERS: ["E"],
    INDICES: [3],
    HINTS: [7, 0, 4],
  },
  {
    WORD: "PROVINCE",
    ALPHA: "CEINOPR",
    LETTERS: ["V"],
    INDICES: [3],
    HINTS: [7, 6, 1],
  },
  {
    WORD: "APPROVAL",
    ALPHA: "AALOPRV",
    LETTERS: ["P"],
    INDICES: [2],
    HINTS: [0, 3, 6],
  },
  {
    WORD: "CONCRETE",
    ALPHA: "CCEEORT",
    LETTERS: ["N"],
    INDICES: [2],
    HINTS: [4, 7, 6],
  },
  {
    WORD: "HANDMADE",
    ALPHA: "ADDEHMN",
    LETTERS: ["A"],
    INDICES: [5],
    HINTS: [6, 0, 4],
  },
  {
    WORD: "RANKINGS",
    ALPHA: "AIKNNRS",
    LETTERS: ["G"],
    INDICES: [6],
    HINTS: [7, 2, 5],
  },
  {
    WORD: "UNIVERSE",
    ALPHA: "EENRSUV",
    LETTERS: ["I"],
    INDICES: [2],
    HINTS: [5, 6, 7],
  },
  {
    WORD: "DISORDER",
    ALPHA: "DEIORRS",
    LETTERS: ["D"],
    INDICES: [5],
    HINTS: [6, 4, 7],
  },
  {
    WORD: "SPECTRUM",
    ALPHA: "CEMPRSU",
    LETTERS: ["T"],
    INDICES: [4],
    HINTS: [5, 2, 0],
  },
  {
    WORD: "OBSERVED",
    ALPHA: "BDEORSV",
    LETTERS: ["E"],
    INDICES: [3],
    HINTS: [0, 2, 1],
  },
  {
    WORD: "RESOURCE",
    ALPHA: "EEORRSU",
    LETTERS: ["C"],
    INDICES: [6],
    HINTS: [0, 1, 5],
  },
  {
    WORD: "ROMANTIC",
    ALPHA: "ACMNORT",
    LETTERS: ["I"],
    INDICES: [6],
    HINTS: [0, 4, 1],
  },
  {
    WORD: "BRINGING",
    ALPHA: "BGGINNR",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [7, 0, 6],
  },
  {
    WORD: "WILDLIFE",
    ALPHA: "DEFILLW",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [3, 4, 6],
  },
  {
    WORD: "COMMONLY",
    ALPHA: "CLMMOOY",
    LETTERS: ["N"],
    INDICES: [5],
    HINTS: [7, 2, 1],
  },
  {
    WORD: "LEARNERS",
    ALPHA: "AEELNRS",
    LETTERS: ["R"],
    INDICES: [6],
    HINTS: [0, 7, 5],
  },
  {
    WORD: "ANALYSTS",
    ALPHA: "AALNSSY",
    LETTERS: ["T"],
    INDICES: [6],
    HINTS: [1, 3, 2],
  },
  {
    WORD: "HUNDREDS",
    ALPHA: "DEHNRSU",
    LETTERS: ["D"],
    INDICES: [6],
    HINTS: [5, 1, 7],
  },
  {
    WORD: "MARSHALL",
    ALPHA: "AAHLLMR",
    LETTERS: ["S"],
    INDICES: [3],
    HINTS: [5, 7, 4],
  },
  {
    WORD: "CALENDAR",
    ALPHA: "ACDELNR",
    LETTERS: ["A"],
    INDICES: [1],
    HINTS: [7, 2, 4],
  },
  {
    WORD: "SPENDING",
    ALPHA: "DEGINNS",
    LETTERS: ["P"],
    INDICES: [1],
    HINTS: [5, 2, 7],
  },
  {
    WORD: "ELEPHANT",
    ALPHA: "AEEHLPT",
    LETTERS: ["N"],
    INDICES: [6],
    HINTS: [1, 5, 2],
  },
  {
    WORD: "INCOMING",
    ALPHA: "CGIIMNN",
    LETTERS: ["O"],
    INDICES: [3],
    HINTS: [7, 4, 5],
  },
  {
    WORD: "SWITCHES",
    ALPHA: "CEHISST",
    LETTERS: ["W"],
    INDICES: [1],
    HINTS: [4, 0, 5],
  },
  {
    WORD: "LECTURES",
    ALPHA: "CEELRST",
    LETTERS: ["U"],
    INDICES: [4],
    HINTS: [1, 2, 6],
  },
  {
    WORD: "BENJAMIN",
    ALPHA: "ABEIJNN",
    LETTERS: ["M"],
    INDICES: [5],
    HINTS: [7, 6, 4],
  },
  {
    WORD: "LITERACY",
    ALPHA: "AEILRTY",
    LETTERS: ["C"],
    INDICES: [6],
    HINTS: [7, 4, 3],
  },
  {
    WORD: "DESCRIBE",
    ALPHA: "BCDEEIR",
    LETTERS: ["S"],
    INDICES: [2],
    HINTS: [4, 1, 3],
  },
  {
    WORD: "TRANSFER",
    ALPHA: "EFNRRST",
    LETTERS: ["A"],
    INDICES: [2],
    HINTS: [3, 1, 0],
  },
  {
    WORD: "EDUCATED",
    ALPHA: "ACDDETU",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [1, 7, 2],
  },
  {
    WORD: "RETRIEVE",
    ALPHA: "EEEIRTV",
    LETTERS: ["R"],
    INDICES: [3],
    HINTS: [1, 5, 4],
  },
  {
    WORD: "SUPPLIED",
    ALPHA: "DEILPSU",
    LETTERS: ["P"],
    INDICES: [3],
    HINTS: [4, 5, 6],
  },
  {
    WORD: "SUDDENLY",
    ALPHA: "DDELSUY",
    LETTERS: ["N"],
    INDICES: [5],
    HINTS: [4, 7, 2],
  },
  {
    WORD: "CONTESTS",
    ALPHA: "CNOSSTT",
    LETTERS: ["E"],
    INDICES: [4],
    HINTS: [6, 3, 7],
  },
  {
    WORD: "EQUATION",
    ALPHA: "EINOQTU",
    LETTERS: ["A"],
    INDICES: [3],
    HINTS: [2, 1, 7],
  },
  {
    WORD: "CONGRESS",
    ALPHA: "CEGNRSS",
    LETTERS: ["O"],
    INDICES: [1],
    HINTS: [0, 7, 2],
  },
  {
    WORD: "BOUNDARY",
    ALPHA: "ABDNRUY",
    LETTERS: ["O"],
    INDICES: [1],
    HINTS: [6, 5, 3],
  },
  {
    WORD: "UNSIGNED",
    ALPHA: "DGINNSU",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [4, 7, 2],
  },
  {
    WORD: "ACCIDENT",
    ALPHA: "ACDEINT",
    LETTERS: ["C"],
    INDICES: [2],
    HINTS: [0, 7, 6],
  },
  {
    WORD: "TERMINAL",
    ALPHA: "AEILMNT",
    LETTERS: ["R"],
    INDICES: [2],
    HINTS: [4, 0, 7],
  },
  {
    WORD: "PREMIERE",
    ALPHA: "EEIMPRR",
    LETTERS: ["E"],
    INDICES: [2],
    HINTS: [4, 6, 0],
  },
  {
    WORD: "PENSIONS",
    ALPHA: "EINOPSS",
    LETTERS: ["N"],
    INDICES: [6],
    HINTS: [7, 1, 0],
  },
  {
    WORD: "INVOLVED",
    ALPHA: "DEINOVV",
    LETTERS: ["L"],
    INDICES: [4],
    HINTS: [6, 5, 3],
  },
  {
    WORD: "RINGTONE",
    ALPHA: "EGINNOR",
    LETTERS: ["T"],
    INDICES: [4],
    HINTS: [6, 7, 0],
  },
  {
    WORD: "FRIENDLY",
    ALPHA: "DEFINRY",
    LETTERS: ["L"],
    INDICES: [6],
    HINTS: [0, 4, 7],
  },
  {
    WORD: "GRADUATE",
    ALPHA: "ADEGRTU",
    LETTERS: ["A"],
    INDICES: [2],
    HINTS: [7, 0, 3],
  },
  {
    WORD: "EXCHANGE",
    ALPHA: "CEEGHNX",
    LETTERS: ["A"],
    INDICES: [4],
    HINTS: [7, 2, 1],
  },
  {
    WORD: "STERLING",
    ALPHA: "EGLNRST",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [4, 6, 3],
  },
  {
    WORD: "NORMALLY",
    ALPHA: "ALMNORY",
    LETTERS: ["L"],
    INDICES: [5],
    HINTS: [3, 4, 0],
  },
  {
    WORD: "DEADLINE",
    ALPHA: "ADEEILN",
    LETTERS: ["D"],
    INDICES: [3],
    HINTS: [1, 4, 7],
  },
  {
    WORD: "BELIEVED",
    ALPHA: "BDEEILV",
    LETTERS: ["E"],
    INDICES: [1],
    HINTS: [5, 0, 3],
  },
  {
    WORD: "STARTING",
    ALPHA: "AGINSTT",
    LETTERS: ["R"],
    INDICES: [3],
    HINTS: [6, 1, 5],
  },
  {
    WORD: "RELIGION",
    ALPHA: "EGIINOR",
    LETTERS: ["L"],
    INDICES: [2],
    HINTS: [1, 0, 4],
  },
  {
    WORD: "SENATORS",
    ALPHA: "AENORSS",
    LETTERS: ["T"],
    INDICES: [4],
    HINTS: [0, 6, 7],
  },
  {
    WORD: "ENGAGING",
    ALPHA: "AEGGGIN",
    LETTERS: ["N"],
    INDICES: [1],
    HINTS: [3, 2, 5],
  },
  {
    WORD: "INSTANCE",
    ALPHA: "CEINNST",
    LETTERS: ["A"],
    INDICES: [4],
    HINTS: [2, 3, 7],
  },
  {
    WORD: "BASEMENT",
    ALPHA: "ABEENST",
    LETTERS: ["M"],
    INDICES: [4],
    HINTS: [5, 2, 7],
  },
  {
    WORD: "CITIZENS",
    ALPHA: "CEIISTZ",
    LETTERS: ["N"],
    INDICES: [6],
    HINTS: [0, 1, 7],
  },
  {
    WORD: "DIAMETER",
    ALPHA: "ADEEIMR",
    LETTERS: ["T"],
    INDICES: [5],
    HINTS: [3, 6, 7],
  },
  {
    WORD: "ENGINEER",
    ALPHA: "EEEINNR",
    LETTERS: ["G"],
    INDICES: [2],
    HINTS: [6, 5, 1],
  },
  {
    WORD: "CLOTHING",
    ALPHA: "CGHINOT",
    LETTERS: ["L"],
    INDICES: [1],
    HINTS: [7, 4, 5],
  },
  {
    WORD: "ACCESSED",
    ALPHA: "ACDEESS",
    LETTERS: ["C"],
    INDICES: [2],
    HINTS: [4, 0, 5],
  },
  {
    WORD: "CIVILIAN",
    ALPHA: "ACIIINV",
    LETTERS: ["L"],
    INDICES: [4],
    HINTS: [5, 0, 3],
  },
  {
    WORD: "MAJORITY",
    ALPHA: "AIJMORY",
    LETTERS: ["T"],
    INDICES: [6],
    HINTS: [2, 4, 0],
  },
  {
    WORD: "OVERHEAD",
    ALPHA: "ADEEHOV",
    LETTERS: ["R"],
    INDICES: [3],
    HINTS: [5, 2, 1],
  },
  {
    WORD: "TARGETED",
    ALPHA: "ADEEGRT",
    LETTERS: ["T"],
    INDICES: [5],
    HINTS: [1, 6, 7],
  },
  {
    WORD: "WHENEVER",
    ALPHA: "EEENRVW",
    LETTERS: ["H"],
    INDICES: [1],
    HINTS: [5, 3, 0],
  },
  {
    WORD: "PREMISES",
    ALPHA: "EEIPRSS",
    LETTERS: ["M"],
    INDICES: [3],
    HINTS: [7, 2, 0],
  },
  {
    WORD: "CAPTURED",
    ALPHA: "CDEPRTU",
    LETTERS: ["A"],
    INDICES: [1],
    HINTS: [7, 5, 6],
  },
  {
    WORD: "VALIDITY",
    ALPHA: "ADILTVY",
    LETTERS: ["I"],
    INDICES: [3],
    HINTS: [1, 0, 2],
  },
  {
    WORD: "DISPUTES",
    ALPHA: "DEIPSTU",
    LETTERS: ["S"],
    INDICES: [2],
    HINTS: [3, 0, 6],
  },
  {
    WORD: "PROCEEDS",
    ALPHA: "CDEOPRS",
    LETTERS: ["E"],
    INDICES: [4],
    HINTS: [2, 1, 6],
  },
  {
    WORD: "PARALLEL",
    ALPHA: "AAELLLP",
    LETTERS: ["R"],
    INDICES: [2],
    HINTS: [7, 0, 1],
  },
  {
    WORD: "BREAKING",
    ALPHA: "ABEGIKN",
    LETTERS: ["R"],
    INDICES: [1],
    HINTS: [3, 6, 4],
  },
  {
    WORD: "MUSICIAN",
    ALPHA: "ACIIMNU",
    LETTERS: ["S"],
    INDICES: [2],
    HINTS: [3, 6, 4],
  },
  {
    WORD: "NICKNAME",
    ALPHA: "ACEKMNN",
    LETTERS: ["I"],
    INDICES: [1],
    HINTS: [3, 4, 5],
  },
  {
    WORD: "FILENAME",
    ALPHA: "AEEFIMN",
    LETTERS: ["L"],
    INDICES: [2],
    HINTS: [6, 3, 5],
  },
  {
    WORD: "PEACEFUL",
    ALPHA: "ACEEFLP",
    LETTERS: ["U"],
    INDICES: [6],
    HINTS: [2, 5, 1],
  },
  {
    WORD: "SUBURBAN",
    ALPHA: "ABNRSUU",
    LETTERS: ["B"],
    INDICES: [5],
    HINTS: [6, 1, 0],
  },
  {
    WORD: "SHOULDER",
    ALPHA: "DEHLORS",
    LETTERS: ["U"],
    INDICES: [3],
    HINTS: [7, 2, 1],
  },
  {
    WORD: "ADAPTERS",
    ALPHA: "AADPRST",
    LETTERS: ["E"],
    INDICES: [5],
    HINTS: [0, 6, 7],
  },
  {
    WORD: "STATIONS",
    ALPHA: "AIOSSTT",
    LETTERS: ["N"],
    INDICES: [6],
    HINTS: [3, 4, 0],
  },
  {
    WORD: "DISTINCT",
    ALPHA: "CDIISTT",
    LETTERS: ["N"],
    INDICES: [5],
    HINTS: [4, 2, 7],
  },
  {
    WORD: "EMPLOYER",
    ALPHA: "EELMPRY",
    LETTERS: ["O"],
    INDICES: [4],
    HINTS: [7, 2, 3],
  },
  {
    WORD: "TRIBUNAL",
    ALPHA: "ABLNRTU",
    LETTERS: ["I"],
    INDICES: [2],
    HINTS: [4, 7, 1],
  },
  {
    WORD: "HEARINGS",
    ALPHA: "AEGHIRS",
    LETTERS: ["N"],
    INDICES: [5],
    HINTS: [6, 3, 7],
  },
  {
    WORD: "EXTENDED",
    ALPHA: "DDEENTX",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [4, 5, 1],
  },
  {
    WORD: "CHARMING",
    ALPHA: "ACGIMNR",
    LETTERS: ["H"],
    INDICES: [1],
    HINTS: [3, 6, 5],
  },
  {
    WORD: "HANDBAGS",
    ALPHA: "ABDGHNS",
    LETTERS: ["A"],
    INDICES: [5],
    HINTS: [7, 6, 3],
  },
  {
    WORD: "REVENUES",
    ALPHA: "EEERSUV",
    LETTERS: ["N"],
    INDICES: [4],
    HINTS: [7, 1, 5],
  },
  {
    WORD: "MINISTER",
    ALPHA: "EIIMNRT",
    LETTERS: ["S"],
    INDICES: [4],
    HINTS: [6, 5, 0],
  },
  {
    WORD: "COMPUTED",
    ALPHA: "CDEMOPT",
    LETTERS: ["U"],
    INDICES: [4],
    HINTS: [5, 0, 3],
  },
  {
    WORD: "BEGINNER",
    ALPHA: "BEEGINR",
    LETTERS: ["N"],
    INDICES: [5],
    HINTS: [6, 7, 3],
  },
  {
    WORD: "PROPOSED",
    ALPHA: "DEOOPRS",
    LETTERS: ["P"],
    INDICES: [3],
    HINTS: [1, 5, 7],
  },
  {
    WORD: "NORTHERN",
    ALPHA: "EHNNRRT",
    LETTERS: ["O"],
    INDICES: [1],
    HINTS: [3, 7, 0],
  },
  {
    WORD: "AUCTIONS",
    ALPHA: "AINOSTU",
    LETTERS: ["C"],
    INDICES: [2],
    HINTS: [7, 3, 4],
  },
  {
    WORD: "CONCEPTS",
    ALPHA: "CCEOPST",
    LETTERS: ["N"],
    INDICES: [2],
    HINTS: [3, 7, 4],
  },
  {
    WORD: "INVESTOR",
    ALPHA: "EINORSV",
    LETTERS: ["T"],
    INDICES: [5],
    HINTS: [1, 2, 0],
  },
  {
    WORD: "CORDLESS",
    ALPHA: "CDELRSS",
    LETTERS: ["O"],
    INDICES: [1],
    HINTS: [0, 7, 2],
  },
  {
    WORD: "DOCTRINE",
    ALPHA: "DEINORT",
    LETTERS: ["C"],
    INDICES: [2],
    HINTS: [0, 5, 1],
  },
  {
    WORD: "CONSTANT",
    ALPHA: "ACNNSTT",
    LETTERS: ["O"],
    INDICES: [1],
    HINTS: [3, 5, 6],
  },
  {
    WORD: "INFINITE",
    ALPHA: "EFIINNT",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [4, 6, 2],
  },
  {
    WORD: "DETECTED",
    ALPHA: "CDDEETT",
    LETTERS: ["E"],
    INDICES: [3],
    HINTS: [7, 2, 4],
  },
  {
    WORD: "ARRANGED",
    ALPHA: "AADENRR",
    LETTERS: ["G"],
    INDICES: [5],
    HINTS: [2, 1, 4],
  },
  {
    WORD: "DECISION",
    ALPHA: "CDEINOS",
    LETTERS: ["I"],
    INDICES: [3],
    HINTS: [0, 1, 4],
  },
  {
    WORD: "ENJOYING",
    ALPHA: "EGIJNOY",
    LETTERS: ["N"],
    INDICES: [6],
    HINTS: [5, 4, 7],
  },
  {
    WORD: "BLOGGING",
    ALPHA: "BGGILNO",
    LETTERS: ["G"],
    INDICES: [4],
    HINTS: [2, 0, 1],
  },
  {
    WORD: "PLUMBING",
    ALPHA: "BGIMNPU",
    LETTERS: ["L"],
    INDICES: [1],
    HINTS: [3, 5, 2],
  },
  {
    WORD: "REFUGEES",
    ALPHA: "EEFGRSU",
    LETTERS: ["E"],
    INDICES: [5],
    HINTS: [2, 0, 7],
  },
  {
    WORD: "SHEPHERD",
    ALPHA: "DEHHPRS",
    LETTERS: ["E"],
    INDICES: [2],
    HINTS: [1, 4, 0],
  },
  {
    WORD: "FOOTWEAR",
    ALPHA: "AEFOORT",
    LETTERS: ["W"],
    INDICES: [4],
    HINTS: [7, 0, 1],
  },
  {
    WORD: "WATCHING",
    ALPHA: "ACGINTW",
    LETTERS: ["H"],
    INDICES: [4],
    HINTS: [7, 2, 1],
  },
  {
    WORD: "RELEASES",
    ALPHA: "AEELRSS",
    LETTERS: ["E"],
    INDICES: [3],
    HINTS: [0, 2, 5],
  },
  {
    WORD: "UPDATING",
    ALPHA: "ADGNPTU",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [1, 3, 4],
  },
  {
    WORD: "PROPERLY",
    ALPHA: "ELPPRRY",
    LETTERS: ["O"],
    INDICES: [2],
    HINTS: [5, 6, 7],
  },
  {
    WORD: "REPLACED",
    ALPHA: "ADEELPR",
    LETTERS: ["C"],
    INDICES: [5],
    HINTS: [6, 2, 1],
  },
  {
    WORD: "PETITION",
    ALPHA: "EINOPTT",
    LETTERS: ["I"],
    INDICES: [3],
    HINTS: [1, 4, 7],
  },
  {
    WORD: "CLIMBING",
    ALPHA: "BCGIILM",
    LETTERS: ["N"],
    INDICES: [6],
    HINTS: [5, 2, 3],
  },
  {
    WORD: "PLATINUM",
    ALPHA: "AIMNPTU",
    LETTERS: ["L"],
    INDICES: [1],
    HINTS: [0, 5, 7],
  },
  {
    WORD: "VISITORS",
    ALPHA: "IIORSTV",
    LETTERS: ["S"],
    INDICES: [2],
    HINTS: [6, 1, 5],
  },
  {
    WORD: "DETAILED",
    ALPHA: "DDEEILT",
    LETTERS: ["A"],
    INDICES: [3],
    HINTS: [1, 2, 4],
  },
  {
    WORD: "COMPILED",
    ALPHA: "CDEIMOP",
    LETTERS: ["L"],
    INDICES: [5],
    HINTS: [1, 0, 3],
  },
  {
    WORD: "DISASTER",
    ALPHA: "ADEIRST",
    LETTERS: ["S"],
    INDICES: [4],
    HINTS: [6, 7, 1],
  },
  {
    WORD: "PHARMACY",
    ALPHA: "AACHPRY",
    LETTERS: ["M"],
    INDICES: [4],
    HINTS: [1, 7, 0],
  },
  {
    WORD: "ELECTRON",
    ALPHA: "CEELNOT",
    LETTERS: ["R"],
    INDICES: [5],
    HINTS: [1, 0, 4],
  },
  {
    WORD: "ENDORSED",
    ALPHA: "DDEENRS",
    LETTERS: ["O"],
    INDICES: [3],
    HINTS: [1, 6, 7],
  },
  {
    WORD: "MERCHANT",
    ALPHA: "ACHMNRT",
    LETTERS: ["E"],
    INDICES: [1],
    HINTS: [7, 2, 0],
  },
  {
    WORD: "ARCHIVES",
    ALPHA: "ACEHRSV",
    LETTERS: ["I"],
    INDICES: [4],
    HINTS: [2, 7, 1],
  },
  {
    WORD: "INTEREST",
    ALPHA: "EEINRTT",
    LETTERS: ["S"],
    INDICES: [6],
    HINTS: [5, 4, 0],
  },
  {
    WORD: "COLLAPSE",
    ALPHA: "ACELOPS",
    LETTERS: ["L"],
    INDICES: [2],
    HINTS: [4, 6, 0],
  },
  {
    WORD: "PRINTING",
    ALPHA: "GINNPRT",
    LETTERS: ["I"],
    INDICES: [2],
    HINTS: [1, 0, 6],
  },
  {
    WORD: "BUSINESS",
    ALPHA: "BENSSSU",
    LETTERS: ["I"],
    INDICES: [3],
    HINTS: [7, 4, 5],
  },
  {
    WORD: "TUTORIAL",
    ALPHA: "ALORTTU",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [4, 2, 1],
  },
  {
    WORD: "BOUTIQUE",
    ALPHA: "BEIOTUU",
    LETTERS: ["Q"],
    INDICES: [5],
    HINTS: [1, 4, 3],
  },
  {
    WORD: "MAGNETIC",
    ALPHA: "ACEGIMT",
    LETTERS: ["N"],
    INDICES: [3],
    HINTS: [0, 7, 4],
  },
  {
    WORD: "INTENDED",
    ALPHA: "DDEEINN",
    LETTERS: ["T"],
    INDICES: [2],
    HINTS: [4, 6, 1],
  },
  {
    WORD: "THEATERS",
    ALPHA: "AEHRSTT",
    LETTERS: ["E"],
    INDICES: [5],
    HINTS: [0, 3, 4],
  },
  {
    WORD: "ATHLETIC",
    ALPHA: "ACEHITT",
    LETTERS: ["L"],
    INDICES: [3],
    HINTS: [7, 2, 0],
  },
  {
    WORD: "APPLYING",
    ALPHA: "AGILNPY",
    LETTERS: ["P"],
    INDICES: [1],
    HINTS: [0, 3, 6],
  },
  {
    WORD: "OVERSEAS",
    ALPHA: "AEORSSV",
    LETTERS: ["E"],
    INDICES: [5],
    HINTS: [1, 4, 6],
  },
  {
    WORD: "THROWING",
    ALPHA: "GHINORT",
    LETTERS: ["W"],
    INDICES: [4],
    HINTS: [0, 2, 5],
  },
  {
    WORD: "HIGHWAYS",
    ALPHA: "AGHHSWY",
    LETTERS: ["I"],
    INDICES: [1],
    HINTS: [4, 0, 7],
  },
  {
    WORD: "BRACELET",
    ALPHA: "ABEELRT",
    LETTERS: ["C"],
    INDICES: [3],
    HINTS: [1, 2, 5],
  },
  {
    WORD: "COMBINED",
    ALPHA: "BCDEINO",
    LETTERS: ["M"],
    INDICES: [2],
    HINTS: [5, 1, 4],
  },
  {
    WORD: "ASBESTOS",
    ALPHA: "ABEOSST",
    LETTERS: ["S"],
    INDICES: [1],
    HINTS: [5, 0, 3],
  },
  {
    WORD: "DOWNTOWN",
    ALPHA: "DNNOTWW",
    LETTERS: ["O"],
    INDICES: [5],
    HINTS: [3, 7, 4],
  },
  {
    WORD: "ATTEMPTS",
    ALPHA: "AMPSTTT",
    LETTERS: ["E"],
    INDICES: [3],
    HINTS: [2, 0, 6],
  },
  {
    WORD: "METALLIC",
    ALPHA: "ACELLMT",
    LETTERS: ["I"],
    INDICES: [6],
    HINTS: [7, 5, 2],
  },
  {
    WORD: "ANSWERED",
    ALPHA: "ADEENRW",
    LETTERS: ["S"],
    INDICES: [2],
    HINTS: [1, 5, 7],
  },
  {
    WORD: "MONITORS",
    ALPHA: "IMNOOST",
    LETTERS: ["R"],
    INDICES: [6],
    HINTS: [3, 5, 2],
  },
  {
    WORD: "SEMESTER",
    ALPHA: "EEEMRSS",
    LETTERS: ["T"],
    INDICES: [5],
    HINTS: [7, 6, 4],
  },
  {
    WORD: "PRESENTS",
    ALPHA: "ENPRSST",
    LETTERS: ["E"],
    INDICES: [2],
    HINTS: [3, 7, 5],
  },
  {
    WORD: "HARDWOOD",
    ALPHA: "DDHOORW",
    LETTERS: ["A"],
    INDICES: [1],
    HINTS: [7, 3, 2],
  },
  {
    WORD: "HOSPITAL",
    ALPHA: "AHILPST",
    LETTERS: ["O"],
    INDICES: [1],
    HINTS: [0, 4, 6],
  },
  {
    WORD: "GUARDIAN",
    ALPHA: "ADGINRU",
    LETTERS: ["A"],
    INDICES: [6],
    HINTS: [7, 3, 1],
  },
  {
    WORD: "IMPAIRED",
    ALPHA: "ADIIMPR",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [2, 5, 1],
  },
  {
    WORD: "CITATION",
    ALPHA: "ACIINOT",
    LETTERS: ["T"],
    INDICES: [4],
    HINTS: [3, 1, 6],
  },
  {
    WORD: "RESIDENT",
    ALPHA: "DEENRST",
    LETTERS: ["I"],
    INDICES: [3],
    HINTS: [0, 7, 2],
  },
  {
    WORD: "FEATURED",
    ALPHA: "ADEEFTU",
    LETTERS: ["R"],
    INDICES: [5],
    HINTS: [4, 7, 6],
  },
  {
    WORD: "SNAPSHOT",
    ALPHA: "AHOPSST",
    LETTERS: ["N"],
    INDICES: [1],
    HINTS: [6, 5, 7],
  },
  {
    WORD: "MILLIONS",
    ALPHA: "ILLMNOS",
    LETTERS: ["I"],
    INDICES: [4],
    HINTS: [7, 6, 3],
  },
  {
    WORD: "THINKING",
    ALPHA: "GHIKNNT",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [4, 0, 1],
  },
  {
    WORD: "KNITTING",
    ALPHA: "GIKNNTT",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [1, 7, 0],
  },
  {
    WORD: "BARGAINS",
    ALPHA: "AABGINS",
    LETTERS: ["R"],
    INDICES: [2],
    HINTS: [4, 5, 3],
  },
  {
    WORD: "DYNAMICS",
    ALPHA: "ACDIMNS",
    LETTERS: ["Y"],
    INDICES: [1],
    HINTS: [6, 3, 7],
  },
  {
    WORD: "CHARGERS",
    ALPHA: "ACEHRRS",
    LETTERS: ["G"],
    INDICES: [4],
    HINTS: [1, 0, 5],
  },
  {
    WORD: "BRIEFING",
    ALPHA: "BEFGIIN",
    LETTERS: ["R"],
    INDICES: [1],
    HINTS: [4, 0, 2],
  },
  {
    WORD: "USERNAME",
    ALPHA: "AEEMNSU",
    LETTERS: ["R"],
    INDICES: [3],
    HINTS: [2, 7, 1],
  },
  {
    WORD: "HERITAGE",
    ALPHA: "AEEGHIR",
    LETTERS: ["T"],
    INDICES: [4],
    HINTS: [3, 5, 2],
  },
  {
    WORD: "GLOSSARY",
    ALPHA: "GLORSSY",
    LETTERS: ["A"],
    INDICES: [5],
    HINTS: [4, 3, 6],
  },
  {
    WORD: "SEQUENCE",
    ALPHA: "CEEENSU",
    LETTERS: ["Q"],
    INDICES: [2],
    HINTS: [7, 6, 0],
  },
  {
    WORD: "CUSTOMER",
    ALPHA: "CEMRSTU",
    LETTERS: ["O"],
    INDICES: [4],
    HINTS: [7, 0, 6],
  },
  {
    WORD: "EMPLOYEE",
    ALPHA: "EEEMOPY",
    LETTERS: ["L"],
    INDICES: [3],
    HINTS: [4, 6, 0],
  },
  {
    WORD: "ELEMENTS",
    ALPHA: "EEELMNS",
    LETTERS: ["T"],
    INDICES: [6],
    HINTS: [3, 5, 0],
  },
  {
    WORD: "POSTCARD",
    ALPHA: "ACDOPRS",
    LETTERS: ["T"],
    INDICES: [3],
    HINTS: [2, 0, 5],
  },
  {
    WORD: "SAMPLING",
    ALPHA: "AGILMNS",
    LETTERS: ["P"],
    INDICES: [3],
    HINTS: [1, 6, 7],
  },
  {
    WORD: "SUGGESTS",
    ALPHA: "EGGSSSU",
    LETTERS: ["T"],
    INDICES: [6],
    HINTS: [2, 0, 7],
  },
  {
    WORD: "REGISTRY",
    ALPHA: "EGIRRTY",
    LETTERS: ["S"],
    INDICES: [4],
    HINTS: [1, 7, 0],
  },
  {
    WORD: "PODCASTS",
    ALPHA: "CDOPSST",
    LETTERS: ["A"],
    INDICES: [4],
    HINTS: [0, 6, 2],
  },
  {
    WORD: "INSERTED",
    ALPHA: "DEEINST",
    LETTERS: ["R"],
    INDICES: [4],
    HINTS: [3, 2, 5],
  },
  {
    WORD: "RECENTLY",
    ALPHA: "CELNRTY",
    LETTERS: ["E"],
    INDICES: [3],
    HINTS: [6, 4, 7],
  },
  {
    WORD: "ENTITIES",
    ALPHA: "EEIINST",
    LETTERS: ["T"],
    INDICES: [2],
    HINTS: [1, 6, 7],
  },
  {
    WORD: "MISTRESS",
    ALPHA: "IMRSSST",
    LETTERS: ["E"],
    INDICES: [5],
    HINTS: [6, 4, 7],
  },
  {
    WORD: "DISABLED",
    ALPHA: "ABDDEIS",
    LETTERS: ["L"],
    INDICES: [5],
    HINTS: [4, 2, 1],
  },
  {
    WORD: "PARADISE",
    ALPHA: "AAEIPRS",
    LETTERS: ["D"],
    INDICES: [4],
    HINTS: [6, 2, 5],
  },
  {
    WORD: "DURATION",
    ALPHA: "DINORTU",
    LETTERS: ["A"],
    INDICES: [3],
    HINTS: [5, 2, 7],
  },
  {
    WORD: "TRAVELER",
    ALPHA: "EELRRTV",
    LETTERS: ["A"],
    INDICES: [2],
    HINTS: [3, 7, 0],
  },
  {
    WORD: "ASSEMBLY",
    ALPHA: "ABLMSSY",
    LETTERS: ["E"],
    INDICES: [3],
    HINTS: [4, 5, 0],
  },
  {
    WORD: "THOUGHTS",
    ALPHA: "GHOSTTU",
    LETTERS: ["H"],
    INDICES: [1],
    HINTS: [6, 3, 2],
  },
  {
    WORD: "CABINETS",
    ALPHA: "ACEINST",
    LETTERS: ["B"],
    INDICES: [2],
    HINTS: [1, 5, 6],
  },
  {
    WORD: "FINDINGS",
    ALPHA: "DFGINNS",
    LETTERS: ["I"],
    INDICES: [1],
    HINTS: [7, 5, 2],
  },
  {
    WORD: "WELLNESS",
    ALPHA: "EELLSSW",
    LETTERS: ["N"],
    INDICES: [4],
    HINTS: [1, 5, 7],
  },
  {
    WORD: "CREATING",
    ALPHA: "ACGINRT",
    LETTERS: ["E"],
    INDICES: [2],
    HINTS: [5, 7, 0],
  },
  {
    WORD: "MOREOVER",
    ALPHA: "EMOORRV",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [2, 4, 7],
  },
  {
    WORD: "BACHELOR",
    ALPHA: "BCEHLOR",
    LETTERS: ["A"],
    INDICES: [1],
    HINTS: [5, 2, 7],
  },
  {
    WORD: "BASEBALL",
    ALPHA: "AABBLLS",
    LETTERS: ["E"],
    INDICES: [3],
    HINTS: [0, 5, 6],
  },
  {
    WORD: "COOKBOOK",
    ALPHA: "BCKKOOO",
    LETTERS: ["O"],
    INDICES: [6],
    HINTS: [4, 7, 0],
  },
  {
    WORD: "SCANNING",
    ALPHA: "ACGINNS",
    LETTERS: ["N"],
    INDICES: [4],
    HINTS: [2, 5, 1],
  },
  {
    WORD: "BROTHERS",
    ALPHA: "BEHORST",
    LETTERS: ["R"],
    INDICES: [6],
    HINTS: [0, 5, 2],
  },
  {
    WORD: "FRACTION",
    ALPHA: "ACFNORT",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [7, 1, 3],
  },
  {
    WORD: "OPERATOR",
    ALPHA: "AEOOPRT",
    LETTERS: ["R"],
    INDICES: [3],
    HINTS: [6, 5, 1],
  },
  {
    WORD: "GANGBANG",
    ALPHA: "AABGGGN",
    LETTERS: ["N"],
    INDICES: [2],
    HINTS: [5, 0, 7],
  },
  {
    WORD: "ACOUSTIC",
    ALPHA: "ACCIOTU",
    LETTERS: ["S"],
    INDICES: [4],
    HINTS: [6, 1, 3],
  },
  {
    WORD: "QUARTERS",
    ALPHA: "EQRRSTU",
    LETTERS: ["A"],
    INDICES: [2],
    HINTS: [4, 6, 1],
  },
  {
    WORD: "SURFACES",
    ALPHA: "AEFRSSU",
    LETTERS: ["C"],
    INDICES: [5],
    HINTS: [7, 6, 0],
  },
  {
    WORD: "ABSOLUTE",
    ALPHA: "ABELOTU",
    LETTERS: ["S"],
    INDICES: [2],
    HINTS: [5, 6, 7],
  },
  {
    WORD: "PROMISED",
    ALPHA: "DIMOPRS",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [3, 1, 4],
  },
  {
    WORD: "BROCHURE",
    ALPHA: "BEHORRU",
    LETTERS: ["C"],
    INDICES: [3],
    HINTS: [2, 1, 6],
  },
  {
    WORD: "EVALUATE",
    ALPHA: "AAEELUV",
    LETTERS: ["T"],
    INDICES: [6],
    HINTS: [5, 0, 1],
  },
  {
    WORD: "ADJUSTED",
    ALPHA: "ADDEJST",
    LETTERS: ["U"],
    INDICES: [3],
    HINTS: [1, 7, 6],
  },
  {
    WORD: "ADVOCATE",
    ALPHA: "AACDEOT",
    LETTERS: ["V"],
    INDICES: [2],
    HINTS: [6, 1, 4],
  },
  {
    WORD: "RECEPTOR",
    ALPHA: "CEOPRRT",
    LETTERS: ["E"],
    INDICES: [1],
    HINTS: [7, 2, 0],
  },
  {
    WORD: "MOISTURE",
    ALPHA: "EIMOSTU",
    LETTERS: ["R"],
    INDICES: [6],
    HINTS: [4, 1, 0],
  },
  {
    WORD: "STRONGLY",
    ALPHA: "GNORSTY",
    LETTERS: ["L"],
    INDICES: [6],
    HINTS: [2, 1, 4],
  },
  {
    WORD: "SAPPHIRE",
    ALPHA: "AEHPPRS",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [1, 0, 4],
  },
  {
    WORD: "LISTINGS",
    ALPHA: "GILNSST",
    LETTERS: ["I"],
    INDICES: [4],
    HINTS: [0, 3, 6],
  },
  {
    WORD: "PROPERTY",
    ALPHA: "OPPRRTY",
    LETTERS: ["E"],
    INDICES: [4],
    HINTS: [5, 6, 0],
  },
  {
    WORD: "FIGHTERS",
    ALPHA: "FGHIRST",
    LETTERS: ["E"],
    INDICES: [5],
    HINTS: [4, 2, 7],
  },
  {
    WORD: "CONSIDER",
    ALPHA: "CDINORS",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [3, 7, 4],
  },
  {
    WORD: "FLEXIBLE",
    ALPHA: "BEEFILX",
    LETTERS: ["L"],
    INDICES: [6],
    HINTS: [0, 3, 5],
  },
  {
    WORD: "CONTRARY",
    ALPHA: "ACNORRY",
    LETTERS: ["T"],
    INDICES: [3],
    HINTS: [5, 2, 6],
  },
  {
    WORD: "SHIPMENT",
    ALPHA: "HIMNPST",
    LETTERS: ["E"],
    INDICES: [5],
    HINTS: [1, 3, 7],
  },
  {
    WORD: "OPENINGS",
    ALPHA: "EINNOPS",
    LETTERS: ["G"],
    INDICES: [6],
    HINTS: [7, 0, 1],
  },
  {
    WORD: "DECREASE",
    ALPHA: "ACDEEES",
    LETTERS: ["R"],
    INDICES: [3],
    HINTS: [4, 1, 6],
  },
  {
    WORD: "PAINTING",
    ALPHA: "AGIINNP",
    LETTERS: ["T"],
    INDICES: [4],
    HINTS: [6, 0, 5],
  },
  {
    WORD: "MOUNTING",
    ALPHA: "GIMNNOT",
    LETTERS: ["U"],
    INDICES: [2],
    HINTS: [1, 4, 3],
  },
  {
    WORD: "LESBIANS",
    ALPHA: "ABEILSS",
    LETTERS: ["N"],
    INDICES: [6],
    HINTS: [1, 0, 5],
  },
  {
    WORD: "MINIMIZE",
    ALPHA: "EIIMMNZ",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [2, 7, 0],
  },
  {
    WORD: "BEHAVIOR",
    ALPHA: "ABEHORV",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [1, 6, 2],
  },
  {
    WORD: "TRUSTEES",
    ALPHA: "EERSSTU",
    LETTERS: ["T"],
    INDICES: [4],
    HINTS: [5, 1, 2],
  },
  {
    WORD: "MAXIMIZE",
    ALPHA: "EIIMMXZ",
    LETTERS: ["A"],
    INDICES: [1],
    HINTS: [3, 7, 6],
  },
  {
    WORD: "CATHOLIC",
    ALPHA: "ACCHILO",
    LETTERS: ["T"],
    INDICES: [2],
    HINTS: [4, 0, 1],
  },
  {
    WORD: "BROWSING",
    ALPHA: "BGINRSW",
    LETTERS: ["O"],
    INDICES: [2],
    HINTS: [5, 7, 3],
  },
  {
    WORD: "NOTEBOOK",
    ALPHA: "EKNOOOT",
    LETTERS: ["B"],
    INDICES: [4],
    HINTS: [5, 0, 2],
  },
  {
    WORD: "MISTAKES",
    ALPHA: "EIKMSST",
    LETTERS: ["A"],
    INDICES: [4],
    HINTS: [6, 5, 2],
  },
  {
    WORD: "ARGUMENT",
    ALPHA: "AEGMNRT",
    LETTERS: ["U"],
    INDICES: [3],
    HINTS: [7, 5, 0],
  },
  {
    WORD: "MORTGAGE",
    ALPHA: "EGGMORT",
    LETTERS: ["A"],
    INDICES: [5],
    HINTS: [2, 6, 7],
  },
  {
    WORD: "BULLETIN",
    ALPHA: "BEILNTU",
    LETTERS: ["L"],
    INDICES: [2],
    HINTS: [1, 4, 6],
  },
  {
    WORD: "CONFLICT",
    ALPHA: "CCFILNT",
    LETTERS: ["O"],
    INDICES: [1],
    HINTS: [3, 7, 6],
  },
  {
    WORD: "BELIEVES",
    ALPHA: "BEEILSV",
    LETTERS: ["E"],
    INDICES: [4],
    HINTS: [3, 7, 0],
  },
  {
    WORD: "INTERACT",
    ALPHA: "ACINRTT",
    LETTERS: ["E"],
    INDICES: [3],
    HINTS: [6, 0, 2],
  },
  {
    WORD: "ENSURING",
    ALPHA: "EGINNRU",
    LETTERS: ["S"],
    INDICES: [2],
    HINTS: [5, 6, 1],
  },
  {
    WORD: "LAUNCHES",
    ALPHA: "ACEHLNS",
    LETTERS: ["U"],
    INDICES: [2],
    HINTS: [4, 7, 1],
  },
  {
    WORD: "JUNCTION",
    ALPHA: "CIJNNOT",
    LETTERS: ["U"],
    INDICES: [1],
    HINTS: [6, 2, 4],
  },
  {
    WORD: "MONETARY",
    ALPHA: "AEMNOTY",
    LETTERS: ["R"],
    INDICES: [6],
    HINTS: [1, 7, 2],
  },
  {
    WORD: "METADATA",
    ALPHA: "AAADEMT",
    LETTERS: ["T"],
    INDICES: [2],
    HINTS: [1, 5, 0],
  },
  {
    WORD: "ACCURACY",
    ALPHA: "AACCRUY",
    LETTERS: ["C"],
    INDICES: [6],
    HINTS: [4, 3, 0],
  },
  {
    WORD: "CENTERED",
    ALPHA: "CDEEENT",
    LETTERS: ["R"],
    INDICES: [5],
    HINTS: [0, 6, 3],
  },
  {
    WORD: "ALUMINUM",
    ALPHA: "AILMMUU",
    LETTERS: ["N"],
    INDICES: [5],
    HINTS: [4, 7, 6],
  },
  {
    WORD: "RAILROAD",
    ALPHA: "AADIORR",
    LETTERS: ["L"],
    INDICES: [3],
    HINTS: [6, 2, 1],
  },
  {
    WORD: "VERTICAL",
    ALPHA: "ACEILRV",
    LETTERS: ["T"],
    INDICES: [3],
    HINTS: [1, 6, 7],
  },
  {
    WORD: "RECORDED",
    ALPHA: "CDDEERR",
    LETTERS: ["O"],
    INDICES: [3],
    HINTS: [2, 7, 0],
  },
  {
    WORD: "EVERYONE",
    ALPHA: "EEENORV",
    LETTERS: ["Y"],
    INDICES: [4],
    HINTS: [1, 2, 5],
  },
  {
    WORD: "DARKNESS",
    ALPHA: "ADEKRSS",
    LETTERS: ["N"],
    INDICES: [4],
    HINTS: [2, 5, 3],
  },
  {
    WORD: "CIRCULAR",
    ALPHA: "ACCILRR",
    LETTERS: ["U"],
    INDICES: [4],
    HINTS: [0, 6, 5],
  },
  {
    WORD: "NITROGEN",
    ALPHA: "EGINNOR",
    LETTERS: ["T"],
    INDICES: [2],
    HINTS: [7, 3, 5],
  },
  {
    WORD: "PROVIDED",
    ALPHA: "DDIOPRV",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [0, 7, 5],
  },
  {
    WORD: "RECEIVER",
    ALPHA: "CEEERRV",
    LETTERS: ["I"],
    INDICES: [4],
    HINTS: [0, 1, 7],
  },
  {
    WORD: "ENSEMBLE",
    ALPHA: "BEEEMNS",
    LETTERS: ["L"],
    INDICES: [6],
    HINTS: [7, 5, 0],
  },
  {
    WORD: "COMPUTER",
    ALPHA: "CMOPRTU",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [1, 7, 2],
  },
  {
    WORD: "SCHOLARS",
    ALPHA: "ACLORSS",
    LETTERS: ["H"],
    INDICES: [2],
    HINTS: [4, 5, 7],
  },
  {
    WORD: "OCCURRED",
    ALPHA: "CCDORRU",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [4, 2, 3],
  },
  {
    WORD: "FOLLOWED",
    ALPHA: "DEFLLOO",
    LETTERS: ["W"],
    INDICES: [5],
    HINTS: [3, 2, 6],
  },
  {
    WORD: "ADOPTION",
    ALPHA: "AINOOPT",
    LETTERS: ["D"],
    INDICES: [1],
    HINTS: [2, 6, 5],
  },
  {
    WORD: "ADVISORS",
    ALPHA: "ADIORSS",
    LETTERS: ["V"],
    INDICES: [2],
    HINTS: [3, 5, 4],
  },
  {
    WORD: "COURTESY",
    ALPHA: "CEOSTUY",
    LETTERS: ["R"],
    INDICES: [3],
    HINTS: [2, 7, 1],
  },
  {
    WORD: "MATERIAL",
    ALPHA: "AAEILMT",
    LETTERS: ["R"],
    INDICES: [4],
    HINTS: [6, 3, 7],
  },
  {
    WORD: "SPRINGER",
    ALPHA: "EGINRRS",
    LETTERS: ["P"],
    INDICES: [1],
    HINTS: [0, 4, 2],
  },
  {
    WORD: "OFFSHORE",
    ALPHA: "EFFHORS",
    LETTERS: ["O"],
    INDICES: [5],
    HINTS: [1, 2, 4],
  },
  {
    WORD: "WHATEVER",
    ALPHA: "AEEHRVW",
    LETTERS: ["T"],
    INDICES: [3],
    HINTS: [4, 7, 6],
  },
  {
    WORD: "LATITUDE",
    ALPHA: "DEILTTU",
    LETTERS: ["A"],
    INDICES: [1],
    HINTS: [7, 6, 2],
  },
  {
    WORD: "FRONTIER",
    ALPHA: "FINORRT",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [4, 5, 0],
  },
  {
    WORD: "DISEASES",
    ALPHA: "ADEEISS",
    LETTERS: ["S"],
    INDICES: [2],
    HINTS: [4, 0, 1],
  },
  {
    WORD: "CATERING",
    ALPHA: "ACEGINT",
    LETTERS: ["R"],
    INDICES: [4],
    HINTS: [7, 3, 5],
  },
  {
    WORD: "ENCLOSED",
    ALPHA: "DEELNOS",
    LETTERS: ["C"],
    INDICES: [2],
    HINTS: [4, 7, 1],
  },
  {
    WORD: "MEDICAID",
    ALPHA: "ACDDEIM",
    LETTERS: ["I"],
    INDICES: [3],
    HINTS: [7, 5, 1],
  },
  {
    WORD: "INCURRED",
    ALPHA: "CDEINRU",
    LETTERS: ["R"],
    INDICES: [5],
    HINTS: [2, 6, 1],
  },
  {
    WORD: "GENEROUS",
    ALPHA: "EEGNRSU",
    LETTERS: ["O"],
    INDICES: [5],
    HINTS: [6, 4, 3],
  },
  {
    WORD: "MEMORIES",
    ALPHA: "EIMMORS",
    LETTERS: ["E"],
    INDICES: [6],
    HINTS: [2, 3, 7],
  },
  {
    WORD: "DELIVERY",
    ALPHA: "DEILRVY",
    LETTERS: ["E"],
    INDICES: [1],
    HINTS: [2, 3, 4],
  },
  {
    WORD: "COMPOSED",
    ALPHA: "CDEMOOS",
    LETTERS: ["P"],
    INDICES: [3],
    HINTS: [1, 7, 2],
  },
  {
    WORD: "REVIEWER",
    ALPHA: "EEERRVW",
    LETTERS: ["I"],
    INDICES: [3],
    HINTS: [7, 0, 2],
  },
  {
    WORD: "EVIDENCE",
    ALPHA: "CDEEINV",
    LETTERS: ["E"],
    INDICES: [4],
    HINTS: [1, 2, 3],
  },
  {
    WORD: "LITERARY",
    ALPHA: "AILRRTY",
    LETTERS: ["E"],
    INDICES: [3],
    HINTS: [5, 7, 2],
  },
  {
    WORD: "CONTENTS",
    ALPHA: "CENOSTT",
    LETTERS: ["N"],
    INDICES: [5],
    HINTS: [4, 6, 3],
  },
  {
    WORD: "LIKEWISE",
    ALPHA: "EEIIKLW",
    LETTERS: ["S"],
    INDICES: [6],
    HINTS: [3, 2, 5],
  },
  {
    WORD: "VALUABLE",
    ALPHA: "ABELLUV",
    LETTERS: ["A"],
    INDICES: [1],
    HINTS: [6, 3, 0],
  },
  {
    WORD: "IDENTIFY",
    ALPHA: "DEFINTY",
    LETTERS: ["I"],
    INDICES: [5],
    HINTS: [7, 4, 6],
  },
  {
    WORD: "ENTIRELY",
    ALPHA: "EELNRTY",
    LETTERS: ["I"],
    INDICES: [3],
    HINTS: [1, 4, 6],
  },
  {
    WORD: "TIMELINE",
    ALPHA: "EEIILMT",
    LETTERS: ["N"],
    INDICES: [6],
    HINTS: [5, 3, 1],
  },
  {
    WORD: "PRESERVE",
    ALPHA: "EEPRRSV",
    LETTERS: ["E"],
    INDICES: [2],
    HINTS: [6, 5, 3],
  },
  {
    WORD: "REQUESTS",
    ALPHA: "EQRSSTU",
    LETTERS: ["E"],
    INDICES: [1],
    HINTS: [6, 2, 7],
  },
  {
    WORD: "FOUNTAIN",
    ALPHA: "AFINNTU",
    LETTERS: ["O"],
    INDICES: [1],
    HINTS: [5, 6, 7],
  },
  {
    WORD: "INNOCENT",
    ALPHA: "CEINNOT",
    LETTERS: ["N"],
    INDICES: [1],
    HINTS: [3, 7, 0],
  },
  {
    WORD: "MAINTAIN",
    ALPHA: "AIIMNNT",
    LETTERS: ["A"],
    INDICES: [5],
    HINTS: [7, 2, 4],
  },
  {
    WORD: "CLEANERS",
    ALPHA: "ACEELNS",
    LETTERS: ["R"],
    INDICES: [6],
    HINTS: [3, 5, 0],
  },
  {
    WORD: "PROMOTED",
    ALPHA: "DEMOOPT",
    LETTERS: ["R"],
    INDICES: [1],
    HINTS: [7, 4, 3],
  },
];

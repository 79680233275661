export const seven_hints = [
  { WORD: "DRESSED", ALPHA: "DDEERSS", HINTS: [0, 6, 3] },
  { WORD: "CORRECT", ALPHA: "CCEORRT", HINTS: [2, 4, 6] },
  { WORD: "WANTING", ALPHA: "AGINNTW", HINTS: [2, 5, 1] },
  { WORD: "DEGREES", ALPHA: "DEEEGRS", HINTS: [1, 3, 0] },
  { WORD: "SHELTER", ALPHA: "EEHLRST", HINTS: [6, 0, 1] },
  { WORD: "MEMBERS", ALPHA: "BEEMMRS", HINTS: [5, 2, 1] },
  { WORD: "CAPTURE", ALPHA: "ACEPRTU", HINTS: [3, 5, 0] },
  { WORD: "DIETARY", ALPHA: "ADEIRTY", HINTS: [0, 4, 6] },
  { WORD: "EXPLODE", ALPHA: "DEELOPX", HINTS: [2, 4, 0] },
  { WORD: "REMOVAL", ALPHA: "AELMORV", HINTS: [2, 0, 3] },
  { WORD: "VIOLATE", ALPHA: "AEILOTV", HINTS: [0, 1, 4] },
  { WORD: "CAMERAS", ALPHA: "AACEMRS", HINTS: [4, 1, 2] },
  { WORD: "AMAZING", ALPHA: "AAGIMNZ", HINTS: [2, 6, 1] },
  { WORD: "CONSENT", ALPHA: "CENNOST", HINTS: [2, 0, 4] },
  { WORD: "MOMENTS", ALPHA: "EMMNOST", HINTS: [0, 1, 6] },
  { WORD: "BROKERS", ALPHA: "BEKORRS", HINTS: [6, 4, 2] },
  { WORD: "DEVELOP", ALPHA: "DEELOPV", HINTS: [3, 4, 6] },
  { WORD: "INVOLVE", ALPHA: "EILNOVV", HINTS: [0, 3, 2] },
  { WORD: "ACADEMY", ALPHA: "AACDEMY", HINTS: [5, 2, 0] },
  { WORD: "PLASTIC", ALPHA: "ACILPST", HINTS: [2, 3, 0] },
  { WORD: "FEDERAL", ALPHA: "ADEEFLR", HINTS: [0, 4, 1] },
  { WORD: "BANKING", ALPHA: "ABGIKNN", HINTS: [6, 3, 0] },
  { WORD: "POINTED", ALPHA: "DEINOPT", HINTS: [6, 0, 2] },
  { WORD: "VICTIMS", ALPHA: "CIIMSTV", HINTS: [6, 3, 0] },
  { WORD: "WEBSITE", ALPHA: "BEEISTW", HINTS: [0, 5, 1] },
  { WORD: "QUIETLY", ALPHA: "EILQTUY", HINTS: [4, 5, 3] },
  { WORD: "MATCHED", ALPHA: "ACDEHMT", HINTS: [6, 4, 3] },
  { WORD: "ASSISTS", ALPHA: "AISSSST", HINTS: [0, 1, 2] },
  { WORD: "CLINICS", ALPHA: "CCIILNS", HINTS: [4, 1, 3] },
  { WORD: "PARTIAL", ALPHA: "AAILPRT", HINTS: [3, 4, 1] },
  { WORD: "EXPENSE", ALPHA: "EEENPSX", HINTS: [6, 2, 4] },
  { WORD: "REDUCED", ALPHA: "CDDEERU", HINTS: [4, 3, 6] },
  { WORD: "PRINTED", ALPHA: "DEINPRT", HINTS: [0, 2, 1] },
  { WORD: "PHOENIX", ALPHA: "EHINOPX", HINTS: [5, 4, 3] },
  { WORD: "INVITED", ALPHA: "DEIINTV", HINTS: [0, 4, 6] },
  { WORD: "LAUNDRY", ALPHA: "ADLNRUY", HINTS: [4, 5, 0] },
  { WORD: "REMOVED", ALPHA: "DEEMORV", HINTS: [2, 4, 0] },
  { WORD: "STADIUM", ALPHA: "ADIMSTU", HINTS: [1, 0, 6] },
  { WORD: "FINANCE", ALPHA: "ACEFINN", HINTS: [1, 0, 6] },
  { WORD: "VIRUSES", ALPHA: "EIRSSUV", HINTS: [5, 0, 2] },
  { WORD: "RECEIPT", ALPHA: "CEEIPRT", HINTS: [1, 2, 4] },
  { WORD: "REACHES", ALPHA: "ACEEHRS", HINTS: [5, 6, 1] },
  { WORD: "TREATED", ALPHA: "ADEERTT", HINTS: [5, 4, 2] },
  { WORD: "REUNION", ALPHA: "EINNORU", HINTS: [5, 3, 4] },
  { WORD: "ATTACKS", ALPHA: "AACKSTT", HINTS: [1, 0, 4] },
  { WORD: "UNUSUAL", ALPHA: "ALNSUUU", HINTS: [4, 1, 3] },
  { WORD: "CHANGES", ALPHA: "ACEGHNS", HINTS: [2, 5, 6] },
  { WORD: "OUTCOME", ALPHA: "CEMOOTU", HINTS: [5, 0, 3] },
  { WORD: "CAREFUL", ALPHA: "ACEFLRU", HINTS: [2, 5, 3] },
  { WORD: "ISLANDS", ALPHA: "ADILNSS", HINTS: [6, 3, 1] },
  { WORD: "DISPLAY", ALPHA: "ADILPSY", HINTS: [3, 6, 2] },
  { WORD: "SURVEYS", ALPHA: "ERSSUVY", HINTS: [6, 2, 4] },
  { WORD: "SUBLIME", ALPHA: "BEILMSU", HINTS: [0, 6, 1] },
  { WORD: "WEALTHY", ALPHA: "AEHLTWY", HINTS: [0, 6, 4] },
  { WORD: "PASSAGE", ALPHA: "AAEGPSS", HINTS: [2, 0, 4] },
  { WORD: "POVERTY", ALPHA: "EOPRTVY", HINTS: [4, 5, 6] },
  { WORD: "SINGLES", ALPHA: "EGILNSS", HINTS: [3, 5, 6] },
  { WORD: "APPAREL", ALPHA: "AAELPPR", HINTS: [6, 3, 4] },
  { WORD: "TESTIFY", ALPHA: "EFISTTY", HINTS: [1, 0, 2] },
  { WORD: "PORTION", ALPHA: "INOOPRT", HINTS: [4, 2, 1] },
  { WORD: "INSTALL", ALPHA: "AILLNST", HINTS: [0, 2, 6] },
  { WORD: "SETTLED", ALPHA: "DEELSTT", HINTS: [0, 3, 2] },
  { WORD: "MACHINE", ALPHA: "ACEHIMN", HINTS: [5, 6, 1] },
  { WORD: "MASSAGE", ALPHA: "AAEGMSS", HINTS: [6, 4, 5] },
  { WORD: "HIGHWAY", ALPHA: "AGHHIWY", HINTS: [6, 1, 5] },
  { WORD: "BURNING", ALPHA: "BGINNRU", HINTS: [2, 5, 4] },
  { WORD: "SERIOUS", ALPHA: "EIORSSU", HINTS: [6, 2, 1] },
  { WORD: "OFFENSE", ALPHA: "EEFFNOS", HINTS: [3, 4, 1] },
  { WORD: "KILLING", ALPHA: "GIIKLLN", HINTS: [4, 2, 5] },
  { WORD: "HEADING", ALPHA: "ADEGHIN", HINTS: [2, 5, 1] },
  { WORD: "DURABLE", ALPHA: "ABDELRU", HINTS: [5, 0, 2] },
  { WORD: "FOOTAGE", ALPHA: "AEFGOOT", HINTS: [6, 1, 2] },
  { WORD: "CALLING", ALPHA: "ACGILLN", HINTS: [3, 2, 0] },
  { WORD: "MIXTURE", ALPHA: "EIMRTUX", HINTS: [3, 5, 2] },
  { WORD: "PROTECT", ALPHA: "CEOPRTT", HINTS: [6, 0, 5] },
  { WORD: "SCHOOLS", ALPHA: "CHLOOSS", HINTS: [1, 0, 2] },
  { WORD: "BOATING", ALPHA: "ABGINOT", HINTS: [6, 1, 5] },
  { WORD: "SITTING", ALPHA: "GIINSTT", HINTS: [0, 2, 3] },
  { WORD: "WALKING", ALPHA: "AGIKLNW", HINTS: [3, 1, 4] },
  { WORD: "ABILITY", ALPHA: "ABIILTY", HINTS: [6, 0, 5] },
  { WORD: "POTTERY", ALPHA: "EOPRTTY", HINTS: [2, 4, 0] },
  { WORD: "DESKTOP", ALPHA: "DEKOPST", HINTS: [2, 1, 5] },
  { WORD: "FACTORY", ALPHA: "ACFORTY", HINTS: [3, 2, 5] },
  { WORD: "OFFLINE", ALPHA: "EFFILNO", HINTS: [6, 0, 4] },
  { WORD: "SUSPECT", ALPHA: "CEPSSTU", HINTS: [1, 6, 4] },
  { WORD: "ACCEPTS", ALPHA: "ACCEPST", HINTS: [2, 0, 3] },
  { WORD: "BELIEFS", ALPHA: "BEEFILS", HINTS: [4, 1, 2] },
  { WORD: "ILLNESS", ALPHA: "EILLNSS", HINTS: [1, 3, 0] },
  { WORD: "ASPECTS", ALPHA: "ACEPSST", HINTS: [3, 5, 2] },
  { WORD: "LONGEST", ALPHA: "EGLNOST", HINTS: [1, 2, 5] },
  { WORD: "DAMAGES", ALPHA: "AADEGMS", HINTS: [5, 1, 2] },
  { WORD: "HARMFUL", ALPHA: "AFHLMRU", HINTS: [1, 2, 4] },
  { WORD: "OPTIMUM", ALPHA: "IMMOPTU", HINTS: [0, 3, 1] },
  { WORD: "ADVANCE", ALPHA: "AACDENV", HINTS: [5, 6, 3] },
  { WORD: "EXISTED", ALPHA: "DEEISTX", HINTS: [2, 3, 4] },
  { WORD: "ACHIEVE", ALPHA: "ACEEHIV", HINTS: [4, 0, 3] },
  { WORD: "ANYMORE", ALPHA: "AEMNORY", HINTS: [4, 5, 2] },
  { WORD: "KEYWORD", ALPHA: "DEKORWY", HINTS: [5, 2, 4] },
  { WORD: "AIRPORT", ALPHA: "AIOPRRT", HINTS: [1, 0, 5] },
  { WORD: "ENDLESS", ALPHA: "DEELNSS", HINTS: [0, 6, 1] },
  { WORD: "QUALITY", ALPHA: "AILQTUY", HINTS: [4, 5, 0] },
  { WORD: "NEUTRAL", ALPHA: "AELNRTU", HINTS: [1, 2, 4] },
  { WORD: "SECONDS", ALPHA: "CDENOSS", HINTS: [2, 1, 0] },
  { WORD: "BEARING", ALPHA: "ABEGINR", HINTS: [1, 3, 4] },
  { WORD: "PROJECT", ALPHA: "CEJOPRT", HINTS: [1, 6, 2] },
  { WORD: "VARIETY", ALPHA: "AEIRTVY", HINTS: [1, 6, 4] },
  { WORD: "SURGEON", ALPHA: "EGNORSU", HINTS: [5, 0, 2] },
  { WORD: "GROUNDS", ALPHA: "DGNORSU", HINTS: [1, 0, 4] },
  { WORD: "SCHEMES", ALPHA: "CEEHMSS", HINTS: [6, 4, 3] },
  { WORD: "FORMATS", ALPHA: "AFMORST", HINTS: [6, 0, 5] },
  { WORD: "DIGITAL", ALPHA: "ADGIILT", HINTS: [5, 3, 1] },
  { WORD: "RANGING", ALPHA: "AGGINNR", HINTS: [0, 6, 5] },
  { WORD: "ENJOYED", ALPHA: "DEEJNOY", HINTS: [6, 2, 3] },
  { WORD: "WINNING", ALPHA: "GIINNNW", HINTS: [4, 2, 5] },
  { WORD: "EXPRESS", ALPHA: "EEPRSSX", HINTS: [4, 5, 1] },
  { WORD: "COLUMNS", ALPHA: "CLMNOSU", HINTS: [6, 0, 4] },
  { WORD: "SPATIAL", ALPHA: "AAILPST", HINTS: [1, 4, 2] },
  { WORD: "MAILING", ALPHA: "AGIILMN", HINTS: [0, 1, 3] },
  { WORD: "VEHICLE", ALPHA: "CEEHILV", HINTS: [4, 5, 0] },
  { WORD: "CHANGED", ALPHA: "ACDEGHN", HINTS: [2, 5, 3] },
  { WORD: "VENTURE", ALPHA: "EENRTUV", HINTS: [6, 5, 1] },
  { WORD: "JUMPING", ALPHA: "GIJMNPU", HINTS: [3, 1, 2] },
  { WORD: "PENGUIN", ALPHA: "EGINNPU", HINTS: [5, 4, 1] },
  { WORD: "PROUDLY", ALPHA: "DLOPRUY", HINTS: [4, 2, 6] },
  { WORD: "GROCERY", ALPHA: "CEGORRY", HINTS: [6, 4, 3] },
  { WORD: "PICKING", ALPHA: "CGIIKNP", HINTS: [6, 5, 1] },
  { WORD: "COLLECT", ALPHA: "CCELLOT", HINTS: [4, 3, 2] },
  { WORD: "TRACTOR", ALPHA: "ACORRTT", HINTS: [0, 5, 1] },
  { WORD: "CAPTAIN", ALPHA: "AACINPT", HINTS: [4, 2, 6] },
  { WORD: "GUITARS", ALPHA: "AGIRSTU", HINTS: [2, 3, 1] },
  { WORD: "DOLLARS", ALPHA: "ADLLORS", HINTS: [1, 3, 4] },
  { WORD: "WEBPAGE", ALPHA: "ABEEGPW", HINTS: [6, 5, 3] },
  { WORD: "DISCUSS", ALPHA: "CDISSSU", HINTS: [1, 2, 0] },
  { WORD: "IMPACTS", ALPHA: "ACIMPST", HINTS: [3, 1, 5] },
  { WORD: "VITAMIN", ALPHA: "AIIMNTV", HINTS: [3, 5, 4] },
  { WORD: "TARGETS", ALPHA: "AEGRSTT", HINTS: [4, 3, 2] },
  { WORD: "DEVICES", ALPHA: "CDEEISV", HINTS: [3, 0, 5] },
  { WORD: "OPPOSED", ALPHA: "DEOOPPS", HINTS: [4, 0, 6] },
  { WORD: "ASSURED", ALPHA: "ADERSSU", HINTS: [3, 2, 4] },
  { WORD: "BORDERS", ALPHA: "BDEORRS", HINTS: [6, 3, 2] },
  { WORD: "THEREOF", ALPHA: "EEFHORT", HINTS: [0, 3, 5] },
  { WORD: "PENDING", ALPHA: "DEGINNP", HINTS: [5, 4, 3] },
  { WORD: "CHARITY", ALPHA: "ACHIRTY", HINTS: [5, 0, 4] },
  { WORD: "WEBCAMS", ALPHA: "ABCEMSW", HINTS: [2, 4, 3] },
  { WORD: "CENTRAL", ALPHA: "ACELNRT", HINTS: [3, 6, 2] },
  { WORD: "ADOPTED", ALPHA: "ADDEOPT", HINTS: [5, 4, 6] },
  { WORD: "FORMING", ALPHA: "FGIMNOR", HINTS: [5, 6, 4] },
  { WORD: "OFFICER", ALPHA: "CEFFIOR", HINTS: [3, 4, 5] },
  { WORD: "PAYABLE", ALPHA: "AABELPY", HINTS: [3, 5, 6] },
  { WORD: "UNIFORM", ALPHA: "FIMNORU", HINTS: [6, 4, 3] },
  { WORD: "HEALTHY", ALPHA: "AEHHLTY", HINTS: [6, 3, 4] },
  { WORD: "EXPLORE", ALPHA: "EELOPRX", HINTS: [6, 1, 0] },
  { WORD: "VOLUMES", ALPHA: "ELMOSUV", HINTS: [2, 4, 3] },
  { WORD: "ANATOMY", ALPHA: "AAMNOTY", HINTS: [0, 6, 5] },
  { WORD: "MUSCLES", ALPHA: "CELMSSU", HINTS: [4, 5, 0] },
  { WORD: "HOBBIES", ALPHA: "BBEHIOS", HINTS: [6, 1, 0] },
  { WORD: "ANALYST", ALPHA: "AALNSTY", HINTS: [0, 3, 5] },
  { WORD: "MAPPING", ALPHA: "AGIMNPP", HINTS: [1, 4, 2] },
  { WORD: "SYSTEMS", ALPHA: "EMSSSTY", HINTS: [1, 0, 3] },
  { WORD: "EFFORTS", ALPHA: "EFFORST", HINTS: [6, 2, 0] },
  { WORD: "ADAPTOR", ALPHA: "AADOPRT", HINTS: [4, 0, 5] },
  { WORD: "CUTTING", ALPHA: "CGINTTU", HINTS: [4, 0, 2] },
  { WORD: "SCRATCH", ALPHA: "ACCHRST", HINTS: [2, 1, 3] },
  { WORD: "BICYCLE", ALPHA: "BCCEILY", HINTS: [5, 1, 6] },
  { WORD: "COUNTRY", ALPHA: "CNORTUY", HINTS: [3, 2, 6] },
  { WORD: "CRUCIAL", ALPHA: "ACCILRU", HINTS: [3, 5, 4] },
  { WORD: "WHISPER", ALPHA: "EHIPRSW", HINTS: [5, 0, 6] },
  { WORD: "HUNDRED", ALPHA: "DDEHNRU", HINTS: [1, 5, 0] },
  { WORD: "PASSION", ALPHA: "AINOPSS", HINTS: [1, 6, 5] },
  { WORD: "EVIDENT", ALPHA: "DEEINTV", HINTS: [0, 5, 1] },
  { WORD: "ARTWORK", ALPHA: "AKORRTW", HINTS: [1, 0, 4] },
  { WORD: "UTILITY", ALPHA: "IILTTUY", HINTS: [3, 2, 6] },
  { WORD: "TRINITY", ALPHA: "IINRTTY", HINTS: [1, 6, 2] },
  { WORD: "ADDRESS", ALPHA: "ADDERSS", HINTS: [2, 1, 0] },
  { WORD: "TEENAGE", ALPHA: "AEEEGNT", HINTS: [2, 5, 1] },
  { WORD: "HEARING", ALPHA: "AEGHINR", HINTS: [3, 5, 0] },
  { WORD: "INDEXES", ALPHA: "DEEINSX", HINTS: [0, 4, 2] },
  { WORD: "TORTURE", ALPHA: "EORRTTU", HINTS: [1, 3, 4] },
  { WORD: "COMPACT", ALPHA: "ACCMOPT", HINTS: [4, 0, 1] },
  { WORD: "LOGGING", ALPHA: "GGGILNO", HINTS: [2, 5, 3] },
  { WORD: "BANNERS", ALPHA: "ABENNRS", HINTS: [6, 5, 1] },
  { WORD: "DEFINED", ALPHA: "DDEEFIN", HINTS: [5, 4, 2] },
  { WORD: "POSSESS", ALPHA: "EOPSSSS", HINTS: [3, 4, 2] },
  { WORD: "DIPLOMA", ALPHA: "ADILMOP", HINTS: [1, 4, 2] },
  { WORD: "GILBERT", ALPHA: "BEGILRT", HINTS: [6, 4, 3] },
  { WORD: "VANILLA", ALPHA: "AAILLNV", HINTS: [5, 6, 4] },
  { WORD: "AWESOME", ALPHA: "AEEMOSW", HINTS: [5, 1, 2] },
  { WORD: "CENTURY", ALPHA: "CENRTUY", HINTS: [1, 4, 5] },
  { WORD: "MONITOR", ALPHA: "IMNOORT", HINTS: [5, 0, 4] },
  { WORD: "FOREVER", ALPHA: "EEFORRV", HINTS: [0, 6, 3] },
  { WORD: "VIEWING", ALPHA: "EGIINVW", HINTS: [6, 4, 2] },
  { WORD: "CONVERT", ALPHA: "CENORTV", HINTS: [0, 2, 6] },
  { WORD: "OBESITY", ALPHA: "BEIOSTY", HINTS: [6, 5, 4] },
  { WORD: "SURFACE", ALPHA: "ACEFRSU", HINTS: [0, 2, 1] },
  { WORD: "CHANCES", ALPHA: "ACCEHNS", HINTS: [3, 1, 5] },
  { WORD: "DIVIDED", ALPHA: "DDDEIIV", HINTS: [2, 3, 6] },
  { WORD: "SPRINGS", ALPHA: "GINPRSS", HINTS: [3, 0, 5] },
  { WORD: "EDITION", ALPHA: "DEIINOT", HINTS: [1, 0, 3] },
  { WORD: "SATISFY", ALPHA: "AFISSTY", HINTS: [6, 1, 4] },
  { WORD: "ENABLED", ALPHA: "ABDEELN", HINTS: [4, 3, 5] },
  { WORD: "PROPHET", ALPHA: "EHOPPRT", HINTS: [0, 3, 1] },
  { WORD: "DEPENDS", ALPHA: "DDEENPS", HINTS: [3, 6, 5] },
  { WORD: "WESTERN", ALPHA: "EENRSTW", HINTS: [4, 3, 5] },
  { WORD: "THOUGHT", ALPHA: "GHHOTTU", HINTS: [2, 6, 0] },
  { WORD: "LICKING", ALPHA: "CGIIKLN", HINTS: [0, 1, 5] },
  { WORD: "NOVELTY", ALPHA: "ELNOTVY", HINTS: [5, 3, 0] },
  { WORD: "BLANKET", ALPHA: "ABEKLNT", HINTS: [0, 5, 4] },
  { WORD: "TIFFANY", ALPHA: "AFFINTY", HINTS: [6, 1, 5] },
  { WORD: "FEATURE", ALPHA: "AEEFRTU", HINTS: [0, 3, 5] },
  { WORD: "CARDIAC", ALPHA: "AACCDIR", HINTS: [3, 1, 6] },
  { WORD: "FALLING", ALPHA: "AFGILLN", HINTS: [1, 2, 0] },
  { WORD: "SPECIFY", ALPHA: "CEFIPSY", HINTS: [6, 3, 4] },
  { WORD: "HOSTELS", ALPHA: "EHLOSST", HINTS: [4, 1, 3] },
  { WORD: "FINDING", ALPHA: "DFGIINN", HINTS: [4, 0, 5] },
  { WORD: "TEXTILE", ALPHA: "EEILTTX", HINTS: [3, 6, 0] },
  { WORD: "PLACING", ALPHA: "ACGILNP", HINTS: [6, 1, 3] },
  { WORD: "CONDUCT", ALPHA: "CCDNOTU", HINTS: [4, 0, 6] },
  { WORD: "VIOLENT", ALPHA: "EILNOTV", HINTS: [5, 3, 1] },
  { WORD: "LOCKING", ALPHA: "CGIKLNO", HINTS: [5, 2, 3] },
  { WORD: "MENTION", ALPHA: "EIMNNOT", HINTS: [3, 5, 2] },
  { WORD: "BARGAIN", ALPHA: "AABGINR", HINTS: [4, 3, 0] },
  { WORD: "ANCIENT", ALPHA: "ACEINNT", HINTS: [5, 6, 0] },
  { WORD: "ALLOWED", ALPHA: "ADELLOW", HINTS: [0, 3, 1] },
  { WORD: "MERCURY", ALPHA: "CEMRRUY", HINTS: [5, 3, 6] },
  { WORD: "CONTEST", ALPHA: "CENOSTT", HINTS: [6, 1, 2] },
  { WORD: "CRICKET", ALPHA: "CCEIKRT", HINTS: [3, 4, 1] },
  { WORD: "JUSTIFY", ALPHA: "FIJSTUY", HINTS: [6, 4, 2] },
  { WORD: "DEVIANT", ALPHA: "ADEINTV", HINTS: [6, 1, 2] },
  { WORD: "ARRIVED", ALPHA: "ADEIRRV", HINTS: [2, 6, 4] },
  { WORD: "QUIZZES", ALPHA: "EIQSUZZ", HINTS: [2, 1, 0] },
  { WORD: "EMBASSY", ALPHA: "ABEMSSY", HINTS: [2, 5, 0] },
  { WORD: "SCANNED", ALPHA: "ACDENNS", HINTS: [0, 4, 6] },
  { WORD: "BROUGHT", ALPHA: "BGHORTU", HINTS: [4, 3, 1] },
  { WORD: "AMATEUR", ALPHA: "AAEMRTU", HINTS: [2, 1, 5] },
  { WORD: "EDUCATE", ALPHA: "ACDEETU", HINTS: [4, 2, 6] },
  { WORD: "DROPPED", ALPHA: "DDEOPPR", HINTS: [4, 1, 0] },
  { WORD: "CONFIRM", ALPHA: "CFIMNOR", HINTS: [3, 1, 6] },
  { WORD: "SHORTLY", ALPHA: "HLORSTY", HINTS: [6, 2, 0] },
  { WORD: "FURTHER", ALPHA: "EFHRRTU", HINTS: [6, 2, 5] },
  { WORD: "VINTAGE", ALPHA: "AEGINTV", HINTS: [5, 3, 2] },
  { WORD: "COMBINE", ALPHA: "BCEIMNO", HINTS: [6, 4, 3] },
  { WORD: "MUSICAL", ALPHA: "ACILMSU", HINTS: [5, 0, 2] },
  { WORD: "BIDDING", ALPHA: "BDDGIIN", HINTS: [0, 1, 6] },
  { WORD: "HYGIENE", ALPHA: "EEGHINY", HINTS: [0, 3, 6] },
  { WORD: "AUDITOR", ALPHA: "ADIORTU", HINTS: [1, 0, 6] },
  { WORD: "FITTING", ALPHA: "FGIINTT", HINTS: [1, 3, 0] },
  { WORD: "INQUIRE", ALPHA: "EIINQRU", HINTS: [1, 5, 4] },
  { WORD: "RELYING", ALPHA: "EGILNRY", HINTS: [6, 4, 1] },
  { WORD: "DRIVERS", ALPHA: "DEIRRSV", HINTS: [4, 3, 5] },
  { WORD: "ASSUMES", ALPHA: "AEMSSSU", HINTS: [6, 1, 5] },
  { WORD: "TOWARDS", ALPHA: "ADORSTW", HINTS: [5, 1, 2] },
  { WORD: "EXPLAIN", ALPHA: "AEILNPX", HINTS: [0, 2, 5] },
  { WORD: "LICENCE", ALPHA: "CCEEILN", HINTS: [3, 5, 2] },
  { WORD: "ACCUSED", ALPHA: "ACCDESU", HINTS: [6, 2, 4] },
  { WORD: "CHARLIE", ALPHA: "ACEHILR", HINTS: [5, 4, 6] },
  { WORD: "POCKETS", ALPHA: "CEKOPST", HINTS: [1, 6, 3] },
  { WORD: "HANGING", ALPHA: "AGGHINN", HINTS: [2, 5, 4] },
  { WORD: "GARBAGE", ALPHA: "AABEGGR", HINTS: [5, 0, 4] },
  { WORD: "LABELED", ALPHA: "ABDEELL", HINTS: [5, 6, 1] },
  { WORD: "FEMALES", ALPHA: "AEEFLMS", HINTS: [4, 5, 0] },
  { WORD: "BILLING", ALPHA: "BGIILLN", HINTS: [6, 5, 1] },
  { WORD: "AIRLINE", ALPHA: "AEIILNR", HINTS: [2, 1, 6] },
  { WORD: "LECTURE", ALPHA: "CEELRTU", HINTS: [5, 0, 2] },
  { WORD: "LEARNED", ALPHA: "ADEELNR", HINTS: [4, 1, 2] },
  { WORD: "CALCIUM", ALPHA: "ACCILMU", HINTS: [2, 5, 1] },
  { WORD: "CHARGER", ALPHA: "ACEGHRR", HINTS: [4, 3, 5] },
  { WORD: "NATURAL", ALPHA: "AALNRTU", HINTS: [4, 6, 2] },
  { WORD: "ROMANCE", ALPHA: "ACEMNOR", HINTS: [3, 2, 1] },
  { WORD: "REVENGE", ALPHA: "EEEGNRV", HINTS: [6, 0, 4] },
  { WORD: "TIMOTHY", ALPHA: "HIMOTTY", HINTS: [0, 2, 6] },
  { WORD: "PUTTING", ALPHA: "GINPTTU", HINTS: [6, 4, 2] },
  { WORD: "ABANDON", ALPHA: "AABDNNO", HINTS: [0, 5, 6] },
  { WORD: "WORSHIP", ALPHA: "HIOPRSW", HINTS: [3, 5, 1] },
  { WORD: "STRIKES", ALPHA: "EIKRSST", HINTS: [1, 5, 4] },
  { WORD: "COOKING", ALPHA: "CGIKNOO", HINTS: [2, 6, 1] },
  { WORD: "PUZZLES", ALPHA: "ELPSUZZ", HINTS: [0, 4, 1] },
  { WORD: "CUSTODY", ALPHA: "CDOSTUY", HINTS: [3, 2, 4] },
  { WORD: "CLEARLY", ALPHA: "ACELLRY", HINTS: [1, 4, 5] },
  { WORD: "PRIVACY", ALPHA: "ACIPRVY", HINTS: [6, 5, 2] },
  { WORD: "FICTION", ALPHA: "CFIINOT", HINTS: [1, 0, 3] },
  { WORD: "UPGRADE", ALPHA: "ADEGPRU", HINTS: [2, 4, 1] },
  { WORD: "PASSING", ALPHA: "AGINPSS", HINTS: [3, 0, 5] },
  { WORD: "ALGEBRA", ALPHA: "AABEGLR", HINTS: [0, 1, 6] },
  { WORD: "PREVIEW", ALPHA: "EEIPRVW", HINTS: [2, 3, 5] },
  { WORD: "MINIMUM", ALPHA: "IIMMMNU", HINTS: [0, 6, 1] },
  { WORD: "AMONGST", ALPHA: "AGMNOST", HINTS: [2, 0, 3] },
  { WORD: "LESBIAN", ALPHA: "ABEILNS", HINTS: [0, 1, 6] },
  { WORD: "NETWORK", ALPHA: "EKNORTW", HINTS: [6, 4, 1] },
  { WORD: "TUITION", ALPHA: "IINOTTU", HINTS: [2, 5, 6] },
  { WORD: "POULTRY", ALPHA: "LOPRTUY", HINTS: [4, 5, 6] },
  { WORD: "SPONSOR", ALPHA: "NOOPRSS", HINTS: [5, 1, 2] },
  { WORD: "HELPING", ALPHA: "EGHILNP", HINTS: [6, 2, 1] },
  { WORD: "COMPLEX", ALPHA: "CELMOPX", HINTS: [5, 6, 1] },
  { WORD: "WEDDING", ALPHA: "DDEGINW", HINTS: [3, 1, 0] },
  { WORD: "DIAMOND", ALPHA: "ADDIMNO", HINTS: [6, 2, 3] },
  { WORD: "SEGMENT", ALPHA: "EEGMNST", HINTS: [5, 1, 6] },
  { WORD: "ENGINES", ALPHA: "EEGINNS", HINTS: [5, 0, 6] },
  { WORD: "SCANDAL", ALPHA: "AACDLNS", HINTS: [0, 6, 2] },
  { WORD: "LOCATOR", ALPHA: "ACLOORT", HINTS: [2, 1, 6] },
  { WORD: "ESTATES", ALPHA: "AEESSTT", HINTS: [4, 5, 0] },
  { WORD: "SMOKING", ALPHA: "GIKMNOS", HINTS: [5, 0, 3] },
  { WORD: "MARKETS", ALPHA: "AEKMRST", HINTS: [2, 1, 6] },
  { WORD: "PAYMENT", ALPHA: "AEMNPTY", HINTS: [1, 6, 2] },
  { WORD: "INITIAL", ALPHA: "AIIILNT", HINTS: [5, 6, 0] },
  { WORD: "FILLING", ALPHA: "FGIILLN", HINTS: [1, 4, 5] },
  { WORD: "VENDORS", ALPHA: "DENORSV", HINTS: [1, 5, 0] },
  { WORD: "OFFERED", ALPHA: "DEEFFOR", HINTS: [3, 1, 4] },
  { WORD: "IMPLIED", ALPHA: "DEIILMP", HINTS: [0, 4, 1] },
  { WORD: "PODCAST", ALPHA: "ACDOPST", HINTS: [6, 0, 3] },
  { WORD: "TOOLBOX", ALPHA: "BLOOOTX", HINTS: [6, 3, 5] },
  { WORD: "FABRICS", ALPHA: "ABCFIRS", HINTS: [3, 2, 5] },
  { WORD: "LETTING", ALPHA: "EGILNTT", HINTS: [0, 5, 2] },
  { WORD: "MEANING", ALPHA: "AEGIMNN", HINTS: [4, 5, 3] },
  { WORD: "TOOLBAR", ALPHA: "ABLOORT", HINTS: [5, 3, 6] },
  { WORD: "WILLING", ALPHA: "GIILLNW", HINTS: [3, 1, 4] },
  { WORD: "ARCHIVE", ALPHA: "ACEHIRV", HINTS: [4, 3, 5] },
  { WORD: "COMMONS", ALPHA: "CMMNOOS", HINTS: [3, 5, 4] },
  { WORD: "ONGOING", ALPHA: "GGINNOO", HINTS: [4, 6, 0] },
  { WORD: "RAINBOW", ALPHA: "ABINORW", HINTS: [1, 5, 6] },
  { WORD: "BARRIER", ALPHA: "ABEIRRR", HINTS: [4, 2, 3] },
  { WORD: "POPULAR", ALPHA: "ALOPPRU", HINTS: [5, 1, 4] },
  { WORD: "MUSEUMS", ALPHA: "EMMSSUU", HINTS: [2, 4, 5] },
  { WORD: "MANAGER", ALPHA: "AAEGMNR", HINTS: [0, 3, 6] },
  { WORD: "CONSULT", ALPHA: "CLNOSTU", HINTS: [0, 3, 2] },
  { WORD: "TRACKED", ALPHA: "ACDEKRT", HINTS: [2, 6, 4] },
  { WORD: "TOPLESS", ALPHA: "ELOPSST", HINTS: [0, 6, 5] },
  { WORD: "CHASSIS", ALPHA: "ACHISSS", HINTS: [5, 1, 4] },
  { WORD: "PREPAID", ALPHA: "ADEIPPR", HINTS: [6, 2, 3] },
  { WORD: "APPOINT", ALPHA: "AINOPPT", HINTS: [3, 4, 6] },
  { WORD: "BUDGETS", ALPHA: "BDEGSTU", HINTS: [1, 3, 2] },
  { WORD: "IMPLIES", ALPHA: "EIILMPS", HINTS: [0, 3, 5] },
  { WORD: "TOTALLY", ALPHA: "ALLOTTY", HINTS: [4, 0, 1] },
  { WORD: "CAPITAL", ALPHA: "AACILPT", HINTS: [2, 1, 4] },
  { WORD: "CHOICES", ALPHA: "CCEHIOS", HINTS: [4, 5, 2] },
  { WORD: "HUSBAND", ALPHA: "ABDHNSU", HINTS: [6, 5, 2] },
  { WORD: "CIRCUIT", ALPHA: "CCIIRTU", HINTS: [4, 2, 3] },
  { WORD: "PACKAGE", ALPHA: "AACEGKP", HINTS: [3, 6, 1] },
  { WORD: "WELFARE", ALPHA: "AEEFLRW", HINTS: [6, 0, 2] },
  { WORD: "THEOREM", ALPHA: "EEHMORT", HINTS: [4, 3, 0] },
  { WORD: "QUICKLY", ALPHA: "CIKLQUY", HINTS: [5, 1, 0] },
  { WORD: "PURSUIT", ALPHA: "IPRSTUU", HINTS: [4, 3, 2] },
  { WORD: "PENDANT", ALPHA: "ADENNPT", HINTS: [2, 1, 6] },
  { WORD: "DECADES", ALPHA: "ACDDEES", HINTS: [2, 3, 4] },
  { WORD: "VISIBLE", ALPHA: "BEIILSV", HINTS: [6, 4, 5] },
  { WORD: "CLARITY", ALPHA: "ACILRTY", HINTS: [0, 4, 3] },
  { WORD: "FIGURES", ALPHA: "EFGIRSU", HINTS: [2, 3, 4] },
  { WORD: "WELDING", ALPHA: "DEGILNW", HINTS: [6, 3, 5] },
  { WORD: "INSURED", ALPHA: "DEINRSU", HINTS: [6, 2, 1] },
  { WORD: "PRICING", ALPHA: "CGIINPR", HINTS: [5, 6, 0] },
  { WORD: "FACULTY", ALPHA: "ACFLTUY", HINTS: [3, 2, 5] },
  { WORD: "ROUTINE", ALPHA: "EINORTU", HINTS: [4, 3, 1] },
  { WORD: "SEASONS", ALPHA: "AENOSSS", HINTS: [2, 6, 1] },
  { WORD: "TROUBLE", ALPHA: "BELORTU", HINTS: [3, 4, 6] },
  { WORD: "BETWEEN", ALPHA: "BEEENTW", HINTS: [4, 0, 1] },
  { WORD: "TALKING", ALPHA: "AGIKLNT", HINTS: [2, 1, 0] },
  { WORD: "ORDERED", ALPHA: "DDEEORR", HINTS: [2, 5, 3] },
  { WORD: "APPEALS", ALPHA: "AAELPPS", HINTS: [3, 4, 5] },
  { WORD: "HOLLAND", ALPHA: "ADHLLNO", HINTS: [5, 2, 0] },
  { WORD: "GRAPHIC", ALPHA: "ACGHIPR", HINTS: [6, 2, 3] },
  { WORD: "CARRIER", ALPHA: "ACEIRRR", HINTS: [2, 0, 1] },
  { WORD: "EXPORTS", ALPHA: "EOPRSTX", HINTS: [2, 6, 5] },
  { WORD: "HOTTEST", ALPHA: "EHOSTTT", HINTS: [3, 6, 4] },
  { WORD: "JOURNAL", ALPHA: "AJLNORU", HINTS: [4, 3, 6] },
  { WORD: "COLOGNE", ALPHA: "CEGLNOO", HINTS: [3, 4, 2] },
  { WORD: "MANUALS", ALPHA: "AALMNSU", HINTS: [4, 1, 3] },
  { WORD: "MISTAKE", ALPHA: "AEIKMST", HINTS: [4, 3, 1] },
  { WORD: "DEFENCE", ALPHA: "CDEEEFN", HINTS: [3, 5, 4] },
  { WORD: "NAUGHTY", ALPHA: "AGHNTUY", HINTS: [5, 1, 3] },
  { WORD: "POWERED", ALPHA: "DEEOPRW", HINTS: [6, 3, 4] },
  { WORD: "HUNTING", ALPHA: "GHINNTU", HINTS: [5, 1, 4] },
  { WORD: "RESUMES", ALPHA: "EEMRSSU", HINTS: [4, 6, 2] },
  { WORD: "STRETCH", ALPHA: "CEHRSTT", HINTS: [1, 2, 5] },
  { WORD: "CRITICS", ALPHA: "CCIIRST", HINTS: [4, 5, 2] },
  { WORD: "BRIEFLY", ALPHA: "BEFILRY", HINTS: [1, 2, 5] },
  { WORD: "DANCING", ALPHA: "ACDGINN", HINTS: [4, 5, 2] },
  { WORD: "IMPROVE", ALPHA: "EIMOPRV", HINTS: [5, 3, 2] },
  { WORD: "SUCKING", ALPHA: "CGIKNSU", HINTS: [4, 2, 0] },
  { WORD: "THEREBY", ALPHA: "BEEHRTY", HINTS: [6, 2, 4] },
  { WORD: "JOINING", ALPHA: "GIIJNNO", HINTS: [1, 3, 4] },
  { WORD: "DEFECTS", ALPHA: "CDEEFST", HINTS: [5, 4, 0] },
  { WORD: "REACHED", ALPHA: "ACDEEHR", HINTS: [6, 2, 3] },
  { WORD: "CLEANUP", ALPHA: "ACELNPU", HINTS: [1, 6, 5] },
  { WORD: "PARKING", ALPHA: "AGIKNPR", HINTS: [3, 2, 5] },
  { WORD: "DISMISS", ALPHA: "DIIMSSS", HINTS: [1, 2, 3] },
  { WORD: "CONTACT", ALPHA: "ACCNOTT", HINTS: [0, 4, 6] },
  { WORD: "CURIOUS", ALPHA: "CIORSUU", HINTS: [4, 0, 3] },
  { WORD: "CEILING", ALPHA: "CEGIILN", HINTS: [2, 4, 3] },
  { WORD: "HEALING", ALPHA: "AEGHILN", HINTS: [0, 1, 4] },
  { WORD: "COMPUTE", ALPHA: "CEMOPTU", HINTS: [1, 6, 4] },
  { WORD: "APPEARS", ALPHA: "AAEPPRS", HINTS: [3, 4, 5] },
  { WORD: "GEOLOGY", ALPHA: "EGGLOOY", HINTS: [2, 1, 4] },
  { WORD: "OPENING", ALPHA: "EGINNOP", HINTS: [1, 5, 6] },
  { WORD: "PRAIRIE", ALPHA: "AEIIPRR", HINTS: [0, 4, 6] },
  { WORD: "REGULAR", ALPHA: "AEGLRRU", HINTS: [6, 0, 3] },
  { WORD: "SOONEST", ALPHA: "ENOOSST", HINTS: [2, 0, 4] },
  { WORD: "VICTORY", ALPHA: "CIORTVY", HINTS: [1, 5, 2] },
  { WORD: "CULTURE", ALPHA: "CELRTUU", HINTS: [6, 2, 4] },
  { WORD: "INVALID", ALPHA: "ADIILNV", HINTS: [6, 5, 3] },
  { WORD: "PLAYING", ALPHA: "AGILNPY", HINTS: [3, 5, 2] },
  { WORD: "DESIGNS", ALPHA: "DEGINSS", HINTS: [5, 0, 4] },
  { WORD: "EXTRACT", ALPHA: "ACERTTX", HINTS: [4, 1, 2] },
  { WORD: "BAPTIST", ALPHA: "ABIPSTT", HINTS: [2, 1, 3] },
  { WORD: "LEISURE", ALPHA: "EEILRSU", HINTS: [3, 1, 0] },
  { WORD: "PERIODS", ALPHA: "DEIOPRS", HINTS: [2, 1, 4] },
  { WORD: "HOSTING", ALPHA: "GHINOST", HINTS: [4, 1, 5] },
  { WORD: "EXTENDS", ALPHA: "DEENSTX", HINTS: [5, 6, 2] },
  { WORD: "WATCHED", ALPHA: "ACDEHTW", HINTS: [1, 6, 3] },
  { WORD: "SENSORS", ALPHA: "ENORSSS", HINTS: [5, 3, 2] },
  { WORD: "TRUSTED", ALPHA: "DERSTTU", HINTS: [1, 3, 4] },
  { WORD: "HELPFUL", ALPHA: "EFHLLPU", HINTS: [0, 2, 4] },
  { WORD: "TOURISM", ALPHA: "IMORSTU", HINTS: [6, 1, 0] },
  { WORD: "PERHAPS", ALPHA: "AEHPPRS", HINTS: [4, 3, 1] },
  { WORD: "BINDING", ALPHA: "BDGIINN", HINTS: [3, 4, 5] },
  { WORD: "INVOICE", ALPHA: "CEIINOV", HINTS: [2, 4, 0] },
  { WORD: "SYMPTOM", ALPHA: "MMOPSTY", HINTS: [0, 3, 4] },
  { WORD: "SUBJECT", ALPHA: "BCEJSTU", HINTS: [6, 5, 1] },
  { WORD: "BALLOON", ALPHA: "ABLLNOO", HINTS: [6, 2, 5] },
  { WORD: "ADVISOR", ALPHA: "ADIORSV", HINTS: [4, 5, 6] },
  { WORD: "ACCOUNT", ALPHA: "ACCNOTU", HINTS: [0, 1, 6] },
  { WORD: "MARKING", ALPHA: "AGIKMNR", HINTS: [2, 0, 1] },
  { WORD: "WATCHES", ALPHA: "ACEHSTW", HINTS: [0, 1, 2] },
  { WORD: "MINIMAL", ALPHA: "AIILMMN", HINTS: [4, 5, 2] },
  { WORD: "PRODUCE", ALPHA: "CDEOPRU", HINTS: [1, 0, 2] },
  { WORD: "CLASSES", ALPHA: "ACELSSS", HINTS: [0, 2, 5] },
  { WORD: "RUNNING", ALPHA: "GINNNRU", HINTS: [2, 1, 6] },
  { WORD: "EMERALD", ALPHA: "ADEELMR", HINTS: [1, 0, 2] },
  { WORD: "HEATHER", ALPHA: "AEEHHRT", HINTS: [4, 1, 5] },
  { WORD: "QUARTER", ALPHA: "AEQRRTU", HINTS: [0, 2, 1] },
  { WORD: "COMPETE", ALPHA: "CEEMOPT", HINTS: [5, 2, 4] },
  { WORD: "IMAGING", ALPHA: "AGGIIMN", HINTS: [4, 1, 2] },
  { WORD: "AQUATIC", ALPHA: "AACIQTU", HINTS: [6, 3, 1] },
  { WORD: "COUNCIL", ALPHA: "CCILNOU", HINTS: [5, 4, 1] },
  { WORD: "CHICKEN", ALPHA: "CCEHIKN", HINTS: [2, 4, 6] },
  { WORD: "FASTEST", ALPHA: "AEFSSTT", HINTS: [6, 1, 3] },
  { WORD: "SURFING", ALPHA: "FGINRSU", HINTS: [3, 6, 5] },
  { WORD: "RADICAL", ALPHA: "AACDILR", HINTS: [2, 0, 3] },
  { WORD: "NERVOUS", ALPHA: "ENORSUV", HINTS: [3, 5, 1] },
  { WORD: "LENDING", ALPHA: "DEGILNN", HINTS: [1, 0, 2] },
  { WORD: "TOURIST", ALPHA: "IORSTTU", HINTS: [1, 0, 5] },
  { WORD: "BETTING", ALPHA: "BEGINTT", HINTS: [3, 1, 6] },
  { WORD: "SURGERY", ALPHA: "EGRRSUY", HINTS: [6, 0, 1] },
  { WORD: "TRANSIT", ALPHA: "AINRSTT", HINTS: [5, 1, 3] },
  { WORD: "BESIDES", ALPHA: "BDEEISS", HINTS: [2, 3, 6] },
  { WORD: "EXPOSED", ALPHA: "DEEOPSX", HINTS: [4, 5, 3] },
  { WORD: "DRIVING", ALPHA: "DGIINRV", HINTS: [0, 1, 6] },
  { WORD: "MUSTANG", ALPHA: "AGMNSTU", HINTS: [3, 2, 4] },
  { WORD: "VISITOR", ALPHA: "IIORSTV", HINTS: [2, 0, 3] },
  { WORD: "PHYSICS", ALPHA: "CHIPSSY", HINTS: [4, 5, 2] },
  { WORD: "THERMAL", ALPHA: "AEHLMRT", HINTS: [2, 1, 3] },
  { WORD: "ENTERED", ALPHA: "DEEENRT", HINTS: [0, 5, 2] },
  { WORD: "EMBRACE", ALPHA: "ABCEEMR", HINTS: [6, 3, 5] },
  { WORD: "SUPPOSE", ALPHA: "EOPPSSU", HINTS: [1, 6, 3] },
  { WORD: "ABSENCE", ALPHA: "ABCEENS", HINTS: [2, 1, 3] },
  { WORD: "NOTHING", ALPHA: "GHINNOT", HINTS: [4, 6, 0] },
  { WORD: "IMAGINE", ALPHA: "AEGIIMN", HINTS: [3, 4, 6] },
  { WORD: "MODULAR", ALPHA: "ADLMORU", HINTS: [0, 3, 4] },
  { WORD: "PEOPLES", ALPHA: "EELOPPS", HINTS: [3, 4, 2] },
  { WORD: "INQUIRY", ALPHA: "IINQRUY", HINTS: [1, 3, 5] },
  { WORD: "FISHING", ALPHA: "FGHIINS", HINTS: [4, 2, 1] },
  { WORD: "MEASURE", ALPHA: "AEEMRSU", HINTS: [5, 6, 2] },
  { WORD: "RESOLVE", ALPHA: "EELORSV", HINTS: [0, 6, 3] },
  { WORD: "ENEMIES", ALPHA: "EEEIMNS", HINTS: [4, 6, 1] },
  { WORD: "SELLING", ALPHA: "EGILLNS", HINTS: [6, 2, 5] },
  { WORD: "TOUCHED", ALPHA: "CDEHOTU", HINTS: [6, 5, 4] },
  { WORD: "JOURNEY", ALPHA: "EJNORUY", HINTS: [1, 2, 3] },
  { WORD: "COMMENT", ALPHA: "CEMMNOT", HINTS: [1, 2, 6] },
  { WORD: "PROGRAM", ALPHA: "AGMOPRR", HINTS: [4, 1, 0] },
  { WORD: "ATTRACT", ALPHA: "AACRTTT", HINTS: [6, 2, 5] },
  { WORD: "SPIRITS", ALPHA: "IIPRSST", HINTS: [0, 6, 3] },
  { WORD: "EMPEROR", ALPHA: "EEMOPRR", HINTS: [3, 0, 6] },
  { WORD: "BEACHES", ALPHA: "ABCEEHS", HINTS: [3, 6, 2] },
  { WORD: "CHARGED", ALPHA: "ACDEGHR", HINTS: [6, 5, 2] },
  { WORD: "COTTAGE", ALPHA: "ACEGOTT", HINTS: [2, 4, 3] },
  { WORD: "COURAGE", ALPHA: "ACEGORU", HINTS: [6, 5, 2] },
  { WORD: "OUTDOOR", ALPHA: "DOOORTU", HINTS: [1, 6, 2] },
  { WORD: "MONTHLY", ALPHA: "HLMNOTY", HINTS: [0, 4, 1] },
  { WORD: "HORIZON", ALPHA: "HINOORZ", HINTS: [2, 0, 5] },
  { WORD: "GLUCOSE", ALPHA: "CEGLOSU", HINTS: [6, 3, 1] },
  { WORD: "TICKETS", ALPHA: "CEIKSTT", HINTS: [1, 5, 4] },
  { WORD: "DELETED", ALPHA: "DDEEELT", HINTS: [1, 4, 5] },
  { WORD: "LATINAS", ALPHA: "AAILNST", HINTS: [3, 2, 0] },
  { WORD: "BOLIVIA", ALPHA: "ABIILOV", HINTS: [1, 5, 6] },
  { WORD: "COMPANY", ALPHA: "ACMNOPY", HINTS: [4, 0, 6] },
  { WORD: "COLLEGE", ALPHA: "CEEGLLO", HINTS: [5, 3, 2] },
  { WORD: "BECOMES", ALPHA: "BCEEMOS", HINTS: [0, 6, 5] },
  { WORD: "ENQUIRY", ALPHA: "EINQRUY", HINTS: [3, 4, 2] },
  { WORD: "COMFORT", ALPHA: "CFMOORT", HINTS: [1, 6, 0] },
  { WORD: "BACKING", ALPHA: "ABCGIKN", HINTS: [4, 5, 3] },
  { WORD: "CONCORD", ALPHA: "CCDNOOR", HINTS: [0, 2, 1] },
  { WORD: "THERAPY", ALPHA: "AEHPRTY", HINTS: [5, 3, 0] },
  { WORD: "ACQUIRE", ALPHA: "ACEIQRU", HINTS: [1, 5, 6] },
  { WORD: "FINALLY", ALPHA: "AFILLNY", HINTS: [5, 2, 4] },
  { WORD: "COVERED", ALPHA: "CDEEORV", HINTS: [3, 0, 1] },
  { WORD: "WINNERS", ALPHA: "EINNRSW", HINTS: [4, 2, 0] },
  { WORD: "MATCHES", ALPHA: "ACEHMST", HINTS: [2, 0, 3] },
  { WORD: "QUANTUM", ALPHA: "AMNQTUU", HINTS: [0, 2, 4] },
  { WORD: "PROMOTE", ALPHA: "EMOOPRT", HINTS: [6, 4, 1] },
  { WORD: "APPLIED", ALPHA: "ADEILPP", HINTS: [3, 5, 1] },
  { WORD: "MADONNA", ALPHA: "AADMNNO", HINTS: [1, 3, 0] },
  { WORD: "EPISODE", ALPHA: "DEEIOPS", HINTS: [4, 2, 3] },
  { WORD: "TODDLER", ALPHA: "DDELORT", HINTS: [4, 1, 0] },
  { WORD: "WAITING", ALPHA: "AGIINTW", HINTS: [2, 5, 6] },
  { WORD: "CLOTHES", ALPHA: "CEHLOST", HINTS: [3, 6, 5] },
  { WORD: "BROWSER", ALPHA: "BEORRSW", HINTS: [2, 3, 6] },
  { WORD: "NURSING", ALPHA: "GINNRSU", HINTS: [6, 0, 4] },
  { WORD: "WRAPPED", ALPHA: "ADEPPRW", HINTS: [4, 5, 3] },
  { WORD: "LAPTOPS", ALPHA: "ALOPPST", HINTS: [4, 0, 2] },
  { WORD: "CONCERT", ALPHA: "CCENORT", HINTS: [0, 1, 4] },
  { WORD: "UTILIZE", ALPHA: "EIILTUZ", HINTS: [6, 3, 1] },
  { WORD: "LIBRARY", ALPHA: "ABILRRY", HINTS: [2, 6, 1] },
  { WORD: "INFANTS", ALPHA: "AFINNST", HINTS: [0, 2, 1] },
  { WORD: "DOCTORS", ALPHA: "CDOORST", HINTS: [5, 2, 6] },
  { WORD: "REFLECT", ALPHA: "CEEFLRT", HINTS: [1, 2, 3] },
  { WORD: "DEFENSE", ALPHA: "DEEEFNS", HINTS: [0, 6, 2] },
  { WORD: "SUMMARY", ALPHA: "AMMRSUY", HINTS: [1, 0, 3] },
  { WORD: "OPINION", ALPHA: "IINNOOP", HINTS: [4, 0, 6] },
  { WORD: "CUSTOMS", ALPHA: "CMOSSTU", HINTS: [6, 3, 1] },
  { WORD: "PREMIUM", ALPHA: "EIMMPRU", HINTS: [5, 0, 1] },
  { WORD: "PRETEND", ALPHA: "DEENPRT", HINTS: [4, 1, 2] },
  { WORD: "EMOTION", ALPHA: "EIMNOOT", HINTS: [4, 3, 6] },
  { WORD: "THROUGH", ALPHA: "GHHORTU", HINTS: [3, 6, 2] },
  { WORD: "HOUSING", ALPHA: "GHINOSU", HINTS: [2, 5, 4] },
  { WORD: "SURVIVE", ALPHA: "EIRSUVV", HINTS: [4, 2, 0] },
  { WORD: "ETHICAL", ALPHA: "ACEHILT", HINTS: [3, 1, 0] },
  { WORD: "VISITED", ALPHA: "DEIISTV", HINTS: [5, 6, 2] },
  { WORD: "REALIZE", ALPHA: "AEEILRZ", HINTS: [5, 2, 3] },
  { WORD: "ENHANCE", ALPHA: "ACEEHNN", HINTS: [6, 0, 1] },
  { WORD: "ANALYZE", ALPHA: "AAELNYZ", HINTS: [2, 0, 3] },
  { WORD: "SQUEEZE", ALPHA: "EEEQSUZ", HINTS: [3, 0, 5] },
  { WORD: "BUTTONS", ALPHA: "BNOSTTU", HINTS: [4, 1, 3] },
  { WORD: "SUPPORT", ALPHA: "OPPRSTU", HINTS: [1, 5, 0] },
  { WORD: "INJURED", ALPHA: "DEIJNRU", HINTS: [4, 5, 0] },
  { WORD: "ELDERLY", ALPHA: "DEELLRY", HINTS: [5, 3, 0] },
  { WORD: "BEDDING", ALPHA: "BDDEGIN", HINTS: [2, 6, 0] },
  { WORD: "TOBACCO", ALPHA: "ABCCOOT", HINTS: [1, 0, 4] },
  { WORD: "PLANNED", ALPHA: "ADELNNP", HINTS: [5, 6, 4] },
  { WORD: "PREFERS", ALPHA: "EEFPRRS", HINTS: [6, 5, 2] },
  { WORD: "CONCEPT", ALPHA: "CCENOPT", HINTS: [6, 4, 5] },
  { WORD: "STRINGS", ALPHA: "GINRSST", HINTS: [4, 3, 1] },
  { WORD: "BENEATH", ALPHA: "ABEEHNT", HINTS: [1, 3, 2] },
  { WORD: "ROYALTY", ALPHA: "ALORTYY", HINTS: [3, 4, 1] },
  { WORD: "PERSONS", ALPHA: "ENOPRSS", HINTS: [6, 0, 1] },
  { WORD: "DECIDED", ALPHA: "CDDDEEI", HINTS: [0, 4, 1] },
  { WORD: "ECLIPSE", ALPHA: "CEEILPS", HINTS: [2, 3, 0] },
  { WORD: "BOOKING", ALPHA: "BGIKNOO", HINTS: [3, 2, 5] },
  { WORD: "MYSTERY", ALPHA: "EMRSTYY", HINTS: [0, 3, 6] },
  { WORD: "ROUGHLY", ALPHA: "GHLORUY", HINTS: [5, 4, 6] },
  { WORD: "UNKNOWN", ALPHA: "KNNNOUW", HINTS: [0, 4, 1] },
  { WORD: "WEEKEND", ALPHA: "DEEEKNW", HINTS: [3, 5, 6] },
  { WORD: "STATION", ALPHA: "AINOSTT", HINTS: [0, 3, 1] },
  { WORD: "TRIBUTE", ALPHA: "BEIRTTU", HINTS: [5, 0, 6] },
  { WORD: "VACCINE", ALPHA: "ACCEINV", HINTS: [4, 2, 1] },
  { WORD: "ANYBODY", ALPHA: "ABDNOYY", HINTS: [5, 1, 2] },
  { WORD: "DAMAGED", ALPHA: "AADDEGM", HINTS: [5, 1, 6] },
  { WORD: "LUGGAGE", ALPHA: "AEGGGLU", HINTS: [2, 4, 3] },
  { WORD: "ATHLETE", ALPHA: "AEEHLTT", HINTS: [4, 6, 1] },
  { WORD: "ORGANIC", ALPHA: "ACGINOR", HINTS: [2, 4, 6] },
  { WORD: "EXCLUDE", ALPHA: "CDEELUX", HINTS: [3, 5, 2] },
  { WORD: "FAILURE", ALPHA: "AEFILRU", HINTS: [5, 0, 2] },
  { WORD: "ARRIVAL", ALPHA: "AAILRRV", HINTS: [1, 3, 5] },
  { WORD: "KARAOKE", ALPHA: "AAEKKOR", HINTS: [2, 6, 5] },
  { WORD: "UPDATED", ALPHA: "ADDEPTU", HINTS: [2, 4, 5] },
  { WORD: "BALANCE", ALPHA: "AABCELN", HINTS: [6, 4, 1] },
  { WORD: "EXECUTE", ALPHA: "CEEETUX", HINTS: [4, 1, 5] },
  { WORD: "COOKIES", ALPHA: "CEIKOOS", HINTS: [6, 0, 5] },
  { WORD: "PRIVATE", ALPHA: "AEIPRTV", HINTS: [4, 5, 2] },
  { WORD: "SEAFOOD", ALPHA: "ADEFOOS", HINTS: [3, 6, 1] },
  { WORD: "THUNDER", ALPHA: "DEHNRTU", HINTS: [6, 0, 5] },
  { WORD: "SOMEHOW", ALPHA: "EHMOOSW", HINTS: [2, 1, 5] },
  { WORD: "BILLION", ALPHA: "BIILLNO", HINTS: [6, 4, 3] },
  { WORD: "ATTEMPT", ALPHA: "AEMPTTT", HINTS: [4, 6, 5] },
  { WORD: "CONTROL", ALPHA: "CLNOORT", HINTS: [0, 2, 1] },
  { WORD: "CHRONIC", ALPHA: "CCHINOR", HINTS: [2, 6, 3] },
  { WORD: "AIRFARE", ALPHA: "AAEFIRR", HINTS: [2, 5, 4] },
  { WORD: "SCIENCE", ALPHA: "CCEEINS", HINTS: [5, 1, 4] },
  { WORD: "WRITING", ALPHA: "GIINRTW", HINTS: [0, 6, 5] },
  { WORD: "PHANTOM", ALPHA: "AHMNOPT", HINTS: [1, 3, 0] },
  { WORD: "APPLIES", ALPHA: "AEILPPS", HINTS: [6, 4, 1] },
  { WORD: "EXACTLY", ALPHA: "ACELTXY", HINTS: [2, 0, 6] },
  { WORD: "COURIER", ALPHA: "CEIORRU", HINTS: [4, 3, 6] },
  { WORD: "VESSELS", ALPHA: "EELSSSV", HINTS: [5, 1, 2] },
  { WORD: "ADVISER", ALPHA: "ADEIRSV", HINTS: [3, 5, 0] },
  { WORD: "LIBERTY", ALPHA: "BEILRTY", HINTS: [5, 0, 6] },
  { WORD: "LOTTERY", ALPHA: "ELORTTY", HINTS: [3, 1, 5] },
  { WORD: "LOCALLY", ALPHA: "ACLLLOY", HINTS: [5, 1, 3] },
  { WORD: "ADAPTED", ALPHA: "AADDEPT", HINTS: [3, 2, 5] },
  { WORD: "AMBIENT", ALPHA: "ABEIMNT", HINTS: [1, 5, 4] },
  { WORD: "OPERATE", ALPHA: "AEEOPRT", HINTS: [6, 0, 3] },
  { WORD: "DERIVED", ALPHA: "DDEEIRV", HINTS: [0, 2, 5] },
  { WORD: "LAWSUIT", ALPHA: "AILSTUW", HINTS: [1, 3, 4] },
  { WORD: "UNIFIED", ALPHA: "DEFIINU", HINTS: [5, 6, 2] },
  { WORD: "MANAGED", ALPHA: "AADEGMN", HINTS: [4, 5, 0] },
  { WORD: "LOGICAL", ALPHA: "ACGILLO", HINTS: [6, 1, 3] },
  { WORD: "USUALLY", ALPHA: "ALLSUUY", HINTS: [4, 5, 1] },
  { WORD: "ALLEGED", ALPHA: "ADEEGLL", HINTS: [5, 6, 0] },
  { WORD: "GETTING", ALPHA: "EGGINTT", HINTS: [1, 3, 2] },
  { WORD: "TWISTED", ALPHA: "DEISTTW", HINTS: [0, 3, 1] },
  { WORD: "DYNAMIC", ALPHA: "ACDIMNY", HINTS: [0, 6, 4] },
  { WORD: "SHUTTLE", ALPHA: "EHLSTTU", HINTS: [6, 5, 4] },
  { WORD: "ELEGANT", ALPHA: "AEEGLNT", HINTS: [4, 0, 3] },
  { WORD: "EXAMINE", ALPHA: "AEEIMNX", HINTS: [1, 5, 0] },
  { WORD: "RECRUIT", ALPHA: "CEIRRTU", HINTS: [3, 1, 5] },
  { WORD: "SHOWING", ALPHA: "GHINOSW", HINTS: [0, 4, 1] },
  { WORD: "EXHIBIT", ALPHA: "BEHIITX", HINTS: [5, 4, 1] },
  { WORD: "LOOKING", ALPHA: "GIKLNOO", HINTS: [6, 0, 3] },
  { WORD: "ARRANGE", ALPHA: "AAEGNRR", HINTS: [1, 0, 3] },
  { WORD: "CHANNEL", ALPHA: "ACEHLNN", HINTS: [4, 6, 1] },
  { WORD: "VILLAGE", ALPHA: "AEGILLV", HINTS: [1, 2, 4] },
  { WORD: "TRIUMPH", ALPHA: "HIMPRTU", HINTS: [1, 5, 4] },
  { WORD: "FIFTEEN", ALPHA: "EEFFINT", HINTS: [6, 1, 4] },
  { WORD: "SUICIDE", ALPHA: "CDEIISU", HINTS: [5, 1, 3] },
  { WORD: "INDUCED", ALPHA: "CDDEINU", HINTS: [5, 1, 2] },
  { WORD: "PLAYBOY", ALPHA: "ABLOPYY", HINTS: [4, 3, 2] },
  { WORD: "RESTORE", ALPHA: "EEORRST", HINTS: [1, 5, 3] },
  { WORD: "WEIGHTS", ALPHA: "EGHISTW", HINTS: [4, 2, 5] },
  { WORD: "FUTURES", ALPHA: "EFRSTUU", HINTS: [2, 3, 0] },
  { WORD: "BLOCKED", ALPHA: "BCDEKLO", HINTS: [2, 3, 0] },
  { WORD: "BIGGEST", ALPHA: "BEGGIST", HINTS: [0, 6, 3] },
  { WORD: "GENERIC", ALPHA: "CEEGINR", HINTS: [2, 6, 3] },
  { WORD: "HAPPENS", ALPHA: "AEHNPPS", HINTS: [1, 5, 2] },
  { WORD: "WARRIOR", ALPHA: "AIORRRW", HINTS: [0, 3, 1] },
  { WORD: "CATALOG", ALPHA: "AACGLOT", HINTS: [0, 5, 1] },
  { WORD: "PAINFUL", ALPHA: "AFILNPU", HINTS: [5, 3, 4] },
  { WORD: "HANDFUL", ALPHA: "ADFHLNU", HINTS: [0, 1, 4] },
  { WORD: "OPTIMAL", ALPHA: "AILMOPT", HINTS: [2, 1, 5] },
  { WORD: "FLIGHTS", ALPHA: "FGHILST", HINTS: [2, 4, 1] },
  { WORD: "EXCITED", ALPHA: "CDEEITX", HINTS: [1, 6, 4] },
  { WORD: "AUTHORS", ALPHA: "AHORSTU", HINTS: [2, 4, 1] },
  { WORD: "RELEASE", ALPHA: "AEEELRS", HINTS: [3, 4, 2] },
  { WORD: "GENETIC", ALPHA: "CEEGINT", HINTS: [4, 3, 2] },
  { WORD: "PATIENT", ALPHA: "AEINPTT", HINTS: [5, 3, 2] },
  { WORD: "PAYROLL", ALPHA: "ALLOPRY", HINTS: [2, 0, 1] },
  { WORD: "CLASSIC", ALPHA: "ACCILSS", HINTS: [4, 2, 0] },
  { WORD: "COMMAND", ALPHA: "ACDMMNO", HINTS: [3, 2, 6] },
  { WORD: "DEVOTED", ALPHA: "DDEEOTV", HINTS: [3, 5, 4] },
  { WORD: "DEFICIT", ALPHA: "CDEFIIT", HINTS: [3, 6, 1] },
  { WORD: "GAZETTE", ALPHA: "AEEGTTZ", HINTS: [3, 2, 6] },
  { WORD: "WHEREAS", ALPHA: "AEEHRSW", HINTS: [3, 4, 2] },
  { WORD: "AFFECTS", ALPHA: "ACEFFST", HINTS: [5, 0, 6] },
  { WORD: "FLORIST", ALPHA: "FILORST", HINTS: [1, 0, 4] },
  { WORD: "BRACKET", ALPHA: "ABCEKRT", HINTS: [3, 5, 0] },
  { WORD: "DRESSES", ALPHA: "DEERSSS", HINTS: [0, 1, 6] },
  { WORD: "PERFUME", ALPHA: "EEFMPRU", HINTS: [6, 1, 5] },
  { WORD: "PACKING", ALPHA: "ACGIKNP", HINTS: [4, 1, 3] },
  { WORD: "SHERIFF", ALPHA: "EFFHIRS", HINTS: [5, 1, 3] },
  { WORD: "CURRENT", ALPHA: "CENRRTU", HINTS: [4, 1, 6] },
  { WORD: "COSTUME", ALPHA: "CEMOSTU", HINTS: [0, 1, 3] },
  { WORD: "CAPABLE", ALPHA: "AABCELP", HINTS: [6, 2, 4] },
  { WORD: "EQUALLY", ALPHA: "AELLQUY", HINTS: [0, 6, 5] },
  { WORD: "GREATLY", ALPHA: "AEGLRTY", HINTS: [5, 6, 4] },
  { WORD: "GRAVITY", ALPHA: "AGIRTVY", HINTS: [6, 5, 0] },
  { WORD: "TURNING", ALPHA: "GINNRTU", HINTS: [0, 5, 3] },
  { WORD: "BREATHE", ALPHA: "ABEEHRT", HINTS: [3, 1, 2] },
  { WORD: "CONTEXT", ALPHA: "CENOTTX", HINTS: [2, 6, 1] },
  { WORD: "EVENING", ALPHA: "EEGINNV", HINTS: [6, 4, 2] },
  { WORD: "WISHING", ALPHA: "GHIINSW", HINTS: [3, 6, 5] },
  { WORD: "FREEDOM", ALPHA: "DEEFMOR", HINTS: [2, 5, 1] },
  { WORD: "SPECIES", ALPHA: "CEEIPSS", HINTS: [3, 6, 1] },
  { WORD: "RECEIVE", ALPHA: "CEEEIRV", HINTS: [3, 6, 5] },
  { WORD: "HARMONY", ALPHA: "AHMNORY", HINTS: [3, 1, 5] },
  { WORD: "WEBCAST", ALPHA: "ABCESTW", HINTS: [3, 6, 2] },
  { WORD: "HEAVILY", ALPHA: "AEHILVY", HINTS: [2, 6, 0] },
  { WORD: "BELONGS", ALPHA: "BEGLNOS", HINTS: [5, 2, 6] },
  { WORD: "MILLION", ALPHA: "IILLMNO", HINTS: [6, 4, 0] },
  { WORD: "METHODS", ALPHA: "DEHMOST", HINTS: [6, 2, 5] },
  { WORD: "CLOSING", ALPHA: "CGILNOS", HINTS: [2, 3, 1] },
  { WORD: "WARNING", ALPHA: "AGINNRW", HINTS: [0, 2, 1] },
  { WORD: "PREDICT", ALPHA: "CDEIPRT", HINTS: [1, 4, 2] },
  { WORD: "BOTTLES", ALPHA: "BELOSTT", HINTS: [0, 6, 3] },
  { WORD: "VOLTAGE", ALPHA: "AEGLOTV", HINTS: [5, 0, 1] },
  { WORD: "UPDATES", ALPHA: "ADEPSTU", HINTS: [1, 3, 4] },
  { WORD: "ANXIETY", ALPHA: "AEINTXY", HINTS: [3, 6, 4] },
  { WORD: "STYLISH", ALPHA: "HILSSTY", HINTS: [0, 2, 3] },
  { WORD: "ANTENNA", ALPHA: "AAENNNT", HINTS: [5, 2, 3] },
  { WORD: "FORMULA", ALPHA: "AFLMORU", HINTS: [3, 5, 2] },
  { WORD: "PRIMARY", ALPHA: "AIMPRRY", HINTS: [3, 0, 4] },
  { WORD: "MISSION", ALPHA: "IIMNOSS", HINTS: [5, 0, 4] },
  { WORD: "EXTREME", ALPHA: "EEEMRTX", HINTS: [3, 0, 2] },
  { WORD: "CYCLING", ALPHA: "CCGILNY", HINTS: [0, 2, 1] },
  { WORD: "WHETHER", ALPHA: "EEHHRTW", HINTS: [1, 6, 0] },
  { WORD: "HOLDERS", ALPHA: "DEHLORS", HINTS: [5, 6, 2] },
  { WORD: "COUPLED", ALPHA: "CDELOPU", HINTS: [0, 1, 6] },
  { WORD: "BELIEVE", ALPHA: "BEEEILV", HINTS: [2, 4, 1] },
  { WORD: "EXCERPT", ALPHA: "CEEPRTX", HINTS: [1, 6, 3] },
  { WORD: "PROFILE", ALPHA: "EFILOPR", HINTS: [5, 0, 1] },
  { WORD: "WEARING", ALPHA: "AEGINRW", HINTS: [0, 4, 5] },
  { WORD: "BROTHER", ALPHA: "BEHORRT", HINTS: [0, 1, 3] },
  { WORD: "ACRYLIC", ALPHA: "ACCILRY", HINTS: [5, 1, 3] },
  { WORD: "HAZARDS", ALPHA: "AADHRSZ", HINTS: [5, 6, 1] },
  { WORD: "FAILING", ALPHA: "AFGIILN", HINTS: [3, 4, 5] },
  { WORD: "ALCOHOL", ALPHA: "ACHLLOO", HINTS: [2, 0, 4] },
  { WORD: "FOREIGN", ALPHA: "EFGINOR", HINTS: [1, 5, 3] },
  { WORD: "PREVENT", ALPHA: "EENPRTV", HINTS: [0, 3, 6] },
  { WORD: "HABITAT", ALPHA: "AABHITT", HINTS: [3, 5, 2] },
  { WORD: "PULLING", ALPHA: "GILLNPU", HINTS: [6, 2, 1] },
  { WORD: "WORKING", ALPHA: "GIKNORW", HINTS: [2, 4, 0] },
  { WORD: "COACHES", ALPHA: "ACCEHOS", HINTS: [3, 2, 1] },
  { WORD: "WINDOWS", ALPHA: "DINOSWW", HINTS: [3, 4, 2] },
  { WORD: "ECOLOGY", ALPHA: "CEGLOOY", HINTS: [3, 2, 5] },
  { WORD: "SOCIETY", ALPHA: "CEIOSTY", HINTS: [4, 0, 5] },
  { WORD: "PLANNER", ALPHA: "AELNNPR", HINTS: [0, 3, 2] },
  { WORD: "SUCCEED", ALPHA: "CCDEESU", HINTS: [2, 1, 3] },
  { WORD: "IMPOSED", ALPHA: "DEIMOPS", HINTS: [0, 4, 5] },
  { WORD: "MAXIMUM", ALPHA: "AIMMMUX", HINTS: [0, 1, 3] },
  { WORD: "EXHAUST", ALPHA: "AEHSTUX", HINTS: [4, 2, 3] },
  { WORD: "FACTORS", ALPHA: "ACFORST", HINTS: [1, 6, 3] },
  { WORD: "CUISINE", ALPHA: "CEIINSU", HINTS: [6, 5, 2] },
  { WORD: "PACIFIC", ALPHA: "ACCFIIP", HINTS: [0, 5, 4] },
  { WORD: "STOMACH", ALPHA: "ACHMOST", HINTS: [4, 0, 3] },
  { WORD: "MAILMAN", ALPHA: "AAILMMN", HINTS: [4, 6, 3] },
  { WORD: "SIGNALS", ALPHA: "AGILNSS", HINTS: [1, 6, 0] },
  { WORD: "SHIPPED", ALPHA: "DEHIPPS", HINTS: [0, 1, 4] },
  { WORD: "DIAGRAM", ALPHA: "AADGIMR", HINTS: [5, 4, 6] },
  { WORD: "PREMIER", ALPHA: "EEIMPRR", HINTS: [4, 6, 2] },
  { WORD: "HISTORY", ALPHA: "HIORSTY", HINTS: [4, 3, 5] },
  { WORD: "APPROVE", ALPHA: "AEOPPRV", HINTS: [4, 1, 3] },
  { WORD: "DECLINE", ALPHA: "CDEEILN", HINTS: [0, 5, 4] },
  { WORD: "SMALLER", ALPHA: "AELLMRS", HINTS: [0, 5, 6] },
  { WORD: "NURSERY", ALPHA: "ENRRSUY", HINTS: [3, 1, 5] },
  { WORD: "FANTASY", ALPHA: "AAFNSTY", HINTS: [2, 4, 6] },
  { WORD: "FOLDING", ALPHA: "DFGILNO", HINTS: [4, 2, 6] },
  { WORD: "SUCCESS", ALPHA: "CCESSSU", HINTS: [5, 2, 3] },
  { WORD: "REFUGEE", ALPHA: "EEEFGRU", HINTS: [3, 2, 1] },
  { WORD: "PRODUCT", ALPHA: "CDOPRTU", HINTS: [1, 0, 5] },
  { WORD: "EXPIRED", ALPHA: "DEEIPRX", HINTS: [4, 6, 1] },
  { WORD: "BATTERY", ALPHA: "ABERTTY", HINTS: [5, 0, 4] },
  { WORD: "HANDLED", ALPHA: "ADDEHLN", HINTS: [0, 2, 5] },
  { WORD: "STUDIOS", ALPHA: "DIOSSTU", HINTS: [0, 3, 4] },
  { WORD: "NUMBERS", ALPHA: "BEMNRSU", HINTS: [5, 6, 0] },
  { WORD: "TRUSTEE", ALPHA: "EERSTTU", HINTS: [5, 4, 1] },
  { WORD: "KINGDOM", ALPHA: "DGIKMNO", HINTS: [3, 2, 5] },
  { WORD: "LEGALLY", ALPHA: "AEGLLLY", HINTS: [3, 5, 6] },
  { WORD: "GRAMMAR", ALPHA: "AAGMMRR", HINTS: [2, 5, 1] },
  { WORD: "CONSUME", ALPHA: "CEMNOSU", HINTS: [2, 0, 4] },
  { WORD: "SCRIPTS", ALPHA: "CIPRSST", HINTS: [1, 0, 6] },
  { WORD: "MILEAGE", ALPHA: "AEEGILM", HINTS: [0, 1, 2] },
  { WORD: "HOLDING", ALPHA: "DGHILNO", HINTS: [4, 1, 6] },
  { WORD: "LOADING", ALPHA: "ADGILNO", HINTS: [4, 6, 2] },
  { WORD: "KNIGHTS", ALPHA: "GHIKNST", HINTS: [3, 5, 1] },
  { WORD: "SHADOWS", ALPHA: "ADHOSSW", HINTS: [3, 1, 2] },
  { WORD: "INSTANT", ALPHA: "AINNSTT", HINTS: [4, 0, 3] },
  { WORD: "GESTURE", ALPHA: "EEGRSTU", HINTS: [3, 6, 5] },
  { WORD: "OBJECTS", ALPHA: "BCEJOST", HINTS: [1, 6, 5] },
  { WORD: "HAMBURG", ALPHA: "ABGHMRU", HINTS: [0, 5, 3] },
  { WORD: "ANOTHER", ALPHA: "AEHNORT", HINTS: [6, 0, 4] },
  { WORD: "ALREADY", ALPHA: "AADELRY", HINTS: [4, 6, 5] },
  { WORD: "WARRANT", ALPHA: "AANRRTW", HINTS: [6, 4, 3] },
  { WORD: "ACROBAT", ALPHA: "AABCORT", HINTS: [5, 2, 4] },
  { WORD: "VIRTUAL", ALPHA: "AILRTUV", HINTS: [4, 0, 6] },
  { WORD: "BOUQUET", ALPHA: "BEOQTUU", HINTS: [5, 1, 3] },
  { WORD: "COMPOSE", ALPHA: "CEMOOPS", HINTS: [5, 6, 2] },
  { WORD: "WELCOME", ALPHA: "CEELMOW", HINTS: [5, 1, 4] },
  { WORD: "FORTUNE", ALPHA: "EFNORTU", HINTS: [1, 6, 5] },
  { WORD: "PURPOSE", ALPHA: "EOPPRSU", HINTS: [6, 5, 1] },
  { WORD: "SAVINGS", ALPHA: "AGINSSV", HINTS: [3, 6, 0] },
  { WORD: "CONTENT", ALPHA: "CENNOTT", HINTS: [4, 6, 0] },
  { WORD: "SPENCER", ALPHA: "CEENPRS", HINTS: [3, 4, 5] },
  { WORD: "KNOWING", ALPHA: "GIKNNOW", HINTS: [1, 6, 0] },
  { WORD: "SCORING", ALPHA: "CGINORS", HINTS: [0, 2, 6] },
  { WORD: "COUNTED", ALPHA: "CDENOTU", HINTS: [4, 6, 3] },
  { WORD: "BIOLOGY", ALPHA: "BGILOOY", HINTS: [3, 1, 6] },
  { WORD: "YOUNGER", ALPHA: "EGNORUY", HINTS: [3, 1, 6] },
  { WORD: "SYMBOLS", ALPHA: "BLMOSSY", HINTS: [2, 3, 0] },
  { WORD: "FOLLOWS", ALPHA: "FLLOOSW", HINTS: [2, 0, 1] },
  { WORD: "COMPARE", ALPHA: "ACEMOPR", HINTS: [6, 1, 5] },
  { WORD: "NUMERIC", ALPHA: "CEIMNRU", HINTS: [2, 5, 1] },
  { WORD: "SUGGEST", ALPHA: "EGGSSTU", HINTS: [4, 6, 1] },
  { WORD: "PARTNER", ALPHA: "AENPRRT", HINTS: [4, 5, 3] },
  { WORD: "GROWING", ALPHA: "GGINORW", HINTS: [0, 6, 4] },
  { WORD: "CRUISES", ALPHA: "CEIRSSU", HINTS: [6, 5, 2] },
  { WORD: "PUBLISH", ALPHA: "BHILPSU", HINTS: [1, 0, 5] },
  { WORD: "DISTANT", ALPHA: "ADINSTT", HINTS: [5, 3, 2] },
  { WORD: "WARMING", ALPHA: "AGIMNRW", HINTS: [3, 2, 5] },
  { WORD: "MORNING", ALPHA: "GIMNNOR", HINTS: [4, 3, 0] },
  { WORD: "QUALIFY", ALPHA: "AFILQUY", HINTS: [3, 4, 2] },
  { WORD: "GOURMET", ALPHA: "EGMORTU", HINTS: [4, 3, 1] },
  { WORD: "LEGENDS", ALPHA: "DEEGLNS", HINTS: [2, 5, 4] },
  { WORD: "CONCERN", ALPHA: "CCENNOR", HINTS: [4, 3, 2] },
  { WORD: "VARYING", ALPHA: "AGINRVY", HINTS: [2, 4, 5] },
  { WORD: "RAILWAY", ALPHA: "AAILRWY", HINTS: [0, 6, 5] },
  { WORD: "OBVIOUS", ALPHA: "BIOOSUV", HINTS: [6, 2, 1] },
  { WORD: "READILY", ALPHA: "ADEILRY", HINTS: [3, 2, 0] },
  { WORD: "CAMPING", ALPHA: "ACGIMNP", HINTS: [2, 6, 4] },
  { WORD: "BUFFALO", ALPHA: "ABFFLOU", HINTS: [6, 5, 4] },
  { WORD: "TELECOM", ALPHA: "CEELMOT", HINTS: [3, 4, 0] },
  { WORD: "ROLLING", ALPHA: "GILLNOR", HINTS: [5, 0, 3] },
  { WORD: "JACKETS", ALPHA: "ACEJKST", HINTS: [5, 3, 1] },
  { WORD: "MODULES", ALPHA: "DELMOSU", HINTS: [2, 3, 1] },
  { WORD: "AWARDED", ALPHA: "AADDERW", HINTS: [0, 6, 2] },
  { WORD: "FASHION", ALPHA: "AFHINOS", HINTS: [0, 4, 2] },
  { WORD: "FUNDING", ALPHA: "DFGINNU", HINTS: [0, 3, 1] },
  { WORD: "STOPPED", ALPHA: "DEOPPST", HINTS: [0, 1, 6] },
  { WORD: "SIMILAR", ALPHA: "AIILMRS", HINTS: [0, 1, 4] },
  { WORD: "LOCATED", ALPHA: "ACDELOT", HINTS: [3, 0, 2] },
  { WORD: "SURPLUS", ALPHA: "LPRSSUU", HINTS: [2, 0, 6] },
  { WORD: "EFFECTS", ALPHA: "CEEFFST", HINTS: [1, 5, 6] },
  { WORD: "CHECKED", ALPHA: "CCDEEHK", HINTS: [2, 6, 5] },
  { WORD: "MAGICAL", ALPHA: "AACGILM", HINTS: [2, 4, 0] },
  { WORD: "ASSAULT", ALPHA: "AALSSTU", HINTS: [2, 1, 0] },
  { WORD: "MOROCCO", ALPHA: "CCMOOOR", HINTS: [6, 1, 3] },
  { WORD: "LANDING", ALPHA: "ADGILNN", HINTS: [1, 6, 0] },
  { WORD: "BECAUSE", ALPHA: "ABCEESU", HINTS: [1, 3, 2] },
  { WORD: "AVERAGE", ALPHA: "AAEEGRV", HINTS: [6, 2, 5] },
  { WORD: "LARGEST", ALPHA: "AEGLRST", HINTS: [2, 0, 6] },
  { WORD: "ENGAGED", ALPHA: "ADEEGGN", HINTS: [5, 1, 0] },
  { WORD: "SEVENTH", ALPHA: "EEHNSTV", HINTS: [2, 1, 5] },
  { WORD: "DONATED", ALPHA: "ADDENOT", HINTS: [3, 5, 0] },
  { WORD: "SOMEONE", ALPHA: "EEMNOOS", HINTS: [5, 3, 0] },
  { WORD: "MIRRORS", ALPHA: "IMORRRS", HINTS: [0, 2, 5] },
  { WORD: "ENSURES", ALPHA: "EENRSSU", HINTS: [2, 4, 6] },
  { WORD: "POLYMER", ALPHA: "ELMOPRY", HINTS: [2, 4, 0] },
  { WORD: "COPYING", ALPHA: "CGINOPY", HINTS: [6, 4, 5] },
  { WORD: "WEAPONS", ALPHA: "AENOPSW", HINTS: [0, 5, 2] },
  { WORD: "CLOSELY", ALPHA: "CELLOSY", HINTS: [1, 5, 4] },
  { WORD: "LAWYERS", ALPHA: "AELRSWY", HINTS: [1, 4, 5] },
  { WORD: "ADVISED", ALPHA: "ADDEISV", HINTS: [4, 2, 0] },
  { WORD: "TRAFFIC", ALPHA: "ACFFIRT", HINTS: [2, 4, 5] },
  { WORD: "ELECTED", ALPHA: "CDEEELT", HINTS: [6, 5, 2] },
  { WORD: "TRIGGER", ALPHA: "EGGIRRT", HINTS: [5, 6, 4] },
  { WORD: "RENEWAL", ALPHA: "AEELNRW", HINTS: [5, 3, 0] },
  { WORD: "ILLEGAL", ALPHA: "AEGILLL", HINTS: [2, 0, 6] },
  { WORD: "DISPUTE", ALPHA: "DEIPSTU", HINTS: [2, 4, 3] },
  { WORD: "JUSTICE", ALPHA: "CEIJSTU", HINTS: [6, 5, 4] },
  { WORD: "DESPITE", ALPHA: "DEEIPST", HINTS: [2, 1, 5] },
  { WORD: "CABINET", ALPHA: "ABCEINT", HINTS: [0, 1, 5] },
  { WORD: "PROBLEM", ALPHA: "BELMOPR", HINTS: [2, 0, 6] },
  { WORD: "INDEXED", ALPHA: "DDEEINX", HINTS: [3, 0, 6] },
  { WORD: "INSIDER", ALPHA: "DEIINRS", HINTS: [6, 4, 1] },
  { WORD: "BASKETS", ALPHA: "ABEKSST", HINTS: [1, 3, 4] },
  { WORD: "PACKETS", ALPHA: "ACEKPST", HINTS: [0, 1, 5] },
  { WORD: "SILICON", ALPHA: "CIILNOS", HINTS: [3, 4, 6] },
  { WORD: "TYPICAL", ALPHA: "ACILPTY", HINTS: [4, 6, 1] },
  { WORD: "HEADSET", ALPHA: "ADEEHST", HINTS: [1, 6, 5] },
  { WORD: "DELAYED", ALPHA: "ADDEELY", HINTS: [3, 5, 1] },
  { WORD: "BOROUGH", ALPHA: "BGHOORU", HINTS: [2, 3, 0] },
  { WORD: "BANGKOK", ALPHA: "ABGKKNO", HINTS: [6, 3, 0] },
  { WORD: "PROVIDE", ALPHA: "DEIOPRV", HINTS: [2, 1, 0] },
  { WORD: "MANDATE", ALPHA: "AADEMNT", HINTS: [0, 5, 4] },
  { WORD: "MISSING", ALPHA: "GIIMNSS", HINTS: [4, 5, 1] },
  { WORD: "JEWELRY", ALPHA: "EEJLRWY", HINTS: [5, 4, 6] },
  { WORD: "CRYSTAL", ALPHA: "ACLRSTY", HINTS: [0, 3, 5] },
  { WORD: "BENEFIT", ALPHA: "BEEFINT", HINTS: [0, 6, 2] },
];

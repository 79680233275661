export const nine_hints = [
  {
    WORD: "ACCORDING",
    ALPHA: "CCDGNO?",
    WILDCARD: "R",
    WILDCARD_INDEX: 4,
    LETTERS: ["A", "I"],
    INDICES: [0, 6],
    HINTS: [8, 2, 1],
  },
  {
    WORD: "MINIATURE",
    ALPHA: "EIIMNT?",
    WILDCARD: "R",
    WILDCARD_INDEX: 7,
    LETTERS: ["U", "A"],
    INDICES: [6, 4],
    HINTS: [8, 3, 2],
  },
  {
    WORD: "ATTENTION",
    ALPHA: "NNOTTT?",
    WILDCARD: "A",
    WILDCARD_INDEX: 0,
    LETTERS: ["I", "E"],
    INDICES: [6, 3],
    HINTS: [1, 8, 7],
  },
  {
    WORD: "HOUSEHOLD",
    ALPHA: "DEHLOS?",
    WILDCARD: "O",
    WILDCARD_INDEX: 1,
    LETTERS: ["U", "H"],
    INDICES: [2, 5],
    HINTS: [4, 6, 8],
  },
  {
    WORD: "GENTLEMAN",
    ALPHA: "AEGNNT?",
    WILDCARD: "L",
    WILDCARD_INDEX: 4,
    LETTERS: ["M", "E"],
    INDICES: [6, 5],
    HINTS: [0, 7, 2],
  },
  {
    WORD: "TERRITORY",
    ALPHA: "IORRTY?",
    WILDCARD: "R",
    WILDCARD_INDEX: 2,
    LETTERS: ["E", "T"],
    INDICES: [1, 0],
    HINTS: [5, 4, 6],
  },
  {
    WORD: "PENINSULA",
    ALPHA: "ALNPSU?",
    WILDCARD: "E",
    WILDCARD_INDEX: 1,
    LETTERS: ["I", "N"],
    INDICES: [3, 2],
    HINTS: [7, 5, 4],
  },
  {
    WORD: "PERFECTLY",
    ALPHA: "CELPTY?",
    WILDCARD: "F",
    WILDCARD_INDEX: 3,
    LETTERS: ["R", "E"],
    INDICES: [2, 4],
    HINTS: [1, 0, 6],
  },
  {
    WORD: "SECONDARY",
    ALPHA: "ACDESY?",
    WILDCARD: "R",
    WILDCARD_INDEX: 7,
    LETTERS: ["N", "O"],
    INDICES: [4, 3],
    HINTS: [2, 0, 6],
  },
  {
    WORD: "YESTERDAY",
    ALPHA: "AERTYY?",
    WILDCARD: "E",
    WILDCARD_INDEX: 1,
    LETTERS: ["D", "S"],
    INDICES: [6, 2],
    HINTS: [7, 5, 0],
  },
  {
    WORD: "APPLIANCE",
    ALPHA: "AEILNP?",
    WILDCARD: "A",
    WILDCARD_INDEX: 0,
    LETTERS: ["P", "C"],
    INDICES: [2, 7],
    HINTS: [1, 6, 4],
  },
  {
    WORD: "EVERYBODY",
    ALPHA: "BEEVYY?",
    WILDCARD: "R",
    WILDCARD_INDEX: 3,
    LETTERS: ["O", "D"],
    INDICES: [6, 7],
    HINTS: [4, 2, 0],
  },
  {
    WORD: "GRADUATES",
    ALPHA: "AADESU?",
    WILDCARD: "R",
    WILDCARD_INDEX: 1,
    LETTERS: ["G", "T"],
    INDICES: [0, 6],
    HINTS: [3, 2, 5],
  },
  {
    WORD: "SCHEDULED",
    ALPHA: "DDEHLU?",
    WILDCARD: "S",
    WILDCARD_INDEX: 0,
    LETTERS: ["E", "C"],
    INDICES: [7, 1],
    HINTS: [5, 6, 4],
  },
  {
    WORD: "MAGNITUDE",
    ALPHA: "DEIMNT?",
    WILDCARD: "A",
    WILDCARD_INDEX: 1,
    LETTERS: ["U", "G"],
    INDICES: [6, 2],
    HINTS: [4, 3, 8],
  },
  {
    WORD: "OPERATING",
    ALPHA: "AEGIOP?",
    WILDCARD: "N",
    WILDCARD_INDEX: 7,
    LETTERS: ["T", "R"],
    INDICES: [5, 3],
    HINTS: [0, 8, 2],
  },
  {
    WORD: "INTENTION",
    ALPHA: "IINNNT?",
    WILDCARD: "E",
    WILDCARD_INDEX: 3,
    LETTERS: ["T", "O"],
    INDICES: [5, 7],
    HINTS: [8, 0, 4],
  },
  {
    WORD: "INCLUSION",
    ALPHA: "CILNNU?",
    WILDCARD: "O",
    WILDCARD_INDEX: 7,
    LETTERS: ["S", "I"],
    INDICES: [5, 0],
    HINTS: [4, 3, 1],
  },
  {
    WORD: "DIVERSITY",
    ALPHA: "DIISVY?",
    WILDCARD: "E",
    WILDCARD_INDEX: 3,
    LETTERS: ["T", "R"],
    INDICES: [7, 4],
    HINTS: [6, 5, 1],
  },
  {
    WORD: "DISPLAYED",
    ALPHA: "DDIPSY?",
    WILDCARD: "A",
    WILDCARD_INDEX: 5,
    LETTERS: ["E", "L"],
    INDICES: [7, 4],
    HINTS: [3, 8, 2],
  },
  {
    WORD: "PARTITION",
    ALPHA: "IINPTT?",
    WILDCARD: "A",
    WILDCARD_INDEX: 1,
    LETTERS: ["O", "R"],
    INDICES: [7, 2],
    HINTS: [6, 8, 3],
  },
  {
    WORD: "WALLPAPER",
    ALPHA: "ALLPRW?",
    WILDCARD: "A",
    WILDCARD_INDEX: 1,
    LETTERS: ["P", "E"],
    INDICES: [6, 7],
    HINTS: [5, 3, 0],
  },
  {
    WORD: "SCHEDULES",
    ALPHA: "CDELSU?",
    WILDCARD: "H",
    WILDCARD_INDEX: 2,
    LETTERS: ["E", "S"],
    INDICES: [3, 0],
    HINTS: [1, 8, 5],
  },
  {
    WORD: "EXCHANGES",
    ALPHA: "CGHNSX?",
    WILDCARD: "A",
    WILDCARD_INDEX: 4,
    LETTERS: ["E", "E"],
    INDICES: [0, 7],
    HINTS: [1, 2, 8],
  },
  {
    WORD: "MECHANISM",
    ALPHA: "CEHMMS?",
    WILDCARD: "N",
    WILDCARD_INDEX: 5,
    LETTERS: ["I", "A"],
    INDICES: [6, 4],
    HINTS: [7, 2, 3],
  },
  {
    WORD: "TELESCOPE",
    ALPHA: "EEEOST?",
    WILDCARD: "C",
    WILDCARD_INDEX: 5,
    LETTERS: ["L", "P"],
    INDICES: [2, 7],
    HINTS: [0, 3, 4],
  },
  {
    WORD: "PURCHASED",
    ALPHA: "ADHPRS?",
    WILDCARD: "E",
    WILDCARD_INDEX: 7,
    LETTERS: ["C", "U"],
    INDICES: [3, 1],
    HINTS: [4, 0, 6],
  },
  {
    WORD: "ADDICTION",
    ALPHA: "CDDINO?",
    WILDCARD: "T",
    WILDCARD_INDEX: 5,
    LETTERS: ["A", "I"],
    INDICES: [0, 6],
    HINTS: [3, 8, 1],
  },
  {
    WORD: "PREGNANCY",
    ALPHA: "CEGPRY?",
    WILDCARD: "A",
    WILDCARD_INDEX: 5,
    LETTERS: ["N", "N"],
    INDICES: [6, 4],
    HINTS: [7, 3, 1],
  },
  {
    WORD: "PROPOSALS",
    ALPHA: "AOPRSS?",
    WILDCARD: "L",
    WILDCARD_INDEX: 7,
    LETTERS: ["P", "O"],
    INDICES: [3, 2],
    HINTS: [8, 5, 6],
  },
  {
    WORD: "EMPLOYEES",
    ALPHA: "EELMPS?",
    WILDCARD: "Y",
    WILDCARD_INDEX: 5,
    LETTERS: ["O", "E"],
    INDICES: [4, 6],
    HINTS: [8, 2, 7],
  },
  {
    WORD: "MODELLING",
    ALPHA: "DEGLLN?",
    WILDCARD: "I",
    WILDCARD_INDEX: 6,
    LETTERS: ["M", "O"],
    INDICES: [0, 1],
    HINTS: [7, 2, 5],
  },
  {
    WORD: "SOMETHING",
    ALPHA: "GHMNST?",
    WILDCARD: "I",
    WILDCARD_INDEX: 6,
    LETTERS: ["O", "E"],
    INDICES: [1, 3],
    HINTS: [8, 5, 2],
  },
  {
    WORD: "CHARLOTTE",
    ALPHA: "ACELOT?",
    WILDCARD: "R",
    WILDCARD_INDEX: 3,
    LETTERS: ["H", "T"],
    INDICES: [1, 6],
    HINTS: [2, 7, 4],
  },
  {
    WORD: "VOLUNTEER",
    ALPHA: "ELORUV?",
    WILDCARD: "T",
    WILDCARD_INDEX: 5,
    LETTERS: ["N", "E"],
    INDICES: [4, 6],
    HINTS: [7, 2, 3],
  },
  {
    WORD: "INTERVALS",
    ALPHA: "ILNRSV?",
    WILDCARD: "E",
    WILDCARD_INDEX: 3,
    LETTERS: ["A", "T"],
    INDICES: [6, 2],
    HINTS: [0, 8, 4],
  },
  {
    WORD: "REQUESTED",
    ALPHA: "DEERST?",
    WILDCARD: "Q",
    WILDCARD_INDEX: 2,
    LETTERS: ["E", "U"],
    INDICES: [7, 3],
    HINTS: [1, 0, 5],
  },
  {
    WORD: "ACHIEVING",
    ALPHA: "CEGHNV?",
    WILDCARD: "A",
    WILDCARD_INDEX: 0,
    LETTERS: ["I", "I"],
    INDICES: [6, 3],
    HINTS: [4, 2, 7],
  },
  {
    WORD: "MOTIVATED",
    ALPHA: "DEMOTV?",
    WILDCARD: "I",
    WILDCARD_INDEX: 3,
    LETTERS: ["T", "A"],
    INDICES: [2, 5],
    HINTS: [8, 0, 4],
  },
  {
    WORD: "PROMINENT",
    ALPHA: "IMNOPT?",
    WILDCARD: "R",
    WILDCARD_INDEX: 1,
    LETTERS: ["E", "N"],
    INDICES: [6, 5],
    HINTS: [2, 0, 7],
  },
  {
    WORD: "STRATEGIC",
    ALPHA: "CEGIRT?",
    WILDCARD: "A",
    WILDCARD_INDEX: 3,
    LETTERS: ["T", "S"],
    INDICES: [4, 0],
    HINTS: [5, 1, 8],
  },
  {
    WORD: "APPARATUS",
    ALPHA: "APPRST?",
    WILDCARD: "A",
    WILDCARD_INDEX: 0,
    LETTERS: ["A", "U"],
    INDICES: [3, 7],
    HINTS: [4, 8, 6],
  },
  {
    WORD: "PERCEIVED",
    ALPHA: "CDEEIR?",
    WILDCARD: "E",
    WILDCARD_INDEX: 1,
    LETTERS: ["P", "V"],
    INDICES: [0, 6],
    HINTS: [2, 3, 5],
  },
  {
    WORD: "PURCHASES",
    ALPHA: "AEHPRS?",
    WILDCARD: "C",
    WILDCARD_INDEX: 3,
    LETTERS: ["U", "S"],
    INDICES: [1, 6],
    HINTS: [8, 0, 4],
  },
  {
    WORD: "GRADUATED",
    ALPHA: "AADDRT?",
    WILDCARD: "E",
    WILDCARD_INDEX: 7,
    LETTERS: ["G", "U"],
    INDICES: [0, 4],
    HINTS: [6, 8, 2],
  },
  {
    WORD: "OPPONENTS",
    ALPHA: "ENOPST?",
    WILDCARD: "P",
    WILDCARD_INDEX: 1,
    LETTERS: ["N", "O"],
    INDICES: [6, 0],
    HINTS: [7, 4, 2],
  },
  {
    WORD: "EMPIRICAL",
    ALPHA: "ACELMR?",
    WILDCARD: "P",
    WILDCARD_INDEX: 2,
    LETTERS: ["I", "I"],
    INDICES: [3, 5],
    HINTS: [4, 8, 6],
  },
  {
    WORD: "MORTALITY",
    ALPHA: "ALOTTY?",
    WILDCARD: "I",
    WILDCARD_INDEX: 6,
    LETTERS: ["M", "R"],
    INDICES: [0, 2],
    HINTS: [7, 5, 4],
  },
  {
    WORD: "EXPRESSED",
    ALPHA: "DEEPSX?",
    WILDCARD: "R",
    WILDCARD_INDEX: 3,
    LETTERS: ["E", "S"],
    INDICES: [4, 6],
    HINTS: [7, 8, 1],
  },
  {
    WORD: "PARTICLES",
    ALPHA: "CILPST?",
    WILDCARD: "A",
    WILDCARD_INDEX: 1,
    LETTERS: ["E", "R"],
    INDICES: [7, 2],
    HINTS: [8, 6, 5],
  },
  {
    WORD: "PLATFORMS",
    ALPHA: "AFLMRT?",
    WILDCARD: "S",
    WILDCARD_INDEX: 8,
    LETTERS: ["O", "P"],
    INDICES: [5, 0],
    HINTS: [4, 2, 6],
  },
  {
    WORD: "CONSENSUS",
    ALPHA: "CNOSSS?",
    WILDCARD: "U",
    WILDCARD_INDEX: 7,
    LETTERS: ["N", "E"],
    INDICES: [5, 4],
    HINTS: [2, 3, 8],
  },
  {
    WORD: "SOUTHWEST",
    ALPHA: "HSSTTW?",
    WILDCARD: "O",
    WILDCARD_INDEX: 1,
    LETTERS: ["E", "U"],
    INDICES: [6, 2],
    HINTS: [0, 7, 8],
  },
  {
    WORD: "AUTOMATED",
    ALPHA: "ADEMOT?",
    WILDCARD: "T",
    WILDCARD_INDEX: 2,
    LETTERS: ["U", "A"],
    INDICES: [1, 5],
    HINTS: [3, 0, 7],
  },
  {
    WORD: "ANTIVIRUS",
    ALPHA: "AISTUV?",
    WILDCARD: "N",
    WILDCARD_INDEX: 1,
    LETTERS: ["I", "R"],
    INDICES: [3, 6],
    HINTS: [4, 0, 5],
  },
  {
    WORD: "PRESENTLY",
    ALPHA: "EELPTY?",
    WILDCARD: "S",
    WILDCARD_INDEX: 3,
    LETTERS: ["R", "N"],
    INDICES: [1, 5],
    HINTS: [7, 4, 8],
  },
  {
    WORD: "ANSWERING",
    ALPHA: "AINRSW?",
    WILDCARD: "G",
    WILDCARD_INDEX: 8,
    LETTERS: ["E", "N"],
    INDICES: [4, 1],
    HINTS: [2, 0, 5],
  },
  {
    WORD: "CONVERTED",
    ALPHA: "DEENRT?",
    WILDCARD: "O",
    WILDCARD_INDEX: 1,
    LETTERS: ["V", "C"],
    INDICES: [3, 0],
    HINTS: [5, 8, 7],
  },
  {
    WORD: "IMPRESSED",
    ALPHA: "DEEMSS?",
    WILDCARD: "R",
    WILDCARD_INDEX: 3,
    LETTERS: ["I", "P"],
    INDICES: [0, 2],
    HINTS: [4, 7, 5],
  },
  {
    WORD: "PANTYHOSE",
    ALPHA: "AENPTY?",
    WILDCARD: "O",
    WILDCARD_INDEX: 6,
    LETTERS: ["S", "H"],
    INDICES: [7, 5],
    HINTS: [1, 4, 2],
  },
  {
    WORD: "PRECISION",
    ALPHA: "CIINOR?",
    WILDCARD: "E",
    WILDCARD_INDEX: 2,
    LETTERS: ["S", "P"],
    INDICES: [5, 0],
    HINTS: [8, 4, 3],
  },
  {
    WORD: "WORLDWIDE",
    ALPHA: "DDELRW?",
    WILDCARD: "I",
    WILDCARD_INDEX: 6,
    LETTERS: ["O", "W"],
    INDICES: [1, 5],
    HINTS: [0, 7, 3],
  },
  {
    WORD: "COMPLETED",
    ALPHA: "CDELMP?",
    WILDCARD: "O",
    WILDCARD_INDEX: 1,
    LETTERS: ["E", "T"],
    INDICES: [5, 6],
    HINTS: [8, 3, 0],
  },
  {
    WORD: "ADMISSION",
    ALPHA: "ADIMNS?",
    WILDCARD: "O",
    WILDCARD_INDEX: 7,
    LETTERS: ["I", "S"],
    INDICES: [3, 5],
    HINTS: [0, 8, 1],
  },
  {
    WORD: "CHEMICALS",
    ALPHA: "ACHLMS?",
    WILDCARD: "I",
    WILDCARD_INDEX: 4,
    LETTERS: ["C", "E"],
    INDICES: [0, 2],
    HINTS: [3, 6, 1],
  },
  {
    WORD: "INTEGRITY",
    ALPHA: "GIINTY?",
    WILDCARD: "E",
    WILDCARD_INDEX: 3,
    LETTERS: ["R", "T"],
    INDICES: [5, 7],
    HINTS: [4, 2, 1],
  },
  {
    WORD: "SOCIETIES",
    ALPHA: "EEIISS?",
    WILDCARD: "O",
    WILDCARD_INDEX: 1,
    LETTERS: ["T", "C"],
    INDICES: [5, 2],
    HINTS: [6, 7, 4],
  },
  {
    WORD: "OBJECTIVE",
    ALPHA: "BEIJTV?",
    WILDCARD: "E",
    WILDCARD_INDEX: 3,
    LETTERS: ["O", "C"],
    INDICES: [0, 4],
    HINTS: [6, 7, 1],
  },
  {
    WORD: "CHARACTER",
    ALPHA: "ACEHRR?",
    WILDCARD: "T",
    WILDCARD_INDEX: 6,
    LETTERS: ["A", "C"],
    INDICES: [4, 0],
    HINTS: [8, 3, 2],
  },
  {
    WORD: "EXTREMELY",
    ALPHA: "EEEMRX?",
    WILDCARD: "Y",
    WILDCARD_INDEX: 8,
    LETTERS: ["T", "L"],
    INDICES: [2, 7],
    HINTS: [6, 1, 0],
  },
  {
    WORD: "FREELANCE",
    ALPHA: "ACEEFL?",
    WILDCARD: "N",
    WILDCARD_INDEX: 6,
    LETTERS: ["E", "R"],
    INDICES: [3, 1],
    HINTS: [0, 7, 4],
  },
  {
    WORD: "CHAMPAGNE",
    ALPHA: "ACEGNP?",
    WILDCARD: "M",
    WILDCARD_INDEX: 3,
    LETTERS: ["A", "H"],
    INDICES: [2, 1],
    HINTS: [4, 0, 6],
  },
  {
    WORD: "CHECKLIST",
    ALPHA: "CHKLST?",
    WILDCARD: "E",
    WILDCARD_INDEX: 2,
    LETTERS: ["C", "I"],
    INDICES: [3, 6],
    HINTS: [8, 4, 0],
  },
  {
    WORD: "DEMOCRACY",
    ALPHA: "ACEMOY?",
    WILDCARD: "R",
    WILDCARD_INDEX: 5,
    LETTERS: ["C", "D"],
    INDICES: [7, 0],
    HINTS: [8, 2, 4],
  },
  {
    WORD: "DESIGNING",
    ALPHA: "DEGINN?",
    WILDCARD: "I",
    WILDCARD_INDEX: 3,
    LETTERS: ["G", "S"],
    INDICES: [4, 2],
    HINTS: [0, 1, 5],
  },
  {
    WORD: "SITUATION",
    ALPHA: "AIOSTU?",
    WILDCARD: "N",
    WILDCARD_INDEX: 8,
    LETTERS: ["T", "I"],
    INDICES: [2, 1],
    HINTS: [4, 6, 0],
  },
  {
    WORD: "PRODUCERS",
    ALPHA: "CDERSU?",
    WILDCARD: "O",
    WILDCARD_INDEX: 2,
    LETTERS: ["P", "R"],
    INDICES: [0, 7],
    HINTS: [6, 3, 4],
  },
  {
    WORD: "SUPPORTED",
    ALPHA: "DOPSTU?",
    WILDCARD: "E",
    WILDCARD_INDEX: 7,
    LETTERS: ["P", "R"],
    INDICES: [2, 5],
    HINTS: [8, 3, 6],
  },
  {
    WORD: "OFFENSIVE",
    ALPHA: "EEFFNV?",
    WILDCARD: "S",
    WILDCARD_INDEX: 5,
    LETTERS: ["I", "O"],
    INDICES: [6, 0],
    HINTS: [4, 2, 8],
  },
  {
    WORD: "MARKETING",
    ALPHA: "EGMNRT?",
    WILDCARD: "A",
    WILDCARD_INDEX: 1,
    LETTERS: ["K", "I"],
    INDICES: [3, 6],
    HINTS: [4, 8, 0],
  },
  {
    WORD: "REFERRING",
    ALPHA: "EFGNRR?",
    WILDCARD: "E",
    WILDCARD_INDEX: 1,
    LETTERS: ["I", "R"],
    INDICES: [6, 4],
    HINTS: [3, 0, 7],
  },
  {
    WORD: "NECESSARY",
    ALPHA: "ACERSY?",
    WILDCARD: "N",
    WILDCARD_INDEX: 0,
    LETTERS: ["E", "S"],
    INDICES: [3, 4],
    HINTS: [1, 2, 5],
  },
  {
    WORD: "CHILDHOOD",
    ALPHA: "DHHIOO?",
    WILDCARD: "L",
    WILDCARD_INDEX: 3,
    LETTERS: ["D", "C"],
    INDICES: [4, 0],
    HINTS: [6, 7, 8],
  },
  {
    WORD: "VIBRATORS",
    ALPHA: "BORRST?",
    WILDCARD: "I",
    WILDCARD_INDEX: 1,
    LETTERS: ["V", "A"],
    INDICES: [0, 4],
    HINTS: [6, 8, 7],
  },
  {
    WORD: "BUTTERFLY",
    ALPHA: "BRTTUY?",
    WILDCARD: "L",
    WILDCARD_INDEX: 7,
    LETTERS: ["E", "F"],
    INDICES: [4, 6],
    HINTS: [0, 8, 2],
  },
  {
    WORD: "FORBIDDEN",
    ALPHA: "BDDNOR?",
    WILDCARD: "E",
    WILDCARD_INDEX: 7,
    LETTERS: ["I", "F"],
    INDICES: [4, 0],
    HINTS: [8, 3, 6],
  },
  {
    WORD: "BEGINNERS",
    ALPHA: "BEENNS?",
    WILDCARD: "I",
    WILDCARD_INDEX: 3,
    LETTERS: ["R", "G"],
    INDICES: [7, 2],
    HINTS: [5, 6, 4],
  },
  {
    WORD: "COMBINING",
    ALPHA: "CGIINN?",
    WILDCARD: "M",
    WILDCARD_INDEX: 2,
    LETTERS: ["B", "O"],
    INDICES: [3, 1],
    HINTS: [8, 6, 4],
  },
  {
    WORD: "HEPATITIS",
    ALPHA: "EHIIPT?",
    WILDCARD: "S",
    WILDCARD_INDEX: 8,
    LETTERS: ["A", "T"],
    INDICES: [3, 6],
    HINTS: [5, 0, 2],
  },
  {
    WORD: "OPERATION",
    ALPHA: "AENOOT?",
    WILDCARD: "P",
    WILDCARD_INDEX: 1,
    LETTERS: ["R", "I"],
    INDICES: [3, 6],
    HINTS: [8, 0, 2],
  },
  {
    WORD: "WORKPLACE",
    ALPHA: "CEKPRW?",
    WILDCARD: "O",
    WILDCARD_INDEX: 1,
    LETTERS: ["A", "L"],
    INDICES: [6, 5],
    HINTS: [3, 8, 7],
  },
  {
    WORD: "CONSCIOUS",
    ALPHA: "CCNOSS?",
    WILDCARD: "O",
    WILDCARD_INDEX: 1,
    LETTERS: ["I", "U"],
    INDICES: [5, 7],
    HINTS: [4, 8, 0],
  },
  {
    WORD: "SPECIFICS",
    ALPHA: "CEFIIS?",
    WILDCARD: "P",
    WILDCARD_INDEX: 1,
    LETTERS: ["S", "C"],
    INDICES: [0, 3],
    HINTS: [5, 8, 2],
  },
  {
    WORD: "VARIABLES",
    ALPHA: "AABRSV?",
    WILDCARD: "E",
    WILDCARD_INDEX: 7,
    LETTERS: ["I", "L"],
    INDICES: [3, 6],
    HINTS: [2, 1, 5],
  },
  {
    WORD: "ABSTRACTS",
    ALPHA: "AABSTT?",
    WILDCARD: "R",
    WILDCARD_INDEX: 4,
    LETTERS: ["C", "S"],
    INDICES: [6, 2],
    HINTS: [7, 8, 5],
  },
  {
    WORD: "BANDWIDTH",
    ALPHA: "BDHINT?",
    WILDCARD: "D",
    WILDCARD_INDEX: 3,
    LETTERS: ["W", "A"],
    INDICES: [4, 1],
    HINTS: [5, 0, 8],
  },
  {
    WORD: "HOPEFULLY",
    ALPHA: "FHLOUY?",
    WILDCARD: "E",
    WILDCARD_INDEX: 3,
    LETTERS: ["L", "P"],
    INDICES: [6, 2],
    HINTS: [0, 1, 7],
  },
  {
    WORD: "RECORDING",
    ALPHA: "CDGORR?",
    WILDCARD: "I",
    WILDCARD_INDEX: 6,
    LETTERS: ["E", "N"],
    INDICES: [1, 7],
    HINTS: [8, 3, 2],
  },
  {
    WORD: "ACTIVISTS",
    ALPHA: "ACSSTV?",
    WILDCARD: "I",
    WILDCARD_INDEX: 3,
    LETTERS: ["I", "T"],
    INDICES: [5, 7],
    HINTS: [0, 6, 4],
  },
  {
    WORD: "ELIMINATE",
    ALPHA: "EEILMT?",
    WILDCARD: "N",
    WILDCARD_INDEX: 5,
    LETTERS: ["A", "I"],
    INDICES: [6, 2],
    HINTS: [1, 7, 4],
  },
  {
    WORD: "HARDCOVER",
    ALPHA: "CDHORV?",
    WILDCARD: "R",
    WILDCARD_INDEX: 2,
    LETTERS: ["A", "E"],
    INDICES: [1, 7],
    HINTS: [6, 8, 0],
  },
  {
    WORD: "SPECIFIED",
    ALPHA: "CDEFII?",
    WILDCARD: "S",
    WILDCARD_INDEX: 0,
    LETTERS: ["P", "E"],
    INDICES: [1, 2],
    HINTS: [8, 5, 3],
  },
  {
    WORD: "INVOLVING",
    ALPHA: "ILNOVV?",
    WILDCARD: "G",
    WILDCARD_INDEX: 8,
    LETTERS: ["I", "N"],
    INDICES: [0, 1],
    HINTS: [4, 7, 5],
  },
  {
    WORD: "BOULEVARD",
    ALPHA: "BDLRUV?",
    WILDCARD: "E",
    WILDCARD_INDEX: 4,
    LETTERS: ["O", "A"],
    INDICES: [1, 6],
    HINTS: [8, 5, 7],
  },
  {
    WORD: "RELIGIOUS",
    ALPHA: "GILOSU?",
    WILDCARD: "R",
    WILDCARD_INDEX: 0,
    LETTERS: ["E", "I"],
    INDICES: [1, 3],
    HINTS: [7, 4, 6],
  },
  {
    WORD: "SPECIFIES",
    ALPHA: "FIIPSS?",
    WILDCARD: "C",
    WILDCARD_INDEX: 3,
    LETTERS: ["E", "E"],
    INDICES: [7, 2],
    HINTS: [5, 1, 6],
  },
  {
    WORD: "IMPROVING",
    ALPHA: "GIIMRV?",
    WILDCARD: "P",
    WILDCARD_INDEX: 2,
    LETTERS: ["N", "O"],
    INDICES: [7, 4],
    HINTS: [8, 0, 5],
  },
  {
    WORD: "MEDICINES",
    ALPHA: "CDEIMS?",
    WILDCARD: "I",
    WILDCARD_INDEX: 3,
    LETTERS: ["N", "E"],
    INDICES: [6, 1],
    HINTS: [8, 4, 2],
  },
  {
    WORD: "CONFLICTS",
    ALPHA: "FILNST?",
    WILDCARD: "O",
    WILDCARD_INDEX: 1,
    LETTERS: ["C", "C"],
    INDICES: [6, 0],
    HINTS: [4, 2, 5],
  },
  {
    WORD: "PRISONERS",
    ALPHA: "ENOPRS?",
    WILDCARD: "I",
    WILDCARD_INDEX: 2,
    LETTERS: ["S", "R"],
    INDICES: [3, 1],
    HINTS: [7, 0, 4],
  },
  {
    WORD: "ASSEMBLED",
    ALPHA: "BDEEMS?",
    WILDCARD: "L",
    WILDCARD_INDEX: 6,
    LETTERS: ["A", "S"],
    INDICES: [0, 2],
    HINTS: [3, 8, 4],
  },
  {
    WORD: "ASTRONOMY",
    ALPHA: "NOOSTY?",
    WILDCARD: "A",
    WILDCARD_INDEX: 0,
    LETTERS: ["M", "R"],
    INDICES: [7, 3],
    HINTS: [1, 6, 4],
  },
  {
    WORD: "CONVICTED",
    ALPHA: "CDIOTV?",
    WILDCARD: "E",
    WILDCARD_INDEX: 7,
    LETTERS: ["N", "C"],
    INDICES: [2, 0],
    HINTS: [8, 4, 6],
  },
  {
    WORD: "INVENTORY",
    ALPHA: "NORTVY?",
    WILDCARD: "I",
    WILDCARD_INDEX: 0,
    LETTERS: ["N", "E"],
    INDICES: [1, 3],
    HINTS: [6, 5, 2],
  },
  {
    WORD: "DELICIOUS",
    ALPHA: "EIILOU?",
    WILDCARD: "S",
    WILDCARD_INDEX: 8,
    LETTERS: ["D", "C"],
    INDICES: [0, 4],
    HINTS: [3, 6, 1],
  },
  {
    WORD: "PREFERRED",
    ALPHA: "DEEPRR?",
    WILDCARD: "F",
    WILDCARD_INDEX: 3,
    LETTERS: ["E", "R"],
    INDICES: [7, 6],
    HINTS: [2, 4, 5],
  },
  {
    WORD: "DIRECTIVE",
    ALPHA: "EEIRTV?",
    WILDCARD: "D",
    WILDCARD_INDEX: 0,
    LETTERS: ["C", "I"],
    INDICES: [4, 1],
    HINTS: [7, 6, 5],
  },
  {
    WORD: "ORDINANCE",
    ALPHA: "ADEINR?",
    WILDCARD: "C",
    WILDCARD_INDEX: 7,
    LETTERS: ["O", "N"],
    INDICES: [0, 4],
    HINTS: [8, 3, 6],
  },
  {
    WORD: "WITNESSES",
    ALPHA: "EESSTW?",
    WILDCARD: "N",
    WILDCARD_INDEX: 3,
    LETTERS: ["S", "I"],
    INDICES: [6, 1],
    HINTS: [7, 4, 8],
  },
  {
    WORD: "BOOKSTORE",
    ALPHA: "EKOORT?",
    WILDCARD: "S",
    WILDCARD_INDEX: 4,
    LETTERS: ["O", "B"],
    INDICES: [6, 0],
    HINTS: [7, 8, 1],
  },
  {
    WORD: "DETECTION",
    ALPHA: "CDINTT?",
    WILDCARD: "E",
    WILDCARD_INDEX: 1,
    LETTERS: ["O", "E"],
    INDICES: [7, 3],
    HINTS: [6, 0, 2],
  },
  {
    WORD: "NORTHWEST",
    ALPHA: "HNRSTT?",
    WILDCARD: "E",
    WILDCARD_INDEX: 6,
    LETTERS: ["W", "O"],
    INDICES: [5, 1],
    HINTS: [2, 7, 4],
  },
  {
    WORD: "ATTRIBUTE",
    ALPHA: "BITTTU?",
    WILDCARD: "E",
    WILDCARD_INDEX: 8,
    LETTERS: ["A", "R"],
    INDICES: [0, 3],
    HINTS: [5, 4, 2],
  },
  {
    WORD: "MACINTOSH",
    ALPHA: "ACHNOS?",
    WILDCARD: "I",
    WILDCARD_INDEX: 3,
    LETTERS: ["M", "T"],
    INDICES: [0, 5],
    HINTS: [2, 1, 7],
  },
  {
    WORD: "PROCESSOR",
    ALPHA: "COPRSS?",
    WILDCARD: "E",
    WILDCARD_INDEX: 4,
    LETTERS: ["O", "R"],
    INDICES: [2, 1],
    HINTS: [0, 6, 7],
  },
  {
    WORD: "MACHINERY",
    ALPHA: "CEHIMN?",
    WILDCARD: "Y",
    WILDCARD_INDEX: 8,
    LETTERS: ["A", "R"],
    INDICES: [1, 7],
    HINTS: [3, 4, 6],
  },
  {
    WORD: "SURPRISED",
    ALPHA: "DIRRSU?",
    WILDCARD: "E",
    WILDCARD_INDEX: 7,
    LETTERS: ["S", "P"],
    INDICES: [0, 3],
    HINTS: [4, 5, 6],
  },
  {
    WORD: "INQUIRIES",
    ALPHA: "EIIIQU?",
    WILDCARD: "S",
    WILDCARD_INDEX: 8,
    LETTERS: ["N", "R"],
    INDICES: [1, 5],
    HINTS: [6, 2, 0],
  },
  {
    WORD: "AWARENESS",
    ALPHA: "AARSSW?",
    WILDCARD: "E",
    WILDCARD_INDEX: 4,
    LETTERS: ["N", "E"],
    INDICES: [5, 6],
    HINTS: [7, 3, 1],
  },
  {
    WORD: "FURNITURE",
    ALPHA: "EFINTU?",
    WILDCARD: "R",
    WILDCARD_INDEX: 2,
    LETTERS: ["U", "R"],
    INDICES: [1, 7],
    HINTS: [4, 8, 3],
  },
  {
    WORD: "GUARANTEE",
    ALPHA: "AAEGTU?",
    WILDCARD: "N",
    WILDCARD_INDEX: 5,
    LETTERS: ["R", "E"],
    INDICES: [3, 7],
    HINTS: [4, 6, 1],
  },
  {
    WORD: "STATUTORY",
    ALPHA: "ARSTUY?",
    WILDCARD: "O",
    WILDCARD_INDEX: 6,
    LETTERS: ["T", "T"],
    INDICES: [1, 3],
    HINTS: [5, 4, 0],
  },
  {
    WORD: "CARTRIDGE",
    ALPHA: "CEGRRT?",
    WILDCARD: "A",
    WILDCARD_INDEX: 1,
    LETTERS: ["I", "D"],
    INDICES: [5, 6],
    HINTS: [0, 3, 7],
  },
  {
    WORD: "COGNITIVE",
    ALPHA: "EGIINV?",
    WILDCARD: "O",
    WILDCARD_INDEX: 1,
    LETTERS: ["C", "T"],
    INDICES: [0, 5],
    HINTS: [3, 7, 2],
  },
  {
    WORD: "SUPPLIERS",
    ALPHA: "ILPRSU?",
    WILDCARD: "E",
    WILDCARD_INDEX: 6,
    LETTERS: ["P", "S"],
    INDICES: [2, 0],
    HINTS: [8, 7, 3],
  },
  {
    WORD: "NAVIGATOR",
    ALPHA: "ANORTV?",
    WILDCARD: "I",
    WILDCARD_INDEX: 3,
    LETTERS: ["A", "G"],
    INDICES: [5, 4],
    HINTS: [7, 0, 8],
  },
  {
    WORD: "SOMEWHERE",
    ALPHA: "EEEMRW?",
    WILDCARD: "O",
    WILDCARD_INDEX: 1,
    LETTERS: ["H", "S"],
    INDICES: [5, 0],
    HINTS: [3, 2, 6],
  },
  {
    WORD: "STABILITY",
    ALPHA: "AISTTY?",
    WILDCARD: "L",
    WILDCARD_INDEX: 5,
    LETTERS: ["B", "I"],
    INDICES: [3, 4],
    HINTS: [6, 7, 8],
  },
  {
    WORD: "VARIATION",
    ALPHA: "AIIRTV?",
    WILDCARD: "N",
    WILDCARD_INDEX: 8,
    LETTERS: ["O", "A"],
    INDICES: [7, 1],
    HINTS: [5, 2, 6],
  },
  {
    WORD: "INSURANCE",
    ALPHA: "ACINNU?",
    WILDCARD: "E",
    WILDCARD_INDEX: 8,
    LETTERS: ["R", "S"],
    INDICES: [4, 2],
    HINTS: [3, 0, 7],
  },
  {
    WORD: "ANNOUNCES",
    ALPHA: "AENNSU?",
    WILDCARD: "N",
    WILDCARD_INDEX: 1,
    LETTERS: ["C", "O"],
    INDICES: [6, 3],
    HINTS: [0, 8, 4],
  },
  {
    WORD: "DOCUMENTS",
    ALPHA: "DMNOTU?",
    WILDCARD: "S",
    WILDCARD_INDEX: 8,
    LETTERS: ["E", "C"],
    INDICES: [5, 2],
    HINTS: [6, 0, 7],
  },
  {
    WORD: "ATTITUDES",
    ALPHA: "ADEITU?",
    WILDCARD: "S",
    WILDCARD_INDEX: 8,
    LETTERS: ["T", "T"],
    INDICES: [4, 1],
    HINTS: [2, 6, 0],
  },
  {
    WORD: "COMPOSITE",
    ALPHA: "EIOPST?",
    WILDCARD: "C",
    WILDCARD_INDEX: 0,
    LETTERS: ["O", "M"],
    INDICES: [4, 2],
    HINTS: [7, 6, 3],
  },
  {
    WORD: "NEIGHBORS",
    ALPHA: "BGHNRS?",
    WILDCARD: "I",
    WILDCARD_INDEX: 2,
    LETTERS: ["O", "E"],
    INDICES: [6, 1],
    HINTS: [8, 3, 5],
  },
  {
    WORD: "DEFENSIVE",
    ALPHA: "EEFINV?",
    WILDCARD: "S",
    WILDCARD_INDEX: 5,
    LETTERS: ["E", "D"],
    INDICES: [3, 0],
    HINTS: [2, 7, 8],
  },
  {
    WORD: "RECOGNIZE",
    ALPHA: "CEEIRZ?",
    WILDCARD: "N",
    WILDCARD_INDEX: 5,
    LETTERS: ["G", "O"],
    INDICES: [4, 3],
    HINTS: [7, 1, 8],
  },
  {
    WORD: "FREQUENCY",
    ALPHA: "EFNRUY?",
    WILDCARD: "C",
    WILDCARD_INDEX: 7,
    LETTERS: ["E", "Q"],
    INDICES: [5, 3],
    HINTS: [1, 2, 0],
  },
  {
    WORD: "PROMISING",
    ALPHA: "GIMOPS?",
    WILDCARD: "N",
    WILDCARD_INDEX: 7,
    LETTERS: ["I", "R"],
    INDICES: [4, 1],
    HINTS: [0, 3, 8],
  },
  {
    WORD: "REGULARLY",
    ALPHA: "AEGRUY?",
    WILDCARD: "L",
    WILDCARD_INDEX: 4,
    LETTERS: ["R", "L"],
    INDICES: [0, 7],
    HINTS: [5, 1, 2],
  },
  {
    WORD: "INSTANTLY",
    ALPHA: "ALNNTY?",
    WILDCARD: "I",
    WILDCARD_INDEX: 0,
    LETTERS: ["T", "S"],
    INDICES: [6, 2],
    HINTS: [5, 8, 1],
  },
  {
    WORD: "EXERCISES",
    ALPHA: "EERSSX?",
    WILDCARD: "C",
    WILDCARD_INDEX: 4,
    LETTERS: ["I", "E"],
    INDICES: [5, 7],
    HINTS: [1, 3, 2],
  },
  {
    WORD: "SEXUALITY",
    ALPHA: "AEILTY?",
    WILDCARD: "S",
    WILDCARD_INDEX: 0,
    LETTERS: ["X", "U"],
    INDICES: [2, 3],
    HINTS: [8, 7, 4],
  },
  {
    WORD: "SUFFERING",
    ALPHA: "EFGINS?",
    WILDCARD: "R",
    WILDCARD_INDEX: 5,
    LETTERS: ["F", "U"],
    INDICES: [3, 1],
    HINTS: [0, 4, 7],
  },
  {
    WORD: "GEOGRAPHY",
    ALPHA: "AGGOPY?",
    WILDCARD: "H",
    WILDCARD_INDEX: 7,
    LETTERS: ["R", "E"],
    INDICES: [4, 1],
    HINTS: [0, 3, 6],
  },
  {
    WORD: "GRAPHICAL",
    ALPHA: "AAGILR?",
    WILDCARD: "H",
    WILDCARD_INDEX: 4,
    LETTERS: ["P", "C"],
    INDICES: [3, 6],
    HINTS: [1, 0, 5],
  },
  {
    WORD: "MIGRATION",
    ALPHA: "AIMNOR?",
    WILDCARD: "T",
    WILDCARD_INDEX: 5,
    LETTERS: ["I", "G"],
    INDICES: [6, 2],
    HINTS: [4, 0, 7],
  },
  {
    WORD: "EXCLUSIVE",
    ALPHA: "CEILUV?",
    WILDCARD: "E",
    WILDCARD_INDEX: 0,
    LETTERS: ["S", "X"],
    INDICES: [5, 1],
    HINTS: [2, 6, 3],
  },
  {
    WORD: "ATTEMPTED",
    ALPHA: "DEEMTT?",
    WILDCARD: "P",
    WILDCARD_INDEX: 5,
    LETTERS: ["T", "A"],
    INDICES: [1, 0],
    HINTS: [6, 3, 8],
  },
  {
    WORD: "CONFIGURE",
    ALPHA: "EFINRU?",
    WILDCARD: "C",
    WILDCARD_INDEX: 0,
    LETTERS: ["O", "G"],
    INDICES: [1, 5],
    HINTS: [4, 3, 8],
  },
  {
    WORD: "LIFESTYLE",
    ALPHA: "EILLSY?",
    WILDCARD: "T",
    WILDCARD_INDEX: 5,
    LETTERS: ["E", "F"],
    INDICES: [3, 2],
    HINTS: [0, 4, 1],
  },
  {
    WORD: "BEAUTIFUL",
    ALPHA: "BEFLTU?",
    WILDCARD: "A",
    WILDCARD_INDEX: 2,
    LETTERS: ["I", "U"],
    INDICES: [5, 3],
    HINTS: [8, 7, 0],
  },
  {
    WORD: "AVAILABLE",
    ALPHA: "AAELLV?",
    WILDCARD: "B",
    WILDCARD_INDEX: 6,
    LETTERS: ["A", "I"],
    INDICES: [2, 3],
    HINTS: [5, 8, 4],
  },
  {
    WORD: "LIBRARIES",
    ALPHA: "ABEIRS?",
    WILDCARD: "L",
    WILDCARD_INDEX: 0,
    LETTERS: ["R", "I"],
    INDICES: [3, 1],
    HINTS: [6, 4, 5],
  },
  {
    WORD: "INITIALLY",
    ALPHA: "AIIILY?",
    WILDCARD: "T",
    WILDCARD_INDEX: 3,
    LETTERS: ["N", "L"],
    INDICES: [1, 6],
    HINTS: [0, 4, 5],
  },
  {
    WORD: "FISHERIES",
    ALPHA: "EFHIRS?",
    WILDCARD: "S",
    WILDCARD_INDEX: 2,
    LETTERS: ["E", "I"],
    INDICES: [7, 1],
    HINTS: [6, 3, 0],
  },
  {
    WORD: "SCULPTURE",
    ALPHA: "CLPRUU?",
    WILDCARD: "E",
    WILDCARD_INDEX: 8,
    LETTERS: ["T", "S"],
    INDICES: [5, 0],
    HINTS: [2, 1, 7],
  },
  {
    WORD: "ATHLETICS",
    ALPHA: "ACELST?",
    WILDCARD: "H",
    WILDCARD_INDEX: 2,
    LETTERS: ["I", "T"],
    INDICES: [6, 1],
    HINTS: [3, 7, 4],
  },
  {
    WORD: "OCCURRING",
    ALPHA: "CCGIRU?",
    WILDCARD: "O",
    WILDCARD_INDEX: 0,
    LETTERS: ["R", "N"],
    INDICES: [4, 7],
    HINTS: [5, 6, 1],
  },
  {
    WORD: "FRAGRANCE",
    ALPHA: "AAEGRR?",
    WILDCARD: "N",
    WILDCARD_INDEX: 6,
    LETTERS: ["F", "C"],
    INDICES: [0, 7],
    HINTS: [1, 2, 3],
  },
  {
    WORD: "VACANCIES",
    ALPHA: "ACCEIS?",
    WILDCARD: "V",
    WILDCARD_INDEX: 0,
    LETTERS: ["N", "A"],
    INDICES: [4, 3],
    HINTS: [2, 6, 8],
  },
  {
    WORD: "PRACTICAL",
    ALPHA: "ACILPR?",
    WILDCARD: "T",
    WILDCARD_INDEX: 4,
    LETTERS: ["A", "C"],
    INDICES: [7, 3],
    HINTS: [1, 2, 8],
  },
  {
    WORD: "PROSPECTS",
    ALPHA: "CPPSST?",
    WILDCARD: "O",
    WILDCARD_INDEX: 2,
    LETTERS: ["R", "E"],
    INDICES: [1, 5],
    HINTS: [0, 8, 7],
  },
  {
    WORD: "SUBSTANCE",
    ALPHA: "BCNSTU?",
    WILDCARD: "E",
    WILDCARD_INDEX: 8,
    LETTERS: ["A", "S"],
    INDICES: [5, 0],
    HINTS: [3, 2, 7],
  },
  {
    WORD: "MUSICIANS",
    ALPHA: "IIMNSU?",
    WILDCARD: "A",
    WILDCARD_INDEX: 6,
    LETTERS: ["S", "C"],
    INDICES: [2, 4],
    HINTS: [0, 1, 5],
  },
  {
    WORD: "ASTROLOGY",
    ALPHA: "GLORTY?",
    WILDCARD: "S",
    WILDCARD_INDEX: 1,
    LETTERS: ["A", "O"],
    INDICES: [0, 4],
    HINTS: [6, 5, 3],
  },
  {
    WORD: "VEGETABLE",
    ALPHA: "BEEGLT?",
    WILDCARD: "A",
    WILDCARD_INDEX: 5,
    LETTERS: ["E", "V"],
    INDICES: [3, 0],
    HINTS: [7, 4, 1],
  },
  {
    WORD: "OURSELVES",
    ALPHA: "LRSSUV?",
    WILDCARD: "O",
    WILDCARD_INDEX: 0,
    LETTERS: ["E", "E"],
    INDICES: [4, 7],
    HINTS: [2, 6, 1],
  },
  {
    WORD: "CHOCOLATE",
    ALPHA: "ACEHOO?",
    WILDCARD: "L",
    WILDCARD_INDEX: 5,
    LETTERS: ["T", "C"],
    INDICES: [7, 0],
    HINTS: [3, 1, 6],
  },
  {
    WORD: "COSMETICS",
    ALPHA: "CEIMST?",
    WILDCARD: "S",
    WILDCARD_INDEX: 2,
    LETTERS: ["O", "C"],
    INDICES: [1, 7],
    HINTS: [0, 3, 6],
  },
  {
    WORD: "UNDEFINED",
    ALPHA: "DDEFNN?",
    WILDCARD: "I",
    WILDCARD_INDEX: 5,
    LETTERS: ["E", "U"],
    INDICES: [7, 0],
    HINTS: [4, 3, 2],
  },
  {
    WORD: "CONTACTED",
    ALPHA: "ACCDET?",
    WILDCARD: "N",
    WILDCARD_INDEX: 2,
    LETTERS: ["O", "T"],
    INDICES: [1, 3],
    HINTS: [4, 8, 5],
  },
  {
    WORD: "RECEIVING",
    ALPHA: "CEEGIV?",
    WILDCARD: "N",
    WILDCARD_INDEX: 7,
    LETTERS: ["I", "R"],
    INDICES: [4, 0],
    HINTS: [5, 2, 8],
  },
  {
    WORD: "CANDIDATE",
    ALPHA: "AACEIN?",
    WILDCARD: "T",
    WILDCARD_INDEX: 7,
    LETTERS: ["D", "D"],
    INDICES: [5, 3],
    HINTS: [4, 2, 0],
  },
  {
    WORD: "PATHOLOGY",
    ALPHA: "AGHOPY?",
    WILDCARD: "L",
    WILDCARD_INDEX: 5,
    LETTERS: ["T", "O"],
    INDICES: [2, 4],
    HINTS: [0, 6, 1],
  },
  {
    WORD: "HAPPINESS",
    ALPHA: "EHNPSS?",
    WILDCARD: "I",
    WILDCARD_INDEX: 4,
    LETTERS: ["P", "A"],
    INDICES: [3, 1],
    HINTS: [0, 8, 2],
  },
  {
    WORD: "SCIENTIST",
    ALPHA: "CEINTT?",
    WILDCARD: "S",
    WILDCARD_INDEX: 0,
    LETTERS: ["I", "S"],
    INDICES: [6, 7],
    HINTS: [2, 5, 8],
  },
  {
    WORD: "TRADEMARK",
    ALPHA: "AEKRRT?",
    WILDCARD: "M",
    WILDCARD_INDEX: 5,
    LETTERS: ["D", "A"],
    INDICES: [3, 2],
    HINTS: [8, 4, 6],
  },
  {
    WORD: "INTERFACE",
    ALPHA: "ACEEFN?",
    WILDCARD: "I",
    WILDCARD_INDEX: 0,
    LETTERS: ["T", "R"],
    INDICES: [2, 4],
    HINTS: [7, 8, 6],
  },
  {
    WORD: "CONFIRMED",
    ALPHA: "DFIMOR?",
    WILDCARD: "E",
    WILDCARD_INDEX: 7,
    LETTERS: ["N", "C"],
    INDICES: [2, 0],
    HINTS: [6, 8, 3],
  },
  {
    WORD: "TEXTBOOKS",
    ALPHA: "EOOSTT?",
    WILDCARD: "K",
    WILDCARD_INDEX: 7,
    LETTERS: ["X", "B"],
    INDICES: [2, 4],
    HINTS: [8, 5, 0],
  },
  {
    WORD: "AGGREGATE",
    ALPHA: "AAEGGG?",
    WILDCARD: "E",
    WILDCARD_INDEX: 4,
    LETTERS: ["T", "R"],
    INDICES: [7, 3],
    HINTS: [6, 0, 2],
  },
  {
    WORD: "PRECISELY",
    ALPHA: "ILPRSY?",
    WILDCARD: "E",
    WILDCARD_INDEX: 2,
    LETTERS: ["E", "C"],
    INDICES: [6, 3],
    HINTS: [4, 8, 7],
  },
  {
    WORD: "INFECTION",
    ALPHA: "CFIINN?",
    WILDCARD: "T",
    WILDCARD_INDEX: 5,
    LETTERS: ["E", "O"],
    INDICES: [3, 7],
    HINTS: [1, 0, 2],
  },
  {
    WORD: "WHOLESALE",
    ALPHA: "ELLOSW?",
    WILDCARD: "H",
    WILDCARD_INDEX: 1,
    LETTERS: ["A", "E"],
    INDICES: [6, 4],
    HINTS: [3, 0, 7],
  },
  {
    WORD: "HANDHELDS",
    ALPHA: "AEHLNS?",
    WILDCARD: "D",
    WILDCARD_INDEX: 3,
    LETTERS: ["H", "D"],
    INDICES: [4, 7],
    HINTS: [8, 0, 2],
  },
  {
    WORD: "OPERATORS",
    ALPHA: "OOPRRS?",
    WILDCARD: "T",
    WILDCARD_INDEX: 5,
    LETTERS: ["A", "E"],
    INDICES: [4, 2],
    HINTS: [8, 0, 3],
  },
  {
    WORD: "TRANSFERS",
    ALPHA: "AFNRRS?",
    WILDCARD: "S",
    WILDCARD_INDEX: 4,
    LETTERS: ["E", "T"],
    INDICES: [6, 0],
    HINTS: [5, 2, 3],
  },
  {
    WORD: "ARCHITECT",
    ALPHA: "ACCHRT?",
    WILDCARD: "I",
    WILDCARD_INDEX: 4,
    LETTERS: ["T", "E"],
    INDICES: [5, 6],
    HINTS: [2, 3, 8],
  },
  {
    WORD: "PARAMETER",
    ALPHA: "AAEPRT?",
    WILDCARD: "R",
    WILDCARD_INDEX: 2,
    LETTERS: ["E", "M"],
    INDICES: [7, 4],
    HINTS: [5, 8, 3],
  },
  {
    WORD: "WORKFORCE",
    ALPHA: "CEFORW?",
    WILDCARD: "R",
    WILDCARD_INDEX: 2,
    LETTERS: ["K", "O"],
    INDICES: [3, 1],
    HINTS: [4, 8, 7],
  },
  {
    WORD: "DEPENDENT",
    ALPHA: "EENNPT?",
    WILDCARD: "D",
    WILDCARD_INDEX: 0,
    LETTERS: ["D", "E"],
    INDICES: [5, 3],
    HINTS: [2, 7, 1],
  },
  {
    WORD: "TRAVELLER",
    ALPHA: "ELRRTV?",
    WILDCARD: "A",
    WILDCARD_INDEX: 2,
    LETTERS: ["L", "E"],
    INDICES: [5, 7],
    HINTS: [4, 1, 3],
  },
  {
    WORD: "INCLUSIVE",
    ALPHA: "CEIILU?",
    WILDCARD: "S",
    WILDCARD_INDEX: 5,
    LETTERS: ["V", "N"],
    INDICES: [7, 1],
    HINTS: [4, 8, 2],
  },
  {
    WORD: "PLAINTIFF",
    ALPHA: "AFILNP?",
    WILDCARD: "I",
    WILDCARD_INDEX: 3,
    LETTERS: ["F", "T"],
    INDICES: [7, 5],
    HINTS: [8, 2, 1],
  },
  {
    WORD: "ARTHRITIS",
    ALPHA: "IRRSTT?",
    WILDCARD: "I",
    WILDCARD_INDEX: 5,
    LETTERS: ["H", "A"],
    INDICES: [3, 0],
    HINTS: [2, 6, 8],
  },
  {
    WORD: "TYPICALLY",
    ALPHA: "ACLPTY?",
    WILDCARD: "I",
    WILDCARD_INDEX: 3,
    LETTERS: ["L", "Y"],
    INDICES: [6, 1],
    HINTS: [7, 8, 2],
  },
  {
    WORD: "TESTIMONY",
    ALPHA: "MNSTTY?",
    WILDCARD: "I",
    WILDCARD_INDEX: 4,
    LETTERS: ["E", "O"],
    INDICES: [1, 6],
    HINTS: [2, 8, 7],
  },
  {
    WORD: "AUTHORITY",
    ALPHA: "AHORUY?",
    WILDCARD: "I",
    WILDCARD_INDEX: 6,
    LETTERS: ["T", "T"],
    INDICES: [2, 7],
    HINTS: [5, 1, 4],
  },
  {
    WORD: "CONFUSION",
    ALPHA: "CFINNO?",
    WILDCARD: "S",
    WILDCARD_INDEX: 5,
    LETTERS: ["U", "O"],
    INDICES: [4, 7],
    HINTS: [3, 6, 0],
  },
  {
    WORD: "NIGHTMARE",
    ALPHA: "EHMNRT?",
    WILDCARD: "I",
    WILDCARD_INDEX: 1,
    LETTERS: ["G", "A"],
    INDICES: [2, 6],
    HINTS: [4, 5, 0],
  },
  {
    WORD: "BENCHMARK",
    ALPHA: "ABEHKM?",
    WILDCARD: "N",
    WILDCARD_INDEX: 2,
    LETTERS: ["C", "R"],
    INDICES: [3, 7],
    HINTS: [8, 0, 6],
  },
  {
    WORD: "ANNOUNCED",
    ALPHA: "CDNNOU?",
    WILDCARD: "A",
    WILDCARD_INDEX: 0,
    LETTERS: ["N", "E"],
    INDICES: [1, 7],
    HINTS: [3, 5, 8],
  },
  {
    WORD: "BROADCAST",
    ALPHA: "ABCRST?",
    WILDCARD: "O",
    WILDCARD_INDEX: 2,
    LETTERS: ["A", "D"],
    INDICES: [3, 4],
    HINTS: [7, 0, 5],
  },
  {
    WORD: "NUTRITION",
    ALPHA: "INOTTU?",
    WILDCARD: "I",
    WILDCARD_INDEX: 4,
    LETTERS: ["R", "N"],
    INDICES: [3, 0],
    HINTS: [8, 6, 2],
  },
  {
    WORD: "HEADLINES",
    ALPHA: "ADEHIS?",
    WILDCARD: "N",
    WILDCARD_INDEX: 6,
    LETTERS: ["L", "E"],
    INDICES: [4, 1],
    HINTS: [8, 3, 0],
  },
  {
    WORD: "BREAKFAST",
    ALPHA: "BEFRST?",
    WILDCARD: "A",
    WILDCARD_INDEX: 3,
    LETTERS: ["K", "A"],
    INDICES: [4, 6],
    HINTS: [7, 5, 0],
  },
  {
    WORD: "DIVISIONS",
    ALPHA: "IINSSV?",
    WILDCARD: "I",
    WILDCARD_INDEX: 1,
    LETTERS: ["D", "O"],
    INDICES: [0, 6],
    HINTS: [3, 2, 4],
  },
  {
    WORD: "INFLUENCE",
    ALPHA: "CEINNU?",
    WILDCARD: "L",
    WILDCARD_INDEX: 3,
    LETTERS: ["F", "E"],
    INDICES: [2, 5],
    HINTS: [7, 0, 8],
  },
  {
    WORD: "ADDRESSED",
    ALPHA: "ADDDES?",
    WILDCARD: "R",
    WILDCARD_INDEX: 3,
    LETTERS: ["E", "S"],
    INDICES: [4, 5],
    HINTS: [8, 7, 6],
  },
  {
    WORD: "FESTIVALS",
    ALPHA: "AEFIST?",
    WILDCARD: "L",
    WILDCARD_INDEX: 7,
    LETTERS: ["S", "V"],
    INDICES: [2, 5],
    HINTS: [6, 3, 4],
  },
  {
    WORD: "ELECTORAL",
    ALPHA: "EELLOR?",
    WILDCARD: "T",
    WILDCARD_INDEX: 4,
    LETTERS: ["C", "A"],
    INDICES: [3, 7],
    HINTS: [8, 1, 0],
  },
  {
    WORD: "VOLUNTARY",
    ALPHA: "ALNRVY?",
    WILDCARD: "U",
    WILDCARD_INDEX: 3,
    LETTERS: ["O", "T"],
    INDICES: [1, 5],
    HINTS: [6, 8, 7],
  },
  {
    WORD: "DUPLICATE",
    ALPHA: "AEILPT?",
    WILDCARD: "U",
    WILDCARD_INDEX: 1,
    LETTERS: ["C", "D"],
    INDICES: [5, 0],
    HINTS: [3, 8, 4],
  },
  {
    WORD: "ABANDONED",
    ALPHA: "ADDENO?",
    WILDCARD: "N",
    WILDCARD_INDEX: 3,
    LETTERS: ["A", "B"],
    INDICES: [2, 1],
    HINTS: [5, 6, 7],
  },
  {
    WORD: "EFFICIENT",
    ALPHA: "EFFIIT?",
    WILDCARD: "E",
    WILDCARD_INDEX: 0,
    LETTERS: ["C", "N"],
    INDICES: [4, 7],
    HINTS: [3, 8, 2],
  },
  {
    WORD: "THICKNESS",
    ALPHA: "CHNSST?",
    WILDCARD: "I",
    WILDCARD_INDEX: 2,
    LETTERS: ["K", "E"],
    INDICES: [4, 6],
    HINTS: [8, 0, 3],
  },
  {
    WORD: "FORGOTTEN",
    ALPHA: "EFGNOT?",
    WILDCARD: "R",
    WILDCARD_INDEX: 2,
    LETTERS: ["T", "O"],
    INDICES: [5, 1],
    HINTS: [7, 8, 3],
  },
  {
    WORD: "MANHATTAN",
    ALPHA: "AAHMNN?",
    WILDCARD: "T",
    WILDCARD_INDEX: 5,
    LETTERS: ["T", "A"],
    INDICES: [6, 1],
    HINTS: [3, 8, 7],
  },
  {
    WORD: "ANONYMOUS",
    ALPHA: "MOOSUY?",
    WILDCARD: "A",
    WILDCARD_INDEX: 0,
    LETTERS: ["N", "N"],
    INDICES: [3, 1],
    HINTS: [7, 2, 5],
  },
  {
    WORD: "SHAREWARE",
    ALPHA: "AAEERS?",
    WILDCARD: "H",
    WILDCARD_INDEX: 1,
    LETTERS: ["W", "R"],
    INDICES: [5, 3],
    HINTS: [7, 8, 0],
  },
  {
    WORD: "NATURALLY",
    ALPHA: "ANRTUY?",
    WILDCARD: "L",
    WILDCARD_INDEX: 6,
    LETTERS: ["L", "A"],
    INDICES: [7, 5],
    HINTS: [8, 3, 0],
  },
  {
    WORD: "RECOVERED",
    ALPHA: "CDEERV?",
    WILDCARD: "O",
    WILDCARD_INDEX: 3,
    LETTERS: ["R", "E"],
    INDICES: [0, 5],
    HINTS: [7, 4, 8],
  },
  {
    WORD: "ALEXANDER",
    ALPHA: "ADELNR?",
    WILDCARD: "X",
    WILDCARD_INDEX: 3,
    LETTERS: ["E", "A"],
    INDICES: [2, 0],
    HINTS: [1, 8, 5],
  },
  {
    WORD: "EXCLUDING",
    ALPHA: "DEGLNX?",
    WILDCARD: "I",
    WILDCARD_INDEX: 6,
    LETTERS: ["C", "U"],
    INDICES: [2, 4],
    HINTS: [5, 3, 7],
  },
  {
    WORD: "ORGANIZED",
    ALPHA: "ADEGIN?",
    WILDCARD: "O",
    WILDCARD_INDEX: 0,
    LETTERS: ["R", "Z"],
    INDICES: [1, 6],
    HINTS: [8, 5, 7],
  },
  {
    WORD: "RESPONSES",
    ALPHA: "EEOPSS?",
    WILDCARD: "R",
    WILDCARD_INDEX: 0,
    LETTERS: ["S", "N"],
    INDICES: [2, 5],
    HINTS: [4, 1, 6],
  },
  {
    WORD: "STOCKINGS",
    ALPHA: "IKNSST?",
    WILDCARD: "O",
    WILDCARD_INDEX: 2,
    LETTERS: ["G", "C"],
    INDICES: [7, 3],
    HINTS: [4, 1, 8],
  },
  {
    WORD: "COLLECTED",
    ALPHA: "CDELOT?",
    WILDCARD: "E",
    WILDCARD_INDEX: 4,
    LETTERS: ["C", "L"],
    INDICES: [0, 2],
    HINTS: [7, 1, 8],
  },
  {
    WORD: "INCIDENCE",
    ALPHA: "DEIINN?",
    WILDCARD: "E",
    WILDCARD_INDEX: 5,
    LETTERS: ["C", "C"],
    INDICES: [7, 2],
    HINTS: [6, 3, 0],
  },
  {
    WORD: "INTERVIEW",
    ALPHA: "EINTVW?",
    WILDCARD: "E",
    WILDCARD_INDEX: 3,
    LETTERS: ["I", "R"],
    INDICES: [0, 4],
    HINTS: [2, 7, 1],
  },
  {
    WORD: "DESTROYED",
    ALPHA: "DDEOSY?",
    WILDCARD: "R",
    WILDCARD_INDEX: 4,
    LETTERS: ["T", "E"],
    INDICES: [3, 7],
    HINTS: [0, 8, 6],
  },
  {
    WORD: "EXTENSIVE",
    ALPHA: "EEITVX?",
    WILDCARD: "S",
    WILDCARD_INDEX: 5,
    LETTERS: ["E", "N"],
    INDICES: [0, 4],
    HINTS: [8, 7, 3],
  },
  {
    WORD: "CONNECTED",
    ALPHA: "DENNOT?",
    WILDCARD: "E",
    WILDCARD_INDEX: 4,
    LETTERS: ["C", "C"],
    INDICES: [0, 5],
    HINTS: [8, 3, 2],
  },
  {
    WORD: "DONATIONS",
    ALPHA: "DINNOS?",
    WILDCARD: "T",
    WILDCARD_INDEX: 4,
    LETTERS: ["A", "O"],
    INDICES: [3, 1],
    HINTS: [8, 0, 6],
  },
  {
    WORD: "HOLOCAUST",
    ALPHA: "ACHOTU?",
    WILDCARD: "S",
    WILDCARD_INDEX: 7,
    LETTERS: ["L", "O"],
    INDICES: [2, 3],
    HINTS: [6, 4, 0],
  },
  {
    WORD: "NIGHTLIFE",
    ALPHA: "EFGHLN?",
    WILDCARD: "T",
    WILDCARD_INDEX: 4,
    LETTERS: ["I", "I"],
    INDICES: [1, 6],
    HINTS: [5, 2, 8],
  },
  {
    WORD: "ORGANIZER",
    ALPHA: "EGIRRZ?",
    WILDCARD: "N",
    WILDCARD_INDEX: 4,
    LETTERS: ["A", "O"],
    INDICES: [3, 0],
    HINTS: [8, 7, 5],
  },
  {
    WORD: "PERMANENT",
    ALPHA: "EMNPRT?",
    WILDCARD: "A",
    WILDCARD_INDEX: 4,
    LETTERS: ["N", "E"],
    INDICES: [7, 6],
    HINTS: [8, 2, 1],
  },
  {
    WORD: "GENERATOR",
    ALPHA: "AGNORR?",
    WILDCARD: "E",
    WILDCARD_INDEX: 1,
    LETTERS: ["T", "E"],
    INDICES: [6, 3],
    HINTS: [7, 5, 0],
  },
  {
    WORD: "PHYSICIAN",
    ALPHA: "ACINSY?",
    WILDCARD: "P",
    WILDCARD_INDEX: 0,
    LETTERS: ["H", "I"],
    INDICES: [1, 6],
    HINTS: [2, 3, 5],
  },
  {
    WORD: "FIREPLACE",
    ALPHA: "AEEFPR?",
    WILDCARD: "L",
    WILDCARD_INDEX: 5,
    LETTERS: ["I", "C"],
    INDICES: [1, 7],
    HINTS: [0, 2, 8],
  },
  {
    WORD: "TERRORIST",
    ALPHA: "EIRRST?",
    WILDCARD: "O",
    WILDCARD_INDEX: 4,
    LETTERS: ["R", "T"],
    INDICES: [2, 0],
    HINTS: [8, 6, 5],
  },
  {
    WORD: "EXTENSION",
    ALPHA: "EEINSX?",
    WILDCARD: "O",
    WILDCARD_INDEX: 7,
    LETTERS: ["N", "T"],
    INDICES: [4, 2],
    HINTS: [6, 0, 3],
  },
  {
    WORD: "PORTRAITS",
    ALPHA: "IOPRTT?",
    WILDCARD: "S",
    WILDCARD_INDEX: 8,
    LETTERS: ["A", "R"],
    INDICES: [5, 2],
    HINTS: [0, 4, 6],
  },
  {
    WORD: "CLASSICAL",
    ALPHA: "AACCLS?",
    WILDCARD: "I",
    WILDCARD_INDEX: 5,
    LETTERS: ["L", "S"],
    INDICES: [1, 3],
    HINTS: [4, 8, 2],
  },
  {
    WORD: "APPRAISAL",
    ALPHA: "AALPRS?",
    WILDCARD: "I",
    WILDCARD_INDEX: 5,
    LETTERS: ["P", "A"],
    INDICES: [1, 0],
    HINTS: [3, 8, 7],
  },
  {
    WORD: "PUBLISHED",
    ALPHA: "BDEHIP?",
    WILDCARD: "U",
    WILDCARD_INDEX: 1,
    LETTERS: ["S", "L"],
    INDICES: [5, 3],
    HINTS: [8, 2, 7],
  },
  {
    WORD: "DIMENSION",
    ALPHA: "IIMNNS?",
    WILDCARD: "D",
    WILDCARD_INDEX: 0,
    LETTERS: ["O", "E"],
    INDICES: [7, 3],
    HINTS: [5, 8, 2],
  },
  {
    WORD: "DESCRIBES",
    ALPHA: "CEEIRS?",
    WILDCARD: "D",
    WILDCARD_INDEX: 0,
    LETTERS: ["B", "S"],
    INDICES: [6, 2],
    HINTS: [5, 7, 1],
  },
  {
    WORD: "FAVORITES",
    ALPHA: "AFIOSV?",
    WILDCARD: "R",
    WILDCARD_INDEX: 4,
    LETTERS: ["T", "E"],
    INDICES: [6, 7],
    HINTS: [5, 1, 0],
  },
  {
    WORD: "NONPROFIT",
    ALPHA: "FINNOR?",
    WILDCARD: "T",
    WILDCARD_INDEX: 8,
    LETTERS: ["O", "P"],
    INDICES: [5, 3],
    HINTS: [4, 2, 0],
  },
  {
    WORD: "NUMERICAL",
    ALPHA: "EILMNU?",
    WILDCARD: "A",
    WILDCARD_INDEX: 7,
    LETTERS: ["R", "C"],
    INDICES: [4, 6],
    HINTS: [2, 0, 3],
  },
  {
    WORD: "ZOOPHILIA",
    ALPHA: "AHIIOO?",
    WILDCARD: "P",
    WILDCARD_INDEX: 3,
    LETTERS: ["Z", "L"],
    INDICES: [0, 6],
    HINTS: [1, 5, 7],
  },
  {
    WORD: "PROJECTED",
    ALPHA: "DEEOPT?",
    WILDCARD: "C",
    WILDCARD_INDEX: 5,
    LETTERS: ["J", "R"],
    INDICES: [3, 1],
    HINTS: [7, 4, 6],
  },
  {
    WORD: "TERRORISM",
    ALPHA: "IMORRT?",
    WILDCARD: "E",
    WILDCARD_INDEX: 1,
    LETTERS: ["S", "R"],
    INDICES: [7, 5],
    HINTS: [8, 0, 2],
  },
  {
    WORD: "COMPARING",
    ALPHA: "ACGINP?",
    WILDCARD: "R",
    WILDCARD_INDEX: 5,
    LETTERS: ["O", "M"],
    INDICES: [1, 2],
    HINTS: [0, 6, 4],
  },
  {
    WORD: "RIVERSIDE",
    ALPHA: "DEIIRV?",
    WILDCARD: "S",
    WILDCARD_INDEX: 5,
    LETTERS: ["R", "E"],
    INDICES: [0, 3],
    HINTS: [7, 8, 1],
  },
  {
    WORD: "SACRIFICE",
    ALPHA: "ACEFIR?",
    WILDCARD: "I",
    WILDCARD_INDEX: 4,
    LETTERS: ["S", "C"],
    INDICES: [0, 7],
    HINTS: [1, 5, 3],
  },
  {
    WORD: "WAREHOUSE",
    ALPHA: "AEERSU?",
    WILDCARD: "H",
    WILDCARD_INDEX: 4,
    LETTERS: ["O", "W"],
    INDICES: [5, 0],
    HINTS: [1, 8, 6],
  },
  {
    WORD: "RETENTION",
    ALPHA: "EENNTT?",
    WILDCARD: "I",
    WILDCARD_INDEX: 6,
    LETTERS: ["O", "R"],
    INDICES: [7, 0],
    HINTS: [1, 2, 5],
  },
  {
    WORD: "DESCRIBED",
    ALPHA: "BCDDEI?",
    WILDCARD: "E",
    WILDCARD_INDEX: 1,
    LETTERS: ["R", "S"],
    INDICES: [4, 2],
    HINTS: [3, 8, 5],
  },
  {
    WORD: "APPOINTED",
    ALPHA: "ADINOP?",
    WILDCARD: "E",
    WILDCARD_INDEX: 7,
    LETTERS: ["T", "P"],
    INDICES: [6, 1],
    HINTS: [0, 8, 4],
  },
  {
    WORD: "IMPORTANT",
    ALPHA: "AIMNPT?",
    WILDCARD: "R",
    WILDCARD_INDEX: 4,
    LETTERS: ["O", "T"],
    INDICES: [3, 5],
    HINTS: [8, 0, 2],
  },
  {
    WORD: "LIGHTNING",
    ALPHA: "GHILNT?",
    WILDCARD: "G",
    WILDCARD_INDEX: 2,
    LETTERS: ["I", "N"],
    INDICES: [1, 7],
    HINTS: [8, 4, 6],
  },
  {
    WORD: "THUMBNAIL",
    ALPHA: "BHLMTU?",
    WILDCARD: "I",
    WILDCARD_INDEX: 7,
    LETTERS: ["A", "N"],
    INDICES: [6, 5],
    HINTS: [4, 8, 0],
  },
  {
    WORD: "AGREEMENT",
    ALPHA: "AEGMNT?",
    WILDCARD: "R",
    WILDCARD_INDEX: 2,
    LETTERS: ["E", "E"],
    INDICES: [6, 4],
    HINTS: [8, 7, 3],
  },
  {
    WORD: "VALUATION",
    ALPHA: "AINOUV?",
    WILDCARD: "L",
    WILDCARD_INDEX: 2,
    LETTERS: ["T", "A"],
    INDICES: [5, 1],
    HINTS: [4, 7, 3],
  },
  {
    WORD: "KEYBOARDS",
    ALPHA: "ADKORY?",
    WILDCARD: "S",
    WILDCARD_INDEX: 8,
    LETTERS: ["E", "B"],
    INDICES: [1, 3],
    HINTS: [0, 6, 5],
  },
  {
    WORD: "DEFENDANT",
    ALPHA: "ADFNNT?",
    WILDCARD: "D",
    WILDCARD_INDEX: 0,
    LETTERS: ["E", "E"],
    INDICES: [3, 1],
    HINTS: [5, 7, 6],
  },
  {
    WORD: "VIRTUALLY",
    ALPHA: "ALLTVY?",
    WILDCARD: "I",
    WILDCARD_INDEX: 1,
    LETTERS: ["U", "R"],
    INDICES: [4, 2],
    HINTS: [7, 8, 5],
  },
  {
    WORD: "EXCESSIVE",
    ALPHA: "CEEESV?",
    WILDCARD: "I",
    WILDCARD_INDEX: 6,
    LETTERS: ["X", "S"],
    INDICES: [1, 5],
    HINTS: [8, 2, 0],
  },
  {
    WORD: "INSTITUTE",
    ALPHA: "EIISTU?",
    WILDCARD: "N",
    WILDCARD_INDEX: 1,
    LETTERS: ["T", "T"],
    INDICES: [5, 7],
    HINTS: [4, 6, 2],
  },
  {
    WORD: "THRESHOLD",
    ALPHA: "DHHLOS?",
    WILDCARD: "E",
    WILDCARD_INDEX: 3,
    LETTERS: ["T", "R"],
    INDICES: [0, 2],
    HINTS: [6, 8, 5],
  },
  {
    WORD: "ELSEWHERE",
    ALPHA: "EEELRW?",
    WILDCARD: "H",
    WILDCARD_INDEX: 5,
    LETTERS: ["E", "S"],
    INDICES: [6, 2],
    HINTS: [7, 4, 8],
  },
  {
    WORD: "BLACKJACK",
    ALPHA: "ACCJKL?",
    WILDCARD: "K",
    WILDCARD_INDEX: 4,
    LETTERS: ["B", "A"],
    INDICES: [0, 2],
    HINTS: [6, 3, 8],
  },
  {
    WORD: "OTHERWISE",
    ALPHA: "EEHIOS?",
    WILDCARD: "R",
    WILDCARD_INDEX: 4,
    LETTERS: ["W", "T"],
    INDICES: [5, 1],
    HINTS: [0, 3, 7],
  },
  {
    WORD: "ADDRESSES",
    ALPHA: "DDRSSS?",
    WILDCARD: "E",
    WILDCARD_INDEX: 4,
    LETTERS: ["E", "A"],
    INDICES: [7, 0],
    HINTS: [8, 6, 3],
  },
  {
    WORD: "PASSENGER",
    ALPHA: "EENPSS?",
    WILDCARD: "R",
    WILDCARD_INDEX: 8,
    LETTERS: ["A", "G"],
    INDICES: [1, 6],
    HINTS: [0, 4, 5],
  },
  {
    WORD: "NOTEBOOKS",
    ALPHA: "EKOOOS?",
    WILDCARD: "T",
    WILDCARD_INDEX: 2,
    LETTERS: ["B", "N"],
    INDICES: [4, 0],
    HINTS: [1, 3, 7],
  },
  {
    WORD: "EXISTENCE",
    ALPHA: "CEEINX?",
    WILDCARD: "S",
    WILDCARD_INDEX: 3,
    LETTERS: ["T", "E"],
    INDICES: [4, 5],
    HINTS: [7, 6, 1],
  },
  {
    WORD: "LANDSCAPE",
    ALPHA: "AAELNP?",
    WILDCARD: "S",
    WILDCARD_INDEX: 4,
    LETTERS: ["C", "D"],
    INDICES: [5, 3],
    HINTS: [0, 8, 6],
  },
  {
    WORD: "FORECASTS",
    ALPHA: "AFOSST?",
    WILDCARD: "E",
    WILDCARD_INDEX: 3,
    LETTERS: ["C", "R"],
    INDICES: [4, 2],
    HINTS: [0, 1, 7],
  },
  {
    WORD: "WONDERING",
    ALPHA: "DINNRW?",
    WILDCARD: "G",
    WILDCARD_INDEX: 8,
    LETTERS: ["O", "E"],
    INDICES: [1, 4],
    HINTS: [0, 7, 6],
  },
  {
    WORD: "AMENDMENT",
    ALPHA: "AMMNNT?",
    WILDCARD: "D",
    WILDCARD_INDEX: 4,
    LETTERS: ["E", "E"],
    INDICES: [6, 2],
    HINTS: [3, 7, 1],
  },
  {
    WORD: "GENEALOGY",
    ALPHA: "EEGLNO?",
    WILDCARD: "Y",
    WILDCARD_INDEX: 8,
    LETTERS: ["G", "A"],
    INDICES: [7, 4],
    HINTS: [6, 2, 0],
  },
  {
    WORD: "CONTAINED",
    ALPHA: "ACDENT?",
    WILDCARD: "I",
    WILDCARD_INDEX: 5,
    LETTERS: ["N", "O"],
    INDICES: [2, 1],
    HINTS: [6, 4, 8],
  },
  {
    WORD: "DETECTIVE",
    ALPHA: "DEETTV?",
    WILDCARD: "E",
    WILDCARD_INDEX: 1,
    LETTERS: ["I", "C"],
    INDICES: [6, 4],
    HINTS: [8, 2, 3],
  },
  {
    WORD: "EXPANDING",
    ALPHA: "DGINPX?",
    WILDCARD: "E",
    WILDCARD_INDEX: 0,
    LETTERS: ["N", "A"],
    INDICES: [7, 3],
    HINTS: [2, 6, 1],
  },
  {
    WORD: "FOLLOWING",
    ALPHA: "FLNOOW?",
    WILDCARD: "G",
    WILDCARD_INDEX: 8,
    LETTERS: ["I", "L"],
    INDICES: [6, 3],
    HINTS: [1, 4, 0],
  },
  {
    WORD: "PROVISION",
    ALPHA: "IINOPS?",
    WILDCARD: "R",
    WILDCARD_INDEX: 1,
    LETTERS: ["V", "O"],
    INDICES: [3, 7],
    HINTS: [0, 2, 6],
  },
  {
    WORD: "REFLECTED",
    ALPHA: "CDEEFR?",
    WILDCARD: "T",
    WILDCARD_INDEX: 6,
    LETTERS: ["E", "L"],
    INDICES: [4, 3],
    HINTS: [0, 2, 7],
  },
  {
    WORD: "RENDERING",
    ALPHA: "EGINNR?",
    WILDCARD: "E",
    WILDCARD_INDEX: 1,
    LETTERS: ["D", "R"],
    INDICES: [3, 0],
    HINTS: [8, 4, 5],
  },
  {
    WORD: "PUBLICITY",
    ALPHA: "BCILPY?",
    WILDCARD: "T",
    WILDCARD_INDEX: 7,
    LETTERS: ["U", "I"],
    INDICES: [1, 6],
    HINTS: [4, 3, 0],
  },
  {
    WORD: "COLLEAGUE",
    ALPHA: "CEGLOU?",
    WILDCARD: "A",
    WILDCARD_INDEX: 5,
    LETTERS: ["E", "L"],
    INDICES: [4, 2],
    HINTS: [6, 1, 0],
  },
  {
    WORD: "ANNOTATED",
    ALPHA: "ADNNTT?",
    WILDCARD: "E",
    WILDCARD_INDEX: 7,
    LETTERS: ["O", "A"],
    INDICES: [3, 5],
    HINTS: [0, 2, 8],
  },
  {
    WORD: "MONITORED",
    ALPHA: "DINOOR?",
    WILDCARD: "T",
    WILDCARD_INDEX: 4,
    LETTERS: ["E", "M"],
    INDICES: [7, 0],
    HINTS: [1, 2, 6],
  },
  {
    WORD: "REFERENCE",
    ALPHA: "EEEEFR?",
    WILDCARD: "C",
    WILDCARD_INDEX: 7,
    LETTERS: ["N", "R"],
    INDICES: [6, 4],
    HINTS: [1, 0, 3],
  },
  {
    WORD: "FRONTPAGE",
    ALPHA: "EFNORT?",
    WILDCARD: "A",
    WILDCARD_INDEX: 6,
    LETTERS: ["G", "P"],
    INDICES: [7, 5],
    HINTS: [4, 3, 2],
  },
  {
    WORD: "INCENTIVE",
    ALPHA: "CEINNV?",
    WILDCARD: "E",
    WILDCARD_INDEX: 3,
    LETTERS: ["I", "T"],
    INDICES: [0, 5],
    HINTS: [7, 1, 4],
  },
  {
    WORD: "COMMANDER",
    ALPHA: "ADEMOR?",
    WILDCARD: "M",
    WILDCARD_INDEX: 2,
    LETTERS: ["N", "C"],
    INDICES: [5, 0],
    HINTS: [4, 8, 1],
  },
  {
    WORD: "PORTFOLIO",
    ALPHA: "LOOPRT?",
    WILDCARD: "F",
    WILDCARD_INDEX: 4,
    LETTERS: ["I", "O"],
    INDICES: [7, 5],
    HINTS: [3, 6, 8],
  },
  {
    WORD: "ARBITRARY",
    ALPHA: "ABRRRY?",
    WILDCARD: "A",
    WILDCARD_INDEX: 0,
    LETTERS: ["T", "I"],
    INDICES: [4, 3],
    HINTS: [2, 6, 8],
  },
  {
    WORD: "ENCOUNTER",
    ALPHA: "CENNRT?",
    WILDCARD: "O",
    WILDCARD_INDEX: 3,
    LETTERS: ["E", "U"],
    INDICES: [7, 4],
    HINTS: [5, 0, 1],
  },
  {
    WORD: "AFFILIATE",
    ALPHA: "EFIILT?",
    WILDCARD: "A",
    WILDCARD_INDEX: 0,
    LETTERS: ["F", "A"],
    INDICES: [1, 6],
    HINTS: [7, 3, 4],
  },
  {
    WORD: "DISTANCES",
    ALPHA: "CDNSST?",
    WILDCARD: "E",
    WILDCARD_INDEX: 7,
    LETTERS: ["A", "I"],
    INDICES: [4, 1],
    HINTS: [8, 6, 5],
  },
  {
    WORD: "HIGHLIGHT",
    ALPHA: "GGHHIT?",
    WILDCARD: "L",
    WILDCARD_INDEX: 4,
    LETTERS: ["H", "I"],
    INDICES: [7, 5],
    HINTS: [3, 6, 1],
  },
  {
    WORD: "COMPUTING",
    ALPHA: "GIMNOP?",
    WILDCARD: "T",
    WILDCARD_INDEX: 5,
    LETTERS: ["C", "U"],
    INDICES: [0, 4],
    HINTS: [3, 1, 2],
  },
  {
    WORD: "BROCHURES",
    ALPHA: "CHORRU?",
    WILDCARD: "S",
    WILDCARD_INDEX: 8,
    LETTERS: ["E", "B"],
    INDICES: [7, 0],
    HINTS: [5, 3, 2],
  },
  {
    WORD: "CURRENTLY",
    ALPHA: "CENRUY?",
    WILDCARD: "L",
    WILDCARD_INDEX: 7,
    LETTERS: ["T", "R"],
    INDICES: [6, 2],
    HINTS: [8, 4, 1],
  },
  {
    WORD: "DISCOUNTS",
    ALPHA: "CNOSSU?",
    WILDCARD: "I",
    WILDCARD_INDEX: 1,
    LETTERS: ["D", "T"],
    INDICES: [0, 7],
    HINTS: [5, 3, 4],
  },
  {
    WORD: "SUBSCRIBE",
    ALPHA: "BBCIRS?",
    WILDCARD: "E",
    WILDCARD_INDEX: 8,
    LETTERS: ["U", "S"],
    INDICES: [1, 0],
    HINTS: [3, 2, 6],
  },
  {
    WORD: "ADVANTAGE",
    ALPHA: "AAEGTV?",
    WILDCARD: "N",
    WILDCARD_INDEX: 4,
    LETTERS: ["A", "D"],
    INDICES: [3, 1],
    HINTS: [8, 0, 7],
  },
  {
    WORD: "THESAURUS",
    ALPHA: "AESSTU?",
    WILDCARD: "R",
    WILDCARD_INDEX: 6,
    LETTERS: ["U", "H"],
    INDICES: [7, 1],
    HINTS: [3, 4, 8],
  },
  {
    WORD: "SHORTCUTS",
    ALPHA: "CHORSU?",
    WILDCARD: "S",
    WILDCARD_INDEX: 0,
    LETTERS: ["T", "T"],
    INDICES: [4, 7],
    HINTS: [8, 2, 5],
  },
  {
    WORD: "AFFECTING",
    ALPHA: "AFGINT?",
    WILDCARD: "E",
    WILDCARD_INDEX: 3,
    LETTERS: ["F", "C"],
    INDICES: [2, 4],
    HINTS: [1, 5, 7],
  },
  {
    WORD: "CHALLENGE",
    ALPHA: "EEGHLN?",
    WILDCARD: "C",
    WILDCARD_INDEX: 0,
    LETTERS: ["A", "L"],
    INDICES: [2, 3],
    HINTS: [8, 4, 6],
  },
  {
    WORD: "SHIPMENTS",
    ALPHA: "HIMPST?",
    WILDCARD: "E",
    WILDCARD_INDEX: 5,
    LETTERS: ["N", "S"],
    INDICES: [6, 0],
    HINTS: [8, 3, 4],
  },
  {
    WORD: "APARTMENT",
    ALPHA: "AMPRTT?",
    WILDCARD: "N",
    WILDCARD_INDEX: 7,
    LETTERS: ["E", "A"],
    INDICES: [6, 2],
    HINTS: [8, 4, 5],
  },
  {
    WORD: "AFTERNOON",
    ALPHA: "FNNOOT?",
    WILDCARD: "A",
    WILDCARD_INDEX: 0,
    LETTERS: ["R", "E"],
    INDICES: [4, 3],
    HINTS: [1, 5, 2],
  },
  {
    WORD: "GOVERNING",
    ALPHA: "EGGNOV?",
    WILDCARD: "R",
    WILDCARD_INDEX: 4,
    LETTERS: ["I", "N"],
    INDICES: [6, 5],
    HINTS: [8, 3, 0],
  },
  {
    WORD: "ECONOMICS",
    ALPHA: "CCMOOS?",
    WILDCARD: "I",
    WILDCARD_INDEX: 6,
    LETTERS: ["N", "E"],
    INDICES: [3, 0],
    HINTS: [5, 7, 2],
  },
  {
    WORD: "SOCIOLOGY",
    ALPHA: "CGLOSY?",
    WILDCARD: "O",
    WILDCARD_INDEX: 1,
    LETTERS: ["O", "I"],
    INDICES: [6, 3],
    HINTS: [5, 4, 8],
  },
  {
    WORD: "QUALITIES",
    ALPHA: "AEIISU?",
    WILDCARD: "L",
    WILDCARD_INDEX: 3,
    LETTERS: ["T", "Q"],
    INDICES: [5, 0],
    HINTS: [4, 6, 7],
  },
  {
    WORD: "CUSTOMIZE",
    ALPHA: "EIOSTZ?",
    WILDCARD: "U",
    WILDCARD_INDEX: 1,
    LETTERS: ["C", "M"],
    INDICES: [0, 5],
    HINTS: [8, 6, 2],
  },
  {
    WORD: "STANDINGS",
    ALPHA: "DGNSST?",
    WILDCARD: "I",
    WILDCARD_INDEX: 5,
    LETTERS: ["A", "N"],
    INDICES: [2, 3],
    HINTS: [6, 8, 4],
  },
  {
    WORD: "TREATMENT",
    ALPHA: "AEMRTT?",
    WILDCARD: "E",
    WILDCARD_INDEX: 2,
    LETTERS: ["N", "T"],
    INDICES: [7, 0],
    HINTS: [1, 8, 6],
  },
  {
    WORD: "REGISTRAR",
    ALPHA: "AERRRT?",
    WILDCARD: "S",
    WILDCARD_INDEX: 4,
    LETTERS: ["G", "I"],
    INDICES: [2, 3],
    HINTS: [7, 1, 0],
  },
  {
    WORD: "BROADBAND",
    ALPHA: "ABDDNO?",
    WILDCARD: "B",
    WILDCARD_INDEX: 0,
    LETTERS: ["R", "A"],
    INDICES: [1, 6],
    HINTS: [7, 8, 4],
  },
  {
    WORD: "CONCLUDED",
    ALPHA: "CCDDLN?",
    WILDCARD: "U",
    WILDCARD_INDEX: 5,
    LETTERS: ["O", "E"],
    INDICES: [1, 7],
    HINTS: [0, 4, 6],
  },
  {
    WORD: "VACATIONS",
    ALPHA: "AACOSV?",
    WILDCARD: "N",
    WILDCARD_INDEX: 7,
    LETTERS: ["T", "I"],
    INDICES: [4, 5],
    HINTS: [3, 8, 0],
  },
  {
    WORD: "COMPETENT",
    ALPHA: "CEEPTT?",
    WILDCARD: "O",
    WILDCARD_INDEX: 1,
    LETTERS: ["N", "M"],
    INDICES: [7, 2],
    HINTS: [8, 6, 5],
  },
  {
    WORD: "REVIEWING",
    ALPHA: "EGIIRW?",
    WILDCARD: "E",
    WILDCARD_INDEX: 1,
    LETTERS: ["N", "V"],
    INDICES: [7, 2],
    HINTS: [6, 5, 3],
  },
  {
    WORD: "SQUIRTING",
    ALPHA: "GNRSTU?",
    WILDCARD: "I",
    WILDCARD_INDEX: 3,
    LETTERS: ["Q", "I"],
    INDICES: [1, 6],
    HINTS: [5, 8, 0],
  },
  {
    WORD: "COMMUNITY",
    ALPHA: "CMOTUY?",
    WILDCARD: "I",
    WILDCARD_INDEX: 6,
    LETTERS: ["M", "N"],
    INDICES: [2, 5],
    HINTS: [0, 1, 4],
  },
  {
    WORD: "EXPENSIVE",
    ALPHA: "EEINPV?",
    WILDCARD: "S",
    WILDCARD_INDEX: 5,
    LETTERS: ["E", "X"],
    INDICES: [0, 1],
    HINTS: [7, 6, 4],
  },
  {
    WORD: "EDITORIAL",
    ALPHA: "DIILRT?",
    WILDCARD: "E",
    WILDCARD_INDEX: 0,
    LETTERS: ["O", "A"],
    INDICES: [4, 7],
    HINTS: [1, 2, 5],
  },
  {
    WORD: "PRINCIPAL",
    ALPHA: "CILNPR?",
    WILDCARD: "I",
    WILDCARD_INDEX: 2,
    LETTERS: ["P", "A"],
    INDICES: [0, 7],
    HINTS: [5, 8, 4],
  },
  {
    WORD: "PRODUCING",
    ALPHA: "CDINOP?",
    WILDCARD: "G",
    WILDCARD_INDEX: 8,
    LETTERS: ["R", "U"],
    INDICES: [1, 4],
    HINTS: [7, 6, 3],
  },
  {
    WORD: "BREATHING",
    ALPHA: "ABGHIR?",
    WILDCARD: "E",
    WILDCARD_INDEX: 2,
    LETTERS: ["T", "N"],
    INDICES: [4, 7],
    HINTS: [1, 8, 3],
  },
  {
    WORD: "CONSUMERS",
    ALPHA: "CNRSSU?",
    WILDCARD: "E",
    WILDCARD_INDEX: 6,
    LETTERS: ["O", "M"],
    INDICES: [1, 5],
    HINTS: [4, 3, 2],
  },
  {
    WORD: "ATTENDING",
    ALPHA: "DEGNTT?",
    WILDCARD: "I",
    WILDCARD_INDEX: 6,
    LETTERS: ["N", "A"],
    INDICES: [7, 0],
    HINTS: [3, 2, 4],
  },
  {
    WORD: "EMOTIONAL",
    ALPHA: "ELMNOO?",
    WILDCARD: "T",
    WILDCARD_INDEX: 3,
    LETTERS: ["A", "I"],
    INDICES: [7, 4],
    HINTS: [2, 1, 8],
  },
  {
    WORD: "ENGINEERS",
    ALPHA: "EEGINS?",
    WILDCARD: "R",
    WILDCARD_INDEX: 7,
    LETTERS: ["E", "N"],
    INDICES: [6, 1],
    HINTS: [5, 2, 8],
  },
  {
    WORD: "KNOWLEDGE",
    ALPHA: "DEENOW?",
    WILDCARD: "K",
    WILDCARD_INDEX: 0,
    LETTERS: ["L", "G"],
    INDICES: [4, 7],
    HINTS: [5, 1, 6],
  },
  {
    WORD: "PROMOTING",
    ALPHA: "GMNOOT?",
    WILDCARD: "R",
    WILDCARD_INDEX: 1,
    LETTERS: ["P", "I"],
    INDICES: [0, 6],
    HINTS: [2, 3, 4],
  },
  {
    WORD: "LIABILITY",
    ALPHA: "BIILTY?",
    WILDCARD: "A",
    WILDCARD_INDEX: 2,
    LETTERS: ["I", "L"],
    INDICES: [6, 0],
    HINTS: [7, 3, 5],
  },
  {
    WORD: "SYNTHETIC",
    ALPHA: "HINSTT?",
    WILDCARD: "C",
    WILDCARD_INDEX: 8,
    LETTERS: ["E", "Y"],
    INDICES: [5, 1],
    HINTS: [0, 3, 4],
  },
  {
    WORD: "PREPARING",
    ALPHA: "EINPRR?",
    WILDCARD: "G",
    WILDCARD_INDEX: 8,
    LETTERS: ["A", "P"],
    INDICES: [4, 0],
    HINTS: [1, 6, 7],
  },
  {
    WORD: "DEPENDING",
    ALPHA: "DEEGIP?",
    WILDCARD: "N",
    WILDCARD_INDEX: 4,
    LETTERS: ["N", "D"],
    INDICES: [7, 5],
    HINTS: [2, 8, 3],
  },
  {
    WORD: "RETRIEVAL",
    ALPHA: "EELRTV?",
    WILDCARD: "A",
    WILDCARD_INDEX: 7,
    LETTERS: ["R", "I"],
    INDICES: [0, 4],
    HINTS: [1, 6, 3],
  },
  {
    WORD: "HAZARDOUS",
    ALPHA: "AADSUZ?",
    WILDCARD: "H",
    WILDCARD_INDEX: 0,
    LETTERS: ["O", "R"],
    INDICES: [6, 4],
    HINTS: [5, 1, 8],
  },
  {
    WORD: "MUNICIPAL",
    ALPHA: "AIILNP?",
    WILDCARD: "M",
    WILDCARD_INDEX: 0,
    LETTERS: ["U", "C"],
    INDICES: [1, 4],
    HINTS: [7, 2, 6],
  },
  {
    WORD: "PAINTBALL",
    ALPHA: "AALLPT?",
    WILDCARD: "N",
    WILDCARD_INDEX: 3,
    LETTERS: ["I", "B"],
    INDICES: [2, 5],
    HINTS: [8, 0, 6],
  },
  {
    WORD: "PLACEMENT",
    ALPHA: "EELNPT?",
    WILDCARD: "A",
    WILDCARD_INDEX: 2,
    LETTERS: ["C", "M"],
    INDICES: [3, 5],
    HINTS: [7, 0, 6],
  },
  {
    WORD: "POLLUTION",
    ALPHA: "ILNOOU?",
    WILDCARD: "T",
    WILDCARD_INDEX: 5,
    LETTERS: ["P", "L"],
    INDICES: [0, 3],
    HINTS: [4, 6, 2],
  },
  {
    WORD: "COLLECTOR",
    ALPHA: "CLLORT?",
    WILDCARD: "E",
    WILDCARD_INDEX: 4,
    LETTERS: ["C", "O"],
    INDICES: [5, 1],
    HINTS: [3, 7, 8],
  },
  {
    WORD: "CONDITION",
    ALPHA: "CINOOT?",
    WILDCARD: "N",
    WILDCARD_INDEX: 2,
    LETTERS: ["D", "I"],
    INDICES: [3, 6],
    HINTS: [1, 8, 4],
  },
  {
    WORD: "MOUNTAINS",
    ALPHA: "AMNNSU?",
    WILDCARD: "I",
    WILDCARD_INDEX: 6,
    LETTERS: ["O", "T"],
    INDICES: [1, 4],
    HINTS: [7, 5, 3],
  },
  {
    WORD: "SUBMITTED",
    ALPHA: "BDEMSU?",
    WILDCARD: "T",
    WILDCARD_INDEX: 5,
    LETTERS: ["I", "T"],
    INDICES: [4, 6],
    HINTS: [0, 1, 8],
  },
  {
    WORD: "EXCELLENT",
    ALPHA: "CEELTX?",
    WILDCARD: "N",
    WILDCARD_INDEX: 7,
    LETTERS: ["L", "E"],
    INDICES: [5, 3],
    HINTS: [4, 0, 1],
  },
  {
    WORD: "REMOVABLE",
    ALPHA: "AEMORV?",
    WILDCARD: "L",
    WILDCARD_INDEX: 7,
    LETTERS: ["E", "B"],
    INDICES: [1, 6],
    HINTS: [2, 4, 8],
  },
  {
    WORD: "HIERARCHY",
    ALPHA: "AEHIRY?",
    WILDCARD: "C",
    WILDCARD_INDEX: 6,
    LETTERS: ["H", "R"],
    INDICES: [7, 5],
    HINTS: [4, 8, 1],
  },
  {
    WORD: "NARRATIVE",
    ALPHA: "AAINRV?",
    WILDCARD: "E",
    WILDCARD_INDEX: 8,
    LETTERS: ["R", "T"],
    INDICES: [3, 5],
    HINTS: [1, 2, 0],
  },
  {
    WORD: "EMERGENCY",
    ALPHA: "CEEGRY?",
    WILDCARD: "M",
    WILDCARD_INDEX: 1,
    LETTERS: ["E", "N"],
    INDICES: [2, 6],
    HINTS: [7, 0, 8],
  },
  {
    WORD: "INVESTING",
    ALPHA: "GIINTV?",
    WILDCARD: "E",
    WILDCARD_INDEX: 3,
    LETTERS: ["S", "N"],
    INDICES: [4, 7],
    HINTS: [1, 2, 0],
  },
  {
    WORD: "CRITICISM",
    ALPHA: "CCMRST?",
    WILDCARD: "I",
    WILDCARD_INDEX: 2,
    LETTERS: ["I", "I"],
    INDICES: [4, 6],
    HINTS: [5, 7, 3],
  },
  {
    WORD: "PETROLEUM",
    ALPHA: "ELMPTU?",
    WILDCARD: "O",
    WILDCARD_INDEX: 4,
    LETTERS: ["E", "R"],
    INDICES: [6, 3],
    HINTS: [5, 1, 0],
  },
  {
    WORD: "EQUATIONS",
    ALPHA: "INOQSU?",
    WILDCARD: "E",
    WILDCARD_INDEX: 0,
    LETTERS: ["T", "A"],
    INDICES: [4, 3],
    HINTS: [1, 6, 5],
  },
  {
    WORD: "EXCEPTION",
    ALPHA: "CINOPT?",
    WILDCARD: "E",
    WILDCARD_INDEX: 0,
    LETTERS: ["X", "E"],
    INDICES: [1, 3],
    HINTS: [4, 8, 6],
  },
  {
    WORD: "STANDARDS",
    ALPHA: "ADDSST?",
    WILDCARD: "R",
    WILDCARD_INDEX: 6,
    LETTERS: ["A", "N"],
    INDICES: [2, 3],
    HINTS: [8, 0, 4],
  },
  {
    WORD: "THREESOME",
    ALPHA: "EEHOST?",
    WILDCARD: "R",
    WILDCARD_INDEX: 2,
    LETTERS: ["E", "M"],
    INDICES: [3, 7],
    HINTS: [6, 1, 0],
  },
  {
    WORD: "REGULATED",
    ALPHA: "DEEGLR?",
    WILDCARD: "A",
    WILDCARD_INDEX: 5,
    LETTERS: ["T", "U"],
    INDICES: [6, 3],
    HINTS: [1, 0, 4],
  },
  {
    WORD: "RETURNING",
    ALPHA: "EGNRTU?",
    WILDCARD: "I",
    WILDCARD_INDEX: 6,
    LETTERS: ["R", "N"],
    INDICES: [0, 5],
    HINTS: [3, 7, 8],
  },
  {
    WORD: "ASSOCIATE",
    ALPHA: "AEIOST?",
    WILDCARD: "C",
    WILDCARD_INDEX: 4,
    LETTERS: ["A", "S"],
    INDICES: [6, 1],
    HINTS: [2, 3, 8],
  },
  {
    WORD: "BOOKMARKS",
    ALPHA: "BKOORS?",
    WILDCARD: "A",
    WILDCARD_INDEX: 5,
    LETTERS: ["M", "K"],
    INDICES: [4, 7],
    HINTS: [3, 6, 1],
  },
  {
    WORD: "CHEMISTRY",
    ALPHA: "CEHIMY?",
    WILDCARD: "T",
    WILDCARD_INDEX: 6,
    LETTERS: ["R", "S"],
    INDICES: [7, 5],
    HINTS: [3, 1, 2],
  },
  {
    WORD: "CROSSWORD",
    ALPHA: "CDORSW?",
    WILDCARD: "O",
    WILDCARD_INDEX: 2,
    LETTERS: ["R", "S"],
    INDICES: [7, 3],
    HINTS: [0, 1, 5],
  },
  {
    WORD: "TEMPORARY",
    ALPHA: "MORRTY?",
    WILDCARD: "E",
    WILDCARD_INDEX: 1,
    LETTERS: ["P", "A"],
    INDICES: [3, 6],
    HINTS: [8, 4, 5],
  },
  {
    WORD: "DAUGHTERS",
    ALPHA: "DGHRST?",
    WILDCARD: "A",
    WILDCARD_INDEX: 1,
    LETTERS: ["U", "E"],
    INDICES: [2, 6],
    HINTS: [4, 8, 7],
  },
  {
    WORD: "PROVINCES",
    ALPHA: "EINOPV?",
    WILDCARD: "S",
    WILDCARD_INDEX: 8,
    LETTERS: ["R", "C"],
    INDICES: [1, 6],
    HINTS: [2, 4, 0],
  },
  {
    WORD: "INCORRECT",
    ALPHA: "CCENRT?",
    WILDCARD: "O",
    WILDCARD_INDEX: 3,
    LETTERS: ["I", "R"],
    INDICES: [0, 5],
    HINTS: [4, 7, 8],
  },
  {
    WORD: "PROTECTED",
    ALPHA: "CDOPTT?",
    WILDCARD: "R",
    WILDCARD_INDEX: 1,
    LETTERS: ["E", "E"],
    INDICES: [4, 7],
    HINTS: [3, 5, 2],
  },
  {
    WORD: "MATERNITY",
    ALPHA: "EMRTTY?",
    WILDCARD: "A",
    WILDCARD_INDEX: 1,
    LETTERS: ["I", "N"],
    INDICES: [6, 5],
    HINTS: [0, 8, 3],
  },
  {
    WORD: "PROJECTOR",
    ALPHA: "CJOPRR?",
    WILDCARD: "T",
    WILDCARD_INDEX: 6,
    LETTERS: ["O", "E"],
    INDICES: [7, 4],
    HINTS: [3, 5, 8],
  },
  {
    WORD: "UNLIMITED",
    ALPHA: "DEILMU?",
    WILDCARD: "N",
    WILDCARD_INDEX: 1,
    LETTERS: ["I", "T"],
    INDICES: [5, 6],
    HINTS: [8, 7, 4],
  },
  {
    WORD: "BIOGRAPHY",
    ALPHA: "BGHORY?",
    WILDCARD: "P",
    WILDCARD_INDEX: 6,
    LETTERS: ["I", "A"],
    INDICES: [1, 5],
    HINTS: [2, 3, 8],
  },
  {
    WORD: "EXAMINING",
    ALPHA: "AEGNNX?",
    WILDCARD: "M",
    WILDCARD_INDEX: 3,
    LETTERS: ["I", "I"],
    INDICES: [6, 4],
    HINTS: [0, 2, 5],
  },
  {
    WORD: "OMISSIONS",
    ALPHA: "IIMOSS?",
    WILDCARD: "N",
    WILDCARD_INDEX: 7,
    LETTERS: ["S", "O"],
    INDICES: [3, 6],
    HINTS: [0, 4, 1],
  },
  {
    WORD: "GATHERING",
    ALPHA: "EGGHNT?",
    WILDCARD: "R",
    WILDCARD_INDEX: 5,
    LETTERS: ["I", "A"],
    INDICES: [6, 1],
    HINTS: [7, 2, 8],
  },
  {
    WORD: "PRINTABLE",
    ALPHA: "BILNPR?",
    WILDCARD: "E",
    WILDCARD_INDEX: 8,
    LETTERS: ["T", "A"],
    INDICES: [4, 5],
    HINTS: [6, 2, 1],
  },
  {
    WORD: "COMPUTERS",
    ALPHA: "OPRSTU?",
    WILDCARD: "E",
    WILDCARD_INDEX: 6,
    LETTERS: ["C", "M"],
    INDICES: [0, 2],
    HINTS: [7, 3, 1],
  },
  {
    WORD: "ACCEPTING",
    ALPHA: "ACCGIP?",
    WILDCARD: "N",
    WILDCARD_INDEX: 7,
    LETTERS: ["T", "E"],
    INDICES: [5, 3],
    HINTS: [6, 2, 0],
  },
  {
    WORD: "COMMITTEE",
    ALPHA: "EEIMOT?",
    WILDCARD: "T",
    WILDCARD_INDEX: 5,
    LETTERS: ["M", "C"],
    INDICES: [2, 0],
    HINTS: [4, 1, 7],
  },
  {
    WORD: "COMMITTED",
    ALPHA: "DEMMTT?",
    WILDCARD: "C",
    WILDCARD_INDEX: 0,
    LETTERS: ["I", "O"],
    INDICES: [4, 1],
    HINTS: [2, 3, 7],
  },
  {
    WORD: "FURNISHED",
    ALPHA: "DFINRU?",
    WILDCARD: "S",
    WILDCARD_INDEX: 5,
    LETTERS: ["E", "H"],
    INDICES: [7, 6],
    HINTS: [2, 0, 1],
  },
  {
    WORD: "DEMOCRATS",
    ALPHA: "ACMORS?",
    WILDCARD: "E",
    WILDCARD_INDEX: 1,
    LETTERS: ["D", "T"],
    INDICES: [0, 7],
    HINTS: [3, 8, 6],
  },
  {
    WORD: "ALTERNATE",
    ALPHA: "AEELRT?",
    WILDCARD: "T",
    WILDCARD_INDEX: 2,
    LETTERS: ["A", "N"],
    INDICES: [0, 5],
    HINTS: [3, 7, 1],
  },
  {
    WORD: "EXEMPTION",
    ALPHA: "EIMNOP?",
    WILDCARD: "E",
    WILDCARD_INDEX: 0,
    LETTERS: ["T", "X"],
    INDICES: [5, 1],
    HINTS: [8, 6, 4],
  },
  {
    WORD: "AEROSPACE",
    ALPHA: "ACEEOP?",
    WILDCARD: "S",
    WILDCARD_INDEX: 4,
    LETTERS: ["A", "R"],
    INDICES: [6, 2],
    HINTS: [0, 3, 1],
  },
  {
    WORD: "COMMUNIST",
    ALPHA: "CMMOST?",
    WILDCARD: "I",
    WILDCARD_INDEX: 6,
    LETTERS: ["N", "U"],
    INDICES: [5, 4],
    HINTS: [8, 7, 3],
  },
  {
    WORD: "SYNTHESIS",
    ALPHA: "EHSSSY?",
    WILDCARD: "N",
    WILDCARD_INDEX: 2,
    LETTERS: ["T", "I"],
    INDICES: [3, 7],
    HINTS: [4, 5, 6],
  },
  {
    WORD: "CIGARETTE",
    ALPHA: "CEIRTT?",
    WILDCARD: "A",
    WILDCARD_INDEX: 3,
    LETTERS: ["E", "G"],
    INDICES: [5, 2],
    HINTS: [8, 1, 7],
  },
  {
    WORD: "COMPONENT",
    ALPHA: "MNNOPT?",
    WILDCARD: "E",
    WILDCARD_INDEX: 6,
    LETTERS: ["C", "O"],
    INDICES: [0, 4],
    HINTS: [7, 2, 8],
  },
  {
    WORD: "CRITERION",
    ALPHA: "CIINRT?",
    WILDCARD: "O",
    WILDCARD_INDEX: 7,
    LETTERS: ["E", "R"],
    INDICES: [4, 5],
    HINTS: [6, 8, 1],
  },
  {
    WORD: "MODERATOR",
    ALPHA: "ADMORT?",
    WILDCARD: "E",
    WILDCARD_INDEX: 3,
    LETTERS: ["O", "R"],
    INDICES: [1, 4],
    HINTS: [7, 0, 6],
  },
  {
    WORD: "DISTRICTS",
    ALPHA: "CIIRST?",
    WILDCARD: "S",
    WILDCARD_INDEX: 2,
    LETTERS: ["D", "T"],
    INDICES: [0, 3],
    HINTS: [7, 5, 6],
  },
  {
    WORD: "PRIVILEGE",
    ALPHA: "EGILPV?",
    WILDCARD: "E",
    WILDCARD_INDEX: 6,
    LETTERS: ["I", "R"],
    INDICES: [4, 1],
    HINTS: [7, 2, 5],
  },
  {
    WORD: "FORMATION",
    ALPHA: "FINOOR?",
    WILDCARD: "M",
    WILDCARD_INDEX: 3,
    LETTERS: ["T", "A"],
    INDICES: [5, 4],
    HINTS: [0, 1, 2],
  },
  {
    WORD: "EXPLAINED",
    ALPHA: "DEILPX?",
    WILDCARD: "A",
    WILDCARD_INDEX: 4,
    LETTERS: ["N", "E"],
    INDICES: [6, 7],
    HINTS: [1, 3, 2],
  },
  {
    WORD: "CATHEDRAL",
    ALPHA: "ADEHLT?",
    WILDCARD: "R",
    WILDCARD_INDEX: 6,
    LETTERS: ["A", "C"],
    INDICES: [7, 0],
    HINTS: [8, 5, 1],
  },
  {
    WORD: "NEWSPAPER",
    ALPHA: "ENPPRS?",
    WILDCARD: "A",
    WILDCARD_INDEX: 5,
    LETTERS: ["W", "E"],
    INDICES: [2, 1],
    HINTS: [7, 4, 6],
  },
  {
    WORD: "OVERNIGHT",
    ALPHA: "HNORTV?",
    WILDCARD: "E",
    WILDCARD_INDEX: 2,
    LETTERS: ["G", "I"],
    INDICES: [6, 5],
    HINTS: [7, 4, 3],
  },
  {
    WORD: "COMMODITY",
    ALPHA: "DIMOTY?",
    WILDCARD: "O",
    WILDCARD_INDEX: 1,
    LETTERS: ["M", "C"],
    INDICES: [3, 0],
    HINTS: [2, 6, 4],
  },
  {
    WORD: "BEGINNING",
    ALPHA: "BEGIIN?",
    WILDCARD: "G",
    WILDCARD_INDEX: 2,
    LETTERS: ["N", "N"],
    INDICES: [7, 4],
    HINTS: [6, 0, 8],
  },
  {
    WORD: "TECHNICAL",
    ALPHA: "ACILNT?",
    WILDCARD: "E",
    WILDCARD_INDEX: 1,
    LETTERS: ["H", "C"],
    INDICES: [3, 6],
    HINTS: [8, 4, 5],
  },
  {
    WORD: "DISTURBED",
    ALPHA: "BDDEIS?",
    WILDCARD: "U",
    WILDCARD_INDEX: 4,
    LETTERS: ["T", "R"],
    INDICES: [3, 5],
    HINTS: [0, 6, 2],
  },
  {
    WORD: "IMMEDIATE",
    ALPHA: "DEIMMT?",
    WILDCARD: "A",
    WILDCARD_INDEX: 6,
    LETTERS: ["E", "I"],
    INDICES: [3, 5],
    HINTS: [0, 7, 8],
  },
  {
    WORD: "MESSENGER",
    ALPHA: "EEGNSS?",
    WILDCARD: "R",
    WILDCARD_INDEX: 8,
    LETTERS: ["E", "M"],
    INDICES: [1, 0],
    HINTS: [2, 5, 6],
  },
  {
    WORD: "FINANCING",
    ALPHA: "AFGIIN?",
    WILDCARD: "N",
    WILDCARD_INDEX: 2,
    LETTERS: ["C", "N"],
    INDICES: [5, 7],
    HINTS: [4, 1, 0],
  },
  {
    WORD: "GREETINGS",
    ALPHA: "EEGINS?",
    WILDCARD: "R",
    WILDCARD_INDEX: 1,
    LETTERS: ["T", "G"],
    INDICES: [4, 7],
    HINTS: [8, 3, 0],
  },
  {
    WORD: "FINGERING",
    ALPHA: "EFGINN?",
    WILDCARD: "R",
    WILDCARD_INDEX: 5,
    LETTERS: ["G", "I"],
    INDICES: [3, 1],
    HINTS: [6, 8, 2],
  },
  {
    WORD: "PRIMARILY",
    ALPHA: "AIIRRY?",
    WILDCARD: "P",
    WILDCARD_INDEX: 0,
    LETTERS: ["L", "M"],
    INDICES: [7, 3],
    HINTS: [6, 2, 8],
  },
  {
    WORD: "SELECTING",
    ALPHA: "CEEGNT?",
    WILDCARD: "I",
    WILDCARD_INDEX: 6,
    LETTERS: ["L", "S"],
    INDICES: [2, 0],
    HINTS: [5, 7, 1],
  },
  {
    WORD: "RESPONDED",
    ALPHA: "DDENPS?",
    WILDCARD: "O",
    WILDCARD_INDEX: 4,
    LETTERS: ["R", "E"],
    INDICES: [0, 1],
    HINTS: [8, 5, 2],
  },
  {
    WORD: "DECREASED",
    ALPHA: "ACDDER?",
    WILDCARD: "E",
    WILDCARD_INDEX: 1,
    LETTERS: ["S", "E"],
    INDICES: [6, 4],
    HINTS: [0, 2, 3],
  },
  {
    WORD: "CLEARANCE",
    ALPHA: "ACCEEN?",
    WILDCARD: "R",
    WILDCARD_INDEX: 4,
    LETTERS: ["L", "A"],
    INDICES: [1, 5],
    HINTS: [0, 7, 3],
  },
  {
    WORD: "OCCASIONS",
    ALPHA: "ACINSS?",
    WILDCARD: "C",
    WILDCARD_INDEX: 1,
    LETTERS: ["O", "O"],
    INDICES: [0, 6],
    HINTS: [8, 5, 7],
  },
  {
    WORD: "COMPOUNDS",
    ALPHA: "CDNOSU?",
    WILDCARD: "P",
    WILDCARD_INDEX: 3,
    LETTERS: ["O", "M"],
    INDICES: [1, 2],
    HINTS: [0, 8, 4],
  },
  {
    WORD: "SLIDESHOW",
    ALPHA: "DILOSS?",
    WILDCARD: "W",
    WILDCARD_INDEX: 8,
    LETTERS: ["E", "H"],
    INDICES: [4, 6],
    HINTS: [5, 3, 1],
  },
  {
    WORD: "PROGRAMME",
    ALPHA: "EMOPRR?",
    WILDCARD: "A",
    WILDCARD_INDEX: 5,
    LETTERS: ["M", "G"],
    INDICES: [7, 3],
    HINTS: [8, 2, 6],
  },
  {
    WORD: "PREDICTED",
    ALPHA: "CDEIPT?",
    WILDCARD: "E",
    WILDCARD_INDEX: 2,
    LETTERS: ["R", "D"],
    INDICES: [1, 3],
    HINTS: [6, 5, 8],
  },
  {
    WORD: "MESSAGING",
    ALPHA: "AGGMNS?",
    WILDCARD: "S",
    WILDCARD_INDEX: 2,
    LETTERS: ["I", "E"],
    INDICES: [6, 1],
    HINTS: [7, 8, 5],
  },
  {
    WORD: "DEDICATED",
    ALPHA: "ADDDEI?",
    WILDCARD: "T",
    WILDCARD_INDEX: 6,
    LETTERS: ["C", "E"],
    INDICES: [4, 1],
    HINTS: [2, 5, 0],
  },
  {
    WORD: "CORRECTLY",
    ALPHA: "CELRTY?",
    WILDCARD: "O",
    WILDCARD_INDEX: 1,
    LETTERS: ["R", "C"],
    INDICES: [2, 5],
    HINTS: [8, 7, 3],
  },
  {
    WORD: "DATABASES",
    ALPHA: "AAESST?",
    WILDCARD: "B",
    WILDCARD_INDEX: 4,
    LETTERS: ["A", "D"],
    INDICES: [1, 0],
    HINTS: [6, 3, 5],
  },
  {
    WORD: "CHAMPIONS",
    ALPHA: "AHMNOS?",
    WILDCARD: "C",
    WILDCARD_INDEX: 0,
    LETTERS: ["P", "I"],
    INDICES: [4, 5],
    HINTS: [1, 6, 7],
  },
  {
    WORD: "CAREFULLY",
    ALPHA: "CEFLRY?",
    WILDCARD: "U",
    WILDCARD_INDEX: 5,
    LETTERS: ["A", "L"],
    INDICES: [1, 6],
    HINTS: [4, 3, 0],
  },
  {
    WORD: "MEANWHILE",
    ALPHA: "EHLMNW?",
    WILDCARD: "E",
    WILDCARD_INDEX: 1,
    LETTERS: ["I", "A"],
    INDICES: [6, 2],
    HINTS: [4, 3, 0],
  },
  {
    WORD: "REFERRALS",
    ALPHA: "AELRRS?",
    WILDCARD: "F",
    WILDCARD_INDEX: 2,
    LETTERS: ["E", "R"],
    INDICES: [1, 4],
    HINTS: [7, 6, 5],
  },
  {
    WORD: "HAPPENING",
    ALPHA: "AGHINP?",
    WILDCARD: "E",
    WILDCARD_INDEX: 4,
    LETTERS: ["P", "N"],
    INDICES: [2, 7],
    HINTS: [3, 5, 0],
  },
  {
    WORD: "RETRIEVED",
    ALPHA: "DEERRT?",
    WILDCARD: "V",
    WILDCARD_INDEX: 6,
    LETTERS: ["E", "I"],
    INDICES: [7, 4],
    HINTS: [8, 1, 3],
  },
  {
    WORD: "SUSPENDED",
    ALPHA: "DDENSS?",
    WILDCARD: "E",
    WILDCARD_INDEX: 4,
    LETTERS: ["U", "P"],
    INDICES: [1, 3],
    HINTS: [2, 5, 7],
  },
  {
    WORD: "CELEBRITY",
    ALPHA: "BCEILY?",
    WILDCARD: "E",
    WILDCARD_INDEX: 1,
    LETTERS: ["T", "R"],
    INDICES: [7, 5],
    HINTS: [8, 0, 4],
  },
  {
    WORD: "BEVERAGES",
    ALPHA: "ABEESV?",
    WILDCARD: "R",
    WILDCARD_INDEX: 4,
    LETTERS: ["E", "G"],
    INDICES: [1, 6],
    HINTS: [8, 0, 2],
  },
  {
    WORD: "SURVIVORS",
    ALPHA: "IORSVV?",
    WILDCARD: "S",
    WILDCARD_INDEX: 0,
    LETTERS: ["U", "R"],
    INDICES: [1, 7],
    HINTS: [5, 8, 4],
  },
  {
    WORD: "SUSPECTED",
    ALPHA: "CDEEPS?",
    WILDCARD: "U",
    WILDCARD_INDEX: 1,
    LETTERS: ["S", "T"],
    INDICES: [2, 6],
    HINTS: [3, 4, 7],
  },
  {
    WORD: "BUILDINGS",
    ALPHA: "IILNSU?",
    WILDCARD: "G",
    WILDCARD_INDEX: 7,
    LETTERS: ["B", "D"],
    INDICES: [0, 4],
    HINTS: [2, 5, 8],
  },
  {
    WORD: "OFFICIALS",
    ALPHA: "CFFILS?",
    WILDCARD: "I",
    WILDCARD_INDEX: 3,
    LETTERS: ["O", "A"],
    INDICES: [0, 6],
    HINTS: [5, 7, 4],
  },
  {
    WORD: "ROOMMATES",
    ALPHA: "AEMMOR?",
    WILDCARD: "S",
    WILDCARD_INDEX: 8,
    LETTERS: ["O", "T"],
    INDICES: [2, 6],
    HINTS: [3, 0, 1],
  },
  {
    WORD: "CAMPAIGNS",
    ALPHA: "AAGMPS?",
    WILDCARD: "N",
    WILDCARD_INDEX: 7,
    LETTERS: ["I", "C"],
    INDICES: [5, 0],
    HINTS: [3, 8, 2],
  },
  {
    WORD: "FUNCTIONS",
    ALPHA: "CFOSTU?",
    WILDCARD: "N",
    WILDCARD_INDEX: 2,
    LETTERS: ["I", "N"],
    INDICES: [5, 7],
    HINTS: [1, 8, 3],
  },
  {
    WORD: "UNDERWEAR",
    ALPHA: "DENRRU?",
    WILDCARD: "E",
    WILDCARD_INDEX: 3,
    LETTERS: ["A", "W"],
    INDICES: [7, 5],
    HINTS: [1, 0, 8],
  },
  {
    WORD: "IMPLEMENT",
    ALPHA: "EIMMPT?",
    WILDCARD: "L",
    WILDCARD_INDEX: 3,
    LETTERS: ["N", "E"],
    INDICES: [7, 6],
    HINTS: [0, 4, 2],
  },
  {
    WORD: "CALCULATE",
    ALPHA: "ACLLTU?",
    WILDCARD: "E",
    WILDCARD_INDEX: 8,
    LETTERS: ["C", "A"],
    INDICES: [3, 1],
    HINTS: [4, 5, 0],
  },
  {
    WORD: "DEVELOPED",
    ALPHA: "DDEEPV?",
    WILDCARD: "L",
    WILDCARD_INDEX: 4,
    LETTERS: ["E", "O"],
    INDICES: [3, 5],
    HINTS: [8, 6, 0],
  },
  {
    WORD: "EXECUTIVE",
    ALPHA: "EEIUVX?",
    WILDCARD: "C",
    WILDCARD_INDEX: 3,
    LETTERS: ["T", "E"],
    INDICES: [5, 0],
    HINTS: [4, 6, 2],
  },
  {
    WORD: "LIVESTOCK",
    ALPHA: "CIKSTV?",
    WILDCARD: "O",
    WILDCARD_INDEX: 6,
    LETTERS: ["L", "E"],
    INDICES: [0, 3],
    HINTS: [5, 7, 1],
  },
  {
    WORD: "SOMETIMES",
    ALPHA: "EEIOST?",
    WILDCARD: "S",
    WILDCARD_INDEX: 0,
    LETTERS: ["M", "M"],
    INDICES: [6, 2],
    HINTS: [5, 3, 8],
  },
  {
    WORD: "NORTHEAST",
    ALPHA: "ANRSTT?",
    WILDCARD: "E",
    WILDCARD_INDEX: 5,
    LETTERS: ["H", "O"],
    INDICES: [4, 1],
    HINTS: [2, 3, 6],
  },
  {
    WORD: "OBVIOUSLY",
    ALPHA: "BIOOSY?",
    WILDCARD: "V",
    WILDCARD_INDEX: 2,
    LETTERS: ["U", "L"],
    INDICES: [5, 7],
    HINTS: [0, 3, 1],
  },
  {
    WORD: "PACKAGING",
    ALPHA: "ACGGKN?",
    WILDCARD: "P",
    WILDCARD_INDEX: 0,
    LETTERS: ["A", "I"],
    INDICES: [1, 6],
    HINTS: [7, 4, 2],
  },
  {
    WORD: "RESERVOIR",
    ALPHA: "ERRRSV?",
    WILDCARD: "O",
    WILDCARD_INDEX: 6,
    LETTERS: ["I", "E"],
    INDICES: [7, 1],
    HINTS: [0, 8, 2],
  },
  {
    WORD: "PROTOCOLS",
    ALPHA: "LOOOPS?",
    WILDCARD: "T",
    WILDCARD_INDEX: 3,
    LETTERS: ["R", "C"],
    INDICES: [1, 5],
    HINTS: [7, 8, 0],
  },
  {
    WORD: "ENHANCING",
    ALPHA: "CEGHIN?",
    WILDCARD: "A",
    WILDCARD_INDEX: 3,
    LETTERS: ["N", "N"],
    INDICES: [7, 1],
    HINTS: [8, 4, 6],
  },
  {
    WORD: "SYMPOSIUM",
    ALPHA: "IMOSUY?",
    WILDCARD: "M",
    WILDCARD_INDEX: 2,
    LETTERS: ["S", "P"],
    INDICES: [5, 3],
    HINTS: [7, 4, 6],
  },
  {
    WORD: "HYDRAULIC",
    ALPHA: "ACILRU?",
    WILDCARD: "Y",
    WILDCARD_INDEX: 1,
    LETTERS: ["D", "H"],
    INDICES: [2, 0],
    HINTS: [8, 5, 4],
  },
  {
    WORD: "QUESTIONS",
    ALPHA: "EINQSU?",
    WILDCARD: "T",
    WILDCARD_INDEX: 4,
    LETTERS: ["S", "O"],
    INDICES: [3, 6],
    HINTS: [7, 0, 5],
  },
  {
    WORD: "PERSONNEL",
    ALPHA: "LNOPRS?",
    WILDCARD: "E",
    WILDCARD_INDEX: 1,
    LETTERS: ["E", "N"],
    INDICES: [7, 6],
    HINTS: [5, 2, 3],
  },
  {
    WORD: "SPECIALLY",
    ALPHA: "AELLSY?",
    WILDCARD: "I",
    WILDCARD_INDEX: 4,
    LETTERS: ["C", "P"],
    INDICES: [3, 1],
    HINTS: [8, 7, 6],
  },
  {
    WORD: "EVOLUTION",
    ALPHA: "ENOOTU?",
    WILDCARD: "L",
    WILDCARD_INDEX: 3,
    LETTERS: ["V", "I"],
    INDICES: [1, 6],
    HINTS: [7, 2, 0],
  },
  {
    WORD: "UTILITIES",
    ALPHA: "EISTTU?",
    WILDCARD: "L",
    WILDCARD_INDEX: 3,
    LETTERS: ["I", "I"],
    INDICES: [4, 2],
    HINTS: [7, 5, 6],
  },
  {
    WORD: "ACCESSORY",
    ALPHA: "ACEOSY?",
    WILDCARD: "R",
    WILDCARD_INDEX: 7,
    LETTERS: ["C", "S"],
    INDICES: [1, 5],
    HINTS: [6, 8, 0],
  },
  {
    WORD: "DIRECTORY",
    ALPHA: "CDIORY?",
    WILDCARD: "R",
    WILDCARD_INDEX: 2,
    LETTERS: ["T", "E"],
    INDICES: [5, 3],
    HINTS: [6, 8, 4],
  },
  {
    WORD: "INJECTION",
    ALPHA: "EINNOT?",
    WILDCARD: "I",
    WILDCARD_INDEX: 0,
    LETTERS: ["C", "J"],
    INDICES: [4, 2],
    HINTS: [5, 1, 6],
  },
  {
    WORD: "PROMOTION",
    ALPHA: "MNOORT?",
    WILDCARD: "P",
    WILDCARD_INDEX: 0,
    LETTERS: ["I", "O"],
    INDICES: [6, 4],
    HINTS: [1, 7, 5],
  },
  {
    WORD: "BRILLIANT",
    ALPHA: "ABILLT?",
    WILDCARD: "I",
    WILDCARD_INDEX: 2,
    LETTERS: ["N", "R"],
    INDICES: [7, 1],
    HINTS: [0, 8, 6],
  },
  {
    WORD: "BREAKDOWN",
    ALPHA: "ADEKNO?",
    WILDCARD: "B",
    WILDCARD_INDEX: 0,
    LETTERS: ["R", "W"],
    INDICES: [1, 7],
    HINTS: [3, 8, 5],
  },
  {
    WORD: "ALUMINIUM",
    ALPHA: "IMMNUU?",
    WILDCARD: "L",
    WILDCARD_INDEX: 1,
    LETTERS: ["I", "A"],
    INDICES: [6, 0],
    HINTS: [5, 3, 7],
  },
  {
    WORD: "AUTHENTIC",
    ALPHA: "ACENTT?",
    WILDCARD: "U",
    WILDCARD_INDEX: 1,
    LETTERS: ["I", "H"],
    INDICES: [7, 3],
    HINTS: [2, 4, 5],
  },
  {
    WORD: "RECIPIENT",
    ALPHA: "CEEPRT?",
    WILDCARD: "N",
    WILDCARD_INDEX: 7,
    LETTERS: ["I", "I"],
    INDICES: [3, 5],
    HINTS: [1, 4, 6],
  },
  {
    WORD: "MOVEMENTS",
    ALPHA: "EMNSTV?",
    WILDCARD: "O",
    WILDCARD_INDEX: 1,
    LETTERS: ["M", "E"],
    INDICES: [4, 3],
    HINTS: [0, 5, 2],
  },
  {
    WORD: "CONTRACTS",
    ALPHA: "ACCSTT?",
    WILDCARD: "R",
    WILDCARD_INDEX: 4,
    LETTERS: ["N", "O"],
    INDICES: [2, 1],
    HINTS: [7, 3, 8],
  },
  {
    WORD: "OFFERINGS",
    ALPHA: "FFGIOS?",
    WILDCARD: "N",
    WILDCARD_INDEX: 6,
    LETTERS: ["E", "R"],
    INDICES: [3, 4],
    HINTS: [2, 1, 0],
  },
  {
    WORD: "SEQUENCES",
    ALPHA: "CEQSSU?",
    WILDCARD: "N",
    WILDCARD_INDEX: 5,
    LETTERS: ["E", "E"],
    INDICES: [4, 1],
    HINTS: [8, 6, 0],
  },
  {
    WORD: "CHRONICLE",
    ALPHA: "CILNOR?",
    WILDCARD: "E",
    WILDCARD_INDEX: 8,
    LETTERS: ["H", "C"],
    INDICES: [1, 6],
    HINTS: [7, 4, 5],
  },
  {
    WORD: "EFFECTIVE",
    ALPHA: "CEEFFT?",
    WILDCARD: "I",
    WILDCARD_INDEX: 6,
    LETTERS: ["E", "V"],
    INDICES: [3, 7],
    HINTS: [0, 2, 4],
  },
  {
    WORD: "EXPLOSION",
    ALPHA: "ELNOOS?",
    WILDCARD: "P",
    WILDCARD_INDEX: 2,
    LETTERS: ["X", "I"],
    INDICES: [1, 6],
    HINTS: [3, 7, 0],
  },
  {
    WORD: "PARAGRAPH",
    ALPHA: "AAHPRR?",
    WILDCARD: "G",
    WILDCARD_INDEX: 4,
    LETTERS: ["P", "A"],
    INDICES: [0, 1],
    HINTS: [3, 7, 8],
  },
  {
    WORD: "POSTCARDS",
    ALPHA: "DOPRST?",
    WILDCARD: "A",
    WILDCARD_INDEX: 5,
    LETTERS: ["S", "C"],
    INDICES: [2, 4],
    HINTS: [0, 7, 8],
  },
  {
    WORD: "INDUCTION",
    ALPHA: "DIINNU?",
    WILDCARD: "T",
    WILDCARD_INDEX: 5,
    LETTERS: ["C", "O"],
    INDICES: [4, 7],
    HINTS: [6, 0, 3],
  },
  {
    WORD: "INDICATED",
    ALPHA: "ACDDIN?",
    WILDCARD: "T",
    WILDCARD_INDEX: 6,
    LETTERS: ["I", "E"],
    INDICES: [3, 7],
    HINTS: [8, 2, 0],
  },
  {
    WORD: "COMPANION",
    ALPHA: "ACNNOP?",
    WILDCARD: "I",
    WILDCARD_INDEX: 6,
    LETTERS: ["O", "M"],
    INDICES: [7, 2],
    HINTS: [3, 4, 8],
  },
  {
    WORD: "FINANCIAL",
    ALPHA: "AFILNN?",
    WILDCARD: "I",
    WILDCARD_INDEX: 1,
    LETTERS: ["C", "A"],
    INDICES: [5, 7],
    HINTS: [3, 8, 2],
  },
  {
    WORD: "CONFIDENT",
    ALPHA: "CDEFNO?",
    WILDCARD: "T",
    WILDCARD_INDEX: 8,
    LETTERS: ["N", "I"],
    INDICES: [2, 4],
    HINTS: [6, 1, 0],
  },
  {
    WORD: "INCIDENTS",
    ALPHA: "EIINNS?",
    WILDCARD: "T",
    WILDCARD_INDEX: 7,
    LETTERS: ["C", "D"],
    INDICES: [2, 4],
    HINTS: [1, 3, 8],
  },
  {
    WORD: "MOLECULAR",
    ALPHA: "ACLMOR?",
    WILDCARD: "U",
    WILDCARD_INDEX: 5,
    LETTERS: ["E", "L"],
    INDICES: [3, 2],
    HINTS: [6, 1, 4],
  },
  {
    WORD: "CAMCORDER",
    ALPHA: "CCEORR?",
    WILDCARD: "D",
    WILDCARD_INDEX: 6,
    LETTERS: ["M", "A"],
    INDICES: [2, 1],
    HINTS: [4, 3, 7],
  },
  {
    WORD: "FEATURING",
    ALPHA: "EFGNRU?",
    WILDCARD: "I",
    WILDCARD_INDEX: 6,
    LETTERS: ["T", "A"],
    INDICES: [3, 2],
    HINTS: [1, 4, 0],
  },
  {
    WORD: "FINISHING",
    ALPHA: "FGHIIS?",
    WILDCARD: "N",
    WILDCARD_INDEX: 2,
    LETTERS: ["I", "N"],
    INDICES: [6, 7],
    HINTS: [1, 8, 4],
  },
  {
    WORD: "MORTGAGES",
    ALPHA: "EGGOST?",
    WILDCARD: "A",
    WILDCARD_INDEX: 5,
    LETTERS: ["M", "R"],
    INDICES: [0, 2],
    HINTS: [8, 3, 6],
  },
  {
    WORD: "PAINTINGS",
    ALPHA: "AIINNP?",
    WILDCARD: "S",
    WILDCARD_INDEX: 8,
    LETTERS: ["G", "T"],
    INDICES: [7, 4],
    HINTS: [6, 3, 1],
  },
  {
    WORD: "SIMILARLY",
    ALPHA: "AILMRY?",
    WILDCARD: "L",
    WILDCARD_INDEX: 4,
    LETTERS: ["S", "I"],
    INDICES: [0, 1],
    HINTS: [7, 8, 3],
  },
  {
    WORD: "APPEARING",
    ALPHA: "AAGPPR?",
    WILDCARD: "N",
    WILDCARD_INDEX: 7,
    LETTERS: ["E", "I"],
    INDICES: [3, 6],
    HINTS: [8, 1, 2],
  },
  {
    WORD: "BATHROOMS",
    ALPHA: "ABHOOT?",
    WILDCARD: "S",
    WILDCARD_INDEX: 8,
    LETTERS: ["M", "R"],
    INDICES: [7, 4],
    HINTS: [5, 0, 3],
  },
  {
    WORD: "THERAPIST",
    ALPHA: "AEHPTT?",
    WILDCARD: "S",
    WILDCARD_INDEX: 7,
    LETTERS: ["R", "I"],
    INDICES: [3, 6],
    HINTS: [4, 2, 5],
  },
  {
    WORD: "UPGRADING",
    ALPHA: "DGIPRU?",
    WILDCARD: "N",
    WILDCARD_INDEX: 7,
    LETTERS: ["G", "A"],
    INDICES: [2, 4],
    HINTS: [0, 3, 5],
  },
  {
    WORD: "LITERALLY",
    ALPHA: "AILLLR?",
    WILDCARD: "Y",
    WILDCARD_INDEX: 8,
    LETTERS: ["E", "T"],
    INDICES: [3, 2],
    HINTS: [7, 4, 0],
  },
  {
    WORD: "RENEWABLE",
    ALPHA: "ABEEEL?",
    WILDCARD: "N",
    WILDCARD_INDEX: 2,
    LETTERS: ["W", "R"],
    INDICES: [4, 0],
    HINTS: [6, 5, 7],
  },
  {
    WORD: "SOLUTIONS",
    ALPHA: "NOOSTU?",
    WILDCARD: "L",
    WILDCARD_INDEX: 2,
    LETTERS: ["I", "S"],
    INDICES: [5, 0],
    HINTS: [7, 6, 1],
  },
  {
    WORD: "EXCLUSION",
    ALPHA: "CENOSX?",
    WILDCARD: "U",
    WILDCARD_INDEX: 4,
    LETTERS: ["I", "L"],
    INDICES: [6, 3],
    HINTS: [0, 2, 8],
  },
  {
    WORD: "INVISIBLE",
    ALPHA: "BEIIIN?",
    WILDCARD: "L",
    WILDCARD_INDEX: 7,
    LETTERS: ["V", "S"],
    INDICES: [2, 4],
    HINTS: [3, 1, 5],
  },
  {
    WORD: "OBTAINING",
    ALPHA: "ABGINT?",
    WILDCARD: "O",
    WILDCARD_INDEX: 0,
    LETTERS: ["I", "N"],
    INDICES: [4, 7],
    HINTS: [3, 1, 8],
  },
  {
    WORD: "SATISFIED",
    ALPHA: "ADEFSS?",
    WILDCARD: "T",
    WILDCARD_INDEX: 2,
    LETTERS: ["I", "I"],
    INDICES: [6, 3],
    HINTS: [4, 1, 0],
  },
  {
    WORD: "CONSTRUCT",
    ALPHA: "CCRTTU?",
    WILDCARD: "N",
    WILDCARD_INDEX: 2,
    LETTERS: ["S", "O"],
    INDICES: [3, 1],
    HINTS: [5, 7, 4],
  },
  {
    WORD: "FRANCHISE",
    ALPHA: "ACEFIS?",
    WILDCARD: "N",
    WILDCARD_INDEX: 3,
    LETTERS: ["H", "R"],
    INDICES: [5, 1],
    HINTS: [0, 8, 7],
  },
  {
    WORD: "PRINCIPLE",
    ALPHA: "CENPPR?",
    WILDCARD: "L",
    WILDCARD_INDEX: 7,
    LETTERS: ["I", "I"],
    INDICES: [5, 2],
    HINTS: [4, 6, 3],
  },
  {
    WORD: "PORCELAIN",
    ALPHA: "CENOPR?",
    WILDCARD: "I",
    WILDCARD_INDEX: 7,
    LETTERS: ["A", "L"],
    INDICES: [6, 5],
    HINTS: [8, 1, 4],
  },
  {
    WORD: "DISCOVERY",
    ALPHA: "DEOSVY?",
    WILDCARD: "C",
    WILDCARD_INDEX: 3,
    LETTERS: ["I", "R"],
    INDICES: [1, 7],
    HINTS: [6, 8, 0],
  },
  {
    WORD: "EQUIPMENT",
    ALPHA: "EMNQTU?",
    WILDCARD: "P",
    WILDCARD_INDEX: 4,
    LETTERS: ["I", "E"],
    INDICES: [3, 0],
    HINTS: [5, 1, 2],
  },
  {
    WORD: "POLITICAL",
    ALPHA: "CILLPT?",
    WILDCARD: "O",
    WILDCARD_INDEX: 1,
    LETTERS: ["I", "A"],
    INDICES: [3, 7],
    HINTS: [4, 8, 6],
  },
  {
    WORD: "ISOLATION",
    ALPHA: "ILNOOS?",
    WILDCARD: "T",
    WILDCARD_INDEX: 5,
    LETTERS: ["A", "I"],
    INDICES: [4, 6],
    HINTS: [2, 1, 0],
  },
  {
    WORD: "INVESTORS",
    ALPHA: "ORSSTV?",
    WILDCARD: "E",
    WILDCARD_INDEX: 3,
    LETTERS: ["I", "N"],
    INDICES: [0, 1],
    HINTS: [6, 4, 7],
  },
  {
    WORD: "GRADUALLY",
    ALPHA: "AADLLY?",
    WILDCARD: "R",
    WILDCARD_INDEX: 1,
    LETTERS: ["U", "G"],
    INDICES: [4, 0],
    HINTS: [2, 7, 3],
  },
  {
    WORD: "CONVINCED",
    ALPHA: "DENNOV?",
    WILDCARD: "C",
    WILDCARD_INDEX: 0,
    LETTERS: ["C", "I"],
    INDICES: [6, 4],
    HINTS: [1, 2, 7],
  },
  {
    WORD: "DISCUSSES",
    ALPHA: "CDSSSS?",
    WILDCARD: "E",
    WILDCARD_INDEX: 7,
    LETTERS: ["I", "U"],
    INDICES: [1, 4],
    HINTS: [0, 8, 5],
  },
  {
    WORD: "TRADITION",
    ALPHA: "ADIIOT?",
    WILDCARD: "N",
    WILDCARD_INDEX: 8,
    LETTERS: ["T", "R"],
    INDICES: [5, 1],
    HINTS: [4, 2, 7],
  },
  {
    WORD: "FRANKFURT",
    ALPHA: "AKNRTU?",
    WILDCARD: "R",
    WILDCARD_INDEX: 1,
    LETTERS: ["F", "F"],
    INDICES: [0, 5],
    HINTS: [6, 8, 7],
  },
  {
    WORD: "PROTOTYPE",
    ALPHA: "OOPRTY?",
    WILDCARD: "E",
    WILDCARD_INDEX: 8,
    LETTERS: ["T", "P"],
    INDICES: [5, 7],
    HINTS: [2, 4, 0],
  },
  {
    WORD: "APPLICANT",
    ALPHA: "ACINPT?",
    WILDCARD: "L",
    WILDCARD_INDEX: 3,
    LETTERS: ["P", "A"],
    INDICES: [1, 6],
    HINTS: [2, 8, 7],
  },
  {
    WORD: "GENERALLY",
    ALPHA: "AEGLLY?",
    WILDCARD: "N",
    WILDCARD_INDEX: 2,
    LETTERS: ["E", "R"],
    INDICES: [1, 4],
    HINTS: [0, 8, 5],
  },
  {
    WORD: "DISCUSSED",
    ALPHA: "CDDISU?",
    WILDCARD: "S",
    WILDCARD_INDEX: 2,
    LETTERS: ["E", "S"],
    INDICES: [7, 5],
    HINTS: [6, 1, 4],
  },
  {
    WORD: "REQUIRING",
    ALPHA: "GIQRRU?",
    WILDCARD: "N",
    WILDCARD_INDEX: 7,
    LETTERS: ["E", "I"],
    INDICES: [1, 4],
    HINTS: [5, 6, 2],
  },
  {
    WORD: "ATTORNEYS",
    ALPHA: "ANORTY?",
    WILDCARD: "S",
    WILDCARD_INDEX: 8,
    LETTERS: ["T", "E"],
    INDICES: [2, 6],
    HINTS: [5, 7, 1],
  },
  {
    WORD: "LIBRARIAN",
    ALPHA: "AALNRR?",
    WILDCARD: "I",
    WILDCARD_INDEX: 1,
    LETTERS: ["B", "I"],
    INDICES: [2, 6],
    HINTS: [0, 7, 8],
  },
  {
    WORD: "PRECEDING",
    ALPHA: "CDEEGR?",
    WILDCARD: "I",
    WILDCARD_INDEX: 6,
    LETTERS: ["P", "N"],
    INDICES: [0, 7],
    HINTS: [8, 1, 2],
  },
  {
    WORD: "INFLATION",
    ALPHA: "FIILNN?",
    WILDCARD: "A",
    WILDCARD_INDEX: 4,
    LETTERS: ["O", "T"],
    INDICES: [7, 5],
    HINTS: [6, 1, 2],
  },
  {
    WORD: "PRACTICES",
    ALPHA: "ACIPRS?",
    WILDCARD: "E",
    WILDCARD_INDEX: 7,
    LETTERS: ["C", "T"],
    INDICES: [6, 4],
    HINTS: [1, 2, 8],
  },
  {
    WORD: "FRAMEWORK",
    ALPHA: "AEMRRW?",
    WILDCARD: "K",
    WILDCARD_INDEX: 8,
    LETTERS: ["F", "O"],
    INDICES: [0, 6],
    HINTS: [2, 5, 4],
  },
  {
    WORD: "COPYRIGHT",
    ALPHA: "CGOPTY?",
    WILDCARD: "I",
    WILDCARD_INDEX: 5,
    LETTERS: ["H", "R"],
    INDICES: [7, 4],
    HINTS: [8, 3, 0],
  },
  {
    WORD: "ADDITIONS",
    ALPHA: "DIINOS?",
    WILDCARD: "T",
    WILDCARD_INDEX: 4,
    LETTERS: ["D", "A"],
    INDICES: [1, 0],
    HINTS: [8, 2, 3],
  },
  {
    WORD: "WORKSHOPS",
    ALPHA: "HOOSSW?",
    WILDCARD: "K",
    WILDCARD_INDEX: 3,
    LETTERS: ["R", "P"],
    INDICES: [2, 7],
    HINTS: [4, 6, 8],
  },
  {
    WORD: "SHOWTIMES",
    ALPHA: "EHIMST?",
    WILDCARD: "O",
    WILDCARD_INDEX: 2,
    LETTERS: ["W", "S"],
    INDICES: [3, 0],
    HINTS: [5, 4, 8],
  },
  {
    WORD: "TECHNIQUE",
    ALPHA: "CEEHQU?",
    WILDCARD: "T",
    WILDCARD_INDEX: 0,
    LETTERS: ["I", "N"],
    INDICES: [5, 4],
    HINTS: [6, 1, 8],
  },
  {
    WORD: "UNIVERSAL",
    ALPHA: "AEIRSU?",
    WILDCARD: "L",
    WILDCARD_INDEX: 8,
    LETTERS: ["N", "V"],
    INDICES: [1, 3],
    HINTS: [4, 7, 2],
  },
  {
    WORD: "PROVIDING",
    ALPHA: "GIIORV?",
    WILDCARD: "N",
    WILDCARD_INDEX: 7,
    LETTERS: ["D", "P"],
    INDICES: [5, 0],
    HINTS: [1, 6, 8],
  },
  {
    WORD: "EXPLORING",
    ALPHA: "GILNPR?",
    WILDCARD: "E",
    WILDCARD_INDEX: 0,
    LETTERS: ["O", "X"],
    INDICES: [4, 1],
    HINTS: [5, 6, 8],
  },
  {
    WORD: "TUTORIALS",
    ALPHA: "IORSTU?",
    WILDCARD: "A",
    WILDCARD_INDEX: 6,
    LETTERS: ["L", "T"],
    INDICES: [7, 2],
    HINTS: [5, 1, 3],
  },
  {
    WORD: "MOLECULES",
    ALPHA: "EELLOS?",
    WILDCARD: "U",
    WILDCARD_INDEX: 5,
    LETTERS: ["C", "M"],
    INDICES: [4, 0],
    HINTS: [8, 7, 1],
  },
  {
    WORD: "ACADEMICS",
    ALPHA: "ACCIMS?",
    WILDCARD: "E",
    WILDCARD_INDEX: 4,
    LETTERS: ["D", "A"],
    INDICES: [3, 0],
    HINTS: [7, 5, 8],
  },
  {
    WORD: "PHOTOSHOP",
    ALPHA: "HOOPPS?",
    WILDCARD: "O",
    WILDCARD_INDEX: 2,
    LETTERS: ["T", "H"],
    INDICES: [3, 1],
    HINTS: [5, 8, 7],
  },
  {
    WORD: "QUARTERLY",
    ALPHA: "AQRTUY?",
    WILDCARD: "R",
    WILDCARD_INDEX: 3,
    LETTERS: ["E", "L"],
    INDICES: [5, 7],
    HINTS: [2, 6, 8],
  },
  {
    WORD: "ALLOCATED",
    ALPHA: "DELLOT?",
    WILDCARD: "C",
    WILDCARD_INDEX: 4,
    LETTERS: ["A", "A"],
    INDICES: [5, 0],
    HINTS: [1, 7, 2],
  },
  {
    WORD: "CORRECTED",
    ALPHA: "CDEORR?",
    WILDCARD: "T",
    WILDCARD_INDEX: 6,
    LETTERS: ["E", "C"],
    INDICES: [7, 5],
    HINTS: [8, 1, 0],
  },
  {
    WORD: "CONTINENT",
    ALPHA: "ENNNTT?",
    WILDCARD: "O",
    WILDCARD_INDEX: 1,
    LETTERS: ["I", "C"],
    INDICES: [4, 0],
    HINTS: [2, 3, 8],
  },
  {
    WORD: "AMENITIES",
    ALPHA: "AEIIMS?",
    WILDCARD: "N",
    WILDCARD_INDEX: 3,
    LETTERS: ["T", "E"],
    INDICES: [5, 7],
    HINTS: [0, 1, 8],
  },
  {
    WORD: "TRANSFORM",
    ALPHA: "AFNORT?",
    WILDCARD: "M",
    WILDCARD_INDEX: 8,
    LETTERS: ["R", "S"],
    INDICES: [7, 4],
    HINTS: [6, 2, 5],
  },
  {
    WORD: "INCLUDING",
    ALPHA: "CDGILU?",
    WILDCARD: "I",
    WILDCARD_INDEX: 0,
    LETTERS: ["N", "N"],
    INDICES: [7, 1],
    HINTS: [8, 5, 4],
  },
  {
    WORD: "SECRETARY",
    ALPHA: "ERRSTY?",
    WILDCARD: "E",
    WILDCARD_INDEX: 1,
    LETTERS: ["C", "A"],
    INDICES: [2, 6],
    HINTS: [8, 3, 7],
  },
  {
    WORD: "ENQUIRIES",
    ALPHA: "EIIQSU?",
    WILDCARD: "R",
    WILDCARD_INDEX: 5,
    LETTERS: ["E", "N"],
    INDICES: [0, 1],
    HINTS: [8, 3, 4],
  },
  {
    WORD: "PERMITTED",
    ALPHA: "DEIMRT?",
    WILDCARD: "E",
    WILDCARD_INDEX: 1,
    LETTERS: ["P", "T"],
    INDICES: [0, 5],
    HINTS: [3, 7, 8],
  },
  {
    WORD: "ENCOURAGE",
    ALPHA: "CEEGOU?",
    WILDCARD: "R",
    WILDCARD_INDEX: 5,
    LETTERS: ["A", "N"],
    INDICES: [6, 1],
    HINTS: [7, 3, 0],
  },
  {
    WORD: "CONDUCTED",
    ALPHA: "CCDDET?",
    WILDCARD: "U",
    WILDCARD_INDEX: 4,
    LETTERS: ["N", "O"],
    INDICES: [2, 1],
    HINTS: [5, 7, 6],
  },
  {
    WORD: "CLASSROOM",
    ALPHA: "ACORSS?",
    WILDCARD: "M",
    WILDCARD_INDEX: 8,
    LETTERS: ["O", "L"],
    INDICES: [7, 1],
    HINTS: [4, 2, 5],
  },
  {
    WORD: "MAGAZINES",
    ALPHA: "AAEGSZ?",
    WILDCARD: "I",
    WILDCARD_INDEX: 5,
    LETTERS: ["M", "N"],
    INDICES: [0, 6],
    HINTS: [1, 4, 3],
  },
  {
    WORD: "SENSITIVE",
    ALPHA: "EEINSV?",
    WILDCARD: "T",
    WILDCARD_INDEX: 5,
    LETTERS: ["I", "S"],
    INDICES: [4, 3],
    HINTS: [1, 6, 7],
  },
  {
    WORD: "SERIOUSLY",
    ALPHA: "ILORUY?",
    WILDCARD: "E",
    WILDCARD_INDEX: 1,
    LETTERS: ["S", "S"],
    INDICES: [6, 0],
    HINTS: [7, 5, 2],
  },
  {
    WORD: "DIFFERENT",
    ALPHA: "DEEFNR?",
    WILDCARD: "T",
    WILDCARD_INDEX: 8,
    LETTERS: ["I", "F"],
    INDICES: [1, 3],
    HINTS: [7, 4, 6],
  },
  {
    WORD: "THOUSANDS",
    ALPHA: "DNOSST?",
    WILDCARD: "U",
    WILDCARD_INDEX: 3,
    LETTERS: ["H", "A"],
    INDICES: [1, 5],
    HINTS: [7, 8, 2],
  },
  {
    WORD: "PARENTING",
    ALPHA: "AINNRT?",
    WILDCARD: "G",
    WILDCARD_INDEX: 8,
    LETTERS: ["P", "E"],
    INDICES: [0, 3],
    HINTS: [2, 5, 6],
  },
  {
    WORD: "SOUTHEAST",
    ALPHA: "AHOSTU?",
    WILDCARD: "E",
    WILDCARD_INDEX: 5,
    LETTERS: ["S", "T"],
    INDICES: [0, 3],
    HINTS: [8, 1, 4],
  },
  {
    WORD: "ANIMATION",
    ALPHA: "AIMNNO?",
    WILDCARD: "T",
    WILDCARD_INDEX: 5,
    LETTERS: ["A", "I"],
    INDICES: [4, 2],
    HINTS: [1, 3, 0],
  },
  {
    WORD: "VARIETIES",
    ALPHA: "AEIRSV?",
    WILDCARD: "T",
    WILDCARD_INDEX: 5,
    LETTERS: ["E", "I"],
    INDICES: [4, 6],
    HINTS: [3, 8, 7],
  },
  {
    WORD: "DISORDERS",
    ALPHA: "DIRRSS?",
    WILDCARD: "O",
    WILDCARD_INDEX: 3,
    LETTERS: ["E", "D"],
    INDICES: [6, 0],
    HINTS: [7, 4, 5],
  },
  {
    WORD: "REFINANCE",
    ALPHA: "AEFNNR?",
    WILDCARD: "C",
    WILDCARD_INDEX: 7,
    LETTERS: ["E", "I"],
    INDICES: [1, 3],
    HINTS: [6, 5, 8],
  },
  {
    WORD: "VIOLATION",
    ALPHA: "AIIOOT?",
    WILDCARD: "N",
    WILDCARD_INDEX: 8,
    LETTERS: ["L", "V"],
    INDICES: [3, 0],
    HINTS: [5, 1, 7],
  },
  {
    WORD: "ORGANISMS",
    ALPHA: "GINOSS?",
    WILDCARD: "R",
    WILDCARD_INDEX: 1,
    LETTERS: ["M", "A"],
    INDICES: [7, 3],
    HINTS: [6, 0, 8],
  },
  {
    WORD: "CANCELLED",
    ALPHA: "ACDEEL?",
    WILDCARD: "N",
    WILDCARD_INDEX: 2,
    LETTERS: ["C", "L"],
    INDICES: [3, 6],
    HINTS: [5, 1, 8],
  },
  {
    WORD: "DIFFICULT",
    ALPHA: "DFILTU?",
    WILDCARD: "I",
    WILDCARD_INDEX: 1,
    LETTERS: ["C", "F"],
    INDICES: [5, 2],
    HINTS: [3, 4, 6],
  },
  {
    WORD: "PAPERBACK",
    ALPHA: "AAEKPP?",
    WILDCARD: "R",
    WILDCARD_INDEX: 4,
    LETTERS: ["B", "C"],
    INDICES: [5, 7],
    HINTS: [2, 1, 0],
  },
  {
    WORD: "ALLOWANCE",
    ALPHA: "AELNOW?",
    WILDCARD: "A",
    WILDCARD_INDEX: 0,
    LETTERS: ["L", "C"],
    INDICES: [2, 7],
    HINTS: [6, 8, 4],
  },
  {
    WORD: "AMPLIFIER",
    ALPHA: "EFIILR?",
    WILDCARD: "M",
    WILDCARD_INDEX: 1,
    LETTERS: ["P", "A"],
    INDICES: [2, 0],
    HINTS: [3, 6, 7],
  },
  {
    WORD: "ESTABLISH",
    ALPHA: "BHILST?",
    WILDCARD: "A",
    WILDCARD_INDEX: 3,
    LETTERS: ["S", "E"],
    INDICES: [7, 0],
    HINTS: [6, 1, 2],
  },
  {
    WORD: "RELEVANCE",
    ALPHA: "CEEELR?",
    WILDCARD: "A",
    WILDCARD_INDEX: 5,
    LETTERS: ["N", "V"],
    INDICES: [6, 4],
    HINTS: [1, 7, 2],
  },
  {
    WORD: "EXPANSION",
    ALPHA: "EINNSX?",
    WILDCARD: "O",
    WILDCARD_INDEX: 7,
    LETTERS: ["P", "A"],
    INDICES: [2, 3],
    HINTS: [5, 6, 4],
  },
  {
    WORD: "PROFESSOR",
    ALPHA: "EFOOPR?",
    WILDCARD: "S",
    WILDCARD_INDEX: 5,
    LETTERS: ["R", "S"],
    INDICES: [1, 6],
    HINTS: [3, 8, 2],
  },
  {
    WORD: "CONNECTOR",
    ALPHA: "CCNNOT?",
    WILDCARD: "R",
    WILDCARD_INDEX: 8,
    LETTERS: ["E", "O"],
    INDICES: [4, 1],
    HINTS: [3, 6, 7],
  },
  {
    WORD: "FANTASTIC",
    ALPHA: "CFINTT?",
    WILDCARD: "S",
    WILDCARD_INDEX: 5,
    LETTERS: ["A", "A"],
    INDICES: [4, 1],
    HINTS: [3, 6, 0],
  },
  {
    WORD: "DELIVERED",
    ALPHA: "DDEERV?",
    WILDCARD: "L",
    WILDCARD_INDEX: 2,
    LETTERS: ["E", "I"],
    INDICES: [5, 3],
    HINTS: [0, 4, 7],
  },
  {
    WORD: "EXPERTISE",
    ALPHA: "EEEIPS?",
    WILDCARD: "T",
    WILDCARD_INDEX: 5,
    LETTERS: ["R", "X"],
    INDICES: [4, 1],
    HINTS: [3, 7, 0],
  },
  {
    WORD: "LONGITUDE",
    ALPHA: "EGILNT?",
    WILDCARD: "U",
    WILDCARD_INDEX: 6,
    LETTERS: ["D", "O"],
    INDICES: [7, 1],
    HINTS: [2, 8, 0],
  },
  {
    WORD: "INTENSITY",
    ALPHA: "INSTTY?",
    WILDCARD: "E",
    WILDCARD_INDEX: 3,
    LETTERS: ["N", "I"],
    INDICES: [4, 6],
    HINTS: [8, 1, 2],
  },
  {
    WORD: "QUALIFIED",
    ALPHA: "DEFIQU?",
    WILDCARD: "L",
    WILDCARD_INDEX: 3,
    LETTERS: ["I", "A"],
    INDICES: [6, 2],
    HINTS: [4, 8, 0],
  },
  {
    WORD: "CONCERNED",
    ALPHA: "CDENNO?",
    WILDCARD: "E",
    WILDCARD_INDEX: 4,
    LETTERS: ["C", "R"],
    INDICES: [0, 5],
    HINTS: [2, 7, 3],
  },
  {
    WORD: "STRENGTHS",
    ALPHA: "HRSSTT?",
    WILDCARD: "G",
    WILDCARD_INDEX: 5,
    LETTERS: ["E", "N"],
    INDICES: [3, 4],
    HINTS: [2, 7, 0],
  },
  {
    WORD: "AUTOMATIC",
    ALPHA: "AAIOTU?",
    WILDCARD: "C",
    WILDCARD_INDEX: 8,
    LETTERS: ["T", "M"],
    INDICES: [2, 4],
    HINTS: [0, 6, 5],
  },
  {
    WORD: "MARIJUANA",
    ALPHA: "AAJNRU?",
    WILDCARD: "I",
    WILDCARD_INDEX: 3,
    LETTERS: ["A", "M"],
    INDICES: [6, 0],
    HINTS: [7, 4, 5],
  },
  {
    WORD: "TRANSPORT",
    ALPHA: "ANRSTT?",
    WILDCARD: "O",
    WILDCARD_INDEX: 6,
    LETTERS: ["P", "R"],
    INDICES: [5, 7],
    HINTS: [2, 0, 4],
  },
  {
    WORD: "TRAVELING",
    ALPHA: "AEGLNT?",
    WILDCARD: "I",
    WILDCARD_INDEX: 6,
    LETTERS: ["R", "V"],
    INDICES: [1, 3],
    HINTS: [0, 4, 8],
  },
  {
    WORD: "ABILITIES",
    ALPHA: "ABILST?",
    WILDCARD: "E",
    WILDCARD_INDEX: 7,
    LETTERS: ["I", "I"],
    INDICES: [2, 6],
    HINTS: [1, 5, 8],
  },
  {
    WORD: "ACCESSING",
    ALPHA: "CEGNSS?",
    WILDCARD: "A",
    WILDCARD_INDEX: 0,
    LETTERS: ["I", "C"],
    INDICES: [6, 2],
    HINTS: [8, 3, 5],
  },
  {
    WORD: "UNDERTAKE",
    ALPHA: "DEKNRT?",
    WILDCARD: "A",
    WILDCARD_INDEX: 6,
    LETTERS: ["E", "U"],
    INDICES: [3, 0],
    HINTS: [2, 1, 5],
  },
  {
    WORD: "PASSWORDS",
    ALPHA: "APRSSS?",
    WILDCARD: "W",
    WILDCARD_INDEX: 4,
    LETTERS: ["O", "D"],
    INDICES: [5, 7],
    HINTS: [1, 0, 3],
  },
  {
    WORD: "RECYCLING",
    ALPHA: "CEILRY?",
    WILDCARD: "G",
    WILDCARD_INDEX: 8,
    LETTERS: ["C", "N"],
    INDICES: [4, 7],
    HINTS: [5, 0, 2],
  },
  {
    WORD: "SPIRITUAL",
    ALPHA: "AIILPU?",
    WILDCARD: "S",
    WILDCARD_INDEX: 0,
    LETTERS: ["T", "R"],
    INDICES: [5, 3],
    HINTS: [1, 7, 4],
  },
  {
    WORD: "DEVIATION",
    ALPHA: "ADEIIN?",
    WILDCARD: "T",
    WILDCARD_INDEX: 5,
    LETTERS: ["O", "V"],
    INDICES: [7, 2],
    HINTS: [8, 4, 6],
  },
  {
    WORD: "REVISIONS",
    ALPHA: "EINRSV?",
    WILDCARD: "O",
    WILDCARD_INDEX: 6,
    LETTERS: ["I", "S"],
    INDICES: [3, 4],
    HINTS: [0, 2, 7],
  },
  {
    WORD: "DETERMINE",
    ALPHA: "DEEIMN?",
    WILDCARD: "R",
    WILDCARD_INDEX: 4,
    LETTERS: ["T", "E"],
    INDICES: [2, 1],
    HINTS: [3, 7, 6],
  },
  {
    WORD: "MERCHANTS",
    ALPHA: "ACMNST?",
    WILDCARD: "E",
    WILDCARD_INDEX: 1,
    LETTERS: ["R", "H"],
    INDICES: [2, 4],
    HINTS: [7, 5, 6],
  },
  {
    WORD: "COMMENTED",
    ALPHA: "CDMMNT?",
    WILDCARD: "E",
    WILDCARD_INDEX: 4,
    LETTERS: ["O", "E"],
    INDICES: [1, 7],
    HINTS: [3, 2, 5],
  },
  {
    WORD: "EXTENDING",
    ALPHA: "DEGITX?",
    WILDCARD: "E",
    WILDCARD_INDEX: 0,
    LETTERS: ["N", "N"],
    INDICES: [7, 4],
    HINTS: [5, 3, 6],
  },
  {
    WORD: "INTENSIVE",
    ALPHA: "EEIISV?",
    WILDCARD: "T",
    WILDCARD_INDEX: 2,
    LETTERS: ["N", "N"],
    INDICES: [1, 4],
    HINTS: [5, 3, 8],
  },
  {
    WORD: "INVENTION",
    ALPHA: "IINNTV?",
    WILDCARD: "E",
    WILDCARD_INDEX: 3,
    LETTERS: ["N", "O"],
    INDICES: [1, 7],
    HINTS: [0, 5, 4],
  },
  {
    WORD: "MICROWAVE",
    ALPHA: "CEIRVW?",
    WILDCARD: "M",
    WILDCARD_INDEX: 0,
    LETTERS: ["O", "A"],
    INDICES: [4, 6],
    HINTS: [8, 2, 7],
  },
  {
    WORD: "STRUCTURE",
    ALPHA: "CERTTU?",
    WILDCARD: "S",
    WILDCARD_INDEX: 0,
    LETTERS: ["U", "R"],
    INDICES: [6, 2],
    HINTS: [8, 5, 7],
  },
  {
    WORD: "ELEVATION",
    ALPHA: "ELNOTV?",
    WILDCARD: "I",
    WILDCARD_INDEX: 6,
    LETTERS: ["E", "A"],
    INDICES: [2, 4],
    HINTS: [7, 3, 1],
  },
  {
    WORD: "CERTAINLY",
    ALPHA: "AEILRY?",
    WILDCARD: "T",
    WILDCARD_INDEX: 3,
    LETTERS: ["N", "C"],
    INDICES: [6, 0],
    HINTS: [7, 5, 1],
  },
  {
    WORD: "EXECUTION",
    ALPHA: "CEINUX?",
    WILDCARD: "E",
    WILDCARD_INDEX: 0,
    LETTERS: ["T", "O"],
    INDICES: [5, 7],
    HINTS: [3, 4, 6],
  },
  {
    WORD: "SCRIPTING",
    ALPHA: "CGIIRS?",
    WILDCARD: "P",
    WILDCARD_INDEX: 4,
    LETTERS: ["T", "N"],
    INDICES: [5, 7],
    HINTS: [8, 2, 1],
  },
  {
    WORD: "PROCESSES",
    ALPHA: "CEESSS?",
    WILDCARD: "R",
    WILDCARD_INDEX: 1,
    LETTERS: ["O", "P"],
    INDICES: [2, 0],
    HINTS: [8, 7, 4],
  },
  {
    WORD: "SUGGESTED",
    ALPHA: "DEGSTU?",
    WILDCARD: "E",
    WILDCARD_INDEX: 4,
    LETTERS: ["G", "S"],
    INDICES: [3, 5],
    HINTS: [7, 6, 8],
  },
  {
    WORD: "JEWELLERY",
    ALPHA: "EJLLRY?",
    WILDCARD: "E",
    WILDCARD_INDEX: 1,
    LETTERS: ["W", "E"],
    INDICES: [2, 6],
    HINTS: [7, 3, 4],
  },
  {
    WORD: "BATTERIES",
    ALPHA: "AEISTT?",
    WILDCARD: "R",
    WILDCARD_INDEX: 5,
    LETTERS: ["B", "E"],
    INDICES: [0, 7],
    HINTS: [1, 3, 8],
  },
  {
    WORD: "RADIATION",
    ALPHA: "AADINO?",
    WILDCARD: "T",
    WILDCARD_INDEX: 5,
    LETTERS: ["R", "I"],
    INDICES: [0, 6],
    HINTS: [4, 8, 2],
  },
  {
    WORD: "WEBMASTER",
    ALPHA: "ABERTW?",
    WILDCARD: "S",
    WILDCARD_INDEX: 5,
    LETTERS: ["M", "E"],
    INDICES: [3, 7],
    HINTS: [1, 0, 6],
  },
  {
    WORD: "SPOKESMAN",
    ALPHA: "KMNOSS?",
    WILDCARD: "E",
    WILDCARD_INDEX: 4,
    LETTERS: ["A", "P"],
    INDICES: [7, 1],
    HINTS: [0, 6, 8],
  },
  {
    WORD: "INHERITED",
    ALPHA: "EEHINR?",
    WILDCARD: "D",
    WILDCARD_INDEX: 8,
    LETTERS: ["T", "I"],
    INDICES: [6, 0],
    HINTS: [5, 3, 1],
  },
];

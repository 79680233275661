export const alphaLayout = {
  default: [
    "q w e r t y u i o p",
    "a s d f g h j k l",
    "{enter} z x c v b n m {backspace}",
  ],
};

export const alphaDisplay = {
  "{enter}": "Enter",
  "{backspace}": "Del",
  q: "Q",
  w: "W",
  e: "E",
  r: "R",
  t: "T",
  y: "Y",
  u: "U",
  i: "I",
  o: "O",
  p: "P",
  a: "A",
  s: "S",
  d: "D",
  f: "F",
  g: "G",
  h: "H",
  j: "J",
  k: "K",
  l: "L",
  z: "Z",
  x: "X",
  c: "C",
  v: "V",
  b: "B",
  n: "N",
  m: "M",
};
